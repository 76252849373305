import React from 'react';
import { useSelector } from 'react-redux';
// import profileImg from '../images/profile.jpg';

function TrainerProfile(props) {

    const getTrainerProfie = useSelector(state => state.counterSlice.trainerData)
    
    // console.log("first", getTrainerProfie)
    // console.log("first99", getTrainerProfie.trainer && getTrainerProfie.trainer.firstname)

  return (
    <div className="modal fade" id={props.id} tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-lg">
            <div className="modal-content">
            <div className="modal-header">
                <h1 className="modal-title fs-5" id="exampleModalLabel">Trainer Profile</h1>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
                <div className="card">
                    <div className="card-body">
                        <table className='table table-bordered table-striped rounded-2'>
                            <tbody>
                                <tr>
                                    <th>Name</th>
                                    <td>{getTrainerProfie.trainer_details && getTrainerProfie.trainer_details.firstname} {getTrainerProfie.trainer_details && getTrainerProfie.trainer_details.lastname}</td>
                                </tr>
                                <tr>
                                    <th>Mobile</th>
                                    <td>{getTrainerProfie.trainer_details && getTrainerProfie.trainer_details.mobile}</td>
                                </tr>
                                <tr>
                                    <th>Shift Start</th>
                                    <td>{getTrainerProfie.trainer_details && getTrainerProfie.trainer_details.starttime}</td>
                                </tr>
                                <tr>
                                    <th>Shift End</th>
                                    <td>{getTrainerProfie.trainer_details && getTrainerProfie.trainer_details.endtime}</td>
                                </tr>
                            </tbody>
                        </table>
                        {/* <div className='d-flex justify-content-between'>
                            <img src={profileImg} alt="img" className='w-25 rounded-circle' />
                            <div>
                                <h4>Name:</h4>
                                      <p>{getTrainerProfie.trainer && getTrainerProfie.trainer.firstname} {getTrainerProfie.trainer && getTrainerProfie.trainer.lastname}</p>
                                <h4>Mobile:</h4>
                                      <p>{getTrainerProfie.trainer && getTrainerProfie.trainer.mobile}</p>
                                <h4>Trainer Experience:</h4>
                                      <p>{getTrainerProfie.trainer && getTrainerProfie.trainer.experience}</p>
                            </div>
                            <div>
                                <h4>Shift Start:</h4>
                                      <p>{getTrainerProfie.trainer && getTrainerProfie.trainer.starttime}</p>
                                <h4>Shift End:</h4>
                                      <p>{getTrainerProfie.trainer && getTrainerProfie.trainer.endtime}</p>
                            </div>
                            <div>
                            </div>
                        </div> */}
                    </div>
                </div>
            </div>
            <div className="modal-footer">
                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
            </div>
            </div>
        </div>
    </div>
  )
}

export default TrainerProfile