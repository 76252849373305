import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import ActivatePlanModel from "./Trainers/ActivatePlanModel";
import Loading from "./Loading";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import ActivateAddonsModal from "./Trainers/ActivateAddonsModal";
import Modal from 'react-bootstrap/Modal';

function UserDetails() {
  const getUserProfile = useSelector((state) => state.counterSlice.userDetails);
  const [showPlanModel, setShowPlanModel] = useState(false);
  const [showAddModal, setShowAddModal] = useState(false);
  const [showRemain, setShowRemain] = useState(false);
  const [qrModel, setQrModel] = useState(false);
  const [addonsValue, setAddonsValue] = useState("");
  const [subDuration, setSubDuration] = useState("");
  const [memberID, setMemberID] = useState("");
  const [staffID, setStaffID] = useState("");
  const [commonId, setCommonId] = useState("");
  const [remainAmount, setRemainAmount] = useState(0);
  const [memberDetails, setMemberDetails] = useState({});
  // console.log('user details', getUserProfile);
  const [transactionId, setTransactionId] = useState("");
  const [qrData, setQrData] = useState("");
  const options = { year: "numeric", month: "long", day: "numeric" };
  const [loading, setLoading] = useState(false);
  const [selectedOption, setSelectedOption] = useState('fullpayemnt');
  const [totalAmountDue, setTotalAmountDue] = useState(0);
  const [totalAmountPaid, setTotalAmountPaid] = useState(0);
  const [traData, setTraData] = useState([]);

  const getGymId = useSelector((state) => state.counterSlice.gymId);
  // console.log("the token is ", getGymId)
  const deviceToken = getGymId.device_token;
  // console.log(deviceToken);

  const config = {
    headers: {
      Authorization: deviceToken,
    },
  };

  const loadScript = (src) => {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  };


  const fetchQrCode = async () => {
    try {
      const qrcode = `${process.env.REACT_APP_URL}getGymAdminQR`;
      const getCode = await axios.get(qrcode, config);
      setQrData(getCode?.data?.data?.qr_image);
    }
    catch (error) {
      console.log('Error fetching duration data:', error);
    }
  }

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);

  const fetchMemberDetails = async () => {
    const res = await axios.get(
      `${process.env.REACT_APP_URL}getAllUserById/${getUserProfile?.member_id}`,
      config
    );
    if (res?.status === 200) {
      setMemberDetails(res?.data.data);
      setTraData(res?.data?.data?.payment)
    }
  };
  // useEffect(() => {
  //     fetchMemberDetails();
  // })

  useEffect(() => {
    if (getUserProfile?.member_id) {
      fetchMemberDetails();
    }
  }, [getUserProfile?.member_id]);

  const handleActivatePlan = () => {
    setShowPlanModel(true);
  };

  const handleAddonsPlan = (value) => {
    setShowAddModal(true);
    setAddonsValue(value);
    setSubDuration(memberDetails?.subscription_addon_details?.duration);
    setMemberID(memberDetails?.subscription_addon_details?.member_id);
    setStaffID(memberDetails?.subscription_addon_details?.staff_id);
    setCommonId(memberDetails?.subscription_addon_details?.common_id);
  };

  const success_state = {
    order_id: "",
    payment_status: 1,
    payment_id: "",
    signature: "",
  };

  const handlePayBalance = async () => {
    const Res = await loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );
    if (!Res) {
      alert("Razorpay SDK failed to load. Are you online?");
      return;
    }
    // const amount = res?.data.paydata.amount;
    const options = {
      key: memberDetails?.rpay_key,
      name: "GYMApplication",
      description: "Test Transaction",
      order_id: memberDetails?.payment?.order_id,
      // order_id: 'order_Lepy9UPxcYNYXD',
      // callback_url: 'https://eneqd3r9zrjok.x.pipedream.net/',
      handler: function (response) {
        success_state.order_id = response.razorpay_order_id;
        success_state.payment_id = response.razorpay_payment_id;
        success_state.signature = response.razorpay_signature;
        // alert(response.razorpay_payment_id);
        // alert(response.razorpay_order_id);
        // alert(response.razorpay_signature);
        // console.log(response);
        if (response) {
          const suc_data = { ...success_state };
          axios
            .post(`${process.env.REACT_APP_URL}update_payment`, suc_data)
            .then((resp) => {
              if (resp?.status === 200) {
                toast.success(resp?.data.message, {
                  position: "top-right",
                  autoClose: 3000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: "light",
                });
              }
              // console.log(resp);
            })
            .catch((err) => {
              console.log(err);
            });
        }
      },
      prefill: {
        name: memberDetails?.firstname,
        contact: memberDetails?.mobile,
      },
      theme: {
        color: "#3399cc",
      },
    };

    const paymentObject = new window.Razorpay(options);
    paymentObject.open();
    paymentObject.on("payment.failed", function (response) {
      alert(response.error.code);
      alert(response.error.description);
      alert(response.error.source);
      alert(response.error.step);
      alert(response.error.reason);
      alert(response.error.metadata.order_id);
      alert(response.error.metadata.payment_id);
    });
  };

  const handleRemain = (ammount) => {
    setRemainAmount(ammount);
    setShowRemain(true);
    fetchQrCode();
  }
  useEffect(() => {
    calculateTotals();
  }, [traData])

  const calculateTotals = () => {
    let due = 0;
    let paid = 0;
// console.log("traData", traData)
    traData.forEach(transaction => {
      due += transaction.amount_due;
      paid += transaction.amount_paid;
    });

    setTotalAmountDue(due);
    setTotalAmountPaid(paid);
  };
// console.log("due", )
// console.log("paid", )
  const handleTransaction = async (e) => {
    e.preventDefault();
    let trans = {
      member_id: getUserProfile?.member_id,
      transaction_id: transactionId,
      amount_paid: totalAmountDue
    }
    try {
      const transaction = `${process.env.REACT_APP_URL}addRemainingTransactionId `;
      const res = await axios.post(transaction, trans, config,);
      if (res?.status === 200) {
        toast.success(res?.data.message)
        fetchMemberDetails();
        setShowRemain(false);
      }
    } catch (error) {
      console.error("Error fetching addons data: ", error);
    }

  }


  return (
    <>
      {loading ? (
        <Loading loading={loading} />
      ) : (
        <div className="container-fluid py-sm-5 py-md-5 py-lg-5 py-xl-5 py-xxl-5 px-5">
          <div className="row mt-5">
            <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
              <h3 className="">User Subscription Details</h3>
            </div>
            <div className="col-sm-6">
              <button
                type="button"
                className="btn btn-outline-primary float-end"
                onClick={() => handleAddonsPlan("Zumba")}
              >
                ADD Add-Ons
              </button>
            </div>
          </div>

          <div className="row mt-3 min-vh-100">
            <div className="col-sm-12 col-md-4 col-xl-3 mb-4">
              <div className="card h-100">
                <h3 className="card-header">Activate Plan Details</h3>
                <div className="card-body">
                  {memberDetails === null ||
                    memberDetails?.subscription_addon_details === null ? (
                    <>
                      <h2>No Plan Active</h2>
                    </>
                  ) : (
                    <>
                      <table className="table table-bordered table-striped rounded-2">
                        <tbody>
                          {/* <tr>
                                                <th>Name</th>
                                                <td>{memberDetails && memberDetails?.firstname} {memberDetails && memberDetails?.lastname}</td>
                                            </tr> */}
                          <tr>
                            <th>Plan Start</th>
                            <td>
                              {memberDetails?.subscription_addon_details &&
                                new Date(
                                  memberDetails?.subscription_addon_details?.startdate
                                ).toLocaleDateString("en-US", options)}
                            </td>
                          </tr>
                          <tr>
                            <th>Plan Expiry</th>
                            <td>
                              {memberDetails?.subscription_addon_details &&
                                new Date(
                                  memberDetails?.subscription_addon_details?.enddate
                                ).toLocaleDateString("en-US", options)}
                            </td>
                          </tr>
                          <tr>
                            <th>Active Plan</th>
                            <td>
                              {memberDetails?.subscription_details &&
                                memberDetails?.subscription_details
                                  ?.duration}{" "}
                              {memberDetails?.subscription_details &&
                                memberDetails?.subscription_details?.type}
                            </td>
                          </tr>
                          <tr>
                            <th>Plan Fees</th>
                            <td>
                              &#8377;
                              {memberDetails?.subscription_details &&
                                memberDetails?.subscription_details
                                  ?.subsriberfees}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </>
                  )}
                </div>
                <div className="card-footer">
                  <button
                    type="button"
                    className="btn btn-outline-primary float-end"
                    disabled={memberDetails?.payment?.status === "paid"}
                    onClick={() => handleActivatePlan()}
                  >
                    Activate Plan
                  </button>
                </div>
              </div>
            </div>

            {memberDetails === null ? (
              <></>
            ) : (
              <>
                {memberDetails?.trainer_addon_details != null && (
                  <div className="col-sm-12 col-md-4 col-xl-3 mb-4">
                    <div className="card h-100">
                      <h3 className="card-header">Trainer Details</h3>
                      <div className="card-body">
                        {memberDetails === null ||
                          memberDetails?.trainer_addon_details === null ? (
                          <>
                            <h2>No Trainer Active</h2>
                          </>
                        ) : (
                          <>
                            <table className="table table-bordered table-striped rounded-2">
                              <tbody>
                                <tr>
                                  <th>Name</th>
                                  <td>
                                    {memberDetails?.trainer_addon_details &&
                                      memberDetails?.trainer_addon_details
                                        ?.trainer_firstname}{" "}
                                    {memberDetails?.trainer_addon_details &&
                                      memberDetails?.trainer_addon_details?.trainer_lastname}
                                  </td>
                                </tr>
                                <tr>
                                  <th>Trainer Plan Start</th>
                                  <td>
                                    {memberDetails?.trainer_addon_details &&
                                      new Date(
                                        memberDetails?.trainer_addon_details?.startdate
                                      ).toLocaleDateString("en-US", options)}
                                  </td>
                                </tr>
                                <tr>
                                  <th>Trainer Plan Expiry</th>
                                  <td>
                                    {memberDetails?.trainer_addon_details &&
                                      new Date(
                                        memberDetails?.trainer_addon_details?.enddate
                                      ).toLocaleDateString("en-US", options)}
                                  </td>
                                </tr>
                                <tr>
                                  <th>Trainer Active</th>
                                  <td>
                                    {memberDetails?.trainer_addon_details &&
                                      memberDetails?.trainer_addon_details
                                        ?.duration}{" "}
                                    Days
                                  </td>
                                </tr>
                                <tr>
                                  <th>Trainer Type</th>
                                  <td>
                                    {memberDetails?.trainer_addon_details &&
                                      memberDetails?.trainer_addon_details
                                        ?.type}
                                  </td>
                                </tr>
                                <tr>
                                  <th>Trainer Fees</th>
                                  <td>
                                    &#8377;
                                    {memberDetails?.trainer_addon_details &&
                                      Math.ceil(
                                        memberDetails?.trainer_addon_details
                                          ?.fees
                                      )}
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </>
                        )}
                      </div>
                      {/* <div className='card-footer'>
                                <button type='button' className='btn btn-outline-primary float-end' disabled={memberDetails?.trainer_addon_details != null || memberDetails?.payment?.status === 'attempted'} onClick={() => handleAddonsPlan('Trainer')}>Activate Trainer</button>
                            </div> */}
                    </div>
                  </div>
                )}

                {memberDetails?.locker_addon_details != null && (
                  <div className="col-sm-12 col-md-4 col-xl-3 mb-4">
                    <div className="card h-100">
                      <h3 className="card-header">Locker Details</h3>
                      <div className="card-body">
                        {memberDetails === null ||
                          memberDetails?.locker_addon_details === null ? (
                          <>
                            <h2>No Locker Active</h2>
                          </>
                        ) : (
                          <>
                            <table className="table table-bordered table-striped rounded-2">
                              <tbody>
                                {/* <tr>
                                  <th>Locker</th>
                                  <td>
                                    {memberDetails?.locker_details &&
                                      memberDetails?.locker_details?.locker_no}
                                  </td>
                                </tr> */}
                                <tr>
                                  <th>Locker Plan Start</th>
                                  <td>
                                    {memberDetails?.locker_addon_details &&
                                      new Date(
                                        memberDetails?.locker_addon_details?.startdate
                                      ).toLocaleDateString("en-US", options)}
                                  </td>
                                </tr>
                                <tr>
                                  <th>Locker Plan Expiry</th>
                                  <td>
                                    {memberDetails?.locker_addon_details &&
                                      new Date(
                                        memberDetails?.locker_addon_details?.enddate
                                      ).toLocaleDateString("en-US", options)}
                                  </td>
                                </tr>
                                <tr>
                                  <th>Locker Active</th>
                                  <td>
                                    {memberDetails?.locker_addon_details &&
                                      memberDetails?.locker_addon_details
                                        ?.duration}{" "}
                                    Days
                                  </td>
                                </tr>
                                <tr>
                                  <th>Locker Fees</th>
                                  <td>
                                    &#8377;
                                    {memberDetails?.locker_addon_details &&
                                      memberDetails?.locker_addon_details?.fees}
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </>
                        )}
                      </div>
                      <div className="card-footer">
                        <button
                          type="button"
                          className="btn btn-outline-primary float-end"
                          disabled={
                            memberDetails?.locker_addon_details != null ||
                            memberDetails?.payment?.status === "attempted"
                          }
                          onClick={() => handleAddonsPlan("Locker")}
                        >
                          Activate Add-Ons
                        </button>
                      </div>
                    </div>
                  </div>
                )}
                {/* -------------------boxing addon ------------------- */}

                {memberDetails?.boxing_addon_details != null && (
                  <div className="col-sm-12 col-md-4 col-xl-3 mb-4">
                    <div className="card h-100">
                      <h3 className="card-header">Boxing Add-Ons Details</h3>
                      <div className="card-body">
                        {memberDetails === null ||
                          memberDetails?.boxing_addon_details === null ? (
                          <>
                            <h2>No Locker Active</h2>
                          </>
                        ) : (
                          <>
                            <table className="table table-bordered table-striped rounded-2">
                              <tbody>
                                <tr>
                                  <th>Addon ID</th>
                                  <td>
                                    {
                                      memberDetails?.boxing_addon_details
                                        ?.addon_id
                                    }
                                  </td>
                                </tr>
                                <tr>
                                  <th>Boxing Plan Start</th>
                                  <td>
                                    {memberDetails?.boxing_addon_details &&
                                      new Date(
                                        memberDetails?.boxing_addon_details?.startdate
                                      ).toLocaleDateString("en-US", options)}
                                  </td>
                                </tr>
                                <tr>
                                  <th>Boxing Plan Expiry</th>
                                  <td>
                                    {memberDetails?.boxing_addon_details &&
                                      new Date(
                                        memberDetails?.boxing_addon_details?.enddate
                                      ).toLocaleDateString("en-US", options)}
                                  </td>
                                </tr>
                                <tr>
                                  <th>Boxing Active</th>
                                  <td>
                                    {memberDetails?.boxing_addon_details &&
                                      memberDetails?.boxing_addon_details
                                        ?.duration}{" "}
                                    Days
                                  </td>
                                </tr>
                                <tr>
                                  <th>Boxing Fees</th>
                                  <td>
                                    &#8377;
                                    {memberDetails?.boxing_addon_details &&
                                      memberDetails?.boxing_addon_details?.fees}
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </>
                        )}
                      </div>
                      {/* <div className='card-footer'>
                                    <button type='button' className='btn btn-outline-primary float-end' disabled={memberDetails?.boxing_addon_details != null || memberDetails?.payment?.status === 'attempted'} onClick={() => handleAddonsPlan('Locker')}>Activate Add-Ons</button>
                                </div> */}
                    </div>
                  </div>
                )}
                {/* ----------------Yoga Addon detail ----------- */}

                {memberDetails?.yoga_addon_details != null && (
                  <div className="col-sm-12 col-md-4 col-xl-3 mb-4">
                    <div className="card h-100">
                      <h3 className="card-header">Yoga Add-Ons Details</h3>
                      <div className="card-body">
                        {memberDetails === null ||
                          memberDetails?.yoga_addon_details === null ? (
                          <>
                            <h2>No Yoga Addon Active</h2>
                          </>
                        ) : (
                          <>
                            <table className="table table-bordered table-striped rounded-2">
                              <tbody>
                                <tr>
                                  <th>Addon ID</th>
                                  <td>
                                    {
                                      memberDetails?.yoga_addon_details
                                        ?.addon_id
                                    }
                                  </td>
                                </tr>
                                <tr>
                                  <th>Yoga Plan Start</th>
                                  <td>
                                    {memberDetails?.yoga_addon_details &&
                                      new Date(
                                        memberDetails?.yoga_addon_details?.startdate
                                      ).toLocaleDateString("en-US", options)}
                                  </td>
                                </tr>
                                <tr>
                                  <th>Yoga Plan Expiry</th>
                                  <td>
                                    {memberDetails?.yoga_addon_details &&
                                      new Date(
                                        memberDetails?.yoga_addon_details?.enddate
                                      ).toLocaleDateString("en-US", options)}
                                  </td>
                                </tr>
                                <tr>
                                  <th>Yoga Active</th>
                                  <td>
                                    {memberDetails?.yoga_addon_details &&
                                      memberDetails?.yoga_addon_details
                                        ?.duration}{" "}
                                    Days
                                  </td>
                                </tr>
                                <tr>
                                  <th>Yoga Fees</th>
                                  <td>
                                    &#8377;
                                    {memberDetails?.yoga_addon_details &&
                                      memberDetails?.yoga_addon_details?.fees}
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </>
                        )}
                      </div>
                      {/* <div className='card-footer'>
                                    <button type='button' className='btn btn-outline-primary float-end' disabled={memberDetails?.yoga_addon_details != null || memberDetails?.payment?.status === 'attempted'} onClick={() => handleAddonsPlan('Yoga')}>Activate Add-Ons</button>
                                </div> */}
                    </div>
                  </div>
                )}
                {/* -------- steam Addon ------------------ */}
                {memberDetails?.steam_addon_details != null && (
                  <div className="col-sm-12 col-md-4 col-xl-3 mb-4">
                    <div className="card h-100">
                      <h3 className="card-header">Steam Add-Ons Details</h3>
                      <div className="card-body">
                        {memberDetails === null ||
                          memberDetails?.steam_addon_details === null ? (
                          <>
                            <h2>No Steam Active</h2>
                          </>
                        ) : (
                          <>
                            <table className="table table-bordered table-striped rounded-2">
                              <tbody>
                                <tr>
                                  <th>Addon ID</th>
                                  <td>
                                    {
                                      memberDetails?.steam_addon_details
                                        ?.addon_id
                                    }
                                  </td>
                                </tr>
                                <tr>
                                  <th>Steam Plan Start</th>
                                  <td>
                                    {memberDetails?.steam_addon_details &&
                                      new Date(
                                        memberDetails?.steam_addon_details?.startdate
                                      ).toLocaleDateString("en-US", options)}
                                  </td>
                                </tr>
                                <tr>
                                  <th>Steam Plan Expiry</th>
                                  <td>
                                    {memberDetails?.steam_addon_details &&
                                      new Date(
                                        memberDetails?.steam_addon_details?.enddate
                                      ).toLocaleDateString("en-US", options)}
                                  </td>
                                </tr>
                                <tr>
                                  <th>Steam Active</th>
                                  <td>
                                    {memberDetails?.steam_addon_details &&
                                      memberDetails?.steam_addon_details
                                        ?.duration}{" "}
                                    Days
                                  </td>
                                </tr>
                                <tr>
                                  <th>Steam Fees</th>
                                  <td>
                                    &#8377;
                                    {memberDetails?.steam_addon_details &&
                                      memberDetails?.steam_addon_details?.fees}
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </>
                        )}
                      </div>
                      {/* <div className='card-footer'>
                                    <button type='button' className='btn btn-outline-primary float-end' disabled={memberDetails?.steam_addon_details != null || memberDetails?.payment?.status === 'attempted'} onClick={() => handleAddonsPlan('Steam')}>Activate Add-Ons</button>
                                </div> */}
                    </div>
                  </div>
                )}

                {/* ---------------zumba----------------- */}
                {memberDetails?.zumba_addon_details != null && (
                  <div className="col-sm-12 col-md-4 col-xl-3 mb-4">
                    <div className="card h-100">
                      <h3 className="card-header">Zumba Add-Ons Details</h3>
                      <div className="card-body">
                        {memberDetails === null ||
                          memberDetails?.zumba_addon_details === null ? (
                          <>
                            <h2>No Zumba Active</h2>
                          </>
                        ) : (
                          <>
                            <table className="table table-bordered table-striped rounded-2">
                              <tbody>
                                <tr>
                                  <th>Zumba ID</th>
                                  <td>
                                    {
                                      memberDetails?.zumba_addon_details
                                        ?.addon_id
                                    }
                                  </td>
                                </tr>
                                <tr>
                                  <th>Zumba Plan Start</th>
                                  <td>
                                    {memberDetails?.zumba_addon_details &&
                                      new Date(
                                        memberDetails?.zumba_addon_details?.startdate
                                      ).toLocaleDateString("en-US", options)}
                                  </td>
                                </tr>
                                <tr>
                                  <th>Zumba Plan Expiry</th>
                                  <td>
                                    {memberDetails?.zumba_addon_details &&
                                      new Date(
                                        memberDetails?.zumba_addon_details?.enddate
                                      ).toLocaleDateString("en-US", options)}
                                  </td>
                                </tr>
                                <tr>
                                  <th>Zumba Active</th>
                                  <td>
                                    {memberDetails?.zumba_addon_details &&
                                      memberDetails?.zumba_addon_details
                                        ?.duration}{" "}
                                    Days
                                  </td>
                                </tr>
                                <tr>
                                  <th>Zumba Fees</th>
                                  <td>
                                    &#8377;
                                    {memberDetails?.zumba_addon_details &&
                                      memberDetails?.zumba_addon_details?.fees}
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </>
                        )}
                      </div>
                      {/* <div className='card-footer'>
                                    <button type='button' className='btn btn-outline-primary float-end' disabled={memberDetails?.zumba_addon_details != null || memberDetails?.payment?.status === 'attempted'} onClick={() => handleAddonsPlan('Zumba')}>Activate Add-Ons</button>
                                </div> */}
                    </div>
                  </div>
                )}

                {/* ----------------dance----------------- */}
                {memberDetails?.dance_addon_details != null && (
                  <div className="col-sm-12 col-md-4 col-xl-3 mb-4">
                    <div className="card h-100">
                      <h3 className="card-header">Dance Add-Ons Details</h3>
                      <div className="card-body">
                        {memberDetails === null ||
                          memberDetails?.dance_addon_details === null ? (
                          <>
                            <h2>No Dance Active</h2>
                          </>
                        ) : (
                          <>
                            <table className="table table-bordered table-striped rounded-2">
                              <tbody>
                                <tr>
                                  <th>Dance ID</th>
                                  <td>
                                    {
                                      memberDetails?.dance_addon_details
                                        ?.addon_id
                                    }
                                  </td>
                                </tr>
                                <tr>
                                  <th>Dance Plan Start</th>
                                  <td>
                                    {memberDetails?.dance_addon_details &&
                                      new Date(
                                        memberDetails?.dance_addon_details?.startdate
                                      ).toLocaleDateString("en-US", options)}
                                  </td>
                                </tr>
                                <tr>
                                  <th>Dance Plan Expiry</th>
                                  <td>
                                    {memberDetails?.dance_addon_details &&
                                      new Date(
                                        memberDetails?.dance_addon_details?.enddate
                                      ).toLocaleDateString("en-US", options)}
                                  </td>
                                </tr>
                                <tr>
                                  <th>Dance Active</th>
                                  <td>
                                    {memberDetails?.dance_addon_details &&
                                      memberDetails?.dance_addon_details
                                        ?.duration}{" "}
                                    Days
                                  </td>
                                </tr>
                                <tr>
                                  <th>Dance Fees</th>
                                  <td>
                                    &#8377;
                                    {memberDetails?.dance_addon_details &&
                                      memberDetails?.dance_addon_details?.fees}
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </>
                        )}
                      </div>
                      {/* <div className='card-footer'>
                                    <button type='button' className='btn btn-outline-primary float-end' disabled={memberDetails?.dance_addon_details != null || memberDetails?.payment?.status === 'attempted'} onClick={() => handleAddonsPlan('Dance')}>Activate Add-Ons</button>
                                </div> */}
                    </div>
                  </div>
                )}

                {/* --------------------crossfit------------------------- */}
                {memberDetails?.crossfit_addon_details != null && (
                  <div className="col-sm-12 col-md-4 col-xl-3 mb-4">
                    <div className="card h-100">
                      <h3 className="card-header">Crossfit Add-Ons Details</h3>
                      <div className="card-body">
                        {memberDetails === null ||
                          memberDetails?.crossfit_addon_details === null ? (
                          <>
                            <h2>No Crossfit Active</h2>
                          </>
                        ) : (
                          <>
                            <table className="table table-bordered table-striped rounded-2">
                              <tbody>
                                <tr>
                                  <th>Crossfit ID</th>
                                  <td>
                                    {
                                      memberDetails?.crossfit_addon_details
                                        ?.addon_id
                                    }
                                  </td>
                                </tr>
                                <tr>
                                  <th>Crossfit Plan Start</th>
                                  <td>
                                    {memberDetails?.crossfit_addon_details &&
                                      new Date(
                                        memberDetails?.crossfit_addon_details?.startdate
                                      ).toLocaleDateString("en-US", options)}
                                  </td>
                                </tr>
                                <tr>
                                  <th>Crossfit Plan Expiry</th>
                                  <td>
                                    {memberDetails?.crossfit_addon_details &&
                                      new Date(
                                        memberDetails?.crossfit_addon_details?.enddate
                                      ).toLocaleDateString("en-US", options)}
                                  </td>
                                </tr>
                                <tr>
                                  <th>Crossfit Active</th>
                                  <td>
                                    {memberDetails?.crossfit_addon_details &&
                                      memberDetails?.crossfit_addon_details
                                        ?.duration}{" "}
                                    Days
                                  </td>
                                </tr>
                                <tr>
                                  <th>Crossfit Fees</th>
                                  <td>
                                    &#8377;
                                    {memberDetails?.crossfit_addon_details &&
                                      memberDetails?.crossfit_addon_details
                                        ?.fees}
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </>
                        )}
                      </div>
                      {/* <div className='card-footer'>
                                    <button type='button' className='btn btn-outline-primary float-end' disabled={memberDetails?.crossfit_addon_details != null || memberDetails?.payment?.status === 'attempted'} onClick={() => handleAddonsPlan('Crossfit')}>Activate Add-Ons</button>
                                </div> */}
                    </div>
                  </div>
                )}

                {/* -------------------Cycling----------------- */}
                {memberDetails?.cycling_addon_details != null && (
                  <div className="col-sm-12 col-md-4 col-xl-3 mb-4">
                    <div className="card h-100">
                      <h3 className="card-header">Cycling Add-Ons Details</h3>
                      <div className="card-body">
                        {memberDetails === null ||
                          memberDetails?.cycling_addon_details === null ? (
                          <>
                            <h2>No Cycling Active</h2>
                          </>
                        ) : (
                          <>
                            <table className="table table-bordered table-striped rounded-2">
                              <tbody>
                                <tr>
                                  <th>Cycling ID</th>
                                  <td>
                                    {
                                      memberDetails?.cycling_addon_details
                                        ?.addon_id
                                    }
                                  </td>
                                </tr>
                                <tr>
                                  <th>Cycling Plan Start</th>
                                  <td>
                                    {memberDetails?.cycling_addon_details &&
                                      new Date(
                                        memberDetails?.cycling_addon_details?.startdate
                                      ).toLocaleDateString("en-US", options)}
                                  </td>
                                </tr>
                                <tr>
                                  <th>Cycling Plan Expiry</th>
                                  <td>
                                    {memberDetails?.cycling_addon_details &&
                                      new Date(
                                        memberDetails?.cycling_addon_details?.enddate
                                      ).toLocaleDateString("en-US", options)}
                                  </td>
                                </tr>
                                <tr>
                                  <th>Cycling Active</th>
                                  <td>
                                    {memberDetails?.cycling_addon_details &&
                                      memberDetails?.cycling_addon_details
                                        ?.duration}{" "}
                                    Days
                                  </td>
                                </tr>
                                <tr>
                                  <th>Cycling Fees</th>
                                  <td>
                                    &#8377;
                                    {memberDetails?.cycling_addon_details &&
                                      memberDetails?.cycling_addon_details
                                        ?.fees}
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </>
                        )}
                      </div>
                      {/* <div className='card-footer'>
                                    <button type='button' className='btn btn-outline-primary float-end' disabled={memberDetails?.cycling_addon_details != null || memberDetails?.payment?.status === 'attempted'} onClick={() => handleAddonsPlan('Dance')}>Activate Add-Ons</button>
                                </div> */}
                    </div>
                  </div>
                )}

                {/* ----------------- functional training------------------ */}
                {memberDetails?.functional_training_addon_details != null && (
                  <div className="col-sm-12 col-md-4 col-xl-3 mb-4">
                    <div className="card h-100">
                      <h3 className="card-header">
                        Functional Training Add-Ons Details
                      </h3>
                      <div className="card-body">
                        {memberDetails === null ||
                          memberDetails?.functional_training_addon_details ===
                          null ? (
                          <>
                            <h2>No Functional Training Active</h2>
                          </>
                        ) : (
                          <>
                            <table className="table table-bordered table-striped rounded-2">
                              <tbody>
                                <tr>
                                  <th>Functional Training ID</th>
                                  <td>
                                    {
                                      memberDetails
                                        ?.functional_training_addon_details
                                        ?.addon_id
                                    }
                                  </td>
                                </tr>
                                <tr>
                                  <th>Functional Training Plan Start</th>
                                  <td>
                                    {memberDetails?.functional_training_addon_details &&
                                      new Date(
                                        memberDetails?.functional_training_addon_details?.startdate
                                      ).toLocaleDateString("en-US", options)}
                                  </td>
                                </tr>
                                <tr>
                                  <th>Functional Training Plan Expiry</th>
                                  <td>
                                    {memberDetails?.functional_training_addon_details &&
                                      new Date(
                                        memberDetails?.functional_training_addon_details?.enddate
                                      ).toLocaleDateString("en-US", options)}
                                  </td>
                                </tr>
                                <tr>
                                  <th>Functional Training Active</th>
                                  <td>
                                    {memberDetails?.functional_training_addon_details &&
                                      memberDetails
                                        ?.functional_training_addon_details
                                        ?.duration}{" "}
                                    Days
                                  </td>
                                </tr>
                                <tr>
                                  <th>Functional Training Fees</th>
                                  <td>
                                    &#8377;
                                    {memberDetails?.functional_training_addon_details &&
                                      memberDetails
                                        ?.functional_training_addon_details
                                        ?.fees}
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </>
                        )}
                      </div>
                      {/* <div className='card-footer'>
                                    <button type='button' className='btn btn-outline-primary float-end' disabled={memberDetails?.functional_training_addon_details != null || memberDetails?.payment?.status === 'attempted'} onClick={() => handleAddonsPlan('Dance')}>Activate Add-Ons</button>
                                </div> */}
                    </div>
                  </div>
                )}
                {/* ----------------------massage------------------ */}
                {memberDetails?.massage_addon_details != null && (
                  <div className="col-sm-12 col-md-4 col-xl-3 mb-4">
                    <div className="card h-100">
                      <h3 className="card-header">Massage Add-Ons Details</h3>
                      <div className="card-body">
                        {memberDetails === null ||
                          memberDetails?.massage_addon_details === null ? (
                          <>
                            <h2>No Massage Active</h2>
                          </>
                        ) : (
                          <>
                            <table className="table table-bordered table-striped rounded-2">
                              <tbody>
                                <tr>
                                  <th>Massage Training ID</th>
                                  <td>
                                    {
                                      memberDetails?.massage_addon_details
                                        ?.addon_id
                                    }
                                  </td>
                                </tr>
                                <tr>
                                  <th>Massage Plan Start</th>
                                  <td>
                                    {memberDetails?.massage_addon_details &&
                                      new Date(
                                        memberDetails?.massage_addon_details?.startdate
                                      ).toLocaleDateString("en-US", options)}
                                  </td>
                                </tr>
                                <tr>
                                  <th>Massage Plan Expiry</th>
                                  <td>
                                    {memberDetails?.massage_addon_details &&
                                      new Date(
                                        memberDetails?.massage_addon_details?.enddate
                                      ).toLocaleDateString("en-US", options)}
                                  </td>
                                </tr>
                                <tr>
                                  <th>Massage Active</th>
                                  <td>
                                    {memberDetails?.massage_addon_details &&
                                      memberDetails?.massage_addon_details
                                        ?.duration}{" "}
                                    Days
                                  </td>
                                </tr>
                                <tr>
                                  <th>Massage Fees</th>
                                  <td>
                                    &#8377;
                                    {memberDetails?.massage_addon_details &&
                                      memberDetails?.massage_addon_details
                                        ?.fees}
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </>
                        )}
                      </div>
                      {/* <div className='card-footer'>
                                    <button type='button' className='btn btn-outline-primary float-end' disabled={memberDetails?.massage_addon_details != null || memberDetails?.payment?.status === 'attempted'} onClick={() => handleAddonsPlan('Dance')}>Activate Add-Ons</button>
                                </div> */}
                    </div>
                  </div>
                )}

                {/* ----------------------Nutrition Consulting----------------- */}
                {memberDetails?.nutrition_consulting_addon_details != null && (
                  <div className="col-sm-12 col-md-4 col-xl-3 mb-4">
                    <div className="card h-100">
                      <h3 className="card-header">
                        Nutrition Consulting Add-Ons Details
                      </h3>
                      <div className="card-body">
                        {memberDetails === null ||
                          memberDetails?.nutrition_consulting_addon_details ===
                          null ? (
                          <>
                            <h2>No Nutrition Consulting Active</h2>
                          </>
                        ) : (
                          <>
                            <table className="table table-bordered table-striped rounded-2">
                              <tbody>
                                <tr>
                                  <th>Nutrition Consulting ID</th>
                                  <td>
                                    {
                                      memberDetails
                                        ?.nutrition_consulting_addon_details
                                        ?.addon_id
                                    }
                                  </td>
                                </tr>
                                <tr>
                                  <th>Nutrition Consulting Plan Start</th>
                                  <td>
                                    {memberDetails?.nutrition_consulting_addon_details &&
                                      new Date(
                                        memberDetails?.nutrition_consulting_addon_details?.startdate
                                      ).toLocaleDateString("en-US", options)}
                                  </td>
                                </tr>
                                <tr>
                                  <th>Nutrition Consulting Plan Expiry</th>
                                  <td>
                                    {memberDetails?.nutrition_consulting_addon_details &&
                                      new Date(
                                        memberDetails?.nutrition_consulting_addon_details?.enddate
                                      ).toLocaleDateString("en-US", options)}
                                  </td>
                                </tr>
                                <tr>
                                  <th>Nutrition Consulting Active</th>
                                  <td>
                                    {memberDetails?.nutrition_consulting_addon_details &&
                                      memberDetails
                                        ?.nutrition_consulting_addon_details
                                        ?.duration}{" "}
                                    Days
                                  </td>
                                </tr>
                                <tr>
                                  <th>Nutrition Consulting Fees</th>
                                  <td>
                                    &#8377;
                                    {memberDetails?.nutrition_consulting_addon_details &&
                                      memberDetails
                                        ?.nutrition_consulting_addon_details
                                        ?.fees}
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </>
                        )}
                      </div>
                      {/* <div className='card-footer'>
                                    <button type='button' className='btn btn-outline-primary float-end' disabled={memberDetails?.nutrition_consulting_addon_details != null || memberDetails?.payment?.status === 'attempted'} onClick={() => handleAddonsPlan('Dance')}>Activate Add-Ons</button>
                                </div> */}
                    </div>
                  </div>
                )}

                {/* ------------------ weight trainig ------------------ */}
                {memberDetails?.weight_training_addon_details != null && (
                  <div className="col-sm-12 col-md-4 col-xl-3 mb-4">
                    <div className="card h-100">
                      <h3 className="card-header">
                        Weigth Training Add-Ons Details
                      </h3>
                      <div className="card-body">
                        {memberDetails === null ||
                          memberDetails?.weight_training_addon_details ===
                          null ? (
                          <>
                            <h2>No Weigth Training Active</h2>
                          </>
                        ) : (
                          <>
                            <table className="table table-bordered table-striped rounded-2">
                              <tbody>
                                <tr>
                                  <th>Weigth Training ID</th>
                                  <td>
                                    {
                                      memberDetails
                                        ?.weight_training_addon_details
                                        ?.addon_id
                                    }
                                  </td>
                                </tr>
                                <tr>
                                  <th>Weigth Training Plan Start</th>
                                  <td>
                                    {memberDetails?.weight_training_addon_details &&
                                      new Date(
                                        memberDetails?.weight_training_addon_details?.startdate
                                      ).toLocaleDateString("en-US", options)}
                                  </td>
                                </tr>
                                <tr>
                                  <th>Weigth Training Plan Expiry</th>
                                  <td>
                                    {memberDetails?.weight_training_addon_details &&
                                      new Date(
                                        memberDetails?.weight_training_addon_details?.enddate
                                      ).toLocaleDateString("en-US", options)}
                                  </td>
                                </tr>
                                <tr>
                                  <th>Weigth Training Active</th>
                                  <td>
                                    {memberDetails?.weight_training_addon_details &&
                                      memberDetails
                                        ?.weight_training_addon_details
                                        ?.duration}{" "}
                                    Days
                                  </td>
                                </tr>
                                <tr>
                                  <th>Weigth Training Fees</th>
                                  <td>
                                    &#8377;
                                    {memberDetails?.weight_training_addon_details &&
                                      memberDetails
                                        ?.weight_training_addon_details?.fees}
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </>
                        )}
                      </div>
                      {/* <div className='card-footer'>
                                    <button type='button' className='btn btn-outline-primary float-end' disabled={memberDetails?.weight_training_addon_details != null || memberDetails?.payment?.status === 'attempted'} onClick={() => handleAddonsPlan('Dance')}>Activate Add-Ons</button>
                                </div> */}
                    </div>
                  </div>
                )}
                {/* ------------------ hiit exercixe---------------- */}
                {memberDetails?.hiit_excercise_classes_details != null && (
                  <div className="col-sm-12 col-md-4 col-xl-3 mb-4">
                    <div className="card h-100">
                      <h3 className="card-header">
                        Hiit Excercise Classes Add-Ons Details
                      </h3>
                      <div className="card-body">
                        {memberDetails === null ||
                          memberDetails?.hiit_excercise_classes_details ===
                          null ? (
                          <>
                            <h2>No Hiit Excercise Classes Active</h2>
                          </>
                        ) : (
                          <>
                            <table className="table table-bordered table-striped rounded-2">
                              <tbody>
                                <tr>
                                  <th>Hiit Excercise Classes ID</th>
                                  <td>
                                    {
                                      memberDetails
                                        ?.hiit_excercise_classes_details
                                        ?.addon_id
                                    }
                                  </td>
                                </tr>
                                <tr>
                                  <th>Hiit Excercise Classes Plan Start</th>
                                  <td>
                                    {memberDetails?.hiit_excercise_classes_details &&
                                      new Date(
                                        memberDetails?.hiit_excercise_classes_details?.startdate
                                      ).toLocaleDateString("en-US", options)}
                                  </td>
                                </tr>
                                <tr>
                                  <th>Hiit Excercise Classes Plan Expiry</th>
                                  <td>
                                    {memberDetails?.hiit_excercise_classes_details &&
                                      new Date(
                                        memberDetails?.hiit_excercise_classes_details?.enddate
                                      ).toLocaleDateString("en-US", options)}
                                  </td>
                                </tr>
                                <tr>
                                  <th>Hiit Excercise Classes Active</th>
                                  <td>
                                    {memberDetails?.hiit_excercise_classes_details &&
                                      memberDetails
                                        ?.hiit_excercise_classes_details
                                        ?.duration}{" "}
                                    Days
                                  </td>
                                </tr>
                                <tr>
                                  <th>Hiit Excercise Classes Fees</th>
                                  <td>
                                    &#8377;
                                    {memberDetails?.hiit_excercise_classes_details &&
                                      memberDetails
                                        ?.hiit_excercise_classes_details?.fees}
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </>
                        )}
                      </div>
                      {/* <div className='card-footer'>
                                    <button type='button' className='btn btn-outline-primary float-end' disabled={memberDetails?.hiit_excercise_classes_details != null || memberDetails?.payment?.status === 'attempted'} onClick={() => handleAddonsPlan('Dance')}>Activate Add-Ons</button>
                                </div> */}
                    </div>
                  </div>
                )}

                {/* ------------------------------- fitness studio------------- */}
                {memberDetails?.fitness_studio_addon_details != null && (
                  <div className="col-sm-12 col-md-4 col-xl-3 mb-4">
                    <div className="card h-100">
                      <h3 className="card-header">
                        Fitness Studio Add-Ons Details
                      </h3>
                      <div className="card-body">
                        {memberDetails === null ||
                          memberDetails?.fitness_studio_addon_details === null ? (
                          <>
                            <h2>No Fitness Studio Active</h2>
                          </>
                        ) : (
                          <>
                            <table className="table table-bordered table-striped rounded-2">
                              <tbody>
                                <tr>
                                  <th>Fitness Studio ID</th>
                                  <td>
                                    {
                                      memberDetails
                                        ?.fitness_studio_addon_details?.addon_id
                                    }
                                  </td>
                                </tr>
                                <tr>
                                  <th>Fitness Studio Plan Start</th>
                                  <td>
                                    {memberDetails?.fitness_studio_addon_details &&
                                      new Date(
                                        memberDetails?.fitness_studio_addon_details?.startdate
                                      ).toLocaleDateString("en-US", options)}
                                  </td>
                                </tr>
                                <tr>
                                  <th>Fitness Studio Plan Expiry</th>
                                  <td>
                                    {memberDetails?.fitness_studio_addon_details &&
                                      new Date(
                                        memberDetails?.fitness_studio_addon_details?.enddate
                                      ).toLocaleDateString("en-US", options)}
                                  </td>
                                </tr>
                                <tr>
                                  <th>Fitness Studio Active</th>
                                  <td>
                                    {memberDetails?.fitness_studio_addon_details &&
                                      memberDetails
                                        ?.fitness_studio_addon_details
                                        ?.duration}{" "}
                                    Days
                                  </td>
                                </tr>
                                <tr>
                                  <th>Fitness Studio Fees</th>
                                  <td>
                                    &#8377;
                                    {memberDetails?.fitness_studio_addon_details &&
                                      memberDetails
                                        ?.fitness_studio_addon_details?.fees}
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </>
                        )}
                      </div>
                      {/* <div className='card-footer'>
                                    <button type='button' className='btn btn-outline-primary float-end' disabled={memberDetails?.fitness_studio_addon_details != null || memberDetails?.payment?.status === 'attempted'} onClick={() => handleAddonsPlan('Dance')}>Activate Add-Ons</button>
                                </div> */}
                    </div>
                  </div>
                )}

                {/* <div className='col-sm-12 col-md-4 col-xl-3 mb-4'>
                            <div className='card'>
                                <h3 className='card-header'>Add-Ons Details</h3>
                                <div className='card-body'>
                                    {
                                        memberDetails === null || memberDetails?.upgrade_addon_details === null ?
                                        <>
                                            <h2>No Upgrade Active</h2>
                                        </>
                                        :
                                        <>
                                        <table className='table table-bordered table-striped rounded-2'>
                                            <tbody>
                                                <tr>
                                                    <th>Upgrade</th>
                                                    <td>{memberDetails?.upgrade_addon_details && memberDetails?.upgrade_addon_details?.addon_type === 'Upgrade' ? 'Upgrade Active' : 'No Upgrade'} </td>
                                                </tr>
                                                <tr>
                                                    <th>Upgrade Plan Start</th>
                                                    <td>{memberDetails?.upgrade_addon_details && new Date(memberDetails?.upgrade_addon_details?.startdate).toLocaleDateString('en-US', options)}</td>
                                                </tr>
                                                <tr>
                                                    <th>Upgrade Plan Expiry</th>
                                                    <td>{memberDetails?.upgrade_addon_details && new Date(memberDetails?.upgrade_addon_details?.enddate).toLocaleDateString('en-US', options)}</td>
                                                </tr>
                                                <tr>
                                                    <th>Upgrade Active</th>
                                                    <td>{memberDetails?.upgrade_addon_details && memberDetails?.upgrade_addon_details?.duration} Days</td>
                                                </tr>
                                                <tr>
                                                    <th>Upgrade Fees</th>
                                                    <td>&#8377;{memberDetails?.upgrade_addon_details && memberDetails?.upgrade_addon_details?.fees}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        </>
                                    }
                                    
                                </div>
                                <div className='card-footer'>
                                    <button type='button' className='btn btn-outline-primary float-end' disabled={memberDetails?.upgrade_addon_details != null || memberDetails?.payment?.status === 'attempted'} onClick={() => handleAddonsPlan('Upgrade')}>Activate Add-Ons</button>
                                </div>
                            </div>
                        </div>     */}
              </>
            )}
          </div>

          {memberDetails === null ? (
            <></>
          ) : (
            <>
              <div className="row py-5">
                <div className="col-sm-12 col-md-4 col-xl-3 mb-4">
                  <div className="card h-100">
                    <h3 className="card-header">Total Amount</h3>
                    <div className="card-body">
                      <table className="table table-bordered table-striped rounded-2">
                        <tbody>
                          <tr>
                            <th>Amount Paid</th>
                            <td>
                              &#8377;
                              {/* {memberDetails?.payment &&
                                Math.ceil(memberDetails?.payment?.amount_paid)} */}
                                {totalAmountPaid}
                            </td>
                          </tr>
                          <tr>
                            <th>Outstanding Balance</th>
                            <td>
                              &#8377;
                              {/* {memberDetails?.payment &&
                                Math.ceil(memberDetails?.payment?.amount_due)} */}
                                 {totalAmountDue}
                            </td>
                          </tr>
                          <tr>
                            <th>Total Balance</th>
                            <td>
                              &#8377;
                              {/* {memberDetails?.payment &&
                                Math.ceil(memberDetails?.payment?.amount)} */}
                                  { totalAmountPaid+ totalAmountDue}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div className="card-footer">
                      <button
                        type="button"
                        className="btn btn-outline-primary float-end"
                        disabled={memberDetails?.payment?.amount_due == 0}
                        // onClick={() => handlePayBalance()}
                        onClick={() => handleRemain(memberDetails?.payment?.amount_due)}
                      >
                        Pay Remaining Amount
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
          <ToastContainer />
          {showPlanModel && (
            <ActivatePlanModel
              showPlanModel={showPlanModel}
              setShowPlanModel={setShowPlanModel}
              memberDetails={memberDetails}
              fetchMemberDetails={fetchMemberDetails}
            />
          )}
          {showAddModal && (
            <ActivateAddonsModal
              showAddModal={showAddModal}
              setShowAddModal={setShowAddModal}
              fetchMemberDetails={fetchMemberDetails}
              qrModel={qrModel}
              setQrModel={setQrModel}
              // addonsValue={addonsValue}
              subDuration={subDuration}
              memberID={memberID}
              staffID={staffID}
              commonId={commonId}
            />
          )}
        </div>
      )}

      <Modal show={showRemain} size='lg'>
        <Modal.Header>
          <Modal.Title>Scan Qr Code</Modal.Title>
          <button type="button" data-bs-dismiss="modal" className="btn-close" onClick={() => setShowRemain(false)}></button>
        </Modal.Header>
        <Modal.Body>
          <div className='text-center'>
            {/* <h1>₹ {selectedOption === "partialpay" ? (remainAmount) / 2 : (remainAmount)}</h1> */}
            <h1>₹ {totalAmountDue}</h1>
            {/* <div>
              <label>
                <input
                  type="radio"
                  value="fullpayemnt"
                  checked={selectedOption === 'fullpayemnt'}
                  onChange={(e) => setSelectedOption(e.target.value)}
                />
                Full Payment
              </label>

              <label className='mx-4'>
                <input
                  type="radio"
                  value="partialpay"
                  checked={selectedOption === 'partialpay'}
                  onChange={(e) => setSelectedOption(e.target.value)}
                />
                Partial Payment
              </label>
            </div> */}
            <div>
              <img src={`${process.env.REACT_APP_URL}/${qrData}`} height={"350px"} />
            </div>
            <div className='mt-3 py-3'>
              <form
                onSubmit={handleTransaction}
              >
                <input placeholder='Enter Transaction Id' required
                  value={transactionId} onChange={(e) => setTransactionId(e.target.value)}
                  className='w-50' />
                <button type='submit' className='ms-3 btn btn-success'>Submit</button>
              </form>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>

        </Modal.Footer>
      </Modal>

    </>
  );
}

export default UserDetails;
