import axios from "axios";
import React, { useEffect, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import Loading from "./Loading";
import { useSelector } from "react-redux";
import FormInput from "./FormInput";

const Profile = () => {
  let adminId = localStorage.getItem("adminId");
  const [values, setValues] = useState([]);
  const [isUpdatingLogo, setIsUpdatingLogo] = useState(false);
  const getGymId = useSelector((state) => state.counterSlice.gymId);
  console.log("the token is ", getGymId);
  const deviceToken = getGymId.device_token;
  console.log(deviceToken);
  const [passwordForm, setPasswordForm] = useState({
    current_password: "",
    new_password: "",
  });
  const [showHide, setShowHide] = useState(false);
  const [nshowHide, nsetShowHide] = useState(false);
  const handlePasswordChange = async () => {
    const { current_password, new_password } = passwordForm;

    if (!current_password || !new_password) {
      toast.error("Please fill in both current and new passwords.", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      return;
    }

    const config = {
      headers: {
        Authorization: deviceToken,
      },
    };

    setLoading(true);

    try {
      const res = await axios.post(
        `${process.env.REACT_APP_URL}changePassword`,
        {
          current_password,
          new_password,
        },
        config
      );

      if (res.status === 200) {
        setLoading(false);
        toast.success(res.data.message, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });

        // Clear the password fields after successful password change.
        setPasswordForm({
          current_password: "",
          new_password: "",
        });
      }
    } catch (error) {
      setLoading(false);
      toast.error("An error occurred while changing the password.", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  const handlePasswordInputChange = (e) => {
    const { name, value } = e.target;
    setPasswordForm({
      ...passwordForm,
      [name]: value,
    });
  };

  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);

  useEffect(() => {
    fetchAdminProfile();
    // eslint-disable-next-line
  }, []);

  const handleUpdateProfile = async (data) => {
    const config = {
      headers: {
        Authorization: deviceToken,
      },
    };
    setLoading(true);
    const res = await axios.post(
      `${process.env.REACT_APP_URL}updateAdminGym`,
      data,
      config
    );
    if (res?.status === 200) {
      setLoading(false);
      fetchAdminProfile();
      toast.success(res?.data.message, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  const fetchAdminProfile = async () => {
    const config = {
      headers: {
        Authorization: deviceToken,
      },
    };
    const getAdmin = await axios.get(
      `${process.env.REACT_APP_URL}GetGymAdmin`,
      config
    );
    setValues(getAdmin?.data.data[0]);
  };

  const handleChange = (e) => {
    setValues((pre) => ({ ...pre, [e.target.name]: e.target.value }));
  };

  const handleSumbit = (e) => {
    e.preventDefault();
    handleUpdateProfile(values);
  };

  const handleLogoChange = async (e) => {
    const file = e.target.files[0];
    if (file) {
      setIsUpdatingLogo(false); // Close the logo update field

      const formData = new FormData();
      formData.append("logo", file);

      const config = {
        headers: {
          Authorization: deviceToken,
          "Content-Type": "multipart/form-data",
        },
      };

      setLoading(true);

      try {
        const res = await axios.post(
          `${process.env.REACT_APP_URL}updateAdminGymLogo`,
          formData,
          config
        );

        if (res.status === 200) {
          setLoading(false);
          fetchAdminProfile(); // Refresh the gym profile
          toast.success(res.data.message, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }
      } catch (error) {
        setLoading(false);
        toast.error("An error occurred while updating the logo.", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    }
  };

  const calculateMinTime = (openingTime) => {
    if (!openingTime) {
      return ""; // Handle the case where openingTime is not available
    }

    const openingTimeObj = new Date(`1970-01-01T${openingTime}`);
    const minTimeObj = new Date(openingTimeObj.getTime() + 60 * 60 * 1000); // Add 1 hour in milliseconds

    // Format the minimum time as "HH:mm"
    const minTime = minTimeObj.toTimeString().slice(0, 5);

    return minTime;
  };

  return (
    <>
      {loading ? (
        <Loading loading={loading} />
      ) : (
        <div
          className="container py-sm-5 py-md-5 py-lg-5 py-xl-5 py-xxl-5 mt-5"
          style={{ marginBottom: "20px" }}
        >
          <div className="row">
            {/* Left Column */}
            <div className="col-6">
              <div className="card">
                <div className="card-header">
                  <div className="card-title">GYM Application</div>
                </div>
                <div className="card-body">
                  <div className="" style={{ width: "20%" }}>
                    <label htmlFor="gymLogo" className="form-label"></label>
                    {isUpdatingLogo ? (
                      <div>
                        <input
                          type="file"
                          name="logo"
                          accept="image/*"
                          onChange={handleLogoChange}
                        />
                        <button
                          className="btn btn-outline-secondary"
                          onClick={() => setIsUpdatingLogo(false)}
                        >
                          Cancel
                        </button>
                      </div>
                    ) : (
                      <div>
                        {values?.logo && (
                          <img
                            src={`${process.env.REACT_APP_URL}${values.logo}`}
                            alt="Gym Logo"
                            className="img-fluid"
                          />
                        )}
                        <button
                          style={{
                            marginLeft: "250px",
                            width: "150px",
                          }}
                          className="btn btn-outline-primary"
                          onClick={() => setIsUpdatingLogo(true)}
                        >
                          Update Logo
                        </button>
                      </div>
                    )}
                  </div>

                  <form action="" onSubmit={handleSumbit}>
                    <div className="mb-3">
                      <label htmlFor="gym_name" className="form-label">
                        Gym Name
                      </label>
                      {/* <input
                        type="text"
                        name="gym_name"
                        className="form-control"
                        placeholder="Your Name"
                        pattern="[A-Za-z]{3,12}"
                        title="please valid name and use only alphabets"
                        value={values?.gym_name}
                        required
                        onChange={handleChange}
                      /> */}
                      <FormInput
                        value={values?.gym_name}
                        onChange={handleChange}
                        name="gym_name"
                        placeholder="Your Name"
                        errorMessage="Gym Name should be 2-30 characters and shouldn't include any special character or number!"
                        // label="Gym Name"
                        pattern="^[A-Za-z ]{2,30}$"
                        required={true}
                      />
                    </div>
                    <div className="mb-3">
                      <label htmlFor="username" className="form-label">
                        Username
                      </label>
                      {/* <input
                                                type="text"
                                                name="username"
                                                className="form-control"
                                                value={values?.username}
                                                placeholder="Address"
                                                required
                                                onChange={handleChange}
                                            /> */}
                      <FormInput
                        value={values?.username}
                        onChange={handleChange}
                        name="username"
                        placeholder="Username"
                        errorMessage="Username should be 2-30 characters"
                        // label="Gym Name"
                        pattern="^[a-zA-Z0-9.!@#$]{2,30}$"
                        required={true}
                      />
                    </div>
                    <div className="mb-3">
                      <label htmlFor="mobile" className="form-label">
                        Mobile No.
                      </label>
                      {/* <input
                                                type="text"
                                                name="mobile"
                                                className="form-control"
                                                placeholder="Mobile Number"
                                                pattern="[0-9]{10,10}"
                                                title="please valid mobile number and only 10 numbers"
                                                value={values?.mobile}
                                                required
                                                onChange={handleChange}
                                            /> */}
                      <FormInput
                        value={values?.mobile}
                        onChange={handleChange}
                        name="mobile"
                        placeholder="Mobile Number"
                        errorMessage="please valid mobile number and only 10 numbers"
                        // label="Gym Name"
                        pattern="^\d{10}$"
                        required={true}
                      />
                    </div>
                    <div className="mb-3">
                      <label htmlFor="opening_time" className="form-label">
                        GYM Opening Time
                      </label>
                      {/* <input
                                                type="time"
                                                name="opening_time"
                                                className="form-control"
                                                value={values?.opening_time}
                                                placeholder="Gym Start Timing"
                                                required
                                                onChange={handleChange}
                                            /> */}
                      <FormInput
                        type="time"
                        name="opening_time"
                        placeholder="Gym Start Timing"
                        value={values?.opening_time}
                        onChange={handleChange}
                        errorMessage="please enter Gym Start Timing"
                        required={true}
                      />
                    </div>
                    <div className="mb-3">
                      <label htmlFor="closing_time" className="form-label">
                        GYM Closing Time
                      </label>
                      {/* <input
                                                type="time"
                                                name="closing_time"
                                                className="form-control"
                                                value={values?.closing_time}
                                                placeholder="Gym End Timing"
                                                required
                                                onChange={handleChange}
                                            /> */}
                      <FormInput
                        type="time"
                        name="closing_time"
                        placeholder="Gym End Timing"
                        value={values?.closing_time}
                        min={calculateMinTime(values?.opening_time)}
                        onChange={handleChange}
                        errorMessage="please enter valid Gym End Timing"
                        required={true}
                      />
                    </div>
                    <div className="mb-3">
                      <label htmlFor="owner_name" className="form-label">
                        Owner Name
                      </label>
                      {/* <input
                                                type="text"
                                                name="owner_name"
                                                className="form-control"
                                                value={values?.owner_name}
                                                pattern="[A-Za-z]{3,12}"
                                                title="please valid name and use only alphabets"
                                                placeholder="Owner Name"
                                                required
                                                onChange={handleChange}
                                            /> */}
                      <FormInput
                        value={values?.owner_name}
                        onChange={handleChange}
                        name="owner_name"
                        placeholder="Owner Name"
                        errorMessage="please valid name and use only alphabets"
                        // label="Gym Name"
                        pattern="[A-Za-z ]{3,30}"
                        required={true}
                      />
                    </div>
                    <div className="mb-3">
                      <label htmlFor="gym_location" className="form-label">
                        Address
                      </label>
                      {/* <input
                                                type="text"
                                                name="gym_location"
                                                className="form-control"
                                                value={values?.gym_location}
                                                placeholder="Address"
                                                required
                                                onChange={handleChange}
                                            /> */}
                      <FormInput
                        value={values?.gym_location}
                        onChange={handleChange}
                        name="gym_location"
                        placeholder="Address"
                        errorMessage="please enter valid address"
                        // label="Gym Name"
                        // pattern="{3,12}"
                        required={true}
                      />
                    </div>

                    {/* ... Other Profile Form Fields ... */}
                    <button className="btn btn-outline-primary">Save</button>
                  </form>
                </div>
              </div>
            </div>

            {/* Right Column */}
            <div className="col-6 p-1">
              <div className="card">
                <div className="card-header">
                  <div className="card-title">Change Password</div>
                </div>
                <div className="card-body">
                  <form onSubmit={handlePasswordChange}>
                    <div className="mb-3 password-div">
                      <label htmlFor="current_password" className="form-label">
                        Current Password
                      </label>
                      {/* <input
                                                type={showHide === true ? "text" : "password"}
                                                className="form-control"
                                                placeholder="Password"
                                                title="Please Enter Current Password"
                                                name="current_password"
                                                // className='form-control'
                                                value={passwordForm.current_password}
                                                onChange={handlePasswordInputChange}
                                                required
                                            /> */}
                      <FormInput
                        name="current_password"
                        placeholder="Password"
                        type={showHide === true ? "text" : "password"}
                        value={passwordForm.current_password}
                        onChange={handlePasswordInputChange}
                        errorMessage=" Please enter current password "
                        // label="Gym Name"
                        // pattern="^(?=.*[0-9])(?=.*[a-zA-Z])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,20}$"
                        required={true}
                      />
                      <i
                        className={
                          showHide
                            ? "eye-b fa-solid fa-eye"
                            : "eye-b fa-solid fa-eye-slash"
                        }
                        onClick={() => {
                          setShowHide(!showHide);
                        }}
                      ></i>
                    </div>
                    <div className="mb-3 password-div">
                      <label htmlFor="new_password" className="form-label">
                        New Password
                      </label>
                      {/* <input
                                                type={nshowHide === true ? "text" : "password"}
                                                className="form-control"
                                                placeholder="Password"
                                                title="Please Enter New Password"
                                                name="new_password"
                                                // className='form-control'
                                                value={passwordForm.new_password}
                                                onChange={handlePasswordInputChange}
                                                required
                                            /> */}
                      <FormInput
                        name="new_password"
                        placeholder="Password"
                        type={nshowHide === true ? "text" : "password"}
                        value={passwordForm.new_password}
                        onChange={handlePasswordInputChange}
                        errorMessage=" Password must: - Be between 8 and 20 characters in length, Include at least one digit (0-9), Include at least one special character (!@#$%^&*) "
                        // label="Gym Name"
                        pattern={`^(?=.*[0-9])(?=.*[a-zA-Z])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,20}$`}
                        required={true}
                      />
                      <i
                        className={
                          nshowHide
                            ? "eye-b fa-solid fa-eye"
                            : "eye-b fa-solid fa-eye-slash"
                        }
                        onClick={() => {
                          nsetShowHide(!nshowHide);
                        }}
                      ></i>
                    </div>
                    <button className="btn btn-outline-primary">
                      Change Password
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <ToastContainer />
        </div>
      )}
    </>
  );
};

export default Profile;
