import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import FormInput from "../FormInput";

function AddEditLockerModal({
  show,
  setShow,
  handleAddLocker,
  details,
  handleUpdateLocker,
}) {
  const [lockerModel, setLockerModel] = useState(details);

  useEffect(() => {
    setLockerModel(details);
  }, [details]);

  const handleInput = (e) => {
    setLockerModel({ ...lockerModel, [e.target.name]: e.target.value });
  };

  const handleSumbit = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("title", lockerModel?.title);
    formData.append("description", lockerModel?.description);
    formData.append("price", "");
    formData.append("price_30", lockerModel?.price_30 || "");
    details ? handleUpdateLocker(lockerModel) : handleAddLocker(lockerModel);
    setShow(false);
    // console.log('locker', lockerModel);
  };

  return (
    <Modal show={show} size="lg">
      <Modal.Header>
        <Modal.Title>Locker details</Modal.Title>
        <button
          type="button"
          data-bs-dismiss="modal"
          className="btn-close"
          onClick={() => setShow(false)}
        ></button>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={handleSumbit}>
          <div className="row">
            <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
              <div className="mb-3">
                <label htmlFor="lockerName" className="form-label">
                  Locker Name:
                </label>
                <FormInput
                  value={lockerModel?.title}
                  onChange={handleInput}
                  name="title"
                  placeholder="First Name"
                  errorMessage="Locker Name should be 2-30 characters and shouldn't include any special character or number!"
                  // label="Gym Name"
                  pattern="^[A-Za-z ]{2,30}$"
                  required={true}
                />
              </div>
            </div>
            <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
              <div className="mb-3">
                <label htmlFor="price_30" className="form-label">
                  Price for 30 Days
                </label>
                {/* <input
                  type="text"
                  className="form-control"
                  name="price_30"
                  onChange={handleInput}
                  value={lockerModel?.price_30 || ""}
                  placeholder="₹"
                /> */}
                <FormInput
                  type="number"
                  value={lockerModel?.price_30}
                  onChange={handleInput}
                  title="only numeric digits"
                  name="price_30"
                  placeholder="₹"
                  errorMessage="Please enter valid price"
                  // label="Gym Name"
                  required={true}
                />
              </div>
            </div>
            {/* <div className='col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6'>
                        <div className='mb-3'>
                            <label htmlFor="lockerPrice" className='form-label'>Locker Price:</label>
                            <input type="text" className='form-control' placeholder='Locker Price' />
                        </div>
                    </div> */}
          </div>

          <div className="row">
            <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
              <div className="mb-3">
                <label htmlFor="price_60" className="form-label">
                  Price for 60 Days
                </label>
                <FormInput
                  type="number"
                  value={lockerModel?.price_60}
                  onChange={handleInput}
                  title="only numeric digits"
                  name="price_60"
                  placeholder="₹"
                  errorMessage="Please enter valid price"
                  // label="Gym Name"
                  required={true}
                />
              </div>
            </div>

            <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
              <div className="mb-3">
                <label htmlFor="price_90" className="form-label">
                  Price for 90 Days
                </label>
                <FormInput
                  type="number"
                  value={lockerModel?.price_90}
                  onChange={handleInput}
                  title="only numeric digits"
                  name="price_90"
                  placeholder="₹"
                  errorMessage="Please enter valid price"
                  // label="Gym Name"
                  required={true}
                />
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
              <div className="mb-3">
                <label htmlFor="price_180" className="form-label">
                  Price for 180 Days
                </label>
                <FormInput
                  type="number"
                  value={lockerModel?.price_180}
                  onChange={handleInput}
                  title="only numeric digits"
                  name="price_180"
                  placeholder="₹"
                  errorMessage="Please enter valid price"
                  // label="Gym Name"
                  required={true}
                />
              </div>
            </div>

            <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
              <div className="mb-3">
                <label htmlFor="price_365" className="form-label">
                  Price for 365 Days
                </label>
                <FormInput
                  type="number"
                  value={lockerModel?.price_365}
                  onChange={handleInput}
                  title="only numeric digits"
                  name="price_365"
                  placeholder="₹"
                  errorMessage="Please enter valid price"
                  // label="Gym Name"
                  required={true}
                />
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
              <div className="mb-3">
                <label htmlFor="lockerDesc" className="form-label">
                  Locker Description:
                </label>
                <textarea
                  className="form-control"
                  name="description"
                  onChange={handleInput}
                  placeholder="Locker Description"
                  pattern="^(?=.*[A-Za-z])[\w\s\-\.,#]+$"
                  value={lockerModel?.description}
                  required
                  rows="6"
                ></textarea>
              </div>
            </div>
          </div>

          <div className="modal-footer">
            <button type="submit" className="btn btn-outline-primary">
              Submit
            </button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
}

export default AddEditLockerModal;
