import React, { useEffect, useState } from 'react'
import Modal from 'react-bootstrap/Modal';
// import moment from 'moment';
// import DatePicker from "react-datepicker";
// import "react-datepicker/dist/react-datepicker.css";
import axios from 'axios';
import { useSelector } from 'react-redux';
import StaffForm from './StaffForm';
import FormInput from '../FormInput';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';
// import { config } from '@fortawesome/fontawesome-svg-core';


function AddEditMemberModel({ show, setShow, details, handleAddMember, handleUpdateMember, hide, setHide , fetchUserMembers}) {

    // const loadScript = (src) => {
    //     return new Promise((resolve) => {
    //         const script = document.createElement('script');
    //         script.src = src;
    //         script.onload = () => {
    //             resolve(true);
    //         };
    //         script.onerror = () => {
    //             resolve(false);
    //         };
    //         document.body.appendChild(script);
    //     });
    // }

    const [memberModel, setMemberModel] = useState(details);
    const [staffList, setStaffList] = useState([]);
    const [showHide, setShowHide] = useState(false);
    const [step, setStep] = useState(0);
    const [image, setImage] = useState(null);
    const getGymId = useSelector(state => state.counterSlice.gymId);
    // console.log("the token is ", getGymId)
    const deviceToken = getGymId.device_token;
    // console.log(deviceToken);
    const config = {
        headers: {
            "Authorization": deviceToken,
        }
    };

    console.log(memberModel)
    // const [subscriberList, setSubscriberList] = useState([]);
    // const [trainerList, setTrainerList] = useState([]);

    // const [plane] = useState({
    //     OneMonth: "500",
    //     ThreeMonts: "1500",
    //     SixMonths: "3000",
    //     year: "5000"
    // })

    useEffect(() => {
        setMemberModel(details);
    }, [details]);

    const handleInput = (e) => {
        setMemberModel((prev) => ({ ...prev, [e.target.name]: e.target.value }));
    };

    // const handleSubmit = (e) => {
    //     e.preventDefault();
    //     details ? handleUpdateMember(memberModel) : handleAddMember(memberModel);
    //     setShow(false);
    //     // console.log("@@@", memberModel);
    // }


    // const fetchAllSubscriber = async () => {
    //     const getAllSubscriber = await axios.get(`${process.env.REACT_APP_URL}getAllSubscriber`);
    //     setSubscriberList(getAllSubscriber?.data.data);
    // }
    const fetchStaffList = async () => {
        const getAllStaff = await axios.get(`${process.env.REACT_APP_URL}getAllStaffByGym`, config);
        setStaffList(getAllStaff?.data.data);
    }

    useEffect(() => {
        // fetchAllSubscriber();
        // fetchAllTrainer();
        fetchStaffList();
    }, []);

    // const fetchAllTrainer = async () => {
    //     const allTrainers = await axios.get(`${process.env.REACT_APP_URL}getAllTrainer`);
    //     setTrainerList(allTrainers?.data.data);
    // }

    // const disablePastDate = () => {
    //     const today = new Date();
    //     const dd = String(today.getDate()).padStart(2, "0");
    //     const mm = String(today.getMonth() + 1).padStart(2, "0");
    //     const yyyy = today.getFullYear();
    //     return yyyy + "-" + mm + "-" + dd;
    // }

    // let planPrice = 0;
    // subscriberList.filter((curVal) => {
    //     // eslint-disable-next-line
    //     if(curVal.id == memberModel?.membershipplan) {
    //         planPrice = curVal.subsriberfees;
    //     }
    //     return planPrice;
    // });

    // let trainerFee = 0;
    // trainerList.filter((curVal) => {
    //     // eslint-disable-next-line
    //     if(curVal.id == memberModel?.trainer) {
    //         trainerFee = curVal.trainerfees
    //     }
    //     return trainerFee;
    // });

    // const displayRazorPay = async () => {
    //     console.log('payment');
    //     const res = await loadScript('https://checkout.razorpay.com/v1/checkout.js');
    //     if(!res) {
    //         alert('Razorpay SDK failed to load. Are you online?');
    //         return;
    //     }
    //     const amount = memberModel?.membershipfees * 100;
    //     const options = {
    //         key: 'rzp_test_dhi4xNZK5z1jxb',
    //         amount: amount,
    //         currency: 'INR',
    //         name: 'GYMApplication',
    //         description: 'Test Transaction',
    //         // order_id: 'order_9A33XWu170gUtm',
    //         callback_url: 'https://eneqd3r9zrjok.x.pipedream.net/',
    //         prefill: {
    //             name: 'Fulaji Bhoir',
    //             email: 'fulaji25@gmail.com',
    //             contact: '9152529169'
    //         },
    //         theme: {
    //             color: '#3399cc'
    //         },
    //     };

    //     const paymentObject = new window.Razorpay(options);
    //     paymentObject.open();
    // }

    const handleImageChange = (e) => {
        const selectedImage = e.target.files[0];
        setImage(selectedImage);
    };

    

    const handleSubmit = (e) => {
        e.preventDefault();

        // if (!image) {
        //     alert('Please select an image');
        //     return;
        // }

        const formData = new FormData();
        formData.append('image', image);
        formData.append('firstname', memberModel.firstname);
        formData.append('lastname', memberModel.lastname);
        formData.append('mobile', memberModel.mobile);
        if (hide == false) {
            formData.append('password', memberModel.password);
        }
        formData.append('address', memberModel.address);
        if (hide == false) {
            formData.append('staff_id', memberModel.staff_id);
        }
        if (hide == false) {
            formData.append('email', memberModel.email);
        }
        if (details) {
            formData.append('id', memberModel.id);
            handleUpdateMember(formData);
        } else {
            handleAddMember(formData);
        }

        // setShow(false);
    };

    //     const handleStaff = (e) => {
    //         e.preventDefault();
    // setStep(1)
    //     }

    useEffect(() => {
        if (hide) {
            setStep(1)
        }

    }, [hide])

    { console.log("staffList:", staffList) }
    { console.log("memberModel:", memberModel) }
    { console.log("Filtered Result:", staffList.filter((item) => item.id == memberModel?.staff_id)[0]?.etime) }

    const handleProfileChange = async (e, memberId) => {
        const imageFile = e.target.files[0];

        // Create a form data object to send the image file
        const formData = new FormData();
        formData.append("image", imageFile);

        const config = {
            headers: {
                "Authorization": deviceToken,
                "Content-Type": "multipart/form-data", // Set the content type for file upload
            },
        };

        try {
            const res = await axios.post(`${process.env.REACT_APP_URL}updateMemberLogo/${memberId}`, formData, config);

            if (res?.status === 200) {
                toast.success('Image updated successfully!');
                fetchUserMembers();
                setShow(false);
            } else {
                // Handle any errors that occur during image upload
                console.error("Image upload failed.");
            }
        } catch (error) {
            console.error("Error uploading image:", error);
        }
    };
  

    return (


        <Modal show={show} size="lg">
            <Modal.Header >
                <Modal.Title>{step === 0 ? "Staff Details" : "Member Details"}</Modal.Title>
                <button type="button" data-bs-dismiss="modal" className="btn-close" onClick={() => setShow(false)}></button>
            </Modal.Header>
            <Modal.Body>
                {step === 0 ?
                    <StaffForm setStep={setStep} memberModel={memberModel} handleInput={handleInput} staffList={staffList} />
                    : step === 1 ?
                    <>
                    {hide &&
                        <div className='d-flex mb-3 align-items-center'>
                            <div >
                                <img
                                    style={{ width: "65px", height: "65px", borderRadius: "50%" }}
                                    src={`${process.env.REACT_APP_URL}${details.image}`}
                                    alt="image"
                                    className="member-image"
                                />
                                
                            </div>
                            <div className='ms-5 ps-5'>
                            <label htmlFor={`imageInput`} className="btn btn-primary  m-0" >
                                <FontAwesomeIcon icon={faEdit} />
                               update image
                                <span className="visually-hidden">Change Image</span>
                            </label>
                            <input
                                type="file"
                                id={`imageInput`}
                                style={{ display: "none" }}
                                onChange={(e) => handleProfileChange(e, details?.member_id)} // Handle image 
                            />
                        </div>
                        </div>
                        }
                    <form action="" onSubmit={handleSubmit}>
                        <div className="row">
                            
                            <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6">

                                <div className="mb-3">
                                    <label htmlFor="firstName" className='form-label'>First Name: </label>
                                    {/* <input type="text" className='form-control'
                                    name='firstname'
                                    value={memberModel?.firstname}
                                    onChange={handleInput}
                                    placeholder='First Name'
                                    pattern='^(?=.*[A-Za-z])[\w\s\-\.,#]+$'
                                    // title='please use only alphabets'
                                    required
                                /> */}
                                    <FormInput
                                        name='firstname'
                                        value={memberModel?.firstname}
                                        onChange={handleInput}
                                        placeholder='First Name'
                                        pattern='^[A-Za-z ]{2,20}$'
                                        errorMessage="firstname should be 2-30 characters"
                                        required={true}
                                    />
                                </div>
                            </div>
                            <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                                <div className="mb-3">
                                    <label htmlFor="lastName" className='form-label'>Last Name: </label>
                                    {/* <input type="text" className='form-control'
                                    name='lastname'
                                    value={memberModel?.lastname}
                                    onChange={handleInput}
                                    placeholder='Last Name'
                                    // pattern='[A-Za-z]{3,25}'
                                    // title='please use only alphabets'
                                    required
                                /> */}
                                    <FormInput
                                        name='lastname'
                                        value={memberModel?.lastname}
                                        onChange={handleInput}
                                        placeholder='Last Name'
                                        pattern='^[A-Za-z ]{1,20}$'
                                        errorMessage="lastname should be 1-30 characters"
                                        required={true}
                                    />
                                </div>
                            </div>
                        </div>


                        <div className="row">
                            <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                                <div className="mb-3">
                                    <label htmlFor="mobileNo" className='form-label'>Mobile No.: </label>
                                    <FormInput
                                        name='mobile'
                                        value={memberModel?.mobile}
                                        onChange={handleInput}
                                        placeholder='Mobile No.'
                                        errorMessage="please enter valid mobile number and only 10 numbers"
                                        // label="Gym Name"
                                        pattern="^\d{10}$"
                                        required={true}
                                    />
                                </div>
                            </div>
                            {
                                hide == false &&
                                <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                                    <div className='mb-3 password-div'>
                                        <label htmlFor="memberPassword" className='form-label'>Member Password:</label>
                                        <FormInput
                                            name='password'
                                            onChange={handleInput}
                                            value={memberModel?.password}
                                            placeholder="Enter Password"
                                            errorMessage=" Password must: - Be between 8 and 20 characters in length, Include at least one digit (0-9), Include at least one special character (!@#$%^&*) "
                                            pattern="^(?=.*[0-9])(?=.*[a-zA-Z])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,20}$"
                                            required={true}
                                        />
                                        <i
                                            className={
                                                showHide
                                                    ? "eye-b fa-solid fa-eye"
                                                    : "eye-b fa-solid fa-eye-slash"
                                            }
                                            onClick={() => {
                                                setShowHide(!showHide);
                                            }}
                                        ></i>
                                    </div>
                                </div>
                            }

                        </div>

                        <div className='row'>
                            {/* {
                            hide==false && 
                                <div className='col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6'>
                                <div className='mb-3'>
                                    <label htmlFor="staffRefer" className='form-label'>Refer By:</label>
                                    <select name="staff_id" className='form-select' value={memberModel?.staff_id} onChange={handleInput} required>
                                        <option defaultValue={''}>Select</option>
                                        {staffList?.map((curVal, i) => (
                                            <option key={i} value={curVal?.id}>
                                                {curVal?.firstName} {curVal?.lastName}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                         } */}


                            <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                                <div className="mb-3">
                                    <label htmlFor="address" className='form-label'>Address: </label>
                                    <FormInput
                                        name='address'
                                        value={memberModel?.address}
                                        onChange={handleInput}
                                        placeholder='Address'
                                        errorMessage="please enter valid address"
                                        // label="Gym Name"
                                        // pattern="{3,12}"
                                        required={true}
                                    />
                                </div>
                            </div>
                        </div>


                        <div className='row'>
                            {
                                hide == false &&
                                <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                                    <div className="mb-3">
                                        <label htmlFor="image" className="form-label">
                                            Member Image:
                                        </label>
                                        <FormInput
                                            name="image"
                                            type="file"
                                            onChange={handleImageChange}
                                            errorMessage="please upload  Member Image"
                                            // label="Gym Name"
                                            // pattern="{3,12}"
                                            required={true}
                                        />
                                    </div>
                                </div>
                            }


                            {
                                hide == false &&
                                <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                                    <div className="mb-3">
                                        <label htmlFor="email" className='form-label'>Email: </label>
                                        <FormInput
                                            type="email"
                                            name='email'
                                            value={memberModel?.email}
                                            onChange={handleInput}
                                            placeholder='Email'
                                            errorMessage="please enter valid email"
                                            // label="Gym Name"
                                            // pattern="^[a-zA-Z0-9.!@#$]{2,30}$"
                                            required={true}
                                        />
                                    </div>
                                </div>
                            }

                        </div>


                        <div className="modal-footer">
                            {hide == false && <button onClick={() => setStep(0)} className="btn btn-outline-primary">Back</button>}
                            <button type="submit" className="btn btn-outline-primary">Submit</button>
                        </div>
                    </form>
                    </>
                     : ""}
            </Modal.Body>
        </Modal>

    )
}

export default AddEditMemberModel



