import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendarCheck,
  faEdit,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import AddEditModel from "./Trainers/AddEditModel";
import axios from "axios";
import { useSelector } from "react-redux";
// Notification
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Pagination from "./Pagination";
import { getTrainerId } from "../redux/counterSlice";
import { setTrainerId } from "../redux/counterSlice";
import { useDispatch } from "react-redux";
import Loading from "./Loading";
import AttendanceCalforTrainer from "./Trainers/AttendanceCalforTrainer";
import { capitalFirstLetter, to12HrFormat } from "../utils/utils";

function Trainers() {
  const dispatch = useDispatch();
  const [details, setDetails] = useState({});
  const [show, setShow] = useState(false);
  const [trainerList, setTrainerList] = useState([]);
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  // eslint-disable-next-line
  const [postsPerPage, setPostsPerPage] = useState(10);
  const [hide, setHide] = useState(false);
  const lastPostIndex = currentPage * postsPerPage;
  const firstPostIndex = lastPostIndex - postsPerPage;
  const getGymId = useSelector((state) => state.counterSlice.gymId);
  // console.log("the token is ", getGymId)
  const deviceToken = getGymId.device_token;
  // console.log(deviceToken);
  const getTrainer = useSelector((state) => state.counterSlice.getTrainerId);
  const [calendarShow, setCalendarShow] = useState(false);
  const [attendanceData, setAttendanceData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const currentPosts = filteredData.slice(firstPostIndex, lastPostIndex);

  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);

  const config = {
    headers: {
      Authorization: deviceToken,
    },
  };

  const handleAddTrainer = async (data) => {
    const res = await axios.post(
      `${process.env.REACT_APP_URL}addTrainer`,
      data,
      config
    );
    if (res?.status === 200) {
      fetchTrainers();
      toast.success(res?.data.message, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  // Update Method
  const handleUpdateTrainer = async (data) => {
    const res = await axios.post(
      `${process.env.REACT_APP_URL}updateTrainer/${data?.id}`,
      data,
      config
    );
    if (res?.status === 200) {
      fetchTrainers();
      toast.success("Trainer update succesfully", {
        // res?.data.message
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  // Make a request for a user with a given ID
  const fetchTrainers = async () => {
    const allTrainers = await axios.get(
      `${process.env.REACT_APP_URL}getAllTrainerByAdmin`,
      config
    );
    setTrainerList(allTrainers.data.data);
  };

  useEffect(() => {
    const filterData = () => {
      const filtered = trainerList?.filter((curVal) => {
        let fullname = `${curVal?.firstname?.toLowerCase()} ${curVal?.lastname?.toLowerCase()}`;
        if (search.toLowerCase() === "") {
          return curVal;
        } else if (fullname.includes(search.toLowerCase())) {
          return curVal;
        }
      });

      setFilteredData(filtered);
    };

    filterData();
  }, [trainerList, search]);

  useEffect(() => {
    fetchTrainers();
  }, []);

  // Delete Method
  const handleDelete = async ({ id = null }) => {
    const res = await axios.delete(
      `${process.env.REACT_APP_URL}deleteTrainer/${id}`
    );
    if (res?.status === 200) {
      fetchTrainers();
      toast.success(res?.data.message, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  const handleOnClickAddTrainer = () => {
    setDetails(null);
    setShow(true);
    setHide(false);
  };

  const handleOnClickEdit = (details) => {
    setDetails(details);
    setShow(true);
    setHide(true);
  };

  const handleCheckIn = async (staffId) => {
    const config = {
      headers: {
        Authorization: deviceToken,
      },
    };

    const data = {
      trainer_id: staffId,
    };

    try {
      const res = await axios.post(
        `${process.env.REACT_APP_URL}AddCheckInTrainer`,
        data,
        config
      );
      if (res?.status === 200) {
        dispatch(getTrainerId(getTrainer));
        toast.success(res?.data.message, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    } catch (error) {
      console.error("Error checking in trainer:", error);
    }
  };

  const handleCheckOut = async (staffId) => {
    const config = {
      headers: {
        Authorization: deviceToken,
      },
    };

    const data = {
      trainer_id: staffId,
    };

    try {
      const res = await axios.post(
        `${process.env.REACT_APP_URL}AddCheckOutTrainer`,
        data,
        config
      );
      if (res?.status === 200) {
        dispatch(getTrainerId(getTrainer));
        toast.success(res?.data.message, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    } catch (error) {
      console.error("Error checking in trainer:", error);
    }
  };

  const handleAttendance = async (id) => {
    const res = await axios.get(
      `${process.env.REACT_APP_URL}TotalAddendanceTrainer/${id}`
    );
    if (res?.status === 200) {
      setAttendanceData(res?.data.data);
      setCalendarShow(true);
    }
  };

  return (
    <>
      {loading ? (
        <Loading loading={loading} />
      ) : (
        <div
          style={{ maxWidth: "1600px" }}
          className="mx-auto container-fluid px-5 py-sm-5 py-md-5 py-lg-5 py-xl-5 py-xxl-5 h-100"
        >
          <div className="row mt-3">
            <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
              <h3 className="mt-5">Trainers List</h3>
            </div>
            <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
              <div className="d-flex justify-content-end mt-5">
                <button
                  className="btn btn-outline-primary"
                  onClick={handleOnClickAddTrainer}
                >
                  Add Trainer
                </button>
              </div>
            </div>
          </div>

          <section className="py-sm-3 py-md-3 py-lg-3 py-xl-3 py-xxl-3">
            <div className="row">
              <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                <input
                  type="text"
                  className="form-control"
                  value={search}
                  onChange={(e) => {
                    setSearch(e.target.value);
                    setCurrentPage(1);
                  }}
                  placeholder="Search Trainers..."
                />
                <div className="table table-responsive-sm table-responsive-md table-responsive-lg table-responsive-xl table-responsive-xxl mt-3">
                  <table className="table table-bordered table-striped-rows table-hover">
                    <thead className="table-dark">
                      <tr>
                        <th>Trainer ID</th>
                        <th>Name</th>
                        <th>Mobile No</th>
                        <th>Experience(Yrs)</th>
                        <th>Shift Timing</th>
                        <th>Attendance</th>
                        <th>Action</th>
                        {/* <th>CheckIn</th>
                      <th>CheckOut</th> */}
                      </tr>
                    </thead>
                    <tbody>
                      {currentPosts?.length > 0 &&
                        // eslint-disable-next-line
                        currentPosts
                          // ?.filter((curVal) => {
                          //   if (search.toLowerCase() === "") {
                          //     return curVal;
                          //   } else if (
                          //     curVal?.firstname
                          //       ?.toLowerCase()
                          //       ?.includes(search.toLowerCase()) ||
                          //     curVal?.lastname
                          //       ?.toLowerCase()
                          //       ?.includes(search.toLowerCase())
                          //   ) {
                          //     return curVal;
                          //   }
                          // })
                          ?.map((curVal, i) => {
                            return (
                              <tr key={i}>
                                <td>{curVal?.trainer_id}</td>
                                <td>
                                  {capitalFirstLetter(curVal?.firstname)}{" "}
                                  {capitalFirstLetter(curVal?.lastname)}
                                </td>
                                <td>{curVal?.mobile}</td>
                                <td>{curVal?.experience} Yrs</td>
                                <td>
                                  {to12HrFormat(curVal?.starttime)} -{" "}
                                  {to12HrFormat(curVal?.endtime)}
                                </td>
                                <td>
                                  <div className="d-flex justify-content-center align-items-center">
                                    <FontAwesomeIcon
                                      style={{ cursor: "pointer" }}
                                      onClick={() =>
                                        handleAttendance(curVal?.trainer_id)
                                      }
                                      icon={faCalendarCheck}
                                    />
                                  </div>
                                </td>
                                <td>
                                  <div className="d-flex justify-content-evenly align-items-center">
                                    <FontAwesomeIcon
                                      onClick={() => handleOnClickEdit(curVal)}
                                      icon={faEdit}
                                    />
                                    <FontAwesomeIcon
                                      onClick={() => handleDelete(curVal)}
                                      icon={faTrash}
                                    />
                                  </div>
                                </td>
                                {/* <td>
                              <button
                                  className="btn btn-primary"
                                  onClick={() => handleCheckIn(curVal?.id)} 
                              >
                                  CheckIn
                              </button>
                          </td>
                          <td>
                              <button
                                  className="btn btn-primary"
                                  onClick={() => handleCheckOut(curVal?.id)} 
                              >
                                  CheckOut
                              </button>
                          </td> */}
                              </tr>
                            );
                          })}
                    </tbody>
                  </table>
                </div>
                <Pagination
                  totalPosts={filteredData?.length}
                  postsPerPage={postsPerPage}
                  setCurrentPage={setCurrentPage}
                  currentPage={currentPage}
                />
              </div>
            </div>
            {show && (
              <AddEditModel
                show={show}
                setShow={setShow}
                hide={hide}
                setHide={setHide}
                details={details}
                handleAddTrainer={handleAddTrainer}
                handleUpdateTrainer={handleUpdateTrainer}
              />
            )}
            {calendarShow && (
              <AttendanceCalforTrainer
                calendarShow={calendarShow}
                setCalendarShow={setCalendarShow}
                attendanceData={attendanceData}
              />
            )}
          </section>
          <ToastContainer />
        </div>
      )}
    </>
  );
}

export default Trainers;
