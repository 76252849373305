import React, { useState, useRef, useEffect } from "react";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import axios from "axios";
import { useSelector } from "react-redux";
import mylogo from "../images/mylogo.jpg";

function Invoice() {
  const [pdf, setPdf] = useState(null);
  const invoiceRef = useRef(null);
  const [transactionId, setTransactionId] = useState(null);
  const [ordersId, setOrdersId] = useState(null);
  const [date, setDate] = useState(null);
  const [subtotal, setSubtotal] = useState(null);
  const [dues, setDues] = useState(null);
  const [paid, setPaid] = useState(null);
  const [status, setStatus] = useState(null);
  const [userId, setUserId] = useState(null);
  const [firstname, setFirstname] = useState(null);
  const [lastname, setLastname] = useState(null);
  const [paymentId, setPaymentId] = useState(null);
  const [logo, setLogo] = useState(null);
  const [location, setLocation] = useState(null);
  const [invoiceData, setInvoiceData] = useState(null);
  const [gymName, setGymName] = useState(null);
  const [memberMobile, setMemberMobile] = useState(null);
  const [subPrice, setSubPrice] = useState(null);
  const [subName, setSubName] = useState(null);
  const [subStart, setSubStart] = useState(null);
  const [subEnd, setSubEnd] = useState(null);
  const [userAddress, setUserAddress] = useState(null);
  const [mobileNumber, setMobileNumber] = useState(null);
  const [serialNumber, setSerialNumber] = useState(1);
  const [subDate, setSubDate] = useState();
  const setOrderId = useSelector((state) => state.counterSlice.orderId);

  useEffect(() => {
    axios
      .get(
        `https://ui15cpxq82.execute-api.us-east-1.amazonaws.com/userCollection/${setOrderId}`
      )
      .then((response) => {
        const data = response.data.data;
        const pay = response.data.payment;
        const gym = response.data.gym;
        const sub = response.data.subscription;
        console.log("invoice: ", sub);

        if (data) {
          setInvoiceData(data);
          setTransactionId(pay.transaction_id)
          setOrdersId(pay.receipt);
          setDate(pay.updated_at);
          setSubtotal(pay.amount);
          setDues(pay.amount_due);
          setPaid(pay.amount_paid);
          setStatus(pay.status);
          setUserId(pay.user_id);
          setPaymentId(data.payment_id);
          setFirstname(pay.firstname);
          setLastname(pay.lastname);
          setLogo(gym.logo);
          setLocation(gym.gym_location);
          setGymName(gym.gym_name);
          setMobileNumber(gym.mobile);
          setMemberMobile(pay.mobile);
          setSubPrice(sub.fees);
          setSubName(sub.addon_type);
          setSubStart(sub.startdate);
          setSubEnd(sub.enddate);
          setUserAddress(pay.address);
          setSubDate(sub.startdate)
        }
      })
      .catch((error) => {
        console.error("Error fetching data from the API:", error);
      });
  }, []);
  // console.log("jdbbs", logo)

  const generatePDF = () => {
    const input = invoiceRef.current;
    const img = new Image();
    img.src = `${mylogo}`;

    img.onload = () => {
      html2canvas(input, {
        // proxy: `${process.env.REACT_APP_URL}${logo}`,
        allowTaint: true,
        useCORS: true,
        // logging: true ,
      }).then((canvas) => {
        const imgData = canvas.toDataURL('image/jpg');
        const pdf = new jsPDF('p', 'px', 'a4', true);
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = pdf.internal.pageSize.getHeight();
        const imgWidth = canvas.width;
        const imgHeight = canvas.height;
        const ratio = Math.min(pdfWidth / imgWidth, pdfHeight / imgHeight);
        const imgX = (pdfWidth - imgWidth * ratio) / 2;
        const imgY = 30;
        pdf.addImage(imgData, 'JPG', imgX, imgY, imgWidth * ratio, imgHeight * ratio);
        pdf.save('invoice.pdf');
      });
    };
  };

  //   const generatePDF = () => { 
  //     // using Java Script method to get PDF file 
  //     fetch(`${process.env.REACT_APP_URL}${logo}`).then(response => { 
  //         response.blob().then(blob => { 
  //             // Creating new object of PDF file 
  //             const fileURL = window.URL.createObjectURL(blob); 
  //             // Setting various property values 
  //             let alink = document.createElement('a'); 
  //             alink.href = fileURL; 
  //             alink.download = 'SamplePDF.pdf'; 
  //             alink.click(); 
  //         }) 
  //     }) 
  // } 

  const invoiceStyles = {
    width: "100%",
    height: "100%",
    // margin: "10 auto",
    backgroundColor: "#fff",
    padding: "5px 30px 150px 30px",
    // paddingBottom:"50px",
    boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
  };

  const headerStyles = {
    textAlign: "left",
    padding: "5px 0",
  };

  const h1Styles = {
    color: "#333",
    fontSize: "20px",
    display: "flex",
    justifyContent: "left",
  };

  const detailsStyles = {
    marginTop: "5px",
    fontSize: "15px",
    display: "grid",
    justifyContent: "left",
    textAlign: "left",
    // width:"35%",

  };

  const imageStyles = {
    display: "flex",
    justifyContent: "center",
    // paddingLeft:"20%",
  };

  const clientDetailsStyles = {
    marginTop: "20px",
    borderTop: "1px solid #ccc",
    paddingTop: "5px",
  };

  const billStyles = {
    display: "flex",
    justifyContent: "left",
    fontSize: "20px",
    color: "#333",
    paddingTop: "20px",
  };

  const h2Styles = {
    display: "flex",
    justifyContent: "center",
    fontSize: "20px",
    color: "#333",
    paddingTop: "20px",

  };

  const clientInfoStyles = {
    fontSize: "16px",
    margin: "5px 0",
    paddingLeft: "25px",
    // width:"40%",
    display: "grid",
    justifyContent: "left",
    textAlign: "left",
    paddingBottom: "15px",
  };

  const tableStyles = {
    width: "100%",
    marginLeft: "14px",
    borderCollapse: "collapse",
    // border:"none",
    // paddingLeft:"20px",

  };

  const tableStyles1 = {
    width: "80%",
    marginLeft: "25%",
    borderCollapse: "collapse",
    // border:"none",
    // paddingLeft:"20%"
  }

  const tablehead = {
    backgroundColor: "grey",
  }

  const thTdStyles = {
    // padding: "8px",
    width: "5%",
    textAlign: "left",
    border: "none",
    paddingLeft: "20px",
    // width:"70%"
  };

  const thStyles = {
    backgroundColor: "grey",
    height: "5vh",
    color: "black",
    width: "5%",
    textAlign: "left",
    // border:"none",
  };

  const subtdStyle = {
    width: "5%",
    textAlign: "left"

  }

  const summaryStyles = {
    marginTop: "25px",
    display: "grid",
    justifyContent: "right",
  };

  const summaryTextStyles = {
    fontSize: "18px",
    margin: "5px 0",
    // display:"grid",
    justifyContent: "left",
    textAlign: "left",
  };

  const strongStyles = {
    fontWeight: "bold",
    color: "#333",
    // tdisplay:"grid",
    // justifyContent:"left",
    textAlign: "left",
  };
  // console.log("invoiceData", invoiceData[0].)

  const dateObject = new Date(date);
  const year = dateObject.getFullYear();
  const month = (dateObject.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-based
  const day = dateObject.getDate().toString().padStart(2, '0');

  // Format the date as a string
  const formattedDate = `${year}-${month}-${day}`;
  return (
    <div>
      <button className="btn btn-danger float-end me-4" style={{ marginTop: "85px" }} onClick={generatePDF}>
        Generate PDF
      </button>
      <center>
        <div className="head" ref={invoiceRef}>
          <div className="head" style={invoiceStyles}>
            <div className="row text-center">
              <h1 >Invoice</h1>
            </div>

            <div className="row">
              <div className="col">
                <div style={headerStyles}>
                  <h1 style={h1Styles}>Sold By:</h1>
                </div>
                <div className="row">
                  <div style={detailsStyles} className="col">
                    <div>
                      <strong>{gymName}</strong>
                    </div>
                    <div>
                      <strong>India</strong>
                    </div>
                    <div>
                      <strong>{mobileNumber}</strong>
                    </div>
                  </div>
                </div>

              </div>






              <div style={imageStyles} className="col">
                {logo && (
                  <div>
                    <img src={mylogo} alt="Gym Logo" style={{ width: "180px", height: "100px", borderStyle: "solid", borderRadius: "10px" }} />
                  </div>
                )}
              </div>
            </div>


            <div style={clientDetailsStyles} className="row">
              <div><h4 style={billStyles}>Bill to: </h4></div>
              <div style={clientInfoStyles} className="col">
                <div>
                  <strong>{firstname} {lastname}</strong>
                </div>
                <div>
                  <strong>{userId}</strong>
                </div>
                <div>
                  <strong >{userAddress}</strong>
                </div>

                <div>
                  <strong>{memberMobile}</strong>
                </div>
              </div>



              <div className="col" style={{ textAlign: "left", paddingLeft: "35%" }}>
                <div>
                  <strong>Transaction Id.# : </strong> {transactionId}
                </div>
                <div>
                  <strong>Invoice No.# : </strong> {ordersId}
                </div>
                <div>
                  <strong>Date: </strong> {formattedDate}
                </div>
                <div>
                  <strong>Payment Mode: </strong>QR Code
                </div>

              </div>

            </div>


            <div>
              <table style={{ width: "100%", border: "3px solid rgb(0, 0, 0)" }}>
                <thead >
                  <tr >
                    <th style={{ width: "250px", backgroundColor: "grey" }}>Serial No.</th>
                    <th style={tablehead}>Description</th>
                    <th style={tablehead}>Price</th>
                    <th style={{ backgroundColor: "grey", paddingLeft: "7%" }}>Invoice Total</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td style={{ verticalAlign: "top", paddingLeft: "35px" }}>{serialNumber}</td>
                    <td style={{ width: "40%" }}>
                      <strong>{subName}</strong>
                      <p>Valid from: {subStart} to {subEnd}</p>

                      {status === "partial paid" ? <strong>Remaing Amount:</strong>
                        :
                        <>
                          <strong>Addons:</strong>

                          {invoiceData?.map((item, index) => (
                            <div key={index}>
                              {item.addon_type}
                              <span style={{ paddingLeft: "35px" }}>Valid from: {item?.startdate} to {item?.enddate}</span>
                            </div>
                          ))}
                        </>
                      }
                    </td>
                    <td>
                      ₹{(subPrice)}
                      <br /><br /><br /><br />
                      {status === "partial paid" ? 
                      <span> ₹ {parseInt(paid).toFixed(2)}</span>
                      :
                      <>
                        {invoiceData?.map((item, index) => (
                          <div key={index}>
                            ₹{(item.fees).toFixed(2)}
                          </div>
                        ))}
                      </>
}
                    </td>
                    <td style={{ verticalAlign: "bottom", paddingLeft: "125px" }}>{parseInt(subtotal).toFixed(2)}</td>
                  </tr>
                </tbody>
              </table>
            </div>


            {/* <div>
            <table style={tableStyles1}>
              <thead>
                <tr>
                  <th style={{width:"435px"}}>Addon</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {invoiceData?.map((item, index) => (
                  <tr key={index}>
                    <td >{item.addon_type}</td>
                    <td >{item.fees}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div> */}

            {/* <div style={{paddingLeft:"77%", display:"flex", alignItems:"left", marginTop:"-5px"}}>
            <strong></strong>₹{(subtotal/100).toFixed(2)}
          </div> */}
            <div style={{ paddingLeft: "75%", display: "flex", alignItems: "left" }}>
              <strong >Paid Amount:</strong> ₹
              {parseInt(paid).toFixed(2)}
            </div>
            <div style={{ paddingLeft: "75%", display: "flex", alignItems: "left" }}>
              <strong >Balance Amount: </strong> ₹
              {parseInt(dues).toFixed(2)}
            </div>

          </div>
        </div>
      </center>
      {/* <div>
        <button onClick={generatePDF}>Generate PDF</button>
      </div> */}
    </div>

  );
}
export default Invoice;
