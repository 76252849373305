import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import EditAddonPrice from "./EditAddonPrice";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { numberWithCommas } from "../utils/utils";

function AddAddonPrice() {
  const [addonDetails, setAddonDetails] = useState([]);
  const [editAddon, setEditAddon] = useState(null);

  const getGymId = useSelector((state) => state.counterSlice.gymId);
  const deviceToken = getGymId.device_token;

  const config = {
    headers: {
      Authorization: deviceToken,
    },
  };

  useEffect(() => {
    fetchAddonDetails();
  }, []);

  const fetchAddonDetails = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_URL}getAllAddonsPriceByGymNew`,
        config
      );
      setAddonDetails(response.data.Data);
    } catch (error) {
      console.error("Error fetching addon details:", error);
    }
  };

  const handleUpdateAddon = (updatedAddon) => {
    setAddonDetails((prevAddons) =>
      prevAddons.map((addon) =>
        addon.addon_id === updatedAddon.id ? updatedAddon : addon
      )
    );
    setEditAddon(null);
  };

  const handleEditAddon = (addon) => {
    setEditAddon(addon);
  };

  return (
    <>
      <center>
        <h2 style={{ marginTop: "80px" }}>Addon Details</h2>
      </center>
      <div
        style={{ maxWidth: "1600px", paddingBottom: "8%" }}
        className="mx-auto container-fluid px-5 mt-4"
      >
        <div className="row">
          <div className="col-md-12">
            <table className="table table-bordered ">
              <thead className="table-dark">
                <tr style={{ textAlign: "center" }}>
                  <th>Addon</th>
                  <th>Details</th>
                  <th>Edit</th>
                </tr>
              </thead>
              <tbody>
                {addonDetails.length > 0 ? (
                  addonDetails.map((addon) => (
                    <tr key={addon.id}>
                      <td
                        style={{ verticalAlign: "middle", textAlign: "center" }}
                      >
                        {addon.addons_name}
                      </td>
                      <td>
                        <table className="table table-bordered">
                          <thead>
                            <tr>
                              <th style={{ textAlign: "center" }}>Duration</th>
                              <th style={{ textAlign: "center" }}>Price</th>
                            </tr>
                          </thead>
                          <tbody>
                            {addon.addon_prices.map((price) => (
                              <tr key={price.id}>
                                <td style={{ textAlign: "center" }}>
                                  {price.duration} days
                                </td>
                                <td style={{ textAlign: "center" }}>
                                  Rs. {numberWithCommas(price.addon_price)}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </td>
                      <td
                        style={{ textAlign: "center", verticalAlign: "middle" }}
                      >
                        <Link to={`/edit-addon/${addon.addon_id}`}>
                          <FontAwesomeIcon icon={faEdit} size="xl" />
                        </Link>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="3">No addon details available</td>
                  </tr>
                )}
              </tbody>
            </table>
            {editAddon && (
              <div className="modal">
                <div className="modal-content">
                  <EditAddonPrice
                    addon={editAddon}
                    onUpdateAddon={handleUpdateAddon}
                    onClose={() => setEditAddon(null)}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default AddAddonPrice;
