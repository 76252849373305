import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import FormInput from "./FormInput";

function EditAddonPrice() {
  const { addon_id } = useParams(); // Get the addon ID from the URL parameter

  const [addonDetails, setAddonDetails] = useState(null);
  const [updatedAddon, setUpdatedAddon] = useState({
    "30_days": 0,
    "60_days": 0,
    "90_days": 0,
    "180_days": 0,
    "365_days": 0,
  });
  const getGymId = useSelector((state) => state.counterSlice.gymId);
  const deviceToken = getGymId.device_token;

  const config = {
    headers: {
      Authorization: deviceToken,
    },
  };

  useEffect(() => {
    fetchAddonDetails();
  }, []);

  const fetchAddonDetails = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_URL}getAddonsPriceByAddonId/${addon_id}`,
        config
      );
      setAddonDetails(response.data.data);

      // Prepopulate the updatedAddon state with the fetched data
      setUpdatedAddon({
        days_30: response.data.data[0]["addon_price"],
        days_60: response.data.data[1]["addon_price"],
        days_90: response.data.data[2]["addon_price"],
        days_180: response.data.data[3]["addon_price"],
        days_365: response.data.data[4]["addon_price"],
      });
    } catch (error) {
      console.error("Error fetching addon details:", error);
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setUpdatedAddon((prevAddon) => ({
      ...prevAddon,
      [name]: value,
    }));
  };

  const handleUpdate = async (id, price) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_URL}updateAddonsPrice/${id}`,
        { addon_price: price }
      );
      if (response.data.status === "200") {
        toast.success(response.data.message);
        console.log(response);
      }
      // Handle the updated addon data, you can redirect or display a success message
    } catch (error) {
      console.error("Error updating addon price:", error);
    }
  };

  return (
    <>
      <div className="container mt-5">
        <h2 style={{ marginTop: "90px" }}>Edit Addon Price</h2>
        {addonDetails && (
          <div className="mt-4">
            <h4>Addon Name: {addonDetails[0].addons_name}</h4>
            <form>
              <table className="table">
                <thead>
                  <tr>
                    <th>Duration</th>
                    <th>Price</th>
                    <th>Edit</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>30 Days</td>
                    <td>
                      <FormInput
                        type="number"
                        name="days_30"
                        value={updatedAddon["days_30"]}
                        onChange={handleInputChange}
                        className="form-control"
                        placeholder="₹"
                        errorMessage="please enter valid Price"
                        required={true}
                      />
                    </td>
                    <td>
                      <button
                        type="button"
                        className="btn btn-outline-primary"
                        onClick={() =>
                          handleUpdate(
                            addonDetails[0].id,
                            updatedAddon["days_30"]
                          )
                        }
                      >
                        Update
                      </button>
                    </td>
                  </tr>
                  <tr>
                    <td>60 Days</td>
                    <td>
                      <FormInput
                        type="number"
                        name="days_60"
                        value={updatedAddon["days_60"]}
                        onChange={handleInputChange}
                        className="form-control"
                        placeholder="₹"
                        errorMessage="please enter valid Price"
                        required={true}
                      />
                    </td>
                    <td>
                      <button
                        type="button"
                        className="btn btn-outline-primary"
                        onClick={() =>
                          handleUpdate(
                            addonDetails[1].id,
                            updatedAddon["days_60"]
                          )
                        }
                      >
                        Update
                      </button>
                    </td>
                  </tr>
                  <tr>
                    <td>90 Days</td>
                    <td>
                      <FormInput
                        type="number"
                        name="days_90"
                        value={updatedAddon["days_90"]}
                        onChange={handleInputChange}
                        className="form-control"
                        placeholder="₹"
                        errorMessage="please enter valid Price"
                        required={true}
                      />
                    </td>
                    <td>
                      <button
                        type="button"
                        className="btn btn-outline-primary"
                        onClick={() =>
                          handleUpdate(
                            addonDetails[2].id,
                            updatedAddon["days_90"]
                          )
                        }
                      >
                        Update
                      </button>
                    </td>
                  </tr>
                  <tr>
                    <td>180 Days</td>
                    <td>
                      <FormInput
                        type="number"
                        name="days_180"
                        value={updatedAddon["days_180"]}
                        onChange={handleInputChange}
                        className="form-control"
                        placeholder="₹"
                        errorMessage="please enter valid Price"
                        required={true}
                      />
                    </td>
                    <td>
                      <button
                        type="button"
                        className="btn btn-outline-primary"
                        onClick={() =>
                          handleUpdate(
                            addonDetails[3].id,
                            updatedAddon["days_180"]
                          )
                        }
                      >
                        Update
                      </button>
                    </td>
                  </tr>
                  <tr>
                    <td>365 Days</td>
                    <td>
                      <FormInput
                        type="number"
                        name="days_365"
                        value={updatedAddon["days_365"]}
                        onChange={handleInputChange}
                        className="form-control"
                        placeholder="₹"
                        errorMessage="please enter valid Price"
                        required={true}
                      />
                    </td>
                    <td>
                      <button
                        type="button"
                        className="btn btn-outline-primary"
                        onClick={() =>
                          handleUpdate(
                            addonDetails[4].id,
                            updatedAddon["days_365"]
                          )
                        }
                      >
                        Update
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
              {/* <div className="text-center">
              <button type="button" className="btn btn-outline-primary" onClick={handleUpdate}> Update </button>
            </div> */}
            </form>
          </div>
        )}
      </div>

      <ToastContainer />
    </>
  );
}

export default EditAddonPrice;
