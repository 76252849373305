import React from 'react'

export default function StaffForm({ setStep, handleInput, memberModel, staffList }) {

    const handleStaff = (e) => {
        e.preventDefault();
        if(memberModel?.staff_id){
            setStep(1)
        }
    }

    return (
        <div>
            <form  onSubmit={handleStaff}>
                <div className='row'>

                    <div className='col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6'>
                        <div className='mb-3'>
                            <label htmlFor="staffRefer" className='form-label'>Staff Name:</label>
                            <select name="staff_id" className='form-select' value={memberModel?.staff_id} onChange={handleInput} required>
                                <option value={""}>Select</option>
                                {staffList?.map((curVal, i) => (
                                    <option key={i} value={curVal?.id}>
                                        {curVal?.firstName} {curVal?.lastName}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>



                    <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                        <div className="mb-3">
                            <label htmlFor="address" className='form-label'>Staff Id: </label>
                            <input type="text" className='form-control'
                                name='staff_id'
                                value={memberModel?.staff_id}
                                onChange={handleInput}
                                placeholder='Staff Id'
                                // pattern='[A-Za-z]{3,25}'
                                // title='please use only alphabets'
                                required
                                readOnly
                            />
                        </div>
                    </div>
                </div>


                <div className='row'>
                    <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                        <div className="mb-3">
                            <label htmlFor="email" className='form-label'>Staff Shift Start Time: </label>
                            <input

                                className='form-control'
                                name='staff_time'
                                value={staffList.filter((item) => item.id == memberModel?.staff_id)[0]?.stime}
                                placeholder='Shift Time'
                                required
                                readOnly
                            />
                        </div>
                    </div>
                    <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                        <div className="mb-3">
                            <label htmlFor="email" className='form-label'>Staff Shift End Time: </label>
                            <input
                                // type="email"
                                className='form-control'
                                name='staff_time'
                                value={staffList.filter((item) => item.id == memberModel?.staff_id)[0]?.etime}
                                onChange={handleInput}
                                placeholder='Shift Time'
                                required
                                readOnly
                            />
                        </div>
                    </div>

                </div>


                <div className="modal-footer">
                    <button type="submit" className="btn btn-outline-primary">Next</button>
                </div>
            </form>
        </div>
    )
}
