import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import FormInput from "../FormInput";

function AddEditSubscriptionModel({
  show,
  setShow,
  details,
  handleAddSubscription,
  handleUpdateSubscription,
}) {
  const [subscribemodel, setSubscribeModel] = useState(details);

  useEffect(() => {
    setSubscribeModel(details);
  }, [details]);

  const handleSubmit = (e) => {
    e.preventDefault();
    details
      ? handleUpdateSubscription(subscribemodel)
      : handleAddSubscription(subscribemodel);
    setShow(false);
  };

  const handleInput = (e) => {
    setSubscribeModel((pre) => ({ ...pre, [e.target.name]: e.target.value }));
  };

  const handleTypeChange = (e) => {
    // Reset duration when type changes
    setSubscribeModel((pre) => ({ ...pre, duration: "" }));
  };

  function restrictInputLength(e, maxLength) {
    const input = e.target;
    if (input.value > maxLength) {
      input.value = maxLength;
    }
  }

  return (
    <Modal show={show} size="lg">
      <Modal.Header>
        <Modal.Title>Active Plan Details</Modal.Title>
        <button
          type="button"
          data-bs-dismiss="modal"
          className="btn-close"
          onClick={() => setShow(false)}
        ></button>
      </Modal.Header>
      <Modal.Body>
        <form action="" onSubmit={handleSubmit}>
          <div className="row">
            <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
              <div className="mb-3">
                <label htmlFor="duration" className="form-label">
                  Duration
                </label>
                <FormInput
                  type="number"
                  className="form-control"
                  name="duration"
                  value={subscribemodel?.duration}
                  placeholder="Duration"
                  title="only numeric digits"
                  onChange={handleInput}
                  onInput={(e) =>
                    restrictInputLength(
                      e,
                      subscribemodel?.type === "Days"
                        ? 31
                        : subscribemodel?.type === "Months"
                        ? 12
                        : undefined
                    )
                  }
                  // pattern="[0-9]{1,3}"
                  min={1}
                  errorMessage="please enter valid Duration"
                  required={true}
                />
              </div>
            </div>
            <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
              <div className="mb-3">
                <label htmlFor="type" className="form-label">
                  Type
                </label>
                <select
                  name="type"
                  className="form-select"
                  value={subscribemodel?.type}
                  onChange={(e) => {
                    handleInput(e);
                    handleTypeChange(e);
                  }}
                  required
                >
                  <option value="" disabled>
                    Select
                  </option>
                  <option value="Days">Days</option>
                  <option value="Months">Months</option>
                  <option value="Years">Years</option>
                </select>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
              <div className="mb-3">
                <label htmlFor="subscriptionFees" className="form-label">
                  Subscription Fees
                </label>
                <FormInput
                  type="number"
                  className="form-control"
                  name="subsriberfees"
                  title="only numeric digits"
                  placeholder="₹"
                  value={subscribemodel?.subsriberfees}
                  onChange={handleInput}
                  errorMessage="please enter valid Subscription Fees"
                  required={true}
                />
              </div>
            </div>
          </div>

          <div className="modal-footer">
            <button type="submit" className="btn btn-outline-primary">
              Submit
            </button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
}

export default AddEditSubscriptionModel;
