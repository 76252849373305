import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
// import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";
// import DatePicker from "react-datepicker";
import FormInput from "../FormInput";

export default function AddEditPrize({
  show,
  setShow,
  details,
  handleUpdatePrize,
  handleAddPrize,
}) {
  // const [startDate, setStartDate] = useState(new Date())
  const [valuePrize, setValuePrize] = useState(details);
  const [error, setError] = useState(null);
  // const [imageUpload, setImageUpload]=useState()

  useEffect(() => {
    setValuePrize(details);
  }, [details]);

  const handleInput = (e) => {
    setValuePrize((prev) => ({ ...prev, [e.target.name]: e.target.value }));

    if (
      e.target.name === "enddate" &&
      new Date(valuePrize.startdate) >= new Date(e.target.value)
    ) {
      setError("Invalid End date");
    } else {
      setError(null);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    details ? handleUpdatePrize(valuePrize) : handleAddPrize(valuePrize);
    setShow(false);
  };

  const disablePastDate = () => {
    const today = new Date();
    const dd = String(today.getDate()).padStart(2, "0");
    const mm = String(today.getMonth() + 1).padStart(2, "0");
    const yyyy = today.getFullYear();
    return yyyy + "-" + mm + "-" + dd;
  };

  return (
    <Modal show={true} size="lg">
      <Modal.Header>
        <Modal.Title>Prize Member details </Modal.Title>
        <button
          type="button"
          data-bs-dismiss="modal"
          className="btn-close"
          onClick={() => setShow(false)}
        ></button>
      </Modal.Header>
      <Modal.Body>
        <>
          <form action="" onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                <div className="mb-3">
                  <label htmlFor="prizeTitle" className="form-label">
                    Title
                  </label>
                  <FormInput
                    value={valuePrize?.title}
                    onChange={handleInput}
                    type="text"
                    name="title"
                    className="form-control"
                    placeholder="Prize Title"
                    errorMessage="Prize Title should be 2-30 characters and shouldn't include any special character or number!"
                    pattern="^[A-Za-z ]{2,30}$"
                    title="Plese enter valid name"
                    required={true}
                  />
                </div>
              </div>
              {/* <div className="col-sm-4 col-md-4 col-lg-4 col-xl-4 col-xxl-4">
                                <div className="mb-3">
                                    <label htmlFor="prizeImage" className='form-label'>Image: </label>
                                    <input type="file" multiple
                                            name='image'
                                            className='form-control' placeholder='' onChange={(e)=>{
                                                setImageUpload(e.target.files)
                                            }} />
                                </div>
                            </div> */}
              <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                <div className="mb-3">
                  <label htmlFor="prizePoint" className="form-label">
                    Points
                  </label>
                  <FormInput
                    type="number"
                    className="form-control"
                    name="point"
                    title="only numeric digits"
                    placeholder="Prize Point"
                    value={valuePrize?.point}
                    onChange={handleInput}
                    errorMessage="please enter valid points"
                    required={true}
                  />
                  {/* <select className='form-select'
                                        name="point"
                                        value={valuePrize?.point}
                                        onChange={handleInput} required>
                                        <option value="">Select Point</option>
                                        <option value='100'>100 Point</option>
                                        <option value="200">200 Point</option>
                                        <option value="300">300 Point</option>
                                        <option value="400">400 Point</option>
                                        <option value="500">500 Point</option>
                                    </select> */}
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                <div className="mb-3">
                  <label htmlFor="planStart" className="form-label">
                    Start Date
                  </label>
                  <FormInput
                    type="date"
                    name="startdate"
                    value={valuePrize?.startdate}
                    // min={disablePastDate()}
                    className="form-control"
                    onChange={handleInput}
                    errorMessage="plese enter valid Start date"
                    required={true}
                  />
                </div>
              </div>

              <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                <div className="mb-3">
                  <label htmlFor="planExpiry" className="form-label">
                    End Date
                  </label>
                  <FormInput
                    type="date"
                    className="form-control"
                    name="enddate"
                    // min={disablePastDate()}
                    value={valuePrize?.enddate}
                    onChange={handleInput}
                    errorMessage="plese enter valid end date"
                    required={true}
                  />
                  {error && <span className="text-danger">{error}</span>}
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                <div className="mb-4">
                  <label htmlFor="desc" className="form-label">
                    Description
                  </label>
                  <textarea
                    className="form-control"
                    name="description"
                    onChange={handleInput}
                    placeholder="Prize Description"
                    pattern="^(?=.*[A-Za-z])[\w\s\-\.,#]+$"
                    value={valuePrize?.description}
                    required
                    rows="6"
                  ></textarea>
                </div>
              </div>
            </div>

            <div className="modal-footer">
              <button type="submit" className="btn btn-outline-primary">
                Submit
              </button>
            </div>
          </form>
        </>
      </Modal.Body>
    </Modal>
  );
}
