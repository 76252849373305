import React from 'react'
import PuffLoader from 'react-spinners/PuffLoader';

function Loading({ loading }) {
  return (
    <div className='d-block position-fixed top-50 start-50'>
        <PuffLoader
        color={'#2762bc'}
        size={60}
        loading={loading}
      />
    </div>
  )
}

export default Loading