export function capitalFirstLetter(str) {
  if (str.length === 0) {
      return str;
  }
  return str.charAt(0).toUpperCase() + str.slice(1);
}

// Add commas to number: 99,999,999
export function numberWithCommas(x) {
  return x?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

//Format time in this form: 10:00 AM
export const to12HrFormat = (time24hr) => {
  if (time24hr) {
    const [hours, minutes] = time24hr?.split(":");
    const hours12hr = hours % 12 || 12;
    const period = hours < 12 ? "AM" : "PM";
    const time12hr = `${hours12hr}:${minutes} ${period}`;

    return time12hr;
  }
  return time24hr;
};


// Format date in this form: Mon, 4 March 2024
export function dateFormat(dateString) {
  const daysOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

  const date = new Date(dateString);

  if (isNaN(date.getTime())) {
      return "Invalid Date";
  }

  const dayOfWeek = daysOfWeek[date.getDay()];
  const day = date.getDate();
  const month = date.toLocaleString('default', { month: 'long' }); // Full month name
  const year = date.getFullYear();

  const formattedDate = `${dayOfWeek}, ${day} ${month} ${year}`;

  return formattedDate;
}

// format date time string in this form: 03 Nov 2023, 01:01 PM
export function formatDateTime(dateString) {
  const date = new Date(dateString);
  const year = date.getFullYear();
  const month = new Intl.DateTimeFormat('en-US', { month: 'short' }).format(date);
  const day = date.getDate().toString().padStart(2, '0');

  let hours = date.getHours();
  const minutes = date.getMinutes().toString().padStart(2, '0');

  const ampm = hours >= 12 ? 'PM' : 'AM';

  hours = hours % 12 || 12;

  const readableForm = `${day} ${month} ${year}, ${hours}:${minutes} ${ampm}`;

  return readableForm;
}
