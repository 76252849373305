import React, { useEffect, useState } from "react";
import axios from "axios";
import Pagination from "./Pagination";
import Loading from "./Loading";

function AllPerformer() {
  const [membersList, setMembersList] = useState([]);
  const [search, setSearch] = useState("");
  const options = { year: "numeric", month: "long", day: "numeric" };
  const [currentPage, setCurrentPage] = useState(1);
  // eslint-disable-next-line
  const [postsPerPage, setPostsPerPage] = useState(10);

  const lastPostIndex = currentPage * postsPerPage;
  const firstPostIndex = lastPostIndex - postsPerPage;
  const [filteredData, setFilteredData] = useState([]);
  const currentPosts = filteredData?.slice(firstPostIndex, lastPostIndex);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);

  const fetcAllPerformer = async () => {
    const data = {
      total: "",
    };
    const topPerformerList = await axios.post(
      `${process.env.REACT_APP_URL}topPerformanceList`,
      data
    );
    setMembersList(topPerformerList?.data.data);
  };
  useEffect(() => {
    fetcAllPerformer();
  }, []);
  // console.log(membersList);

  useEffect(() => {
    const filterData = () => {
      const filtered = membersList?.filter((curVal) => {
        let fullname = `${curVal?.firstname?.toLowerCase()} ${curVal?.lastname?.toLowerCase()}`;
        if (search.toLowerCase() === "") {
          return curVal;
        } else if (
          fullname?.includes(search.toLowerCase()) ||
          curVal?.member_id?.toLowerCase()?.includes(search.toLowerCase()) ||
          curVal?.status?.toLowerCase()?.includes(search.toLowerCase())
        ) {
          return curVal;
        }
      });

      console.log("Filter trainer", filtered);
      setFilteredData(filtered);
    };

    filterData();
  }, [membersList, search]);

  function capitalFirstLetter(str) {
    if (str.length === 0) {
      return str;
    }
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  return (
    <>
      {loading ? (
        <Loading loading={loading} />
      ) : (
        <div
          style={{ maxWidth: "1600px" }}
          className="container-fluid py-sm-5 py-md-5 py-lg-5 py-xl-5 py-xxl-5 px-5"
        >
          <div className="row mt-3">
            <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
              <h3 className="mt-5">All Performer List</h3>
            </div>
          </div>

          <section className="py-sm-3 py-md-3 py-lg-3 py-xl-3 py-xxl-3">
            <div className="row">
              <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                <input
                  type="text"
                  className="form-control"
                  value={search}
                  onChange={(e) => {
                    setSearch(e.target.value);
                    setCurrentPage(1);
                  }}
                  placeholder="Search Members..."
                />
                <div className="table table-responsive-sm table-responsive-md table-responsive-lg table-responsive-xl table-responsive-xxl mt-3">
                  <table className="table table-bordered table-striped-rows table-hover">
                    <thead className="table-dark">
                      <tr>
                        <th>Member Id</th>
                        <th>Memmber Name</th>
                        <th>Mobile No</th>
                        <th>Plan Start</th>
                        <th>Plan Expiray</th>
                        <th>Active Plan</th>
                        <th>Status</th>
                        <th>Points</th>
                        <th>Trainer</th>
                      </tr>
                    </thead>
                    <tbody>
                      {currentPosts?.length > 0 &&
                        // eslint-disable-next-line
                        currentPosts
                          // ?.filter((curVal) => {
                          //   if (search.toLowerCase() === "") {
                          //     return curVal;
                          //   } else if (
                          //     curVal?.firstname
                          //       ?.toLowerCase()
                          //       ?.includes(search.toLowerCase()) ||
                          //     curVal?.lastname
                          //       ?.toLowerCase()
                          //       ?.includes(search.toLowerCase()) ||
                          //     curVal?.member_id
                          //       ?.toLowerCase()
                          //       ?.includes(search.toLowerCase()) ||
                          //     curVal?.status
                          //       ?.toLowerCase()
                          //       ?.includes(search.toLowerCase())
                          //   ) {
                          //     return curVal;
                          //   }
                          // })
                          ?.map((curVal, i) => {
                            return (
                              <tr key={i}>
                                <td>{curVal?.member_id}</td>
                                <td>
                                  {capitalFirstLetter(curVal?.firstname)}{" "}
                                  {capitalFirstLetter(curVal?.lastname)}
                                </td>
                                <td>{curVal?.mobile}</td>
                                {curVal?.subscription_addon_details ? (
                                  <>
                                    <td>
                                      {curVal?.subscription_addon_details &&
                                        new Date(
                                          curVal?.subscription_addon_details?.startdate
                                        ).toLocaleDateString("en-US", options)}
                                    </td>
                                  </>
                                ) : (
                                  <>
                                    <td>No Active Plan</td>
                                  </>
                                )}
                                {curVal?.subscription_addon_details ? (
                                  <>
                                    <td>
                                      {curVal?.subscription_addon_details &&
                                        new Date(
                                          curVal?.subscription_addon_details?.enddate
                                        ).toLocaleDateString("en-US", options)}
                                    </td>
                                  </>
                                ) : (
                                  <>
                                    <td>No Active Plan</td>
                                  </>
                                )}
                                {curVal?.subscription_details ? (
                                  <>
                                    <td>
                                      {curVal?.subscription_details &&
                                        curVal?.subscription_details
                                          .duration}{" "}
                                      {curVal?.subscription_details &&
                                        curVal?.subscription_details.type}
                                    </td>
                                  </>
                                ) : (
                                  <>
                                    <td>No Active Plan</td>
                                  </>
                                )}
                                <td>
                                  {curVal?.status === "active"
                                    ? "Active"
                                    : "Inactive"}
                                </td>
                                <td>{curVal?.points}</td>
                                {curVal?.trainer_details ? (
                                  <>
                                    <td>
                                      {curVal?.trainer_details &&
                                        curVal?.trainer_details?.firstname}
                                    </td>
                                  </>
                                ) : (
                                  <>
                                    <td>No Trainer</td>
                                  </>
                                )}
                              </tr>
                            );
                          })}
                    </tbody>
                  </table>
                </div>
                <Pagination
                  totalPosts={filteredData?.length}
                  postsPerPage={postsPerPage}
                  setCurrentPage={setCurrentPage}
                  currentPage={currentPage}
                />
              </div>
            </div>
          </section>
        </div>
      )}
    </>
  );
}

export default AllPerformer;
