import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import FormInput from "../FormInput";

function AddEditModel({
  show,
  setShow,
  details,
  handleAddTrainer,
  handleUpdateTrainer,
  hide,
  setHide,
}) {
  const [values, setValues] = useState(details);
  const [showHide, setShowHide] = useState(false);
  const [shiftError, setShiftError] = useState("");

  useEffect(() => {
    if (values?.starttime >= values?.endtime) {
      setShiftError("Invalid End Time");
    } else {
      setShiftError("");
    }
  }, [values]);

  useEffect(() => {
    setValues(details);
  }, [details]);

  const handleChange = (e) => {
    setValues((pre) => ({ ...pre, [e.target.name]: e.target.value }));
  };
  console.log("first", values);
  const handleSubmit = (e) => {
    e.preventDefault();
    if (!shiftError) {
      details ? handleUpdateTrainer(values) : handleAddTrainer(values);
      setShow(false);
    }
  };

  function restrictInputLength(e, maxLength) {
    const input = e.target;
    if (input.value.length > maxLength) {
      input.value = input.value.slice(0, maxLength);
    }
  }

  return (
    <Modal show={show} size="lg">
      <Modal.Header>
        <Modal.Title>Trainer Details</Modal.Title>
        <button
          type="button"
          data-bs-dismiss="modal"
          className="btn-close"
          onClick={() => setShow(false)}
        ></button>
      </Modal.Header>
      <Modal.Body>
        <>
          <form action="" onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                <div className="mb-3">
                  <label htmlFor="firstName" className="form-label">
                    First Name{" "}
                  </label>
                  <FormInput
                    value={details?.firstname}
                    onChange={handleChange}
                    name="firstname"
                    placeholder="First Name"
                    errorMessage="First Name should be 2-30 characters and shouldn't include any special character or number!"
                    // label="Gym Name"
                    pattern="^[A-Za-z ]{2,30}$"
                    required={true}
                  />
                </div>
              </div>
              <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                <div className="mb-3">
                  <label htmlFor="lastName" className="form-label">
                    Last Name{" "}
                  </label>
                  <FormInput
                    value={details?.lastname}
                    onChange={handleChange}
                    name="lastname"
                    placeholder="Last Name"
                    errorMessage="Last Name should be 2-30 characters and shouldn't include any special character or number!"
                    // label="Gym Name"
                    pattern="^[A-Za-z ]{2,30}$"
                    required={true}
                  />
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                <div className="mb-3">
                  <label htmlFor="mobileNo" className="form-label">
                    Mobile No{" "}
                  </label>
                  <FormInput
                    value={values?.mobile}
                    onChange={handleChange}
                    name="mobile"
                    placeholder="Mobile Number"
                    errorMessage="Please valid mobile number and only 10 numbers"
                    // label="Gym Name"
                    pattern="^\d{10}$"
                    required={true}
                  />
                </div>
              </div>
              <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                <div className="mb-3">
                  <label htmlFor="experienceTrainer" className="form-label">
                    Trainer Experience (In Years){" "}
                  </label>
                  <FormInput
                    value={values?.experience}
                    onChange={handleChange}
                    name="experience"
                    placeholder="Trainer Experience"
                    errorMessage="Please enter valid experience"
                    // label="Gym Name"
                    pattern="^\d{1,2}$"
                    required={true}
                  />
                </div>
              </div>

              {hide == false && (
                <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                  <div className="mb-3 password-div">
                    <label htmlFor="password" className="form-label">
                      Trainer Password
                    </label>
                    <FormInput
                      name="current_password"
                      placeholder="Password"
                      type={showHide === true ? "text" : "password"}
                      value={values?.password}
                      onChange={handleChange}
                      errorMessage=" Password must: - Be between 8 and 20 characters in length, Include at least one digit (0-9), Include at least one special character (!@#$%^&*) "
                      // label="Gym Name"
                      pattern="^(?=.*[0-9])(?=.*[a-zA-Z])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,20}$"
                      required={true}
                    />
                    <i
                      className={`${
                        showHide
                          ? "eye-b fa-solid fa-eye"
                          : "eye-b fa-solid fa-eye-slash"
                      } mt-1`}
                      onClick={() => {
                        setShowHide(!showHide);
                      }}
                    ></i>
                  </div>
                </div>
              )}
            </div>

            <div className="row">
              <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                <div className="mb-1">
                  <label htmlFor="days_30_fees" className="form-label">
                    1 Month Fees{" "}
                  </label>
                  <FormInput
                    type="number"
                    className="form-control"
                    name="days_30_fees"
                    title="only numeric digits"
                    placeholder="₹"
                    value={values?.days_30_fees}
                    onChange={handleChange}
                    errorMessage="please enter valid Subscription Fees"
                    required={true}
                  />
                </div>
              </div>
              <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                <div className="mb-1">
                  <label htmlFor="days_60_fees" className="form-label">
                    2 Month Fees{" "}
                  </label>
                  <FormInput
                    type="number"
                    className="form-control"
                    name="days_60_fees"
                    title="only numeric digits"
                    placeholder="₹"
                    value={values?.days_60_fees}
                    onChange={handleChange}
                    errorMessage="please enter valid Subscription Fees"
                    required={true}
                  />
                </div>
              </div>
              <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                <div className="mb-1">
                  <label htmlFor="days_90_fees" className="form-label">
                    3 Month Fees{" "}
                  </label>
                  <FormInput
                    type="number"
                    className="form-control"
                    name="days_90_fees"
                    title="only numeric digits"
                    placeholder="₹"
                    value={values?.days_90_fees}
                    onChange={handleChange}
                    errorMessage="please enter valid Subscription Fees"
                    required={true}
                  />
                </div>
              </div>
              <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                <div className="mb-1">
                  <label htmlFor="days_180_fees" className="form-label">
                    6 Month Fees{" "}
                  </label>
                  <FormInput
                    type="number"
                    className="form-control"
                    name="days_180_fees"
                    title="only numeric digits"
                    placeholder="₹"
                    value={values?.days_180_fees}
                    onChange={handleChange}
                    errorMessage="please enter valid Subscription Fees"
                    required={true}
                  />
                </div>
              </div>
              <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                <div className="mb-3">
                  <label htmlFor="days_365_fees" className="form-label">
                    1 Year Fees{" "}
                  </label>
                  <FormInput
                    type="number"
                    className="form-control"
                    name="days_365_fees"
                    title="only numeric digits"
                    placeholder="₹"
                    value={values?.days_365_fees}
                    onChange={handleChange}
                    errorMessage="please enter valid Subscription Fees"
                    required={true}
                  />
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                <div className="mb-1">
                  <label htmlFor="alt_days_30_fees" className="form-label">
                    1 Month Fees (Alternate Days){" "}
                  </label>
                  <FormInput
                    type="number"
                    className="form-control"
                    name="alt_days_30_fees"
                    title="only numeric digits"
                    placeholder="₹"
                    value={values?.alt_days_30_fees}
                    onChange={handleChange}
                    errorMessage="please enter valid Subscription Fees"
                    required={true}
                  />
                </div>
              </div>
              <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                <div className="mb-1">
                  <label htmlFor="alt_days_60_fees" className="form-label">
                    2 Month Fees (Alternate Days){" "}
                  </label>
                  <FormInput
                    type="number"
                    className="form-control"
                    name="alt_days_60_fees"
                    title="only numeric digits"
                    placeholder="₹"
                    value={values?.alt_days_60_fees}
                    onChange={handleChange}
                    errorMessage="please enter valid Subscription Fees"
                    required={true}
                  />
                </div>
              </div>
              <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                <div className="mb-1">
                  <label htmlFor="alt_days_90_fees" className="form-label">
                    3 Month Fees (Alternate Days){" "}
                  </label>
                  <FormInput
                    type="number"
                    className="form-control"
                    name="alt_days_90_fees"
                    title="only numeric digits"
                    placeholder="₹"
                    value={values?.alt_days_90_fees}
                    onChange={handleChange}
                    errorMessage="please enter valid Subscription Fees"
                    required={true}
                  />
                </div>
              </div>
              <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                <div className="mb-1">
                  <label htmlFor="alt_days_180_fees" className="form-label">
                    6 Month Fees (Alternate Days){" "}
                  </label>
                  <FormInput
                    type="number"
                    className="form-control"
                    name="alt_days_180_fees"
                    title="only numeric digits"
                    placeholder="₹"
                    value={values?.alt_days_180_fees}
                    onChange={handleChange}
                    errorMessage="please enter valid Subscription Fees"
                    required={true}
                  />
                </div>
              </div>
              <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                <div className="mb-3">
                  <label htmlFor="alt_days_365_fees" className="form-label">
                    1 Year Fees (Alternate Days){" "}
                  </label>
                  <FormInput
                    type="number"
                    className="form-control"
                    name="alt_days_365_fees"
                    title="only numeric digits"
                    placeholder="₹"
                    value={values?.alt_days_365_fees}
                    onChange={handleChange}
                    errorMessage="please enter valid Subscription Fees"
                    required={true}
                  />
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                <div className="mb-3">
                  <label htmlFor="shiftStart" className="form-label">
                    Shift Start Time{" "}
                  </label>
                  <FormInput
                    type="time"
                    name="starttime"
                    value={values?.starttime}
                    onChange={handleChange}
                    errorMessage="please enter Shift Start Timing"
                    required={true}
                  />
                </div>
              </div>
              <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                <div className="mb-3">
                  <label htmlFor="shiftEnd" className="form-label">
                    Shift End Time{" "}
                  </label>
                  <FormInput
                    type="time"
                    name="endtime"
                    value={values?.endtime}
                    onChange={handleChange}
                    errorMessage="please enter Shift End Timing"
                    required={true}
                  />
                  <span className="input-err" style={{ color: "red" }}>
                    {shiftError && shiftError}
                  </span>
                </div>
              </div>
              {/* <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                                <div className="mb-3">
                                    <label className='form-label'>Fees: </label>
                                    <input type="text" className='form-control'
                                        name='trainerfees'
                                        placeholder="Fees"
                                        value={values?.trainerfees}
                                        onChange={handleChange}
                                        pattern="[0-9]{2,4}"
                                        title="use only number"
                                        required />
                                </div>
                            </div> */}
              <div className="modal-footer">
                <button type="submit" className="btn btn-outline-primary">
                  Submit
                </button>
              </div>
            </div>
          </form>
        </>
      </Modal.Body>
    </Modal>
  );
}

export default React.memo(AddEditModel);
