import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    trainerData: {},
    userDetails: {},
    memberId: {},
    staffId: {},
    islogin:false,
    gymId: {},
    memberId :{},
    OrderId : {},
    getStaffId : {},
    getTrainerId :{},
    trainerId : {},
    addonId:{},
    autoPay:0,
}

export const counterSlice = createSlice({
    name: 'counter',
    initialState,
    reducers: {
        getTrainerData: (state, action) => {
            state.trainerData=action.payload
        },
        getUserData: (state, action) => {
            state.userDetails=action.payload
        },
        checkLogin: (state, action) => {
            state.islogin=action.payload
        },
        getStaffId: (state, action) => {
            state.staffId=action.payload
        },
        setGymId : (state, action) =>{
            state.gymId = action.payload;
        },
        setMemberId : (state, action) =>{
            state.memberId = action.payload;
        },
        setOrderId : (state, action) =>{
            state.orderId = action.payload;
        },
        setStaffId : (state, action) =>{
            state.getStaffId = action.payload;
        },
        getTrainerId : (state, action) =>{
            state.trainerId = action.payload;
        },
        setTrainerId : (state, action) =>{
            state.getTrainerId = action.payload;
        },
        getAddonId: (state, action) =>{
            state.addonId = action.payload;
        },
        approvePay: (state, action) =>{
            state.autoPay = action.payload;
        },
        reset: () => initialState,
    }
})

export const { getTrainerData, getUserData, checkLogin, getStaffId, setGymId, setMemberId, getTrainerId, setOrderId, setStaffId, setTrainerId, getAddonId, reset , approvePay} = counterSlice.actions

export default counterSlice.reducer