import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import AddEditLockerModal from "./Trainers/AddEditLockerModal";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import Pagination from "./Pagination";
import { useSelector } from "react-redux";
import Loading from "./Loading";
import { capitalFirstLetter } from "../utils/utils";

function Locker() {
  const [show, setShow] = useState(false);
  const [lockerList, setLockerList] = useState([]);
  const [details, setDetails] = useState({});
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  // eslint-disable-next-line
  const [postsPerPage, setPostsPerPage] = useState(10);
  const [filteredData, setFilteredData] = useState([]);
  const lastPostIndex = currentPage * postsPerPage;
  const firstPostIndex = lastPostIndex - postsPerPage;
  const currentPosts = filteredData.slice(firstPostIndex, lastPostIndex);
  const getGymId = useSelector((state) => state.counterSlice.gymId);
  // console.log("the token is ", getGymId)
  const deviceToken = getGymId.device_token;
  // console.log(deviceToken);

  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);

  const handleOnClickAddLocker = () => {
    setDetails(null);
    setShow(true);
  };

  const handleAddLocker = async (data) => {
    const config = {
      headers: {
        Authorization: deviceToken,
      },
    };
    const res = await axios.post(
      `${process.env.REACT_APP_URL}addLocker`,
      data,
      config
    );
    if (res?.status === 200) {
      fetchLockers();
      toast.success(res?.data.message, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  const handleUpdateLocker = async (data) => {
    const config = {
      headers: {
        Authorization: deviceToken,
      },
    };
    const res = await axios.post(
      `${process.env.REACT_APP_URL}updateLocker/${data?.id}`,
      data,
      config
    );
    if (res?.status === 200) {
      fetchLockers();
      toast.success(res?.data.message, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  const fetchLockers = async () => {
    const config = {
      headers: {
        Authorization: deviceToken,
      },
    };
    const allLockers = await axios.get(
      `${process.env.REACT_APP_URL}getAllLocker`,
      config
    );
    setLockerList(allLockers?.data.data);
  };

  useEffect(() => {
    const filterData = () => {
      const filtered = lockerList?.filter((curVal) => {
        if (search.toLowerCase() === "") {
          return curVal;
        } else if (
          curVal?.locker_no?.toLowerCase()?.includes(search.toLowerCase()) ||
          curVal?.title?.toLowerCase()?.includes(search.toLowerCase())
        ) {
          return curVal;
        }
      });

      setFilteredData(filtered);
    };

    filterData();
  }, [lockerList, search]);

  useEffect(() => {
    fetchLockers();
  }, []);

  const handleEdit = (details) => {
    setDetails(details);
    setShow(true);
  };

  const handleDelete = async ({ id = null }) => {
    const res = await axios.delete(
      `${process.env.REACT_APP_URL}deleteLocker/${id}`
    );
    if (res?.status === 200) {
      fetchLockers();
      toast.success(res?.data.message, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  return (
    <>
      {loading ? (
        <Loading loading={loading} />
      ) : (
        <div
          style={{ maxWidth: "1600px" }}
          className="mx-auto container-fluid px-5 py-sm-5 py-md-5 py-lg-5 py-xl-5 py-xxl-5 px-5"
        >
          <div className="row mt-3">
            <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
              <h3 className="mt-5">Locker List</h3>
            </div>
            <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
              <div className="d-flex justify-content-end">
                <button
                  className="btn btn-outline-primary mt-5 "
                  data-bs-toggle="modal"
                  onClick={handleOnClickAddLocker}
                >
                  Add Locker
                </button>
              </div>
            </div>
          </div>

          <section className="py-sm-3 py-md-3 py-lg-3 py-xl-3 py-xxl-3">
            <div className="row">
              <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                <input
                  type="text"
                  className="form-control"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                  placeholder="Search Lockers..."
                />
                <div className="table table-responsive-sm table-responsive-md table-responsive-lg table-responsive-xl table-responsive-xxl mt-3">
                  <table className="table table-bordered table-striped-rows table-hover">
                    <thead className="table-dark">
                      <tr>
                        <th>Locker ID</th>
                        <th>Locker Name</th>
                        <th>Locker Description</th>
                        <th>Locker Availability</th>
                        <th>Locker Assign</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {currentPosts?.length > 0 &&
                        // eslint-disable-next-line
                        currentPosts
                          // ?.filter((curVal) => {
                          //   if (search.toLowerCase() === "") {
                          //     return curVal;
                          //   } else if (
                          //     curVal?.locker_no
                          //       ?.toLowerCase()
                          //       ?.includes(search.toLowerCase()) ||
                          //     curVal?.title
                          //       ?.toLowerCase()
                          //       ?.includes(search.toLowerCase())
                          //   ) {
                          //     return curVal;
                          //   }
                          // })
                          ?.map((curVal, i) => {
                            return (
                              <tr key={i}>
                                <td>{curVal?.locker_no}</td>
                                <td>{capitalFirstLetter(curVal?.title)}</td>
                                <td>{curVal?.description}</td>
                                <td>
                                  {curVal?.status === 1 ? "Available" : ""}
                                </td>
                                <td>
                                  {curVal?.assign === 1
                                    ? "Assigned"
                                    : curVal?.assign === 0
                                    ? "Not Assigned"
                                    : ""}
                                </td>
                                <td>
                                  <div className="d-flex justify-content-evenly align-items-center">
                                    <FontAwesomeIcon
                                      onClick={() => handleEdit(curVal)}
                                      icon={faEdit}
                                    />
                                    <FontAwesomeIcon
                                      onClick={() => handleDelete(curVal)}
                                      icon={faTrash}
                                    />
                                  </div>
                                </td>
                              </tr>
                            );
                          })}
                    </tbody>
                  </table>
                </div>
                <Pagination
                  totalPosts={filteredData?.length}
                  postsPerPage={postsPerPage}
                  setCurrentPage={setCurrentPage}
                  currentPage={currentPage}
                />
              </div>
            </div>
            {show && (
              <AddEditLockerModal
                show={show}
                setShow={setShow}
                handleAddLocker={handleAddLocker}
                handleUpdateLocker={handleUpdateLocker}
                details={details}
              />
            )}
          </section>
          <ToastContainer />
        </div>
      )}
    </>
  );
}

export default Locker;
