import axios from 'axios'
import React, { useState } from 'react'

const AddPrize = (props) => {
    const [valuePrize, setValuePrize] = useState({
        title: "",
       
        startdate: "",
        enddate: "",
        point: "",

    })
    const handleInput = (e) => {
        e.preventDefault()
        setValuePrize({ ...valuePrize, [e.target.name]: e.target.value })
    }
    const handleSubmit = (e) => {
        e.preventDefault();
        addprize();
        // console.log("@@@@@@", valuePrize)
    }
    const addprize = async () => {
        // eslint-disable-next-line
        const add = await axios.post(`${process.env.REACT_APP_URL}addPrize`,valuePrize )
        // console.log("first",add.data.data)    
    }



    return (
        <div className="modal fade" id={props.id} tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-lg">
                <div className="modal-content">
                    <div className="modal-header">
                        <h1 className="modal-title fs-5" id="exampleModalLabel">Add Prize</h1>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        <form action="" onSubmit={handleSubmit} >
                            <div className="row">
                            
                                {/* <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                                    <div className="mb-3">
                                        <label htmlFor="firstName" className='form-label'>ID : </label>
                                        <input type="text" name='fname' className='form-control' placeholder='First Name' required />
                                    </div>
                                </div> */}
                                <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                                    <div className="mb-3">
                                        <label htmlFor="lastName" className='form-label'>Name: </label>
                                        <input type="text"
                                            name='title'
                                            value={valuePrize.title}
                                            onChange={handleInput}
                                            className='form-control' placeholder='Last Name' required />
                                    </div>
                                </div>
                                <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                                    <div className="mb-3">
                                        <label htmlFor="mobileNo" className='form-label'>Description: </label>
                                        {/* <input type="text"
                                            name='description'
                                            value={valuePrize.description}
                                            onChange={handleInput}
                                            className='form-control' placeholder='Description' required /> */}
                                    </div>
                                </div>

                            </div>

                            <div className="row">

                                <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                                    <div className="mb-3">
                                        <label htmlFor="experienceTrainer" className='form-label'>Image: </label>
                                        {/* <input type="file" multiple
                                            name='image'
                                            className='form-control' placeholder='' /> */}
                                    </div>
                                </div>

                                <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                                    <div className="mb-3">
                                        <label htmlFor="membershipPlan" className='form-label'>Point</label>
                                        <select className='form-select'
                                            name="point"
                                            value={valuePrize.point}
                                            onChange={handleInput}>
                                            <option value="">Select Point</option>
                                            <option value='100 Point'>100 Point</option>
                                            <option value="200 Point">200 Point</option>
                                            <option value="300 Point">300 Point</option>
                                            <option value="400 Point">400 Point</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                                    <div className="mb-3">
                                        <label htmlFor="planStart" className='form-label'>Start Date</label>
                                        <input type="date" className='form-control'
                                            name='startdate'
                                            value={valuePrize.startdate}
                                            onChange={handleInput}
                                            required />
                                    </div>
                                </div>

                                <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                                    <div className="mb-3">
                                        <label htmlFor="planExpiry" className='form-label'>Plan Expiry: </label>
                                        <input type="date" className='form-control'
                                            name='enddate'
                                            value={valuePrize.enddate}
                                            onChange={handleInput}
                                            required />
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="submit" className="btn btn-primary">Submit</button>
                            </div>
                        </form>
                    </div>

                </div>
            </div>
        </div>)
}

export default AddPrize