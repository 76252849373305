import React, { useState, useEffect } from "react";
import {
  faCalendarCheck,
  faEdit,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import AddEditStaffModal from "./Trainers/AddEditStaffModal";
// Notification
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Pagination from "./Pagination";
import Loading from "./Loading";
import { useDispatch, useSelector } from "react-redux";
import { getStaffId } from "../redux/counterSlice";
import { setStaffId } from "../redux/counterSlice";
import { Link } from "react-router-dom";
import AttendanceCalendar from "./Trainers/AttendanceCalendar";
import AttendanceCalforStaff from "./Trainers/AttendanceCalforStaff";
import { capitalFirstLetter, to12HrFormat } from "../utils/utils";

function Staff() {
  const dispatch = useDispatch();
  const [staffList, setStaffList] = useState([]);
  const [details, setDetails] = useState({});
  const [show, setShow] = useState(false);
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  // eslint-disable-next-line
  const [postsPerPage, setPostsPerPage] = useState(10);
  const [calendarShow, setCalendarShow] = useState(false);
  const [attendanceData, setAttendanceData] = useState([]);
  const lastPostIndex = currentPage * postsPerPage;
  const firstPostIndex = lastPostIndex - postsPerPage;
  const [filteredData, setFilteredData] = useState([]);
  const currentPosts = filteredData.slice(firstPostIndex, lastPostIndex);
  const getGymId = useSelector((state) => state.counterSlice.gymId);
  // console.log("the token is ", getGymId)
  const deviceToken = getGymId.device_token;
  // console.log(deviceToken);
  const getStaff = useSelector((state) => state.counterSlice.getStaffId);
  const [editData, setEditData] = useState({});

  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);

  const fetchStaffList = async () => {
    const config = {
      headers: {
        Authorization: deviceToken,
      },
    };
    const getAllStaff = await axios.get(
      `${process.env.REACT_APP_URL}getAllStaffByGym`,
      config
    );
    setStaffList(getAllStaff?.data.data);
  };
  useEffect(() => {
    fetchStaffList();
  }, []);

  useEffect(() => {
    const filterData = () => {
      const filtered = staffList?.filter((curVal) => {
        let fullname = `${curVal?.firstName?.toLowerCase()} ${curVal?.lastName?.toLowerCase()}`;
        if (search.toLowerCase() === "") {
          return curVal;
        } else if (
          fullname?.includes(search.toLowerCase()) ||
          curVal?.designation?.toLowerCase()?.includes(search.toLowerCase()) ||
          curVal?.manager?.toLowerCase()?.includes(search.toLowerCase())
        ) {
          return curVal;
        }
      });

      console.log("Filter trainer", filtered);
      setFilteredData(filtered);
    };

    filterData();
  }, [staffList, search]);

  const handleAddStaff = async (data) => {
    const config = {
      headers: {
        Authorization: deviceToken,
      },
    };
    const res = await axios.post(
      `${process.env.REACT_APP_URL}addStaff`,
      data,
      config
    );
    if (res?.status === 200) {
      fetchStaffList();
      toast.success(res?.data.message, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  const handleUpdateStaff = async (data) => {
    const config = {
      headers: {
        Authorization: deviceToken,
      },
    };
    const res = await axios.post(
      `${process.env.REACT_APP_URL}updateStaff/${data?.id}`,
      data,
      config
    );
    if (res?.status === 200) {
      fetchStaffList();
      toast.success(res?.data.message, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  const handleOnClickAddStaff = () => {
    setEditData(null);
    setShow(true);
  };

  const handleEdit = (details) => {
    setDetails(details);

    setEditData({
      ...details,
      start_time: details?.stime,
      end_time: details?.etime,
    });

    console.log("Edit data: ", editData);
    setShow(true);
  };

  const handleDelete = async ({ id = null }) => {
    const res = await axios.delete(
      `${process.env.REACT_APP_URL}deleteStaff/${id}`
    );
    if (res?.status === 200) {
      fetchStaffList();
      toast.success(res?.data.message, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  const handleViewCollection = (id) => {
    dispatch(getStaffId(id));
  };
  // console.log(staffList);

  const handleCheckIn = async (staffId) => {
    const config = {
      headers: {
        Authorization: deviceToken,
      },
    };

    const data = {
      staff_id: staffId,
    };

    try {
      const res = await axios.post(
        `${process.env.REACT_APP_URL}AddCheckInStaff`,
        data,
        config
      );
      if (res?.status === 200) {
        dispatch(setStaffId(staffId));
        toast.success(res?.data.message, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    } catch (error) {
      console.error("Error checking in staff:", error);
    }
  };

  const handleCheckOut = async (staffId) => {
    const config = {
      headers: {
        Authorization: deviceToken,
      },
    };

    const data = {
      staff_id: staffId,
    };

    try {
      const res = await axios.post(
        `${process.env.REACT_APP_URL}AddCheckOutStaff`,
        data,
        config
      );
      if (res?.status === 200) {
        dispatch(setStaffId(staffId));
        toast.success(res?.data.message, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    } catch (error) {
      console.error("Error checking in staff:", error);
    }
  };

  const handleAttendance = async (id) => {
    const res = await axios.get(
      `${process.env.REACT_APP_URL}TotalAddendanceStaff/${id}`
    );
    if (res?.status === 200) {
      setAttendanceData(res?.data.data);
      setCalendarShow(true);
    }
  };

  return (
    <>
      {loading ? (
        <Loading loading={loading} />
      ) : (
        <div
          style={{ maxWidth: "1600px" }}
          className="mx-auto container-fluid px-5 py-sm-5 py-md-5 py-lg-5 py-xl-5 py-xxl-5"
        >
          <div className="row mt-3">
            <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
              <h3 className="mt-5">Staff List</h3>
            </div>
            <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
              <div className="d-flex justify-content-end">
                <button
                  className="btn btn-outline-primary mt-5"
                  data-bs-toggle="modal"
                  onClick={handleOnClickAddStaff}
                >
                  Add Staff
                </button>
              </div>
            </div>
          </div>

          <section className="py-sm-3 py-md-3 py-lg-3 py-xl-3 py-xxl-3">
            <div className="row">
              <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                <input
                  type="text"
                  className="form-control"
                  value={search}
                  onChange={(e) => {
                    setSearch(e.target.value);
                    setCurrentPage(1);
                  }}
                  placeholder="Search Staffs..."
                />
                <div className="table table-responsive-sm table-responsive-md table-responsive-lg table-responsive-xl table-responsive-xxl mt-3">
                  <table className="table table-bordered table-striped-rows table-hover">
                    <thead className="table-dark">
                      <tr>
                        <th>Staff ID</th>
                        <th>Name</th>
                        {/* <th>Mobile No</th> */}
                        <th>Designation</th>
                        <th>Manager Role</th>
                        <th>Manager Name</th>
                        <th>Shift Timing</th>
                        <th>Attendance</th>
                        <th>Collection</th>
                        <th>Actions</th>
                        {/* <th>CheckIn</th>
                                            <th>CheckOut</th> */}
                      </tr>
                    </thead>
                    <tbody>
                      {currentPosts?.length > 0 &&
                        // eslint-disable-next-line
                        currentPosts
                          // ?.filter((curVal) => {
                          //   if (search.toLowerCase() === "") {
                          //     return curVal;
                          //   } else if (
                          //     curVal?.firstName
                          //       ?.toLowerCase()
                          //       ?.includes(search.toLowerCase()) ||
                          //     curVal?.lastName
                          //       ?.toLowerCase()
                          //       ?.includes(search.toLowerCase()) ||
                          //     curVal?.designation
                          //       ?.toLowerCase()
                          //       ?.includes(search.toLowerCase()) ||
                          //     curVal?.manager
                          //       ?.toLowerCase()
                          //       ?.includes(search.toLowerCase())
                          //   ) {
                          //     return curVal;
                          //   }
                          // })
                          ?.map((curVal, i) => {
                            // console.log("map##", curVal)
                            return (
                              <tr key={i}>
                                <td>{curVal?.staff_id}</td>
                                <td>
                                  {capitalFirstLetter(curVal?.firstName)}{" "}
                                  {capitalFirstLetter(curVal?.lastName)}
                                </td>
                                {/* <td>{curVal?.mobile}</td> */}
                                <td>{curVal?.designation}</td>
                                <td>{curVal?.manager}</td>
                                <td>{curVal?.reportingManager}</td>
                                <td>
                                  {to12HrFormat(curVal?.stime)} -{" "}
                                  {to12HrFormat(curVal?.etime)}
                                  {/* {curVal?.stime} - {curVal?.etime} */}
                                </td>
                                <td>
                                  <div className="d-flex justify-content-center align-items-center">
                                    <FontAwesomeIcon
                                      style={{ cursor: "pointer" }}
                                      onClick={() =>
                                        handleAttendance(curVal?.staff_id)
                                      }
                                      icon={faCalendarCheck}
                                    />
                                  </div>
                                </td>
                                <td>
                                  <Link
                                    to={"/staffcollection"}
                                    onClick={() =>
                                      handleViewCollection(curVal?.id)
                                    }
                                  >
                                    View Collection
                                  </Link>
                                </td>
                                <td>
                                  <div className="d-flex justify-content-evenly align-items-center">
                                    <FontAwesomeIcon
                                      onClick={() => handleEdit(curVal)}
                                      icon={faEdit}
                                    />
                                    <FontAwesomeIcon
                                      onClick={() => handleDelete(curVal)}
                                      icon={faTrash}
                                    />
                                  </div>
                                </td>
                                {/* <td>
                                      <button
                                          className="btn btn-primary"
                                          onClick={() => handleCheckIn(curVal?.id)} // Pass staffId here
                                      >
                                          CheckIn
                                      </button>
                                    </td>
                                    <td>
                                      <button
                                          className="btn btn-primary"
                                          onClick={() => handleCheckOut(curVal?.id)} // Pass staffId here
                                      >
                                          CheckOut
                                      </button>
                                    </td> */}
                              </tr>
                            );
                          })}
                    </tbody>
                  </table>
                </div>
                <Pagination
                  totalPosts={filteredData?.length}
                  postsPerPage={postsPerPage}
                  setCurrentPage={setCurrentPage}
                  currentPage={currentPage}
                />
              </div>
            </div>

            {show && (
              <AddEditStaffModal
                show={show}
                setShow={setShow}
                details={editData}
                handleAddStaff={handleAddStaff}
                handleUpdateStaff={handleUpdateStaff}
              />
            )}
            {calendarShow && (
              <AttendanceCalforStaff
                calendarShow={calendarShow}
                setCalendarShow={setCalendarShow}
                attendanceData={attendanceData}
              />
            )}
          </section>
          <ToastContainer />
        </div>
      )}
    </>
  );
}

export default Staff;

// import React, { useState, useEffect } from 'react';
// import { faCalendarCheck, faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import axios from 'axios';
// import AddEditStaffModal from './Trainers/AddEditStaffModal';
// // Notification
// import { ToastContainer, toast } from "react-toastify";
// import 'react-toastify/dist/ReactToastify.css';
// import Pagination from './Pagination';
// import Loading from './Loading';
// import { useDispatch, useSelector } from 'react-redux';
// import { getStaffId } from '../redux/counterSlice';
// import { setStaffId } from '../redux/counterSlice';
// import { Link } from 'react-router-dom';
// import AttendanceCalendar from './Trainers/AttendanceCalendar';
// import AttendanceCalforStaff from './Trainers/AttendanceCalforStaff';

// function Staff() {
//     const dispatch = useDispatch();
//     const [staffList, setStaffList] = useState([]);
//     const [details, setDetails] = useState({});
//     const [show, setShow] = useState(false);
//     const [search, setSearch] = useState('');
//     const [currentPage, setCurrentPage] = useState(1);
//     // eslint-disable-next-line
//     const [postsPerPage, setPostsPerPage] = useState(10);
//     const [calendarShow, setCalendarShow] = useState(false);
//     const [attendanceData, setAttendanceData] = useState([]);
//     const lastPostIndex = currentPage * postsPerPage;
//     const firstPostIndex = lastPostIndex - postsPerPage;
//     const currentPosts = staffList.slice(firstPostIndex, lastPostIndex);
//     const getGymId = useSelector(state=>state.counterSlice.gymId);
//     // console.log("the token is ", getGymId)
//     const deviceToken = getGymId.device_token;
//     // console.log(deviceToken);
//     const getStaff = useSelector((state) => state.counterSlice.getStaffId);

//     const [loading, setLoading] = useState(false);
//     useEffect(() => {
//         setLoading(true)
//         setTimeout(() => {
//         setLoading(false)
//         }, 5000)
//     }, [])

//     const fetchStaffList = async () => {
//         const config = {
//             headers:{
//               "Authorization" : deviceToken,
//             }
//         };
//         const getAllStaff = await axios.get(`${process.env.REACT_APP_URL}getAllStaffByGym`, config);
//         setStaffList(getAllStaff?.data.data);
//     }
//     useEffect(() => {
//       fetchStaffList();
//     }, []);

//     const handleAddStaff = async (data) => {
//         const config = {
//             headers:{
//               "Authorization" : deviceToken,
//             }
//           };
//         const res = await axios.post(`${process.env.REACT_APP_URL}addStaff`, data, config);
//         if(res?.status === 200) {
//             fetchStaffList();
//             toast.success(res?.data.message, {
//                 position: 'top-right',
//                 autoClose: 3000,
//                 hideProgressBar: false,
//                 closeOnClick: true,
//                 pauseOnHover: true,
//                 draggable: true,
//                 progress: undefined,
//                 theme: 'light',
//             });
//         }
//     }

//     const handleUpdateStaff =  async (data) => {
//         const config = {
//             headers:{
//               "Authorization" : deviceToken,
//             }
//           };
//         const res = await axios.post(`${process.env.REACT_APP_URL}updateStaff/${data?.id}`, data, config);
//         if(res?.status === 200) {
//             fetchStaffList();
//             toast.success(res?.data.message, {
//                 position: 'top-right',
//                 autoClose: 3000,
//                 hideProgressBar: false,
//                 closeOnClick: true,
//                 pauseOnHover: true,
//                 draggable: true,
//                 progress: undefined,
//                 theme: 'light',
//             });
//         }
//     }

//     const handleOnClickAddStaff = () => {
//         setDetails(null);
//         setShow(true);
//     }

//     const handleEdit = (details) => {
//         setDetails(details);
//         setShow(true);
//     }

//     const handleDelete = async ({id = null}) => {
//         const res = await axios.delete(`${process.env.REACT_APP_URL}deleteStaff/${id}`);
//         if(res?.status === 200) {
//             fetchStaffList();
//             toast.success(res?.data.message, {
//                 position: 'top-right',
//                 autoClose: 3000,
//                 hideProgressBar: false,
//                 closeOnClick: true,
//                 pauseOnHover: true,
//                 draggable: true,
//                 progress: undefined,
//                 theme: 'light',
//             });
//         }
//     }

//     const handleViewCollection = (id) => {
//         dispatch(getStaffId(id));
//     }
//     // console.log(staffList);

//     const handleCheckIn = async (staffId) => {
//         const config = {
//             headers: {
//                 "Authorization": deviceToken,
//             },
//         };

//         const data = {
//             staff_id: staffId,
//         };

//         try {
//             const res = await axios.post(`${process.env.REACT_APP_URL}AddCheckInStaff`, data, config);
//             if (res?.status === 200) {
//                 dispatch(setStaffId(staffId));
//                 toast.success(res?.data.message, {
//                     position: 'top-right',
//                     autoClose: 3000,
//                     hideProgressBar: false,
//                     closeOnClick: true,
//                     pauseOnHover: true,
//                     draggable: true,
//                     progress: undefined,
//                     theme: 'light',
//                 });
//             }
//         } catch (error) {
//             console.error("Error checking in staff:", error);
//         }
//     };

//     const handleCheckOut = async (staffId) => {
//         const config = {
//             headers: {
//                 "Authorization": deviceToken,
//             },
//         };

//         const data = {
//             staff_id: staffId,
//         };

//         try {
//             const res = await axios.post(`${process.env.REACT_APP_URL}AddCheckOutStaff`, data, config);
//             if (res?.status === 200) {
//                 dispatch(setStaffId(staffId));
//                 toast.success(res?.data.message, {
//                     position: 'top-right',
//                     autoClose: 3000,
//                     hideProgressBar: false,
//                     closeOnClick: true,
//                     pauseOnHover: true,
//                     draggable: true,
//                     progress: undefined,
//                     theme: 'light',
//                 });
//             }
//         } catch (error) {
//             console.error("Error checking in staff:", error);
//         }
//     };

//     const handleAttendance =  (id) => {
//         const res =  axios.get(`${process.env.REACT_APP_URL}TotalAddendanceStaff/${id}`);
//         if(res?.status === 200){
//             setAttendanceData(res?.data.data);
//             setCalendarShow(true);
//         }
//         console.log("date", setAttendanceData)
//     }

//     return (
//         <>
//         {
//             loading ? <Loading loading={loading} />
//             :
//             <div className='container py-sm-5 py-md-5 py-lg-5 py-xl-5 py-xxl-5'>
//                 <div className="row">
//                     <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
//                         <h3 className='mt-5'>Staff List</h3>
//                     </div>
//                     <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
//                         <button
//                             className="btn btn-outline-primary mt-5 float-sm-end float-md-end float-lg-end float-xl-end float-xxl-end"
//                             data-bs-toggle="modal"
//                             onClick={handleOnClickAddStaff}
//                         >
//                             Add Staff
//                         </button>
//                     </div>
//                 </div>

//                 <section className='py-sm-5 py-md-5 py-lg-5 py-xl-5 py-xxl-5'>
//                     <div className="row">
//                         <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
//                             <input type="text" className='form-control' value={search} onChange={(e) => setSearch(e.target.value)} placeholder='Search Staffs...' />
//                             <div className='table table-responsive-sm table-responsive-md table-responsive-lg table-responsive-xl table-responsive-xxl mt-3'>
//                                 <table className="table table-bordered table-striped-rows table-hover">
//                                     <thead className='table-dark'>
//                                         <tr>
//                                             <th>Name</th>
//                                             <th>Mobile No</th>
//                                             <th>Designation</th>
//                                             <th>Manager Role</th>
//                                             <th>Manager Name</th>
//                                             <th>Shift Timing</th>
//                                             <th>Attendance</th>
//                                             <th>Collection</th>
//                                             <th>Actions</th>
//                                             <th>CheckIn</th>
//                                             <th>CheckOut</th>
//                                         </tr>
//                                     </thead>
//                                     <tbody>
//                                         {currentPosts?.length > 0 &&
//                                             // eslint-disable-next-line
//                                             currentPosts?.filter((curVal) => {
//                                                 if(search.toLowerCase() === '') {
//                                                     return curVal;
//                                                 }
//                                                 else if(curVal?.firstName?.toLowerCase()?.includes(search.toLowerCase())
//                                                 || curVal?.lastName?.toLowerCase()?.includes(search.toLowerCase())
//                                                 || curVal?.designation?.toLowerCase()?.includes(search.toLowerCase())
//                                                 || curVal?.manager?.toLowerCase()?.includes(search.toLowerCase())
//                                                 ) {
//                                                     return curVal;
//                                                 }
//                                             })
//                                             ?.map((curVal, i) => {
//                                                 // console.log("map##", curVal)
//                                                 return (
//                                                     <tr key={i}>
//                                                         <td>{curVal?.firstName} {curVal?.lastName}</td>
//                                                         <td>{curVal?.mobile}</td>
//                                                         <td>{curVal?.designation}</td>
//                                                         <td>{curVal?.manager}</td>
//                                                         <td>{curVal?.reportingManager}</td>
//                                                         <td>{curVal?.stime} TO {curVal?.etime}</td>
//                                                         <td>
//                                                             <div className='d-flex justify-content-center align-items-center'>
//                                                                 <FontAwesomeIcon style={{cursor:"pointer"}} onClick={() => handleAttendance(curVal?.id)} icon={faCalendarCheck} />
//                                                             </div>
//                                                         </td>
//                                                         <td>
//                                                             <Link to={'/staffcollection'} onClick={() => handleViewCollection(curVal?.id)}>View Collection</Link>
//                                                         </td>
//                                                         <td>
//                                                             <div className="d-flex justify-content-evenly align-items-center">
//                                                                 <FontAwesomeIcon onClick={() => handleEdit(curVal)} icon={faEdit} />
//                                                                 <FontAwesomeIcon onClick={() => handleDelete(curVal)} icon={faTrash} />
//                                                             </div>
//                                                         </td>
//                                                         <td>
//                                                             <button
//                                                                 className="btn btn-primary"
//                                                                 onClick={() => handleCheckIn(curVal?.id)} // Pass staffId here
//                                                             >
//                                                                 CheckIn
//                                                             </button>
//                                                         </td>
//                                                         <td>
//                                                             <button
//                                                                 className="btn btn-primary"
//                                                                 onClick={() => handleCheckOut(curVal?.id)} // Pass staffId here
//                                                             >
//                                                                 CheckOut
//                                                             </button>
//                                                         </td>
//                                                     </tr>
//                                                 )
//                                             })
//                                         }

//                                     </tbody>
//                                 </table>
//                             </div>
//                             <Pagination
//                                 totalPosts={staffList?.length}
//                                 postsPerPage={postsPerPage}
//                                 setCurrentPage={setCurrentPage}
//                                 currentPage={currentPage}
//                             />
//                         </div>
//                     </div>

//                     {show && <AddEditStaffModal
//                         show={show}
//                         setShow={setShow}
//                         details={details}
//                         handleAddStaff={handleAddStaff}
//                         handleUpdateStaff={handleUpdateStaff}
//                     />}
//                     {calendarShow && <AttendanceCalforStaff
//                         calendarShow={calendarShow}
//                         setCalendarShow={setCalendarShow}
//                         attendanceData={attendanceData}
//                     />}
//                 </section>
//                 <ToastContainer />
//             </div>
//         }
//         </>
//     )
// }

// export default Staff
