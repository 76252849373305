import React, { useState, useEffect } from "react";
import axios from "axios";
// import { useSelector } from 'react-redux';
import { useDispatch, useSelector } from "react-redux";
import { setOrderId } from "../redux/counterSlice";
import { useNavigate } from "react-router-dom";
import { formatDateTime, numberWithCommas } from "../utils/utils";

function PaymentHistory() {
  const nav = useNavigate();
  const dispatch = useDispatch();
  const [paymentData, setPaymentData] = useState([]);
  const setMemberId = useSelector((state) => state.counterSlice.memberId);
  //   console.log("hsvhxbkx", setMemberId)

  useEffect(() => {
    // Fetch data from the API using Axios
    axios
      .get(
        `https://ui15cpxq82.execute-api.us-east-1.amazonaws.com/userpayment/${setMemberId}`
      )
      .then((response) => {
        // Assuming the API response has an object of data
        // const data = response.data.data;
        setPaymentData(response?.data?.data); // Set the fetched data in state
      })
      .catch((error) => {
        console.error("Error fetching data from the API:", error);
      });
  }, []);

  const handleOrderId = (value) => {
    dispatch(setOrderId(value));
    nav("/in");
    console.log("value", value);
  };

  return (
    <div
      className="mx-auto table table-responsive-sm table-responsive-md table-responsive-lg table-responsive-xl table-responsive-xxl px-5"
      style={{ marginTop: "120px", maxWidth: "1600px" }}
    >
      <h3 className="mb-3">Payment History</h3>
      <table className="table table-bordered table-striped-rows table-hover">
        <thead className="table-dark">
          <tr>
            <th>Staff Id</th>
            <th>Transaction Id</th>
            <th>Created At</th>
            <th>Amount</th>
            {/* <th>Amount Due</th>
            <th>Paid</th> */}
            <th>Download Invoice</th>
          </tr>
        </thead>
        <tbody>
          {paymentData.length > 0 ? (
            paymentData.map((item) => (
              <tr>
                <td>{item.staff_id}</td>
                <td>{item.transaction_id}</td>
                <td>{formatDateTime(item.created_at)}</td>
                <td>Rs. {numberWithCommas(parseFloat(item.amount_paid).toFixed(2))}</td>
                {/* <td>₹{(parseFloat(item.amount_due) / 100).toFixed(2)}</td>
                <td>₹{(parseFloat(item.amount_paid) / 100).toFixed(2)}</td> */}
                <td>
                  <span
                    className="btn fw-medium"
                    onClick={() => handleOrderId(item.order_id)}
                  >
                    Download
                  </span>{" "}
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="6">No Data Found</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
}

export default PaymentHistory;
