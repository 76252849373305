import React from 'react'

function Pagination({ totalPosts, postsPerPage, setCurrentPage, currentPage}) {
    let pages = [];
    for(let i = 1; i <= Math.ceil(totalPosts / postsPerPage); i++) {
        pages.push(i);
    }

  return (
    <nav aria-label="Page Navigation">
        <ul className="pagination justify-content-end">
            <li className={currentPage === 1 ? 'page-item disabled' : 'page-item'} style={{cursor:"pointer"}}>
                <a className="page-link" onClick={() => setCurrentPage(currentPage - 1)} href>
                    Previous
                </a>
            </li>
            {pages.map((page, index) => {
                return (
                <li key={index} className={page === currentPage ? "page-item active" : "page-item"} style={{cursor:"pointer"}}>
                    <a
                    key={index}
                    onClick={() => setCurrentPage(page)}
                    className="page-link"
                    href
                    >
                    {page}
                    </a>
                </li>
                );
            })}
            <li className={currentPage === pages.length - 0 ? 'page-item disabled' : 'page-item'} style={{cursor:"pointer"}}>
                <a className="page-link" onClick={() => setCurrentPage(currentPage + 1)} href>
                    Next
                </a>
            </li>
        </ul>
    </nav>
  )
}

export default Pagination