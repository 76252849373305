import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import Loading from "../Loading";
import axios from "axios";
import Pagination from "../Pagination";
import { CSVLink } from "react-csv";
import moment from "moment/moment";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import { DateRangePicker } from "react-date-range";

function StaffCollection() {
  const getStaffID = useSelector((state) => state.counterSlice.staffId);
  const [staffCollection, setStaffCollection] = useState([]);
  const [collectionList, setCollectionList] = useState([]);
  const [filterDate, setFilterDate] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  // const [total, setTotal] = useState(0);
  // eslint-disable-next-line
  const [postsPerPage, setPostsPerPage] = useState(10);
  const [filteredData, setFilteredData] = useState([]);
  const lastPostIndex = currentPage * postsPerPage;
  const firstPostIndex = lastPostIndex - postsPerPage;
  const currentPosts = filteredData.slice(firstPostIndex, lastPostIndex);

  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  // console.log(getStaffID);

  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);

  useEffect(() => {
    const filterData = () => {
      const filtered = collectionList?.filter((curVal) => {
        let fullName =
          curVal?.data[0]?.firstname?.toLowerCase() +
          " " +
          curVal?.data[0]?.lastname?.toLowerCase();
        if (search.toLowerCase() === "") {
          return curVal;
        } else if (
          curVal?.data[0]?.member_id
            ?.toLowerCase()
            ?.includes(search.toLowerCase()) ||
          fullName?.includes(search.toLowerCase())
        ) {
          return curVal;
        }
      });

      setFilteredData(filtered);
    };

    filterData();
  }, [collectionList, search]);

  useEffect(() => {
    fetchStaffCollection();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    sortData();
    // eslint-disable-next-line
  }, [staffCollection]);

  const fetchStaffCollection = async () => {
    // setLoading(true);
    const res = await axios.get(
      `${process.env.REACT_APP_URL}staffcollection/${getStaffID}`
    );
    if (res?.status === 200) {
      setStaffCollection(res?.data?.data);
      // sortData();
    }
  };

  const sortData = () => {
    let sortdata = [];
    staffCollection?.sort((a, b) => a.order_id - b.order_id);
    staffCollection?.forEach((curVal) => {
      if (sortdata === undefined || sortdata?.length === 0) {
        let d = {
          common_id: curVal?.common_id,
          data: [curVal],
        };
        sortdata?.push(d);
      } else {
        let st = sortdata?.find((e) => e.common_id === curVal?.common_id);
        if (st !== undefined) {
          let existData = st.data;
          // if(existData?.length !== 4) {
          existData.push(curVal);
          let d = {
            common_id: curVal?.common_id,
            data: existData,
          };
          sortdata?.push(d);
          // }
        } else {
          let d = {
            common_id: curVal?.common_id,
            data: [curVal],
          };
          sortdata?.push(d);
        }
      }
    });
    if (sortdata?.length === 1) {
      setCollectionList(sortdata);
      setFilterDate(sortdata);
    }
    // else {
    // }
    const filterList = sortdata?.filter(
      (v, i, a) => a.findIndex((t) => t.common_id === v.common_id) === i
    );
    setCollectionList(filterList);
    setFilterDate(filterList);
    // setLoading(false);
  };

  const createCsvFileName = () =>
    `Staff_Collection_data_${moment().format("DD-MM-YYYY")}.csv`;

  const headers = [
    { label: "Member ID", key: "member_id" },
    { label: "Firstname", key: "firstname" },
    { label: "Lastname", key: "lastname" },
    { label: "Mobile", key: "mobile" },
    { label: "Subscription and Addons Fees", key: "addons" },
    { label: "Total Amount", key: "amount" },
    { label: "Status", key: "status" },
    { label: "Date", key: "date" },
  ];

  const csvData = collectionList?.map((item) => ({
    member_id: item?.data[0]?.member_id,
    firstname: item?.data[0]?.firstname,
    lastname: item?.data[0]?.lastname,
    mobile: item?.data[0]?.mobile,
    addons: item?.data?.map((e) => `${e?.addon_type}: ${e?.fees}`),
    amount: Math.ceil(item?.data[0]?.amount) / 100,
    status: item?.data[0]?.status,
    date: moment(item?.data[0]?.updated_at).format("YYYY-MM-DD"),
  }));

  const selectionRange = {
    startDate: startDate,
    endDate: endDate,
    key: "selection",
  };

  const handleSelect = (date) => {
    let filteredDate = filterDate?.filter((product) => {
      let collectionDate = new Date(product?.data[0]?.updated_at);
      return (
        collectionDate >= date.selection.startDate &&
        collectionDate <= date.selection.endDate
      );
    });
    setStartDate(date.selection.startDate);
    setEndDate(date.selection.endDate);
    setCollectionList(filteredDate);
  };
  const handleCalendar = () => {
    let showCal = document.getElementById("showCalendar");
    showCal?.classList?.remove("show-calendar");
  };
  const handleClearFilter = () => {
    let showCal = document.getElementById("showCalendar");
    setStartDate(new Date());
    setEndDate(new Date());
    sortData();
    showCal?.classList?.add("show-calendar");
  };
  // console.log(collectionList);

  // let totalamount = 0;
  // const totalAmount = () => {
  //    setTotal(staffCollection?.map((curVal) => totalamount += curVal?.amount_paid))
  // }
  // console.log(Math.ceil(total)/100);

  return (
    <>
      {loading ? (
        <Loading loading={loading} />
      ) : (
        <div
          style={{ maxWidth: "1600px" }}
          className="mx-auto container-fluid px-5 py-sm-5 py-md-5 py-lg-5 py-xl-5 py-xxl-5"
        >
          <div className="d-flex justify-content-between align-items-center mt-5">
            <h3 className="">Staff Collection List</h3>
            <div className="d-flex gap-4">
              <CSVLink
                data={csvData}
                headers={headers}
                filename={createCsvFileName()}
              >
                <button className="btn btn-outline-primary">
                  Export to CSV
                </button>
              </CSVLink>
              <button
                className="btn btn-outline-info"
                type="button"
                onClick={() => handleCalendar()}
              >
                Date Filter
              </button>
              <button
                className="btn btn-outline-danger"
                type="button"
                onClick={() => handleClearFilter()}
              >
                Reset
              </button>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12 col-lg-12">
              <div className="d-flex gap-2 float-end"></div>
              <div className="float-end show-calendar" id="showCalendar">
                <DateRangePicker
                  ranges={[selectionRange]}
                  onChange={handleSelect}
                  staticRanges={[]}
                  inputRanges={[]}
                  maxDate={new Date()}
                />
              </div>
            </div>
          </div>
          <section className="py-sm-3 py-md-3 py-lg-3 py-xl-3 py-xxl-3">
            <div className="row pt-3">
              <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                <input
                  type="text"
                  className="form-control"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                  placeholder="Search..."
                />
                <div className="table table-responsive-sm table-responsive-md table-responsive-lg table-responsive-xl table-responsive-xxl mt-3">
                  <table className="table table-bordered table-striped-rows">
                    <thead className="table-dark">
                      <tr>
                        <th>Member ID</th>
                        <th>Transaction ID</th>
                        <th>Name</th>
                        <th>Mobile No</th>
                        <th>Amount</th>
                        <th>Status</th>
                        <th>Date</th>
                      </tr>
                    </thead>
                    <tbody>
                      {currentPosts?.length > 0 &&
                        // eslint-disable-next-line
                        currentPosts
                          // ?.filter((curVal) => {
                          //   if (search.toLowerCase() === "") {
                          //     return curVal;
                          //   } else if (
                          //     curVal?.data[0]?.member_id
                          //       ?.toLowerCase()
                          //       ?.includes(search.toLowerCase()) ||
                          //     curVal?.data[0]?.firstname
                          //       ?.toLowerCase()
                          //       ?.includes(search.toLowerCase()) ||
                          //     curVal?.data[0]?.lastname
                          //       ?.toLowerCase()
                          //       ?.includes(search.toLowerCase())
                          //   ) {
                          //     return curVal;
                          //   }
                          // })
                          ?.map((curVal, i) => {
                            return (
                              <>
                                <tr key={i}>
                                  <td>{curVal?.data[0]?.member_id}</td>
                                  <td>{curVal?.data[0]?.transaction_id}</td>
                                  <td>
                                    {curVal?.data[0]?.firstname}{" "}
                                    {curVal?.data[0]?.lastname}
                                  </td>
                                  <td>{curVal?.data[0]?.mobile}</td>
                                  <td>
                                    <table className="table table-bordered table-hover m-0">
                                      <thead className="table-secondary">
                                        <tr>
                                          {curVal?.data?.map((e) => {
                                            return (
                                              <th className="fw-medium p-1">
                                                {e?.addon_type}
                                              </th>
                                            );
                                          })}
                                          <th className="fw-medium p-1 text-end">
                                            Total
                                          </th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <tr>
                                          {curVal?.data?.map((e) => {
                                            return (
                                              <td className="p-1">
                                                &#8377;{e?.fees}
                                              </td>
                                            );
                                          })}
                                          <td className="p-1 text-end">
                                            &#8377;
                                            {Math.ceil(
                                              curVal?.data[0]?.amount
                                            )}
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </td>
                                  {/* <td>{curVal?.data?.map((e) => `${e.addon_type} ${e.amount_paid}`)}</td> */}
                                  <td>{curVal?.data[0]?.status === "paid" && curVal?.data[0]?.amount_due == 0 ? "Paid" :
                                 curVal?.data[0]?.status === "paid" && curVal?.data[0]?.amount_due != 0 ?`Partial Pay Rs ${curVal?.data[0]?.amount_paid}` :
                                    curVal?.data[0]?.status === "attempted" ? `Partial Pay Rs ${curVal?.data[0]?.amount_paid}`
                                    : curVal?.data[0]?.status
                                  }</td>
                                  <td>
                                    {new Date(
                                      curVal?.data[0]?.updated_at
                                    ).toLocaleDateString()}
                                  </td>
                                </tr>
                              </>
                            );
                          })}
                      {/* <tr>
                                        <th colSpan={4} className='text-end'>Total</th>
                                        <td>&#8377;{Math.ceil(totalamount)/100}</td>
                                    </tr> */}
                    </tbody>
                  </table>
                </div>
                <Pagination
                  totalPosts={filteredData?.length}
                  postsPerPage={postsPerPage}
                  setCurrentPage={setCurrentPage}
                  currentPage={currentPage}
                />
              </div>
            </div>
          </section>
        </div>
      )}
    </>
  );
}

export default StaffCollection;
