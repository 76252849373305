import axios from "axios";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
// Notification
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { checkLogin, setGymId } from "../redux/counterSlice";
import FormInput from "./FormInput";
// import { getToken } from 'firebase/messaging';
// import { messaging } from '../firebase';

function Login() {
  const getDataInRedux = useSelector((state) => state.counterSlice.islogin);
  // console.log(getDataInRedux);
  const [showHide, setShowHide] = useState(false);

  const [loginValue, setLoginValue] = useState({ email: "", password: "" });
  // const [deviceToken, setDeviceToken] = useState('');
  // console.log(11, loginValue)

  const dispatch = useDispatch();
  const navigate = useNavigate();

  // const requestPermision = async () => {

  // }

  // useEffect(() => {
  //  requestPermision();
  // }, [])

  const loginSubmit = async (e) => {
    e.preventDefault();
    // const permission = await Notification.requestPermission();
    // let token;
    // if(permission === 'granted') {
    //   token = await getToken(messaging);
    // }
    // else if(permission === 'denied') {
    //   alert("You denied for the notification");
    // }
    // console.log(token);
    await axios
      .post(`${process.env.REACT_APP_URL}/login`, {
        email: loginValue.email,
        password: loginValue.password,
      })
      .then((response) => {
        if (response?.status === 200) {
          // const gymId = ;
          dispatch(setGymId(response.data.result)); // store gym id in redux
          // console.log('Stored Gym ID in redux:', gymId); // Debugging: Log the extracted gym ID

          // localStorage.setItem('gymId', gymId);
          // console.log('Stored Gym ID in localStorage');

          dispatch(checkLogin(true));
          // console.log('Dispatched checkLogin(true)');
          toast.success(response?.data.message, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          localStorage.setItem("dataInRedux", getDataInRedux);
          localStorage.setItem("adminId", response.data.result.id);
          navigate("/");
        }
      })
      .catch((error) => {
        // console.log(error);
        toast.warn(error.response.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      });
  };

  // const loginSubmit = (e) => {
  //   e.preventDefault();
  //   axios.post('login', {
  //     email: loginValue.email,
  //     password: loginValue.password
  //   })
  //   .then(function (response) {
  //     console.log(response);
  //     if(response.status === 200) {
  //       dispatch(checkLogin(true));
  //       toast.success(response.data.message);
  //       localStorage.setItem('dataInRedux', getDataInRedux);
  //       localStorage.setItem('adminId', response.data.result.id);
  //       navigate('/');
  //     }
  //     })
  //     .catch(function (error) {
  //       toast.warn(error.response.data.message,{position: "top-right",
  //       autoClose: 5000,
  //       hideProgressBar: false,
  //       closeOnClick: true,
  //       pauseOnHover: true,
  //       draggable: true,
  //       progress: undefined,
  //       theme: "light",});
  //     });
  // }

  const handleLogin = (e) => {
    setLoginValue((pre) => ({ ...pre, [e.target.name]: e.target.value }));
    // console.log(e.target.value);
  };

  return (
    <div
      className="container-sm p-5-md p-0-sm d-flex justify-content-center align-items-center"
      style={{ height: "80vh", maxWidth: "550px" }}
    >
      <div className="card w-100">
        <div className="card-header px-4">
          <div className="card-title">GYM Application</div>
        </div>
        <div className="card-body p-4">
          <form onSubmit={loginSubmit}>
            <div className="mb-3">
              <label htmlFor="userName" className="form-label px-1">
                Email
              </label>
              <FormInput
                name="email"
                placeholder="email@example.com"
                value={loginValue?.email}
                onChange={handleLogin}
                errorMessage="Email is required"
                required={true}
              />
            </div>
            <div className="mb-3 password-div">
              <label htmlFor="password" className="form-label px-1">
                Password
              </label>
              <FormInput
                name="password"
                placeholder="Password"
                type={showHide === true ? "text" : "password"}
                value={loginValue?.password}
                onChange={handleLogin}
                errorMessage="Password is required"
                required={true}
              />
              <i
                className={`${
                  showHide
                    ? "eye-b fa-solid fa-eye"
                    : "eye-b fa-solid fa-eye-slash"
                } mt-1`}
                onClick={() => {
                  setShowHide(!showHide);
                }}
              ></i>
            </div>

            {/* <div>
              <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault"
                onChange={() => {
                  setShowHide(!showHide)
                }} />
            </div> */}

            <button type="submit" className="btn btn-outline-primary">
              LogIn
            </button>
          </form>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
}

export default Login;
