import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { reset } from "../redux/counterSlice";
import { useDispatch, useSelector } from "react-redux";
import Notification from "./Notification";
// import axios from 'axios';

function Header() {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const getDataInRedux = useSelector((state) => state.counterSlice.islogin);
    const gymName = useSelector((state) => state.counterSlice.gymId.gym_name);
    const logo = useSelector((state) => state.counterSlice.gymId)?.logo;
    console.log("first", logo)
    const handleReset = () => {
        dispatch(reset());
        navigate("/login");
        localStorage.removeItem("dataInRedux");
        localStorage.removeItem("adminId");
    };
    // const handleNotification = async () => {
    //     const res = await axios.get(`${process.env.REACT_APP_URL}test_cron/plan_expiry_api`);
    //     setNoti(res?.data.data);
    //     setCount(res?.data.count);
    // }
    // useEffect(() => {
    //     handleNotification();
    // }, [])

    return (
        <nav className="navbar navbar-expand-lg navbar-dark bg-dark fixed-top">
            <div className="container-fluid">
                <Link className="navbar-brand" to={"/"}>
                    <span className='mx-3'> {logo && <img src={`${process.env.REACT_APP_URL}${logo}`} alt='Gym Logo' height={"50px"} style={{ borderRadius: "10px" }} />}</span>
                    <span > {gymName ? gymName : "GYM Application"}</span>
                </Link>
                <button
                    className="navbar-toggler"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#navbarSupportedContent"
                    aria-controls="navbarSupportedContent"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                >
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul className="navbar-nav justify-content-end flex-grow-1 pe-3">
                        <li className="nav-item">
                            <NavLink className="nav-link" activeclassname="active" aria-current="page" to={"/"}>Home</NavLink>
                        </li>
                        <li className="nav-item dropdown">
                            <Link className="nav-link dropdown-toggle" href="/admin" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                <span className='pe-3'>Gym Members</span>
                                
                            </Link>
                            <ul className="dropdown-menu">
                                <li><Link className="dropdown-item" to={'/members'} >Members</Link></li>
                                <li><Link className="dropdown-item" to={'/trainers'} >Trainers</Link></li>
                                <li><Link className="dropdown-item" to={'/staff'} >Staff</Link></li>
                            </ul>
                        </li>
                        {/* <li className="nav-item">
                            <NavLink className="nav-link" activeclassname="active" to={"/members"}>Members</NavLink>
                        </li> */}
                        {/* <li className="nav-item">
                            <NavLink className="nav-link" activeclassname="active" to={'/trainers'}>Trainers</NavLink>
                        </li> */}
                        {/* <li className="nav-item">
                            <NavLink className="nav-link" activeclassname="active" to={'/staff'}>Staff</NavLink>
                        </li> */}
                        {/* <li className="nav-item">
                            <NavLink className="nav-link" activeclassname="active" to={'/prize'}>Prize</NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink className="nav-link" activeclassname="active" to={'/subscription'}>Plans</NavLink>
                        </li> */}

                        <li className="nav-item dropdown">
                            <Link className="nav-link dropdown-toggle" href="/admin" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                <span className='pe-3'>Prize</span>
                                
                            </Link>
                            <ul className="dropdown-menu">
                                <li><Link className="dropdown-item" to={'/prize'} >Prize</Link></li>
                                <li><Link className="dropdown-item" to={'/subscription'} >Plans</Link></li>
                            </ul>
                        </li>
                        {/* <li className="nav-item">
                            <NavLink className="nav-link" activeclassname="active" to={'/locker'}>Locker</NavLink>
                        </li> */}
                        {/* <li className="nav-item">
                            <NavLink className="nav-link" activeclassname="active" to={'/addonprice'}>AddonPrice</NavLink>
                        </li> */}
                        <li className="nav-item dropdown">
                            <Link className="nav-link dropdown-toggle" href="/admin" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                <span className='pe-3'>Addons</span>
                                
                            </Link>
                            <ul className="dropdown-menu">
                                <li><Link className="dropdown-item" to={'/addonprice'} >AddonPrice</Link></li>
                                <li><Link className="dropdown-item" to={'/locker'} >Locker</Link></li>
                            </ul>
                        </li>
                        {/* <li className='nav-item'>
                            <NavLink className='nav-link' activeclassname="active" to={'/notification'}>Notification</NavLink>
                        </li> */}
                        {/* <li className='nav-item'>
                            <NavLink className='nav-link' activeclassname="active" to={'/transaction'}>Transaction</NavLink>
                        </li> */}
                        <li className="nav-item dropdown">
                            <Link className="nav-link dropdown-toggle" href="/admin" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                <span className='pe-3'>Admin</span>
                                <FontAwesomeIcon icon={faUser} />
                            </Link>
                            <ul className="dropdown-menu">
                                <li><Link className="dropdown-item" to={'/profile'} >Profile</Link></li>
                                <li><Link className="dropdown-item" to={'/notification'} >Notification</Link></li>
                                <li><Link className="dropdown-item" to={'/transaction'} >Transaction</Link></li>
                                {getDataInRedux === true &&
                                    <li><Link className="dropdown-item" onClick={handleReset} to={''} >Log out</Link></li>
                                }
                            </ul>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    );
}

export default Header;

