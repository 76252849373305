import React, { useEffect, useState } from "react";
import {
  faCalendarCheck,
  faEdit,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import AddEditMemberModel from "./Trainers/AddEditMemberModel";
// Notification
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AttendanceCalendar from "./Trainers/AttendanceCalendar";
import AddPTDocumentModel from "./Trainers/AddPTDocumentModel";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getUserData } from "../redux/counterSlice";
import Pagination from "./Pagination";
import Loading from "./Loading";
import { CSVLink } from "react-csv";
import moment from "moment/moment";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import { DateRangePicker } from "react-date-range";
import { faImage } from "@fortawesome/free-solid-svg-icons"; // Import the image icon
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { setMemberId } from "../redux/counterSlice";
import { useNavigate } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import {
  capitalFirstLetter,
  dateFormat,
  numberWithCommas,
} from "../utils/utils";
// import { useDispatch, useSelector } from 'react-redux';

function Members() {
  const [show, setShow] = useState(false);
  const navigate = useNavigate();
  const [calendarShow, setCalendarShow] = useState(false);
  const [ptModalShow, setPTModalShow] = useState(false);
  const [details, setDetails] = useState({});
  const [memberList1, setMemberList1] = useState([]);
  const [attendanceData, setAttendanceData] = useState([]);
  const [search, setSearch] = useState("");
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage, setPostsPerPage] = useState(10);
  const [hide, setHide] = useState(false);
  const [filteredData, setFilteredData] = useState([]);
  
  const [activeMember, setActiveMember] = useState([]);
  const [filterActiveData, setFilterActiveData] = useState([]);
  const [activeMemberPage, setActiveMemberPage] = useState(1);
  const [activeSearch, setActiveSearch] = useState("");
  const activeMemLastIndex = activeMemberPage * postsPerPage;
  const activeMemFirstIndex = activeMemLastIndex - postsPerPage;
  const currentActiveMember = filterActiveData.slice(activeMemFirstIndex, activeMemLastIndex);

  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [filterDate, setFilterDate] = useState([]);
  const [unpaidAmount, setUnpaidAmount] = useState("");
  const [paidAmount, setPaidAmount] = useState("");
  const [totalAmount, setTotalAmount] = useState("");
  const [memberData, setMemberData] = useState([]);

  // const [razorPayKey, setRazorPayKey] = useState('');
  const getGymId = useSelector((state) => state.counterSlice.gymId);
  const deviceToken = getGymId.device_token;

  const [qrModel, setQrModel] = useState(false);
  const [orderId, setOrderId] = useState("");
  const [qrData, setQrData] = useState("");
  const [transactionId, setTransactionId] = useState("");

  const config = {
    headers: {
      Authorization: deviceToken,
    },
  };

  const lastPostIndex = currentPage * postsPerPage;
  const firstPostIndex = lastPostIndex - postsPerPage;
  const currentPosts = filteredData?.slice(firstPostIndex, lastPostIndex);

  //   useEffect(() => {
  //     const filterData = () => {
  //         const filtered = memberList1.filter((curElem) => {
  //             if (search === '') {
  //                 return true;
  //             } else {
  //                 return Object.values(curElem).some((value) =>
  //                     value?.toString().toLowerCase().includes(search.toLowerCase())
  //                 );
  //             }
  //         });

  //         setFilteredData(filtered);
  //     };

  //     filterData();
  // }, [memberList1, search]);

  useEffect(() => {
    const filterData = () => {
        const filtered = memberList1.filter((curElem) => {
            if (search === '') {
                return true;
            } else {
                const userIdMatch = curElem.member_id
                    ?.toString()
                    .toLowerCase()
                    .includes(search.toLowerCase());
                const firstNameMatch = curElem.firstname
                    ?.toString()
                    .toLowerCase()
                    .includes(search.toLowerCase());
                const lastNameMatch = curElem.lastname
                    ?.toString()
                    .toLowerCase()
                    .includes(search.toLowerCase());

                return userIdMatch || firstNameMatch || lastNameMatch;
            }
        });

        setFilteredData(filtered);
    };

    filterData();
}, [memberList1, search]);


useEffect(() => {
  const filterActiveData = () => {
      const filteredAct = activeMember.filter((curElem) => {
          if (activeSearch === '') {
              return true;
          } else {
              const userIdMatch = curElem.member_id
                  ?.toString()
                  .toLowerCase()
                  .includes(activeSearch.toLowerCase());
              const firstNameMatch = curElem.firstname
                  ?.toString()
                  .toLowerCase()
                  .includes(activeSearch.toLowerCase());
              const lastNameMatch = curElem.lastname
                  ?.toString()
                  .toLowerCase()
                  .includes(activeSearch.toLowerCase());

              return userIdMatch || firstNameMatch || lastNameMatch;
          }
      });

      setFilterActiveData(filteredAct);
  };
  filterActiveData();
}, [activeMember, activeSearch]);

  const loadScript = (src) => {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  };

  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 5000);
  }, []);

  const handleAddMember = async (formData) => {
    const config = {
      headers: {
        Authorization: deviceToken,
      },
    };
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_URL}addUser`,
        formData,
        config
      );
      console.log(98989, res?.errors?.email[0]);
      if (res?.status === 200) {
        fetchUserMembers();
        toast.success("Member Added Successfully!", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        setShow(false);
      }
    } catch (error) {
      console.log(error?.response?.data.errors?.email[0]);
      toast.error(error?.response?.data.errors?.email[0], {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  const handleUpdateMember = async (formData) => {
    const config = {
      headers: {
        Authorization: deviceToken,
      },
    };
    const res = await axios.post(
      `${process.env.REACT_APP_URL}updateUser/${formData?.id}`,
      formData,
      config
    );
    if (res?.status === 200) {
      fetchUserMembers();
      window.location.reload();
      toast.success(res?.data.message, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  const handleDelete = async ({ id = null }) => {
    const res = await axios.get(`${process.env.REACT_APP_URL}deleteUser/${id}`);
    if (res?.status === 200) {
      fetchUserMembers();
      toast.success(res?.data.message, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  const fetchUserMembers = async () => {
    const config = {
      headers: {
        Authorization: deviceToken,
      },
    };
    const getAllUser = await axios.get(
      `${process.env.REACT_APP_URL}getAllUser`,
      config
    );
    setMemberList1(getAllUser?.data.data);
  };

  const fetchActiveMembers = async () => {
    const config = {
      headers: {
        Authorization: deviceToken,
      },
    };
    const activeUser = await axios.get(
      `${process.env.REACT_APP_URL}getAllActiveUserByGym`,
      config
    );
    setActiveMember(activeUser?.data.data);
    // console.log("active", setActiveMember);
    setFilterDate(activeUser?.data.data);
    setUnpaidAmount(activeUser?.data.total_upaid); /// bhimraj
    setPaidAmount(activeUser?.data.total_paid);
    setTotalAmount(activeUser?.data.total);
    // setRazorPayKey(activeUser?.data.rpay_key);

    if (activeUser?.status === 200) {
      console.log("active", activeUser);
    }
  };

  const handleAddDietPlan = async (data) => {
    const res = await axios.post(
      `${process.env.REACT_APP_URL}add_diet_workout/${details?.member_id}`,
      data
    );
    // console.log(res);
    if (res?.status === 200) {
      fetchUserMembers();
      toast.success(res?.data.message, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  useEffect(() => {
    fetchUserMembers();
    fetchActiveMembers();
  }, []);

  const handleOnClickAddMember = () => {
    setDetails(null);
    setShow(true);
    setHide(false);
    // console.log("first")
  };

  // const handleEdit = (details) => {
  //     setDetails(details);
  //     setShow(true);

  // }
  const handleEdit = (formData) => {
    setDetails(formData);
    setShow(true);
    setHide(true);
  };

  const handleAttendance = async (memberId) => {
    const res = await axios.get(
      `${process.env.REACT_APP_URL}totalAddendance/${memberId}`
    );
    if (res?.status === 200) {
      setAttendanceData(res?.data.data);
      setCalendarShow(true);
    }
  };

  const handlePTModel = (details) => {
    setDetails(details);
    setPTModalShow(true);
  };

  const handleUserDetails = (details) => {
    dispatch(getUserData(details));
  };

  const createCsvFileName = () =>
    `Active_Members_Data_${moment().format("DD-MM-YYYY")}.csv`;

  const headers = [
    { label: "Member ID", key: "member_id" },
    { label: "First Name", key: "firstname" },
    { label: "Last Name", key: "lastname" },
    { label: "Mobile", key: "mobile" },
    { label: "Plan Start Date", key: "startdate" },
    { label: "Plan End Date", key: "enddate" },
    { label: "Duration", key: "duration" },
  ];

  const selectionRange = {
    startDate: startDate,
    endDate: endDate,
    key: "selection",
  };

  const handleSelect = (date) => {
    let filteredDate = filterDate?.filter((product) => {
      let collectionDate = new Date(product?.startdate);
      console.log(collectionDate);
      return (
        collectionDate >= date.selection.startDate &&
        collectionDate <= date.selection.endDate
      );
    });
    setStartDate(date.selection.startDate);
    setEndDate(date.selection.endDate);
    setActiveMember(filteredDate);
  };

  const handleCalendar = () => {
    let showCal = document.getElementById("showMemberCalendar");
    showCal?.classList?.remove("show-member-calendar");
  };
  const handleClearFilter = () => {
    let showCal = document.getElementById("showMemberCalendar");
    setStartDate(new Date());
    setEndDate(new Date());
    fetchActiveMembers();
    showCal?.classList?.add("show-member-calendar");
  };

  const success_state = {
    order_id: "",
    payment_status: 1,
    payment_id: "",
    signature: "",
  };

  // const handleUnpaidAmount = async () => {
  //     const config = {
  //         headers: {
  //             "Authorization": deviceToken,
  //         }
  //     };
  //     const resAdminPay = await axios.post(`${process.env.REACT_APP_URL}adminpayment`, null, config);
  //     const Res = await loadScript('https://checkout.razorpay.com/v1/checkout.js');
  //     if (!Res) {
  //         alert('Razorpay SDK failed to load. Are you online?');
  //         return;
  //     }
  //     // let totalunpaidCost = unpaidAmount * 100;
  //     const options = {
  //         key: resAdminPay?.data.rpay_key,
  //         name: 'GYMApplication',
  //         description: 'Test Transaction',
  //         order_id: resAdminPay?.data.admin_order_id,
  //         // amount: totalunpaidCost,
  //         handler: function (response) {
  //             success_state.order_id = response.razorpay_order_id;
  //             success_state.payment_id = response.razorpay_payment_id;
  //             success_state.signature = response.razorpay_signature;
  //             alert(response.razorpay_payment_id);
  //             alert(response.razorpay_order_id);
  //             alert(response.razorpay_signature);
  //             if (response) {
  //                 const suc_data = { ...success_state }
  //                 axios.post(`${process.env.REACT_APP_URL}/admin_update_payment`, suc_data).then((resp) => {
  //                     // console.log(resp);
  //                     if (resp?.status === 200) {
  //                         toast.success(resp?.data.message, {
  //                             position: 'top-right',
  //                             autoClose: 3000,
  //                             hideProgressBar: false,
  //                             closeOnClick: true,
  //                             pauseOnHover: true,
  //                             draggable: true,
  //                             progress: undefined,
  //                             theme: 'light',
  //                         });
  //                         fetchActiveMembers();
  //                     }
  //                 }).catch((err) => {
  //                     console.log(err);
  //                 })
  //             }
  //         },
  //         theme: {
  //             color: '#3399cc'
  //         },
  //     }
  //     const paymentObject = new window.Razorpay(options);
  //     paymentObject.open();
  //     paymentObject.on('payment.failed', function (response) {
  //         alert(response.error.code);
  //         alert(response.error.description);
  //         alert(response.error.source);
  //         alert(response.error.step);
  //         alert(response.error.reason);
  //         alert(response.error.metadata.order_id);
  //         alert(response.error.metadata.payment_id);
  //     });
  // }

  const activateOrDeactivateMember = async (memberId, status) => {
    try {
      const config = {
        headers: {
          Authorization: deviceToken,
        },
      };
      const response = await axios.get(
        `https://ui15cpxq82.execute-api.us-east-1.amazonaws.com/activateOrDeactivateUser/${memberId}/${status}`,
        config
      );

      if (response.status === 200) {
        const updatedMembers = memberData.map((member) => {
          // Use 'memberData' here
          if (member.id === memberId) {
            member.user_status = status === 1 ? "ACTIVE" : "INACTIVE";
          }
          return member;
        });
        setMemberData(updatedMembers); // Use 'setMemberData' here
        // window.location.reload();
        fetchUserMembers();
      } else {
        // Handle the error case here
      }
    } catch (error) {
      // Handle any errors that occurred during the API call
      console.error("Error activating/deactivating member:", error);
    }
  };

  const handleImageChange = async (e, memberId) => {
    const imageFile = e.target.files[0];

    // Create a form data object to send the image file
    const formData = new FormData();
    formData.append("image", imageFile);

    const config = {
      headers: {
        Authorization: deviceToken,
        "Content-Type": "multipart/form-data", // Set the content type for file upload
      },
    };

    try {
      const res = await axios.post(
        `${process.env.REACT_APP_URL}updateMemberLogo/${memberId}`,
        formData,
        config
      );

      if (res?.status === 200) {
        // Handle success, maybe update the UI or display a message
        fetchUserMembers();
        toast.success("Image updated successfully!", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      } else {
        // Handle any errors that occur during image upload
        console.error("Image upload failed.");
      }
    } catch (error) {
      console.error("Error uploading image:", error);
    }
  };

  const handleHistory = (value) => {
    dispatch(setMemberId(value));
    navigate("/up");
  };

  const handleTransaction = async (e) => {
    e.preventDefault();
    setQrModel(false);
    // let trans = {
    //     order_id : orderId,
    // transaction_id : transactionId,
    // }
    //     try {
    //         const transaction = `${process.env.REACT_APP_URL}addTransactionId `;
    //         const res = await axios.post(transaction, trans,);
    //        if(res?.status === 200){
    //         toast.success(res?.data.message)
    //         setQrModel(false);
    //        }
    //       } catch (error) {
    //         console.error("Error fetching addons data: ", error);
    //       }
  };

  const fetchQrCode = async () => {
    try {
      const qrcode = `${process.env.REACT_APP_URL}sa/getGymSuperAdminQR`;
      const getCode = await axios.get(qrcode);
      setQrData(getCode?.data?.data?.qr_image);
    } catch (error) {
      console.log("Error fetching duration data:", error);
    }
  };

  const handleUnpaidAmount = async () => {
    const resAdminPay = await axios.post(
      `${process.env.REACT_APP_URL}adminpayment`,
      null,
      config
    );
    if (resAdminPay?.status === 200) {
      setOrderId(resAdminPay.data?.admin_order_id);
      fetchQrCode();
      setQrModel(true);
    }
  };

  let tranValue = {
    order_id: orderId,
    admin_transaction_id: transactionId,
    admin_amount: unpaidAmount,
  };
  const handlePayAmount = async (e) => {
    e.preventDefault();
    const resAdminPay = await axios.post(
      `${process.env.REACT_APP_URL}admin_update_payment`,
      tranValue,
      config
    );
    if (resAdminPay?.status == 200) {
      fetchActiveMembers();
      setQrModel(false);
      toast.success(resAdminPay?.data?.message, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  // console.log(orderId);
  return (
    <>
      {loading ? (
        <Loading loading={loading} />
      ) : (
        <div
          style={{ maxWidth: "1600px" }}
          className="mx-auto container-fluid px-5 py-sm-5 py-md-5 py-lg-5 py-xl-5 py-xxl-5 "
        >
          <div className="row mt-4">
            <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
              <h3 className="mt-5">Members List</h3>
            </div>
            <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
              <div className="d-flex justify-content-end">
                <button
                  className="btn btn-outline-primary mt-5"
                  data-bs-toggle="modal"
                  onClick={handleOnClickAddMember}
                >
                  Add Member
                </button>
              </div>
            </div>
          </div>

          <section className="py-sm-3 py-md-3 py-lg-3 py-xl-3 py-xxl-3">
            <div className="row">
              <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                <input
                  type="text"
                  className="form-control"
                  value={search}
                  onChange={(e) => {
                    setSearch(e.target.value);
                    setCurrentPage(1)
                  }}
                  placeholder="Search Members..."
                />
                <div className="table-responsive mt-3">
                  <table className="table table-bordered table-striped-rows table-hover w-100">
                    <thead className="table-dark">
                      <tr>
                        <th
                          style={{
                            whiteSpace: "nowrap",
                          }}
                        >
                          Member ID
                        </th>
                        <th>Image</th>
                        <th>Name</th>
                        <th>Mobile No</th>
                        <th>Invoice</th>
                        {/* <th>Plan Start</th>
                                            <th>Plan Expiry</th>
                                            <th>Active Plan</th>
                                            <th>Plan Fees</th>
                                            <th>Amount Paid</th>
                                            <th>Outstanding Balance</th>
                                            <th>Total Balance</th>
                                            <th>Trainer Fees</th> */}

                        <th
                          style={{
                            whiteSpace: "nowrap",
                          }}
                        >
                          Trainer/PT Details
                        </th>
                        <th>Plan Status</th>
                        <th
                          style={{
                            whiteSpace: "nowrap",
                          }}
                        >
                          Add-Ons Status
                        </th>
                        <th>Attendance</th>
                        <th
                          style={{
                            whiteSpace: "nowrap",
                          }}
                        >
                          Refer By
                        </th>
                        <th
                          style={{
                            whiteSpace: "nowrap",
                          }}
                        >
                          User details
                        </th>
                        <th>Actions</th>
                        <th>User Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {currentPosts.length > 0 &&
                        // eslint-disable-next-line
                        currentPosts
                          //   ?.filter((curVal) => {
                          //     if (search.toLowerCase() === "") {
                          //       return curVal;
                          //     } else if (
                          //       curVal?.firstname
                          //         ?.toLowerCase()
                          //         ?.includes(search.toLowerCase()) ||
                          //       curVal?.lastname
                          //         ?.toLowerCase()
                          //         ?.includes(search.toLowerCase()) ||
                          //       curVal?.status
                          //         ?.toLowerCase()
                          //         ?.includes(search.toLowerCase()) ||
                          //       curVal?.member_id
                          //         ?.toLowerCase()
                          //         ?.includes(search.toLowerCase())
                          //     ) {
                          //       return curVal;
                          //     }
                          //   })
                          ?.map((curVal, i) => {
                            return (
                              <tr
                                key={i}
                                className={
                                  curVal.user_status === "INACTIVE"
                                    ? "inactive-row"
                                    : ""
                                }
                              >
                                <td>{curVal?.member_id}</td>
                                <td>
                                  {curVal.image && (
                                    <div>
                                      <img
                                        style={{
                                          width: "45px",
                                          height: "45px",
                                          borderRadius: "50%",
                                        }}
                                        src={`${process.env.REACT_APP_URL}${curVal.image}`}
                                        alt="image"
                                        className="member-image"
                                      />
                                      {/* <div>
                                                                                <label htmlFor={`imageInput${i}`} className="btn btn-link p-0 m-0">
                                                                                    <FontAwesomeIcon icon={faEdit} />
                                                                                    <span className="visually-hidden">Change Image</span>
                                                                                </label>
                                                                                <input
                                                                                    type="file"
                                                                                    id={`imageInput${i}`}
                                                                                    style={{ display: "none" }}
                                                                                    onChange={(e) => handleImageChange(e, curVal?.member_id)} // Handle image change
                                                                                />
                                                                            </div> */}
                                    </div>
                                  )}
                                </td>
                                <td>
                                  {capitalFirstLetter(curVal?.firstname)}{" "}
                                  {capitalFirstLetter(curVal?.lastname)}
                                </td>
                                <td>{curVal?.mobile}</td>
                                <td>
                                  {/* <Link to={'/up'}>History</Link> */}
                                  <p
                                    style={{ cursor: "pointer" }}
                                    onClick={() =>
                                      handleHistory(curVal?.member_id)
                                    }
                                  >
                                    Invoice
                                  </p>
                                </td>
                                {/* <td>{curVal.planstart}</td>
                                                        <td>{curVal.planexpiry}</td>
                                                        <td>{curVal.subscribers && curVal.subscribers.duration} {curVal.subscribers && curVal.subscribers.type}</td>
                                                        <td>{curVal.subscribers && curVal.subscribers.subsriberfees}</td>
                                                        <td>1500</td>
                                                        <td>3000</td>
                                                        <td>4500</td> */}
                                {/* <td>{curVal.trainer_details && curVal.trainer_details.trainerfees}</td> */}
                                <td>
                                  {curVal?.status === "active" &&
                                    curVal?.trainer_addon_details !== null ? (
                                    <>
                                      {"Trainer"}
                                      {curVal?.diet_plan === null ||
                                        curVal?.workout_plan === null ? (
                                        <i
                                          className="fa-solid fa-file ms-2"
                                          onClick={() => handlePTModel(curVal)}
                                        ></i>
                                      ) : (
                                        <>
                                          {curVal?.diet_plan !== null && (
                                            <a
                                              target="_blank"
                                              rel="noreferrer"
                                              href={
                                                process.env.REACT_APP_URL +
                                                "/upload/files/" +
                                                curVal?.diet_plan
                                              }
                                            >
                                              <i className="fa-solid fa-file-export ms-1"></i>
                                            </a>
                                          )}
                                          {curVal?.workout_plan !== null && (
                                            <a
                                              target="_blank"
                                              rel="noreferrer"
                                              href={
                                                process.env.REACT_APP_URL +
                                                "/upload/files/" +
                                                curVal?.workout_plan
                                              }
                                            >
                                              <i className="fa-solid fa-file-export ms-1"></i>
                                            </a>
                                          )}
                                        </>
                                      )}
                                    </>
                                  ) : (
                                    "No Trainer"
                                  )}
                                </td>
                                <td>
                                  {curVal?.status === "expired"
                                    ? "Plan Inactive"
                                    : "Plan Active"}
                                </td>
                                <td>
                                  {curVal?.status === "expired" ||
                                    curVal?.boxing_addon_details?.addon_id ===
                                    null ||
                                    curVal?.yoga_addon_details?.addon_id ===
                                    null ||
                                    curVal?.dance_addon_details?.addon_id ===
                                    null ||
                                    curVal?.steam_addon_details?.addon_id ===
                                    null ||
                                    curVal?.zumba_addon_details?.addon_id ===
                                    null ||
                                    curVal?.crossfit_addon_details?.addon_id ===
                                    null ||
                                    curVal?.cycling_addon_details?.addon_id ===
                                    null ||
                                    curVal?.functional_training_addon_details
                                      ?.addon_id === null ||
                                    curVal?.massage_addon_details?.addon_id ===
                                    null ||
                                    curVal?.weight_training_addon_details
                                      ?.addon_id === null ||
                                    curVal?.fitness_studio_addon_details
                                      ?.addon_id === null ||
                                    curVal?.hiit_excercise_classes_addon_details
                                      ?.addon_id === null ||
                                    curVal?.nutrition_consulting_addon_details
                                      ?.addon_id === null
                                    ? "Inactive"
                                    : "Active"}
                                </td>
                                <td>
                                  <div className="d-flex justify-content-center align-items-center">
                                    <FontAwesomeIcon
                                      style={{ cursor: "pointer" }}
                                      onClick={() =>
                                        handleAttendance(curVal?.member_id)
                                      }
                                      icon={faCalendarCheck}
                                    />
                                  </div>
                                </td>
                                <td>
                                  {curVal?.Staff_details !== null ? (
                                    <>
                                      {capitalFirstLetter(
                                        curVal?.Staff_details?.firstName
                                      )}
                                    </>
                                  ) : (
                                    "No Refer Staff"
                                  )}
                                </td>
                                <td>
                                  <Link
                                    to={"/viewUser"}
                                    onClick={() => handleUserDetails(curVal)}
                                  >
                                    View User
                                  </Link>
                                  {/* <button className='btn btn-outline-primary' onClick={() => handleUserDetails(curVal?.member_id)}>Activate Plan</button> */}
                                </td>
                                <td>
                                  <div className="d-flex justify-content-evenly align-items-center">
                                    <FontAwesomeIcon
                                      style={{ cursor: "pointer" }}
                                      onClick={() => handleEdit(curVal)}
                                      icon={faEdit}
                                    />
                                    <FontAwesomeIcon
                                      style={{ cursor: "pointer" }}
                                      onClick={() => handleDelete(curVal)}
                                      icon={faTrash}
                                    />
                                  </div>
                                </td>
                                <td>
                                  <div className="full-width-div">
                                    {curVal.user_status === "ACTIVE" ? (
                                      <>
                                        <button
                                          className="btn-red"
                                          onClick={() =>
                                            activateOrDeactivateMember(
                                              curVal.id,
                                              0
                                            )
                                          }
                                        >
                                          Deactivate
                                        </button>
                                      </>
                                    ) : (
                                      <>
                                        <button
                                          className="btn-green"
                                          onClick={() =>
                                            activateOrDeactivateMember(
                                              curVal.id,
                                              1
                                            )
                                          }
                                        >
                                          Activate
                                        </button>
                                      </>
                                    )}
                                  </div>
                                </td>

                                {/* <button className='bg-primary text-align-center'><Subscribers/></button> */}
                              </tr>
                            );
                          })}
                    </tbody>
                  </table>
                </div>
                <Pagination
                  totalPosts={filteredData?.length}
                  postsPerPage={postsPerPage}
                  setCurrentPage={setCurrentPage}
                  currentPage={currentPage}
                />
              </div>
            </div>

            {show && (
              <AddEditMemberModel
                show={show}
                setShow={setShow}
                hide={hide}
                setHide={setHide}
                details={details}
                handleAddMember={handleAddMember}
                handleUpdateMember={handleUpdateMember}
                fetchUserMembers={fetchUserMembers}
              />
            )}
            {calendarShow && (
              <AttendanceCalendar
                calendarShow={calendarShow}
                setCalendarShow={setCalendarShow}
                attendanceData={attendanceData}
              />
            )}
            {ptModalShow && (
              <AddPTDocumentModel
                ptModalShow={ptModalShow}
                setPTModalShow={setPTModalShow}
                handleAddDietPlan={handleAddDietPlan}
              />
            )}
            {/* {showPlanModel && <ActivatePlanModel
                        showPlanModel={showPlanModel}
                        setShowPlanModel={setShowPlanModel}
                        memberId={memberId}
                    />} */}
          </section>

          <div className="row mt-3">
            <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
              <h3 className="">Active Members List</h3>
            </div>
            <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
              <div className="d-flex justify-content-end gap-3">
                <CSVLink
                  data={activeMember}
                  headers={headers}
                  filename={createCsvFileName()}
                >
                  {/* <button className="btn btn-outline-primary mt-5 float-sm-end float-md-end float-lg-end float-xl-end float-xxl-end"> */}
                  <button className="btn btn-outline-primary">
                    Export To CSV
                  </button>
                </CSVLink>

                <button
                  className="btn btn-outline-primary"
                  type="button"
                  onClick={() => handleCalendar()}
                >
                  Date Filter
                </button>
                <button
                  className="btn btn-outline-danger"
                  type="button"
                  onClick={() => handleClearFilter()}
                >
                  Reset
                </button>
              </div>
            </div>
          </div>

          <section className="py-sm-2 py-md-2 py-lg-2 py-xl-2 py-xxl-2 mb-3">
            <div className="row pt-3">
              <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                <input
                  type="text"
                  className="form-control"
                  value={activeSearch}
                  onChange={(e) => {setActiveSearch(e.target.value);
                    setActiveMemberPage(1);
                  }}
                  placeholder="Search Members..."
                />
                <div className="table table-responsive-sm table-responsive-md table-responsive-lg table-responsive-xl table-responsive-xxl mt-3">
                  <table className="table table-bordered table-striped-rows table-hover">
                    <thead className="table-dark">
                      <tr>
                        <th>Member ID</th>
                        <th>Name</th>
                        <th>Mobile</th>
                        <th>Plan Start Date</th>
                        <th>Plan End Date</th>
                        <th>Plan Duration</th>
                        <th>Bill Amount</th>
                        <th>Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {currentActiveMember.length > 0 &&
                        // eslint-disable-next-line
                        currentActiveMember
                          // ?.filter((curVal) => {
                          //   if (activeSearch.toLowerCase() === "") {
                          //     return curVal;
                          //   } else if (
                          //     curVal?.firstname
                          //       ?.toLowerCase()
                          //       ?.includes(activeSearch.toLowerCase()) ||
                          //     curVal?.lastname
                          //       ?.toLowerCase()
                          //       ?.includes(activeSearch.toLowerCase()) ||
                          //     curVal?.startdate
                          //       ?.toLowerCase()
                          //       ?.includes(activeSearch.toLowerCase()) ||
                          //     curVal?.member_id
                          //       ?.toLowerCase()
                          //       ?.includes(activeSearch.toLowerCase())
                          //   ) {
                          //     return curVal;
                          //   }
                          // })
                          ?.map((curVal, i) => {
                            return (
                              <tr key={i}>
                                <td>{curVal?.member_id}</td>
                                <td>
                                  {capitalFirstLetter(curVal?.firstname)}{" "}
                                  {capitalFirstLetter(curVal?.lastname)}
                                </td>
                                <td>{curVal?.mobile}</td>
                                <td>{dateFormat(curVal?.startdate)}</td>
                                <td>{dateFormat(curVal?.enddate)}</td>
                                <td>{`${curVal?.duration} Days`} </td>
                                <td>
                                  Rs. {numberWithCommas(curVal?.billed_amount)}
                                </td>
                                <td>
                                  {curVal?.admin_status === null ||
                                    curVal?.admin_status === "created"
                                    ? "Unpaid"
                                    : "Paid"}
                                </td>
                              </tr>
                            );
                          })}
                    </tbody>
                  </table>
                </div>
                <div className="card float-start mt-5">
                  <div className="d-column bg-body-tertiary card-body">
                    <div className="d-flex">
                      <p>
                        Total Unpaid Amount: &#8377;
                        {numberWithCommas(unpaidAmount)}
                      </p>
                    </div>
                    <div className="d-flex">
                      <p>
                        Total Paid Amount: &#8377;{numberWithCommas(paidAmount)}
                      </p>
                    </div>
                    <div className="d-flex">
                      <p>
                        Total Amount: &#8377;{numberWithCommas(totalAmount)}
                      </p>
                    </div>
                    <button
                      onClick={() => handleUnpaidAmount()}
                      className="btn btn-outline-primary"
                      disabled={!unpaidAmount}
                    >
                      Pay Unpaid Amount
                    </button>
                  </div>
                </div>
                <Pagination
                  totalPosts={filterActiveData?.length}
                  postsPerPage={postsPerPage}
                  setCurrentPage={setActiveMemberPage}
                  currentPage={activeMemberPage}
                />
              </div>
            </div>
          </section>

          <ToastContainer />
        </div>
      )}

      <Modal show={qrModel} size="lg">
        <Modal.Header>
          <Modal.Title>Scan Qr Code</Modal.Title>
          <button
            type="button"
            data-bs-dismiss="modal"
            className="btn-close"
            onClick={() => setQrModel(false)}
          ></button>
        </Modal.Header>
        <Modal.Body>
          <div className="text-center">
            <h1>₹ {unpaidAmount}</h1>
            <div>
              <img
                src={`${process.env.REACT_APP_URL}/${qrData}`}
                height={"350px"}
              />
            </div>
            <div className="mt-3 py-3">
              <form onSubmit={handlePayAmount}>
                <input
                  placeholder="Enter Transaction Id"
                  required
                  value={transactionId}
                  onChange={(e) => setTransactionId(e.target.value)}
                  className="w-50"
                />
                <button type="submit" className="ms-3 btn btn-success">
                  Submit
                </button>
              </form>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default Members;
