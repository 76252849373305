import React from 'react';

function Footer() {
  return (
    <footer className="footer bg-dark">
      <div className="d-flex justify-content-center">
        <span className="text-white text-center">
          <a href="/terms" className="text-white">Terms and Conditions</a> | <a href="/aboutus" className="text-white">About Us</a>
        </span>
        <span className="text-white mx-2">|</span>
        <span className="text-white">GYM Application</span>
        <span className="text-white mx-2">|</span>
        <span className="text-white">Copyright © {new Date().getFullYear()} Lnc software application. All rights reserved.</span>
      </div>
    </footer>
  );
}

export default Footer;
