// import './App.css';
import "./style.css";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Dashboard from "./components/Dashboard";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import Members from "./components/Members";
import Trainers from "./components/Trainers";
// import AddTrainer from './components/AddTrainer';
import TrainerProfile from "./components/TrainerProfile";
import Prize from "./components/Prize";
import AddPrize from "./components/AddPrize";
import Login from "./components/Login";
// import SignUp from './components/Profile';
import Error from "./components/Error";
import Profile from "./components/Profile";
import Staff from "./components/Staff";
import { useSelector } from "react-redux";
// import { checkLogin } from './redux/counterSlice';
import { useEffect, useState } from "react";
import Subscription from "./components/Subscription";
import AllPerformer from "./components/AllPerformer";
import ViewReedemList from "./components/Trainers/ViewReedemList";
import UserDetails from "./components/UserDetails";
import Locker from "./components/Locker";
import Loading from "./components/Loading";
import StaffCollection from "./components/Trainers/StaffCollection";
import AddAddonPrice from "./components/AddAddonPrice";
import EditAddonPrice from "./components/EditAddonPrice";
import React from "react";
import AboutUs from "./components/AboutUs";
import Terms from "./components/Terms";
import Invoice from "./components/Invoice";
import PaymentHistory from "./components/PaymentHistory";
import Notification from "./components/Notification";
import Transaction from "./components/Transaction";
// import AddMembers from './components/AddMembers';

function App() {
  const location = useLocation();
  const getDataInRedux = useSelector((state) => state.counterSlice.islogin);
  const navigate = useNavigate();

  useEffect(() => {
    if (
      getDataInRedux !== true &&
      location.pathname !== "/aboutus" &&
      location.pathname !== "/terms"
    ) {
      navigate("/login");
    }
    // eslint-disable-next-line
  }, [getDataInRedux]);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);

  return (
    <>
      {loading ? (
        <Loading loading={loading} />
      ) : (
        <>
          <div style={{ minHeight: "calc(100vh - 84px)" }}>
          {location.pathname !== "/login" ? <Header /> : null}
          <Routes>
            {/* Routes accessible without login */}
            <Route path="/aboutus" element={<AboutUs />} />
            <Route path="/terms" element={<Terms />} />

            {/* Always render the login page */}
            <Route path="/login" element={<Login />} />

            {/* Routes accessible only when logged in */}
            {getDataInRedux === true && (
              <>
                <Route
                  path="/"
                  element={<Dashboard idModal="trainerModal" />}
                />
                <Route exact path="/members" element={<Members />} />
                <Route exact path="/trainers" element={<Trainers />} />
                <Route exact path="/staff" element={<Staff />} />
                <Route exact path="/prize" element={<Prize id="prize" />} />
                <Route exact path="/subscription" element={<Subscription />} />
                <Route exact path="/locker" element={<Locker />} />
                <Route path="/allPerformer" element={<AllPerformer />} />
                <Route path="/viewReedemList" element={<ViewReedemList />} />
                <Route path="/notification" element={<Notification />} />
                <Route path="/viewUser" element={<UserDetails />} />
                <Route path="/profile" element={<Profile />} />
                <Route path="/transaction" element={<Transaction />} />
                <Route path="/staffcollection" element={<StaffCollection />} />
                <Route path="/loading" element={<Loading />} />
                <Route path="*" element={<Error />} />
                <Route exact path="/addonprice" element={<AddAddonPrice />} />
                <Route
                  path="/edit-addon/:addon_id"
                  element={<EditAddonPrice />}
                />
                {/* <Route path="/in" element={<Invoice/>}/> */}
                <Route path="/in" element={<Invoice />} />
                <Route path="/up" element={<PaymentHistory />} />
              </>
            )}
          </Routes>
          {/* <AddMembers id="MemberAddModal" /> */}
          {/* <AddTrainer id='TrainerAddModal' /> */}
          <TrainerProfile id="trainerModal" />
          <AddPrize id="prize" />
          </div>
          <Footer />
        </>
      )}
    </>
  );
}

export default App;
