import { faEdit, faTrash, faTrophy } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import Pagination from "./Pagination";
import { useSelector } from "react-redux";
import AddEditPrize from "./Trainers/AddEditPrize";
import Loading from "./Loading";
import { capitalFirstLetter, dateFormat } from "../utils/utils";

function Prize(props) {
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [details, setDetails] = useState({});
  const [prizeData, setPrizeData] = useState([]);
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  // eslint-disable-next-line
  const [postsPerPage, setPostsPerPage] = useState(10);
  const [filteredData, setFilteredData] = useState([]);
  const lastPostIndex = currentPage * postsPerPage;
  const firstPostIndex = lastPostIndex - postsPerPage;
  const currentPosts = filteredData.slice(firstPostIndex, lastPostIndex);
  const getGymId = useSelector((state) => state.counterSlice.gymId);
  console.log("the token is ", getGymId);
  const deviceToken = getGymId.device_token;
  console.log(deviceToken);

  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);

  const fetchUserPrize = async () => {
    const config = {
      headers: {
        Authorization: deviceToken,
      },
    };
    const allPrize = await axios.get(
      `${process.env.REACT_APP_URL}getAllPrize`,
      config
    );
    setPrizeData(allPrize.data.data);
    // console.log("ZZZZZZZZZ",allPrize.data.data)
  };

  useEffect(() => {
    const filterData = () => {
      const filtered = prizeData.filter((curVal) => {
        if (search.toLowerCase() === "") {
          return curVal;
        } else if (
          curVal?.title?.toLowerCase()?.includes(search.toLowerCase())
        ) {
          return curVal;
        }
      });

      setFilteredData(filtered);
    };

    filterData();
  }, [prizeData, search]);

  useEffect(() => {
    fetchUserPrize();
  }, []);

  const handleAddPrize = async (data) => {
    const config = {
      headers: {
        Authorization: deviceToken,
      },
    };
    const res = await axios.post(
      `${process.env.REACT_APP_URL}addPrize`,
      data,
      config
    );
    if (res?.status === 200) {
      fetchUserPrize();
      toast.success(res?.data.message, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  const handleUpdatePrize = async (data) => {
    const config = {
      headers: {
        Authorization: deviceToken,
      },
    };
    const res = await axios.post(
      `${process.env.REACT_APP_URL}upadtePrize/${data?.id}`,
      data,
      config
    );
    if (res?.status === 200) {
      fetchUserPrize();
      toast.success(res?.data.message, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  const handleDelete = async ({ id = null }) => {
    const res = await axios.delete(
      `${process.env.REACT_APP_URL}deletePrize/${id}`
    );
    if (res?.status === 200) {
      fetchUserPrize();
      toast.success(res?.data.message, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  const AddPrize = () => {
    setDetails(null);
    setShow(true);
  };

  const handleReedemRequest = async (id) => {
    const res = await axios.get(
      `${process.env.REACT_APP_URL}getRedeemByPriceId/${id}`
    );
    if (res?.data.data.length > 0) {
      navigate("/viewReedemList", { state: id });
    }
    if (res?.data.data.length === 0) {
      // console.log(res?.data.message);
      toast.warning("No reedem request!", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };
  const handleOnClickEdit = (details) => {
    setDetails(details);
    setShow(true);
  };

  return (
    <>
      {loading ? (
        <Loading loading={loading} />
      ) : (
        <div
          style={{ maxWidth: "1600px" }}
          className="mx-auto container-fluid px-5 py-sm-5 py-md-5 py-lg-5 py-xl-5 py-xxl-5"
        >
          <div className="row mt-3">
            <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
              <h3 className="mt-5">Prize List</h3>
            </div>
            <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
              <div className="d-flex justify-content-end">
                <button
                  className="btn btn-outline-primary mt-5"
                  data-bs-toggle="modal"
                  onClick={AddPrize}
                >
                  Add Prize
                </button>
              </div>
            </div>
          </div>

          <section className="py-sm-3 py-md-3 py-lg-3 py-xl-3 py-xxl-3">
            <div className="row">
              <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                <input
                  type="text"
                  className="form-control"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                  placeholder="Search Prizes..."
                />
                <div className="table table-responsive-sm table-responsive-md table-responsive-lg table-responsive-xl table-responsive-xxl mt-3">
                  <table className="table table-bordered table-striped-rows table-hover">
                    <thead className="table-dark">
                      <tr>
                        <th>Id</th>
                        <th>Name</th>
                        <th>Description</th>
                        <th>Point</th>
                        <th>Start Date</th>
                        <th>Start End</th>
                        <th>Redeem</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {currentPosts?.length > 0 &&
                        // eslint-disable-next-line
                        currentPosts
                          // ?.filter((curVal) => {
                          //   if (search.toLowerCase() === "") {
                          //     return curVal;
                          //   } else if (
                          //     curVal?.title
                          //       ?.toLowerCase()
                          //       ?.includes(search.toLowerCase())
                          //   ) {
                          //     return curVal;
                          //   }
                          // })
                          ?.map((curVal, i) => {
                            // console.log("&&&&&&&",ele)
                            return (
                              <tr key={i}>
                                <td>{curVal?.id}</td>
                                <td>{capitalFirstLetter(curVal?.title)}</td>
                                <td>{curVal?.description}</td>
                                <td>{curVal?.point}</td>
                                <td>
                                  {/* {new Date(curVal?.startdate).toDateString()} */}
                                  {dateFormat(curVal?.startdate)}
                                </td>
                                <td>{dateFormat(curVal?.enddate)}</td>
                                <td>
                                  <div className="d-flex justify-content-center">
                                    <FontAwesomeIcon
                                      onClick={() =>
                                        handleReedemRequest(curVal?.id)
                                      }
                                      icon={faTrophy}
                                    />
                                  </div>
                                </td>
                                <td>
                                  <div className="d-flex justify-content-evenly align-items-center">
                                    <FontAwesomeIcon
                                      onClick={() => handleOnClickEdit(curVal)}
                                      icon={faEdit}
                                    />
                                    <FontAwesomeIcon
                                      onClick={() => handleDelete(curVal)}
                                      icon={faTrash}
                                    />
                                  </div>
                                </td>
                              </tr>
                            );
                          })}
                    </tbody>
                  </table>
                </div>
                <Pagination
                  totalPosts={filteredData?.length}
                  postsPerPage={postsPerPage}
                  setCurrentPage={setCurrentPage}
                  currentPage={currentPage}
                />
              </div>
            </div>
            {show && (
              <AddEditPrize
                show={show}
                setShow={setShow}
                details={details}
                handleAddPrize={handleAddPrize}
                handleUpdatePrize={handleUpdatePrize}
              />
            )}
          </section>
          <ToastContainer />
        </div>
      )}
    </>
  );
}

export default Prize;
