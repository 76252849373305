import React, { useEffect, useState, useRef } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import Pagination from "../Pagination";
import Loading from "../Loading";

function ViewReedemList() {
  const { state } = useLocation();
  const [reedemId, setReedemId] = useState();
  const [reedemList, setReedemList] = useState([]);
  // const [valueReedem, setValueReedem] = useState({certificate: null, status: ''});
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  // eslint-disable-next-line
  const [postsPerPage, setPostsPerPage] = useState(10);
  const [filteredData, setFilteredData] = useState([]);
  const lastPostIndex = currentPage * postsPerPage;
  const firstPostIndex = lastPostIndex - postsPerPage;
  const currentPosts = filteredData.slice(firstPostIndex, lastPostIndex);

  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);

  useEffect(() => {
    const filterData = () => {
      const filtered = reedemList?.filter((curVal) => {
        if (search.toLowerCase() === "") {
          return curVal;
        } else if (
          curVal?.member_id?.toLowerCase()?.includes(search.toLowerCase())
        ) {
          return curVal;
        }
      });

      setFilteredData(filtered);
    };

    filterData();
  }, [reedemList, search]);

  const getReedem = async () => {
    const res = await axios.get(
      `${process.env.REACT_APP_URL}getRedeemByPriceId/${state}`
    );
    // console.log(res?.data.data);
    setReedemList(res?.data.data);
  };

  useEffect(() => {
    getReedem();
    // eslint-disable-next-line
  }, []);
  // console.log(reedemList);
  // const handleInput = (e) => {
  //     setValueReedem(e.target.files);
  //     setValueReedem({...valueReedem, [e.target.name]: e.target.value});
  // }
  const refClose = useRef(null);
  const handleSumbit = async (e) => {
    e.preventDefault();
    const file = e.target.certificate.files[0];
    const status = e.target.status.value;
    const formData = new FormData();
    formData.append("certificate", file);
    formData.append("status", parseInt(status));
    formData.append("redeem_id", reedemId);
    console.log("###", formData);
    const headers = { "Content-Type": "multipart/form-data" };
    const res = await axios.post(
      `${process.env.REACT_APP_URL}approveRedeem`,
      formData,
      headers
    );
    // console.log(res);
    if (res?.status === 200) {
      getReedem();
      refClose.current.click();
      toast.success(res?.data.message, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };
  return (
    <>
      {loading ? (
        <Loading loading={loading} />
      ) : (
        <div className="container py-sm-5 py-md-5 py-lg-5 py-xl-5 py-xxl-5">
          <div className="row">
            <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
              <h3 className="mt-5">Reedem List</h3>
            </div>
          </div>

          <section className="py-sm-5 py-md-5 py-lg-5 py-xl-5 py-xxl-5">
            <div className="row">
              <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                <input
                  type="text"
                  className="form-control"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                  placeholder="Search Rewards..."
                />
                <div className="table table-responsive-sm table-responsive-md table-responsive-lg table-responsive-xl table-responsive-xxl mt-3">
                  <table className="table table-bordered table-striped-rows table-hover">
                    <thead className="table-dark">
                      <tr>
                        <th>Member ID</th>
                        <th>Price ID</th>
                        <th>Points</th>
                        <th>Certificate</th>
                        <th>Status</th>
                        <th>Start Date</th>
                        <th>End Date</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {currentPosts?.length > 0 &&
                        // eslint-disable-next-line
                        currentPosts
                          // ?.filter((curVal) => {
                          //   if (search.toLowerCase() === "") {
                          //     return curVal;
                          //   } else if (
                          //     curVal?.member_id
                          //       ?.toLowerCase()
                          //       ?.includes(search.toLowerCase())
                          //   ) {
                          //     return curVal;
                          //   }
                          // })
                          ?.map((curVal, i) => {
                            return (
                              <tr key={i}>
                                <td>{curVal?.member_id}</td>
                                <td>{curVal?.price_id}</td>
                                <td>{curVal?.point}</td>
                                <td>
                                  {curVal?.certificate && (
                                    <a
                                      target="_blank"
                                      rel="noreferrer"
                                      href={
                                        process.env.REACT_APP_URL +
                                        "upload/certificates/" +
                                        curVal?.certificate
                                      }
                                    >
                                      {curVal?.certificate}
                                    </a>
                                  )}
                                </td>
                                <td>
                                  {curVal?.status === 0 && "In-Progress"}
                                  {curVal?.status === 1 && "Approved"}
                                  {curVal?.status === 2 && "Rejected"}
                                </td>
                                <td>
                                  {new Date(curVal?.startDate).toDateString()}
                                </td>
                                <td>
                                  {new Date(curVal?.endDate).toDateString()}
                                </td>
                                <td>
                                  <div className="d-flex justify-content-evenly">
                                    {curVal?.status === 0 && (
                                      <button
                                        onClick={() => {
                                          setReedemId(curVal?.id);
                                        }}
                                        className="btn btn-primary"
                                        data-bs-toggle="modal"
                                        data-bs-target="#changeStatusModal"
                                      >
                                        Change Status
                                      </button>
                                    )}
                                    {curVal?.status === 1 && (
                                      <button className="btn btn-success">
                                        Aprroved
                                      </button>
                                    )}
                                    {curVal?.status === 2 && (
                                      <button className="btn btn-warning">
                                        Rejected
                                      </button>
                                    )}
                                  </div>
                                </td>
                              </tr>
                            );
                          })}
                    </tbody>
                  </table>
                </div>
                <Pagination
                  totalPosts={filteredData?.length}
                  postsPerPage={postsPerPage}
                  setCurrentPage={setCurrentPage}
                  currentPage={currentPage}
                />
              </div>
            </div>
            <ToastContainer />
          </section>

          <div
            className="modal fade"
            id="changeStatusModal"
            tabIndex="-1"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header">
                  <h1 className="modal-title fs-5" id="exampleModalLabel">
                    Reedem Status
                  </h1>
                  <button
                    ref={refClose}
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <div className="modal-body">
                  <form onSubmit={handleSumbit}>
                    <div className="row">
                      <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                        <div className="mb-3">
                          <label htmlFor="certificateFile">Status</label>
                          <select name="status" className="form-select">
                            <option value="" selected disabled>
                              Select Status
                            </option>
                            <option value={1}>Approved</option>
                            <option value={2}>Rejected</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                        <div className="mb-3">
                          <label htmlFor="certificateFile">Certificate</label>
                          <input
                            type="file"
                            className="form-control"
                            name="certificate"
                            accept="application/pdf"
                            title="only PDF file accept"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="mb-3">
                      <input type="hidden" name="redeem_id" />
                    </div>

                    <button type="submit" className="btn btn-outline-primary">
                      Submit
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default ViewReedemList;
