import React, { useEffect, useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import { Calendar, dateFnsLocalizer } from 'react-big-calendar';
import "react-big-calendar/lib/css/react-big-calendar.css";
import format from 'date-fns/format';
import parse from 'date-fns/parse';
import startOfWeek from 'date-fns/startOfWeek';
import getDay from 'date-fns/getDay';
import enIN from 'date-fns/locale/en-IN';

function AttendanceCalforTrainer({ calendarShow, setCalendarShow, attendanceData }) {
    const [attendanceCalendar, setAttendanceCalendar] = useState(attendanceData);
    const [events, setEvents] = useState([])
    useEffect(() => {
        setAttendanceCalendar(attendanceData);
        setEvents(attendanceCalendar);
    }, [attendanceData, attendanceCalendar])
    // console.log(attendanceData);
    const locales = {
        'en-IN': enIN
    }
    const localizer = dateFnsLocalizer({
        format,
        parse,
        startOfWeek,
        getDay,
        locales,
    });
    let meb;
    events.find((ev) => {
        return meb = ev.trainer_id;
    })
    const eve = events.map((e) => {
        let startTime = new Date(e.checkin);
        let endTime = new Date(e.checkout);
        let seconds = (endTime.getTime() - startTime.getTime()) / 1000;
        // let h = Math.floor(seconds / 3600);
        let m = Math.floor(seconds / 60);
        // let s = Math.floor(seconds % 3600 % 60)
        // let hDisplay = h > 0 ? h + (h === 1 ? "hour, " : "hours, ") : "";
        let mDisplay = m > 0 ? m + (m === 1) : "";
        // let sDisplay = s > 0 ? s + (s === 1) : "";
        // console.log(mDisplay);
        // console.log(sDisplay);
        return {
            title: (mDisplay === '0' || mDisplay <= '239') ? 'Absent' : (mDisplay === '240' || mDisplay <= '479') ? 'Half Day' : (mDisplay === '480' || mDisplay <= '600' || mDisplay >= '600') ? 'Present' : '',
            start: new Date(e.checkin),
            end: new Date(e.checkout),
            allDay: false,
            resource: mDisplay,
        }
    })


    return (
        <Modal show={calendarShow} size='lg'>
            <Modal.Header>
                <Modal.Title>Trainer Attendance {meb}</Modal.Title>
                <button type="button" data-bs-dismiss="modal" onClick={() => setCalendarShow(false)} className="btn-close"></button>
            </Modal.Header>
            <Modal.Body>
                <div>
                    <Calendar
                        localizer={localizer}
                        events={eve}
                        startAccessor="start"
                        endAccessor="end"
                        style={{ height: 500 }}
                        eventPropGetter={(eve) => ({
                            style: { backgroundColor: (eve.resource === '0' || eve.resource <= '239') ? '#ff0000' : (eve.resource === '240' || eve.resource <= '479') ? '#FFBF00' : (eve.resource === '480' || eve.resource <= '600' || eve.resource >= '600') ? '#00b300' : '' }
                        })
                        }
                    />
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default AttendanceCalforTrainer;