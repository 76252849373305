import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import FormInput from "../FormInput";

function AddEditStaffModal({
  show,
  setShow,
  details,
  handleAddStaff,
  handleUpdateStaff,
}) {
  const [staffModel, setStaffModel] = useState({
    ...details,
  });
  const [showHide, setShowHide] = useState(false);
  // const [managerRole, setManagerRole] = useState([]);
  console.log("data: ", staffModel);
  const [shiftError, setShiftError] = useState("");

  useEffect(() => {
    if (staffModel?.start_time >= staffModel?.end_time) {
      setShiftError("Invalid End Time");
    } else {
      setShiftError("");
    }
  }, [staffModel]);

  useEffect(() => {
    setStaffModel(details);
  }, [details]);

  const handleInput = (e) => {
    setStaffModel({ ...staffModel, [e.target.name]: e.target.value });
  };

  function restrictInputLength(e, maxLength) {
    const input = e.target;
    if (input.value.length > maxLength) {
      input.value = input.value.slice(0, maxLength);
    }
  }

  const [managerRole] = useState({
    floor: "Floor Manager",
    sales: "Sales Manager",
  });

  let managerrole = null;

  if (staffModel?.designation === "House Keeping") {
    managerrole = managerRole.floor;
    staffModel.manager = managerRole.floor;
  } else if (staffModel?.designation === "Trainer") {
    managerrole = managerRole.floor;
    staffModel.manager = managerRole.floor;
  } else if (staffModel?.designation === "Sales Staff") {
    managerrole = managerRole.sales;
    staffModel.manager = managerRole.sales;
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!shiftError) {
      details ? handleUpdateStaff(staffModel) : handleAddStaff(staffModel);
      setShow(false);
    }
    // console.log('Staff', staffModel);
  };
  return (
    <Modal show={show} size="lg">
      <Modal.Header>
        <Modal.Title>Staff Details</Modal.Title>
        <button
          type="button"
          data-bs-dismiss="modal"
          className="btn-close"
          onClick={() => setShow(false)}
        ></button>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={handleSubmit}>
          <div className="row">
            <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
              <div className="mb-3">
                <label htmlFor="firstName" className="form-label">
                  First Name:{" "}
                </label>
                <FormInput
                  value={staffModel?.firstName}
                  onChange={handleInput}
                  name="firstName"
                  placeholder="First Name"
                  errorMessage="First Name should be 2-30 characters and shouldn't include any special character or number!"
                  // label="Gym Name"
                  pattern="^[A-Za-z ]{2,30}$"
                  required={true}
                />
              </div>
            </div>
            <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
              <div className="mb-3">
                <label htmlFor="lastName" className="form-label">
                  Last Name:{" "}
                </label>
                <FormInput
                  value={staffModel?.lastName}
                  name="lastName"
                  placeholder="Last Name"
                  errorMessage="Last Name should be 2-30 characters and shouldn't include any special character or number!"
                  pattern="^[A-Za-z ]{2,30}$"
                  onChange={handleInput}
                  required={true}
                />
              </div>
            </div>
            <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
              <div className="mb-3 password-div">
                <label htmlFor="password" className="form-label">
                  Staff Password:
                </label>
                <FormInput
                  name="current_password"
                  placeholder="Password"
                  type={showHide === true ? "text" : "password"}
                  value={staffModel?.password}
                  onChange={handleInput}
                  errorMessage=" Password must: - Be between 8 and 20 characters in length, Include at least one digit (0-9), Include at least one special character (!@#$%^&*) "
                  // label="Gym Name"
                  pattern="^(?=.*[0-9])(?=.*[a-zA-Z])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{}$"
                  required={true}
                />
                <i
                  className={
                    showHide
                      ? "eye-b fa-solid fa-eye"
                      : "eye-b fa-solid fa-eye-slash"
                  }
                  onClick={() => {
                    setShowHide(!showHide);
                  }}
                ></i>
              </div>
            </div>
            <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
              <div className="mb-3">
                <label htmlFor="managerName" className="form-label">
                  Manager Name:
                </label>
                <FormInput
                  value={staffModel?.reportingManager}
                  name="reportingManager"
                  placeholder="Manager Name"
                  errorMessage="Manager Name should be 2-30 characters and shouldn't include any special character or number!"
                  pattern="^[A-Za-z ]{2,30}$"
                  onChange={handleInput}
                  required={true}
                />
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
              <div className="mb-3">
                <label htmlFor="jobDesignation" className="form-label">
                  Designation:
                </label>
                <select
                  className="form-select"
                  name="designation"
                  onChange={handleInput}
                  value={staffModel?.designation}
                  required
                >
                  <option value="">Select Designation</option>
                  <option value="House Keeping">House Keeping</option>
                  <option value="Trainer">Trainer</option>
                  <option value="Sales Staff">Sales Staff</option>
                </select>
              </div>
            </div>

            <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
              <div className="mb-3">
                <label htmlFor="jobManager" className="form-label">
                  Manager Role:
                </label>
                {/* <input type="text" className='form-control' name='manager' onChange={handleInput} value={staffModel?.manager ? managerrole : managerrole} required disabled /> */}

                <select
                  name="manager"
                  className="form-select"
                  onChange={handleInput}
                  value={staffModel?.manager || managerrole}
                  required
                >
                  <option value="">Select</option>
                  <option value={"Floor Manager"}>Floor Manager</option>
                  <option value={"Sales Manager"}>Sales Manager</option>
                </select>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
              <div className="mb-3">
                <label htmlFor="shiftStart" className="form-label">
                  Shift Start Time:{" "}
                </label>
                <FormInput
                  type="time"
                  name="start_time"
                  value={staffModel?.start_time || staffModel?.stime}
                  onChange={handleInput}
                  errorMessage="please enter Shift Start Timing"
                  required={true}
                />
              </div>
            </div>
            <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
              <div className="mb-3">
                <label htmlFor="shiftEnd" className="form-label">
                  Shift End Time:{" "}
                </label>
                <FormInput
                  type="time"
                  name="end_time"
                  value={staffModel?.end_time || staffModel?.etime}
                  onChange={handleInput}
                  errorMessage="please enter Shift End Timing"
                  required={true}
                />
                <span className="input-err" style={{ color: "red" }}>
                  {shiftError && shiftError}
                </span>
              </div>
            </div>
          </div>

          <div className="modal-footer">
            <button type="submit" className="btn btn-outline-primary">
              Submit
            </button>
          </div>
        </form>
      </Modal.Body>
      {/* <Modal.Footer> */}
      {/* </Modal.Footer> */}
    </Modal>
  );
}

export default AddEditStaffModal;
