import React from 'react';
import Modal from 'react-bootstrap/Modal';

function AddPTDocumentModel({ptModalShow, setPTModalShow, handleAddDietPlan}) {

    const handleSubmit = (e) => {
        e.preventDefault();
        const workoutFile = e.target.workout_plan.files[0];
        const dietPlan = e.target.diet_plan.files[0];
        const formData = new FormData();
        formData.append('diet_plan', dietPlan);
        formData.append('workout_plan', workoutFile);
        handleAddDietPlan(formData);
        setPTModalShow(false);
        // console.log('###', workoutFile);
        // console.log('###', dietPlan);
    }

  return (
    <Modal show={ptModalShow} size='lg'>
        <Modal.Header>
            <Modal.Title>PT Document</Modal.Title>
            <button type='button' data-bs-dismiss="modal" onClick={() => setPTModalShow(false)} className='btn-close'></button>
        </Modal.Header>
        <Modal.Body>
            <form onSubmit={handleSubmit}>
                <div className='row'>
                    <div className='col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6'>
                        <div className='mb-3'>
                            <label htmlFor="workoutPlan">Workout Plan</label>
                            <input type="file" className='form-control' name='workout_plan' accept='application/pdf' title='only PDF file accept' />
                        </div>
                    </div>
                    <div className='col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6'>
                        <div className='mb-3'>
                            <label htmlFor="workoutPlan">Diet Plan</label>
                            <input type="file" className='form-control' name='diet_plan' accept='application/pdf' title='only PDF file accept' />
                        </div>
                    </div>
                </div>
                <div className="modal-footer">
                    <button type="submit" className="btn btn-outline-primary">Submit</button>
                </div>
            </form>
        </Modal.Body>
    </Modal>
  )
}

export default AddPTDocumentModel