import React, { useEffect, useState } from "react";
import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AddEditSubscriptionModel from "./Trainers/AddEditSubscriptionModel";
import { toast, ToastContainer } from "react-toastify";
import { useSelector } from "react-redux";
import axios from "axios";
import Loading from "./Loading";
import { numberWithCommas } from "../utils/utils";

function Subscription() {
  const [show, setShow] = useState(false);
  const [details, setDetails] = useState({});
  const [subscriberList, setSubscriberList] = useState([]);
  const getGymId = useSelector((state) => state.counterSlice.gymId);
  console.log("the token is ", getGymId);
  const deviceToken = getGymId.device_token;
  console.log(deviceToken);

  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);

  const handleOnClickAddSubscribe = () => {
    setDetails(null);
    setShow(true);
  };

  const handleAddSubscription = async (data) => {
    const config = {
      headers: {
        Authorization: deviceToken,
      },
    };
    const res = await axios.post(
      `${process.env.REACT_APP_URL}addSubscriber`,
      data,
      config
    );
    if (res?.status === 200) {
      fetchAllSubscriber();
      toast.success("Plan Added Successfully", {
        // res?.data.message,
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  const handleUpdateSubscription = async (data) => {
    const config = {
      headers: {
        Authorization: deviceToken,
      },
    };
    const res = await axios.post(
      `${process.env.REACT_APP_URL}updateSubscriber/${data?.id}`,
      data,
      config
    );
    if (res?.status === 200) {
      fetchAllSubscriber();
      toast.success(res?.data.message, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  const fetchAllSubscriber = async () => {
    const config = {
      headers: {
        Authorization: deviceToken,
      },
    };
    const getAllSubscriber = await axios.get(
      `${process.env.REACT_APP_URL}GetAllSubscriberByAdmin`,
      config
    );
    setSubscriberList(getAllSubscriber?.data.data);
  };
  useEffect(() => {
    fetchAllSubscriber();
  }, []);

  const handleEdit = (details) => {
    setDetails(details);
    setShow(true);
  };

  const handleDelete = async ({ id = null }) => {
    const res = await axios.delete(
      `${process.env.REACT_APP_URL}deleteSubscriber/${id}`
    );
    if (res?.status === 200) {
      fetchAllSubscriber();
      toast.success(res?.data.message, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  return (
    <>
      {loading ? (
        <Loading loading={loading} />
      ) : (
        <div
          style={{ maxWidth: "1600px" }}
          className="mx-auto container-fluid px-5 py-sm-5 py-md-5 py-lg-5 py-xl-5 py-xxl-5"
        >
          <div className="row mt-3">
            <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
              <h3 className="mt-5">Active Plans List</h3>
            </div>

            <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
              <div className="d-flex justify-content-end">
                <button
                  className="btn btn-outline-primary mt-5"
                  data-bs-toggle="modal"
                  onClick={handleOnClickAddSubscribe}
                >
                  Add Plan
                </button>
              </div>
            </div>

            <section className="py-sm-3 py-md-3 py-lg-3 py-xl-3 py-xxl-3">
              <div className="row">
                <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                  <div className="table table-responsive-sm table-responsive-md table-responsive-lg table-responsive-xl table-responsive-xxl mt-3">
                    <table className="table table-bordered table-striped-rows table-hover">
                      <thead className="table-dark">
                        <tr>
                          <th>Duration</th>
                          <th>Type</th>
                          <th>Subscription Fees</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {subscriberList.map((curVal, i) => {
                          return (
                            <tr key={i}>
                              <td>{curVal.duration}</td>
                              <td>{curVal.type}</td>
                              <td>
                                {"Rs. "}
                                {numberWithCommas(curVal.subsriberfees)}
                              </td>
                              <td>
                                <div className="d-flex justify-content-evenly align-items-center">
                                  <FontAwesomeIcon
                                    onClick={() => handleEdit(curVal)}
                                    icon={faEdit}
                                  />
                                  <FontAwesomeIcon
                                    onClick={() => handleDelete(curVal)}
                                    icon={faTrash}
                                  />
                                </div>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              {show && (
                <AddEditSubscriptionModel
                  show={show}
                  setShow={setShow}
                  details={details}
                  handleAddSubscription={handleAddSubscription}
                  handleUpdateSubscription={handleUpdateSubscription}
                />
              )}
            </section>
            <ToastContainer />
          </div>
        </div>
      )}
    </>
  );
}

export default Subscription;
