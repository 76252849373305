import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import { approvePay } from "../redux/counterSlice";
import { Await } from "react-router-dom";
import { numberWithCommas } from "../utils/utils";

export default function Transaction() {
  const getGymId = useSelector((state) => state.counterSlice.gymId);
  // console.log("the token is ", getGymId)
  const apprve = useSelector((state) => state.counterSlice.autoPay);
  const deviceToken = getGymId.device_token;
  // console.log(deviceToken);
  const dispatch = useDispatch();
  console.log("gh", apprve);
  const config = {
    headers: {
      Authorization: deviceToken,
    },
  };
  const [data, setData] = useState([]);
  const [autoPay, setAutoPay] = useState(apprve == 1 ? true : false);
  console.log("autoPay", autoPay);
  useEffect(() => {
    fetchData();
  }, []);

  async function fetchData() {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_URL}getTransactionsByGYM`,
        config
      );
      setData(response?.data?.paydata.reverse());
    } catch (error) {
      // Handle errors here
      console.error("Error fetching data:", error);
    }
  }
  console.log("data", data);

  const handleAction = async (order, status) => {
    let actiondata = {
      order_id: order,
      status: status,
    };
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_URL}updateTransactionPayment`,
        actiondata
      );
      if ((response.status = 200)) {
        fetchData();
        toast.success(response?.message);
      }
    } catch (error) {
      // Handle errors here
      console.error("Error fetching data:", error);
    }
  };

  const handleAutoChange = (e) => {
    const val = e.target.checked;
    setAutoPay(e.target.checked);
    autoPayUpdate(val);
  };

  const autoPayUpdate = async (val) => {
    let payStatus = val ? 1 : 0;
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_URL}autoApprovePaymentGym/${payStatus}`,
        config
      );
      if (response.status == 200) {
        dispatch(approvePay(payStatus));
        toast.success(response?.data?.message);
      }
    } catch (error) {
      // Handle errors here
      console.error("Error fetching data:", error);
    }
  };
  return (
    <>
      <div
        style={{ maxWidth: "1600px" }}
        className="mx-auto container-fluid py-sm-5 py-md-5 py-lg-5 py-xl-5 py-xxl-5 px-5"
      >
        <div className="row align.items-center mt-3">
          <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
            <h3 className="mt-5 pt-2">Transaction List</h3>
          </div>
          <div className="col-md-6 d-flex justify-content-end">
            <div>
              <div class="form-check form-switch mt-5">
                <input
                  class="form-check-input rounded-5"
                  style={{ fontSize: "2rem", cursor: "pointer" }}
                  type="checkbox"
                  checked={autoPay}
                  role="switch"
                  id="flexSwitchCheckChecked"
                  onChange={handleAutoChange}
                />
                <label
                  class="form-check-label fw-bold mt-2"
                  for="flexSwitchCheckChecked"
                >
                  Auto Approve Payment
                </label>
              </div>
            </div>
          </div>
        </div>

        <section className="py-sm-3 py-md-3 py-lg-3 py-xl-3 py-xxl-3">
          <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
              {/* <input type="text" className='form-control' value={search} onChange={(e) => setSearch(e.target.value)} placeholder='Search Members...' /> */}
              <div className="table table-responsive-sm table-responsive-md table-responsive-lg table-responsive-xl table-responsive-xxl mt-3">
                <table className="table table-bordered table-striped-rows table-hover">
                  <thead className="table-dark">
                    <tr>
                      <th>Member ID</th>
                      <th>Member Name </th>
                      <th>Staff Name </th>
                      <th>Order Id</th>
                      <th>Total Amount</th>
                      <th>Paid Amount</th>
                      <th>Transaction ID</th>
                      {/* <th>Type</th> */}
                      <th>Status</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data
                      ?.filter((item) => item.transaction_id)
                      .map((item, i) => (
                        <tr key={i} className={`${item?.amount_due !== 0 ? "bg-light": "bg-white"}`}>
                          <td>{item?.user_id}</td>
                          <td>{item?.user_name}</td>
                          <td>{item?.staff_name}</td>
                          <td>{item?.order_id}</td>
                          <td>Rs. {numberWithCommas(item?.amount)}</td>
                          <td>Rs. {numberWithCommas(item?.amount_paid)} <br />
                          <span className="text-primary fw-bold">{item?.amount_due > 0 ? " Partial Payment" : item?.status === "partial paid" ? "Remaing Amount Paid" : null}</span>
                          </td>
                          <td>{item?.transaction_id}</td>
                          {/* <td>
                            {item?.auto_approve === 1 ? (
                              item?.amount_paid == item?.amount ?
                                <span className="text-success fw-medium">
                                  Auto Approve
                                </span>
                                :
                                item?.amount_paid == item?.amount / 2 ?
                                  <span className="text-primary text-nowrap fw-medium">
                                   Auto Approve <br /> Partial Payment Done Rs-{item?.amount_due}
                                  </span>
                                  : null

                            ) : item?.status === null ? (
                              <span className="text-secondary fw-medium">
                                Pending
                              </span>
                            ) : item?.status === "paid" ? (
                              item?.amount_paid == item?.amount ?
                                <span className="text-success fw-medium">
                                  Success
                                </span>
                                :
                                item?.amount_paid == item?.amount / 2 ?
                                  <span className="text-primary text-nowrap fw-medium">
                                    Partial Payment Done Rs-{item?.amount_due}
                                  </span>
                                  : null

                            ) : item?.status === "failed" ? (
                              <span className="text-danger fw-medium">
                                Failed
                              </span>
                            ) : item?.status === "attempted" ? (
                              <span className="text-primary text-nowrap fw-medium">
                                Partial Payment Done Rs-{item?.amount_due}
                              </span>
                            ) : null}
                          </td> */}
                          <td>
                          {item?.auto_approve === 1 ? (
                                <span className="text-success fw-medium">
                                  Auto Approve
                                </span>
                            ) : item?.status === null ? (
                              <span className="text-secondary fw-medium">
                                Pending
                              </span>
                            ) : item?.status === "paid" || item?.status === "attempted" ? (
                                <span className="text-success fw-medium">
                                  Success
                                </span>
                            ) : item?.status === "failed" ? (
                              <span className="text-danger fw-medium">
                                Failed
                              </span>) 
                               : item?.status === "partial paid" ? (
                                <span className="text-success fw-medium">
                                  Success
                                </span>
                            ) : null}
                          </td>
                          <td>
                            {item?.status === null ? (
                              <div className="full-width-div">
                                <button
                                  className="btn-accept"
                                  onClick={() =>
                                    handleAction(item?.order_id, "paid")
                                  }
                                >
                                  Accept
                                </button>
                                <button
                                  className="ms-2 btn-reject"
                                  onClick={() =>
                                    handleAction(item?.order_id, "failed")
                                  }
                                >
                                  Reject
                                </button>
                              </div>
                            ) : null}
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </section>
      </div>
      <ToastContainer />
    </>
  );
}
