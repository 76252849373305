import React, { useEffect, useState } from "react";
// import profileImg from '../images/profile.jpg';
// import qrcodeImg from '../images/qr-code.png';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import axios from "axios";
// import Payment from './Payment';
// eslint-disable-next-line
import { approvePay, getTrainerData } from "../redux/counterSlice";
import QRCode from "react-qr-code";
// eslint-disable-next-line
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import Loading from "./Loading";
import Modal from "react-bootstrap/Modal";
import { capitalFirstLetter, numberWithCommas } from "../utils/utils";
// import WorkoutHistory from './Trainers/WorkoutHistory';
// import { useNavigate } from 'react-router-dom';

function Dashboard(props) {
  const getGymId = useSelector((state) => state.counterSlice.gymId);
  const logo = useSelector((state) => state.counterSlice.gymId)?.logo;
  // console.log("the token is ", getGymId)
  const dispatch = useDispatch();
  // const navigate = useNavigate();
  const [showBtn, setShowBtn] = useState(true);
  const [showQr, setShowQr] = useState(false);
  // const [workoutModalShow, setWorkoutModalShow] = useState(false);
  const [qrData, setQrData] = useState({ qr_code: "" });
  const [qrImg, setQrImg] = useState("");
  const [timeAndDate, setTimeAndDate] = useState("1");
  const [memberList, setMemberList] = useState([]);
  const [memberProfile, setMemberProfile] = useState({});
  const [values, setValues] = useState([]);
  const [unpaidAmount, setUnpaidAmount] = useState("");
  const [totalAmount, setTotalAmount] = useState("");
  const [paidAmount, setPaidAmount] = useState("");
  const [activeMember, setActiveMember] = useState([]);

  // const [workoutData, setWorkoutData] = useState([]);
  const options = { year: "numeric", month: "long", day: "numeric" };
  const deviceToken = getGymId.device_token;
  // console.log(deviceToken);

  const config = {
    headers: {
      Authorization: deviceToken,
    },
  };

  const [qrModel, setQrModel] = useState(false);
  const [orderId, setOrderId] = useState("");
  const [transactionId, setTransactionId] = useState("");

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchAdminProfile();
    fetchActiveMembers();
  }, []);

  const fetchActiveMembers = async () => {
    const config = {
      headers: {
        Authorization: deviceToken,
      },
    };
    const activeUser = await axios.get(
      `${process.env.REACT_APP_URL}getAllActiveUserByGym`,
      config
    );
    setActiveMember(activeUser?.data.data);
    // console.log("active", setActiveMember);
    // setFilterDate(activeUser?.data.data);
    setUnpaidAmount(activeUser?.data.total_upaid);
    setPaidAmount(activeUser?.data.total_paid);
    setTotalAmount(activeUser?.data.total);
    // setRazorPayKey(activeUser?.data.rpay_key);

    // if (activeUser?.status === 200) {
    //   console.log("active", activeUser);
    // }
  };

  const fetchAdminProfile = async () => {
    const config = {
      headers: {
        Authorization: deviceToken,
      },
    };
    const getAdmin = await axios.get(
      `${process.env.REACT_APP_URL}GetGymAdmin`,
      config
    );
    setValues(getAdmin?.data.data[0]);
    dispatch(approvePay(parseInt(getAdmin?.data.data[0].auto_approve)));
  };

  // useEffect(() => {
  //     setLoading(true)
  //     setTimeout(() => {
  //         setLoading(false)
  //     }, 5000)
  // }, [])

  useEffect(() => {
    dispatch(getTrainerData(memberProfile));
    // eslint-disable-next-line
  }, [memberProfile]);
  const getQRCode = async () => {
    const res = await axios.get(`${process.env.REACT_APP_URL}GenrateQrcode11`);
    if (res?.status === 200) {
      setShowQr(true);
      setShowBtn(false);
      setQrData(res?.data.data);
    } else {
      setShowQr(false);
      toast.warning("QR Code is not generated something wrong", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  useEffect(() => {
    // getQRCode();
    setInterval(() => {
      setShowBtn(true);
      setShowQr(false);
    }, 30000);
    clearInterval(showQr);
    clearInterval(showBtn);
  }, [showQr, showBtn]);

  const updateQR = () => {
    const date = new Date().getSeconds();
    setInterval(() => {
      setTimeAndDate(date.toString());
    }, 30000);
  };

  useEffect(() => {
    updateQR();
    clearInterval(timeAndDate);
  }, [timeAndDate]);

  // console.log(9090, memberProfile)

  // Make a request for a user

  // ------------------- GYM Members List---------------
  const fetchGymMembersList = async () => {
    const config = {
      headers: {
        Authorization: deviceToken,
      },
    };
    // console.log(config);
    const data = {
      total: 5,
    };
    const topPerformerList = await axios.post(
      `${process.env.REACT_APP_URL}topPerformanceByGym`,
      data,
      config
    );
    setMemberList(topPerformerList?.data.data);
    // const topPerformer = await axios.get(`${process.env.REACT_APP_URL}topPerformance`);
    // setMemberList(topPerformer?.data.data);
  };

  useEffect(() => {
    fetchGymMembersList();
  }, []);

  const showQR = () => {
    getQRCode();
  };

  // const handleWorkout = async (memberId) => {
  //     const res = await axios.get(`${process.env.REACT_APP_URL}totalAddendance/${memberId}`);
  //     if(res?.status === 200) {
  //         setWorkoutData(res?.data.data);
  //         setWorkoutModalShow(true);
  //     }
  // }

  /**---------- Pay Upaid Amount */

  const fetchQrCode = async () => {
    try {
      const qrcode = `${process.env.REACT_APP_URL}sa/getGymSuperAdminQR`;
      const getCode = await axios.get(qrcode);
      setQrImg(getCode?.data?.data?.qr_image);
    } catch (error) {
      console.log("Error fetching duration data:", error);
    }
  };

  const handleUnpaidAmount = async () => {
    const resAdminPay = await axios.post(
      `${process.env.REACT_APP_URL}adminpayment`,
      null,
      config
    );
    if (resAdminPay?.status === 200) {
      setOrderId(resAdminPay?.data?.admin_order_id);
      fetchQrCode();
      setQrModel(true);
    }
  };

  let tranValue = {
    order_id: orderId,
    admin_transaction_id: transactionId,
    admin_amount: unpaidAmount,
  };

  const handlePayAmount = async (e) => {
    e.preventDefault();
    const resAdminPay = await axios.post(
      `${process.env.REACT_APP_URL}admin_update_payment`,
      tranValue,
      config
    );
    if (resAdminPay?.status == 200) {
      fetchActiveMembers();
      setQrModel(false);
      toast.success(resAdminPay?.data?.message, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  return (
    <>
      {loading ? (
        <Loading loading={loading} />
      ) : (
        <div className="container py-5 mt-4">
          <section className="py-4">
              <img src={`${process.env.REACT_APP_URL}${logo}`} className="rounded-4" style={{width:"600px", height:"600px"}} />
            <div className="row">
              <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                <div className="qr-code mt-4">
                  <div className="carousel slide" id="carouselTopPerformer">
                    <div className="carousel-indicators">
                      {memberList?.map((curVal, i) => {
                        return (
                          <>
                            <button
                              type="button"
                              data-bs-target="#carouselTopPerformer"
                              data-bs-slide-to={i}
                              className={i === 0 ? "active" : ""}
                              aria-current="true"
                              aria-label={`Slide ${i + 1}`}
                            ></button>
                          </>
                        );
                      })}
                    </div>
                    <div className="carousel-inner">
                      {memberList?.map((curVal, i) => {
                        return (
                          <>
                            <div
                              key={i}
                              className={
                                i === 0
                                  ? "carousel-item active"
                                  : "carousel-item"
                              }
                            >
                              <div className="card">
                                <h3 className="card-header">Top Performance</h3>
                                <div key={i} className="card-body">
                                  <table className="table table-bordered table-striped rounded-2">
                                    <tbody>
                                      <tr>
                                        <th>Name</th>
                                        <td>
                                          {capitalFirstLetter(
                                            curVal?.firstname
                                          )}{" "}
                                          {capitalFirstLetter(curVal?.lastname)}
                                        </td>
                                      </tr>
                                      <tr>
                                        <th>Mobile</th>
                                        <td>
                                          {curVal?.mobile && curVal?.mobile}
                                        </td>
                                      </tr>
                                      <tr>
                                        <th>Points</th>
                                        <td>
                                          {curVal?.points && curVal?.points}
                                        </td>
                                      </tr>
                                      <tr>
                                        <th>Plan Start</th>
                                        {curVal?.subscription_addon_details ? (
                                          <>
                                            <td>
                                              {curVal?.subscription_addon_details &&
                                                new Date(
                                                  curVal?.subscription_addon_details?.startdate
                                                ).toLocaleDateString(
                                                  "en-US",
                                                  options
                                                )}
                                            </td>
                                          </>
                                        ) : (
                                          <>
                                            <td>No Active Plan</td>
                                          </>
                                        )}
                                      </tr>
                                      <tr>
                                        <th>Plan Expiry</th>
                                        {curVal?.subscription_addon_details ? (
                                          <>
                                            <td>
                                              {curVal?.subscription_addon_details &&
                                                new Date(
                                                  curVal?.subscription_addon_details?.enddate
                                                ).toLocaleDateString(
                                                  "en-US",
                                                  options
                                                )}
                                            </td>
                                          </>
                                        ) : (
                                          <>
                                            <td>No Active Plan</td>
                                          </>
                                        )}
                                      </tr>
                                      <tr>
                                        <th>Active Plan</th>
                                        {curVal?.subscription_details ? (
                                          <>
                                            <td>
                                              {curVal?.subscription_details &&
                                                curVal?.subscription_details
                                                  .duration}{" "}
                                              {curVal?.subscription_details &&
                                                curVal?.subscription_details
                                                  .type}
                                            </td>
                                          </>
                                        ) : (
                                          <>
                                            <td>No Active Plan</td>
                                          </>
                                        )}
                                      </tr>
                                      <tr>
                                        <th>Status</th>
                                        <td>
                                          {curVal?.status === "active"
                                            ? "Active"
                                            : "Inactive"}
                                        </td>
                                      </tr>
                                      <tr>
                                        <th>Trainer</th>
                                        {curVal?.trainer_details ? (
                                          <>
                                            <td>
                                              {curVal?.trainer_details &&
                                                curVal?.trainer_details
                                                  .firstname}
                                            </td>
                                          </>
                                        ) : (
                                          <>
                                            <td>No Trainer</td>
                                          </>
                                        )}
                                      </tr>
                                    </tbody>
                                  </table>
                                  {/* <div className='d-flex justify-content-between'>
                                                                <img src={profileImg} alt="img" className='w-25 rounded-circle' />
                                                                <div>
                                                                    <h4>Name:</h4>
                                                                    <p>{curVal.firstname} {curVal.lastname}</p>
                                                                    <h4>Mobile:</h4>
                                                                    <p>{curVal.mobile}</p>
                                                                    <h4>Plan Start:</h4>
                                                                    <p>{curVal.planstart}</p>
                                                                </div>
                                                                <div>
                                                                    <h4>Plan Expiry:</h4>
                                                                    <p>{curVal.planexpiry}</p>
                                                                    <h4>Points:</h4>
                                                                    <p>{curVal.points}</p>
                                                                    {
                                                                        curVal.trainer !== null &&
                                                                        <>
                                                                        <h4>Trainer Name:</h4>
                                                                        <p>{curVal.trainer && curVal.trainer.firstname}</p>
                                                                        </>
                                                                    }
                                                                    
                                                                </div>
                                                            </div> */}
                                </div>
                                {/* <div className='card-footer'>
                                                            <button className='btn btn-outline-primary float-end' onClick={() => handleWorkout(curVal.member_id)}>Workout History</button>
                                                        </div> */}
                              </div>
                            </div>
                          </>
                        );
                      })}
                    </div>
                  </div>
                </div>
                {/* <Payment /> */}
              </div>
              <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                <div className="qr-code mt-4" style={{ height: "417px" }}>
                  <div className="card h-100">
                    <h3 className="card-header">QR Code Scanner</h3>
                    <div className="card-body">
                      <div className="d-flex h-100 align-items-center justify-content-center p-3">
                        <div className="d-flex align-items-center justify-content-center me-5">
                          {/* {showBtn && (
                            <button
                              showbtn={showBtn}
                              className="btn btn-outline-primary"
                              onClick={() => showQR()}
                            >
                              Show QR Code
                            </button>
                          )} */}
                          {/* {showQr && ( */}
                          <QRCode
                            showqr={showQr}
                            className="qr-img align-self-start"
                            // size={256}
                            style={{
                              height: "auto",
                              maxWidth: "100%",
                              // width: "100%",
                              marginLeft: "10px",
                            }}
                            value={qrData.qr_code}
                            viewBox={`0 0 256 256`}
                          />
                          {/* )} */}
                        </div>
                        {/* <div className="text-end">
                          {values?.logo && (
                            <img
                              src={`${process.env.REACT_APP_URL}${values.logo}`}
                              alt="Gym Logo"
                              width={"150px"}
                              style={{ borderRadius: "10px" }}
                            />
                          )}
                        </div> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <hr />

          <section className="py-sm-5 py-md-5 py-lg-5 py-xl-5 py-xxl-5">
            <div className="row">
              <div className="col-lg-8 col-xl-8 col-xxl-8">
                <div className="d-flex justify-content-between align-items-center">
                  <h3>Top 5 Performer GYM Members List</h3>
                  <Link to={"/allPerformer"}>View All Performer</Link>
                </div>
                <div className="table table-responsive-sm table-responsive-md table-responsive-lg table-responsive-xl table-responsive-xxl mt-3">
                  <table className="table table-bordered table-striped-rows table-hover">
                    <thead className="table-dark">
                      <tr>
                        <th>Member Id</th>
                        <th>Member Name</th>
                        <th>Points</th>
                        <th>Active Plan</th>
                        <th>Status</th>
                        <th>Trainer</th>
                      </tr>
                    </thead>
                    <tbody>
                      {memberList?.map((curVal, i) => {
                        return (
                          <tr key={i} onClick={() => setMemberProfile(curVal)}>
                            <td>{curVal?.member_id && curVal?.member_id}</td>
                            <td>
                              {curVal?.firstname &&
                                capitalFirstLetter(curVal?.firstname)}{" "}
                              {curVal?.lastname &&
                                capitalFirstLetter(curVal?.lastname)}
                            </td>
                            <td>{curVal?.points && curVal?.points}</td>
                            {curVal?.subscription_details ? (
                              <>
                                <td>
                                  {curVal?.subscription_details &&
                                    curVal?.subscription_details?.duration}{" "}
                                  {curVal?.subscription_details &&
                                    curVal?.subscription_details?.type}
                                </td>
                              </>
                            ) : (
                              <>
                                <td>No Active Plan</td>
                              </>
                            )}
                            <td>
                              {curVal?.status === "active"
                                ? "Active"
                                : "Inactive"}
                            </td>
                            <td>
                              {curVal?.trainer ? curVal?.trainer : "No Trainer"}
                            </td>
                          </tr>
                        );
                      })}
                      {/* {
                                        memberList.map((curVal, i) => {
                                            return <tr
                                                key={i}
                                                onClick={() => setMemberProfile(curVal)}
                                            >
                                                <td>{curVal.user && curVal.user.firstname} {curVal.user && curVal.user.lastname}</td>
                                                <td>{curVal.user && curVal.user.mobile}</td>
                                                <td>{curVal.user && curVal.user.planstart}</td>
                                            </tr>
                                        })
                                    } */}
                    </tbody>
                  </table>
                </div>
              </div>

              <div className="col-lg-4 col-xl-4 col-xxl-4 ps-5 pt-4">
                <div className="d-flex justify-content-end">
                  <div className="card mt-4">
                    <div className="d-column bg-body-tertiary card-body">
                      <div className="d-flex">
                        <p>
                          Total Unpaid Amount: &#8377;
                          {numberWithCommas(unpaidAmount)}
                        </p>
                      </div>
                      <div className="d-flex">
                        <p>
                          Total Paid Amount: &#8377;
                          {numberWithCommas(paidAmount)}
                        </p>
                      </div>
                      <div className="d-flex">
                        <p>
                          Total Amount: &#8377;{numberWithCommas(totalAmount)}
                        </p>
                      </div>
                      <button
                        onClick={() => handleUnpaidAmount()}
                        className="btn btn-outline-primary"
                        disabled={!unpaidAmount}
                      >
                        Pay Unpaid Amount
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              {/* <div
                className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6"
                style={{
                  display:
                    Object.keys(memberProfile).length > 0 ? "block" : "none",
                }}
              >
                <h3>Member Profile</h3>
                <div className="qr-code mt-3 mb-4">
                  {memberProfile && (
                    <>
                      <div className="card">
                        <h3 className="card-header">Member Profile</h3>
                        <div className="card-body">
                          <table className="table table-bordered table-striped rounded-2">
                            <tbody>
                              <tr>
                                <th>Name</th>
                                <td>
                                  {memberProfile?.firstname &&
                                    memberProfile?.firstname}{" "}
                                  {memberProfile?.lastname &&
                                    memberProfile?.lastname}
                                </td>
                              </tr>
                              <tr>
                                <th>Mobile</th>
                                <td>
                                  {memberProfile?.mobile &&
                                    memberProfile?.mobile}
                                </td>
                              </tr>
                              <tr>
                                <th>Points</th>
                                <td>
                                  {memberProfile?.points &&
                                    memberProfile?.points}
                                </td>
                              </tr>
                              <tr>
                                <th>Plan Start</th>
                                {memberProfile?.subscription_addon_details ? (
                                  <>
                                    <td>
                                      {memberProfile?.subscription_addon_details &&
                                        new Date(
                                          memberProfile?.subscription_addon_details?.startdate
                                        ).toLocaleDateString("en-US", options)}
                                    </td>
                                  </>
                                ) : (
                                  <>
                                    <td>No Active Plan</td>
                                  </>
                                )}
                              </tr>
                              <tr>
                                <th>Plan Expiry</th>
                                {memberProfile?.subscription_addon_details ? (
                                  <>
                                    <td>
                                      {memberProfile?.subscription_addon_details &&
                                        new Date(
                                          memberProfile?.subscription_addon_details?.enddate
                                        ).toLocaleDateString("en-US", options)}
                                    </td>
                                  </>
                                ) : (
                                  <>
                                    <td>No Active Plan</td>
                                  </>
                                )}
                              </tr>
                              <tr>
                                <th>Active Plan</th>
                                {memberProfile?.subscription_details ? (
                                  <>
                                    <td>
                                      {memberProfile.subscription_details &&
                                        memberProfile.subscription_details
                                          .duration}{" "}
                                      {memberProfile.subscription_details &&
                                        memberProfile.subscription_details.type}
                                    </td>
                                  </>
                                ) : (
                                  <>
                                    <td>No Active Plan</td>
                                  </>
                                )}
                              </tr>
                              <tr>
                                <th>Status</th>
                                <td>
                                  {memberProfile?.status &&
                                    memberProfile?.status}
                                </td>
                              </tr>
                              {memberProfile.trainer_details && (
                                <>
                                  <tr>
                                    <th>Trainer</th>
                                    <td>
                                      {memberProfile.trainer_details &&
                                        memberProfile.trainer_details.firstname}
                                      <button
                                        className="btn btn-outline-primary float-end"
                                        data-bs-toggle="modal"
                                        data-bs-target={`#${props.idModal}`}
                                      >
                                        View Trainer
                                      </button>
                                    </td>
                                  </tr>
                                </>
                              )}
                            </tbody>
                          </table>
                        </div>
                        <div className='card-footer'>
                                            <button className='btn btn-outline-primary float-end' onClick={() => handleWorkout(memberProfile.member_id)}>Workout History</button>
                                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div> */}
            </div>
          </section>

          {/* {workoutModalShow && <WorkoutHistory
                workoutModalShow={workoutModalShow}
                setWorkoutModalShow={setWorkoutModalShow}
                workoutData={workoutData}
            />} */}
          <ToastContainer />
        </div>
      )}
      <Modal show={qrModel} size="lg">
        <Modal.Header>
          <Modal.Title>Scan Qr Code</Modal.Title>
          <button
            type="button"
            data-bs-dismiss="modal"
            className="btn-close"
            onClick={() => setQrModel(false)}
          ></button>
        </Modal.Header>
        <Modal.Body>
          <div className="text-center">
            <h1>₹ {unpaidAmount}</h1>
            <div>
              <img
                src={`${process.env.REACT_APP_URL}/${qrImg}`}
                height={"350px"}
              />
            </div>
            <div className="mt-3 py-3">
              <form onSubmit={handlePayAmount}>
                <input
                  placeholder="Enter Transaction Id"
                  required
                  value={transactionId}
                  onChange={(e) => setTransactionId(e.target.value)}
                  className="w-50"
                />
                <button type="submit" className="ms-3 btn btn-success">
                  Submit
                </button>
              </form>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default Dashboard;
