import { combineReducers, configureStore } from '@reduxjs/toolkit';
import counterSlice from './counterSlice';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

const persistConfig = {
    key: 'rehydrate',
    storage
}

const persistedReducer = persistReducer(persistConfig, counterSlice);
const reducers = combineReducers({
    counterSlice:persistedReducer
});

export const store = configureStore({
    reducer: reducers,
})
const persiststore = persistStore(store);

export default store;
export { persiststore }