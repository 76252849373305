// -----------------------------------------------------------------------------
// -----------------------------------------------------------------------------
import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import axios, { all } from 'axios';
import { useSelector } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import { getAddonId } from '../../redux/counterSlice';



function ActivatePlanModel({ showPlanModel, setShowPlanModel, memberDetails, fetchMemberDetails}) {
    const getMember = useSelector(state => state.counterSlice.userDetails);
    const [subscriberList, setSubscriberList] = useState([]);
    // const [memberModel, setMemberModel] = useState();
    // const [trainerList, setTrainerList] = useState([]);
    const [qrModel, setQrModel] = useState(false);
    const [qrData, setQrData] = useState(false);
    const [lockerList, setLockerList] = useState([]);
    const [priceList, setPriceList] = useState([]);
    const [lockerPriceList, setLockerPriceList] = useState([]);
    const [step, setStep] = useState(0);
    const [memberModel, setMemberModel] = useState({
        addonsName: '',
        addonsDuration: ''
    });
    const [addonFee, setAddonFee] = useState(0);
    const [trainerList, setTrainerList] = useState([]);
    const [data, setData] = useState([]); // Define the 'data' state variable
    const [addonDuration, setAddonDuration] = useState([]); // State for selected duration
    const [addonPrice, setAddonPrice] = useState([]); // State for addon price
    const [danceAddonPrice, setDanceAddonPrice] = useState([]);
    const [lockerAddonData, setLockerAddonData] = useState(null);
    const [addonName, setAddonName] = useState([]);
    const [selectedTab, setSelectedTab] = useState(null);
    const [planStart, setPlanStart] = useState(null);
    const [addonType, setAddonType] = useState(null);
    const [getAllAddonName, setGetAllAddonName] = useState([]);
    const [fetchAllPrice, setFetchAllPrice] = useState([]);
    const [orderId, setOrderId] = useState("");
    const [transactionId, setTransactionId] = useState("");
    const [rows, setRows] = useState([{ addonsName: '', addonsDuration: '', addonFees: '' , startdate:''}]);
    const [selectedOption, setSelectedOption] = useState('fullpayemnt');
    // const getGymId = useSelector(state=>state.counterSlice.gymId);
  // console.log("the token is ", getGymId)
//   const deviceToken = getGymId.device_token;

    // useEffect(() => {
    //     // Fetch data from your API and set it to the 'data' state variable
    //     async function fetchData() {
    //         try {
    //             const response = await axios.get(`${process.env.REACT_APP_URL}getAllTrainerByAdmin`, );
    //             setData(response.data.data);
    //         } catch (error) {
    //             // Handle errors here
    //             console.error('Error fetching data:', error);
    //         }
    //     }

    //     fetchData(); // Call the fetchData function when the component mounts
    // }, []);
    // const [subscription, setSubscription] = useState([]);
    const getGymId = useSelector(state=>state.counterSlice.gymId);
    // console.log("the token is ", getGymId)
    const deviceToken = getGymId.device_token;
    // console.log(deviceToken);

    const config = {
        headers:{
          "Authorization" : deviceToken,
        }
      };
    
      const fetchQrCode = async () => {
        try {
            const qrcode = `${process.env.REACT_APP_URL}getGymAdminQR`;
            const getCode = await axios.get(qrcode, config);
            setQrData(getCode?.data?.data?.qr_image);
        }
        catch (error) {
            console.log('Error fetching duration data:', error);
        }
    }

    // console.log(memberDetails);

    const loadScript = (src) => {
        return new Promise((resolve) => {
            const script = document.createElement('script');
            script.src = src;
            script.onload = () => {
                resolve(true);
            };
            script.onerror = () => {
                resolve(false);
            };
            document.body.appendChild(script);
        });
    }

    useEffect(() => {
        fetchAllSubscriber();
        fetchAllTrainer();
        fetchLockers();
        fetchAllPricing();
        fetchBoxingAddonData();
        // handleAddonId();
        // fetchAddonName();
    }, []);

    // const fetchAllPricing = async () => {
    //     const getAllPricing = await axios.get(`${process.env.REACT_APP_URL}pricing`, config);
    //     setPriceList(getAllPricing?.data);
    // }
    const fetchAllPricing = async (selectedTrainerId) => {
        try {
            // Construct the API URL with the selected trainer's ID
            const pricingAPIUrl = `${process.env.REACT_APP_URL}pricing/${selectedTrainerId}`;
            const getAllPricing = await axios.get(pricingAPIUrl, config);
            setPriceList(getAllPricing?.data);
        } catch (error) {
            // Handle errors if necessary
            console.error("Error fetching pricing data:", error);
        }
    }

    const fetchBoxingAddonData = async () => {
        try {
            const boxingPricingUrl = `${process.env.REACT_APP_URL}pricing_addons`;
            const getBoxingAddonData = await axios.get(boxingPricingUrl, config);
            setAddonPrice(getBoxingAddonData?.data) 
            // console.log("get bocxing k data", getBoxingAddonData.data.Zumba);
        } catch (error) {
            console.error("Error fetching Boxing Addon data: ", error);
            return null;
        }
    };

      const fetchAddonName = async () => {
        try {
            const addonNameUrl = `${process.env.REACT_APP_URL}getAllAddonsNameByGym`;
            const getAddonName = await axios.get(addonNameUrl, config);
            setAddonName(getAddonName?.data?.Data);
          } catch (error) {
            console.error("Error fetching addons data: ", error);
          }
      }

      const fetchAddonDuration = async () => {
        try {
            const addonDurationUrl = `${process.env.REACT_APP_URL}GetAllAddonDuration`;
            const getAddonDuration = await axios.get(addonDurationUrl, config);
            setAddonDuration(getAddonDuration?.data.data);
        }
        catch (error) {
            console.log('Error fetching duration data:', error);
        }
      }

      useEffect(() => {
        fetchAddonName();
        fetchAddonDuration();
      }, []);
    
      const handleTabClick = (addonId) => {
        setSelectedTab(addonId);
      };

      const fetchLockerAddonData = async (lockerId) => {
        try {
            // Construct the API URL with the locker ID
            const lockerAddonAPIUrl = `${process.env.REACT_APP_URL}pricing_lockers/${lockerId}`;
    
            const response = await axios.get(lockerAddonAPIUrl, config);
            setLockerPriceList(response?.data) // Assuming the API response contains the locker addon pricing data
        } catch (error) {
            console.error("Error fetching Locker Addon data: ", error);
            return null;
        }
    };
 
    const fetchAllSubscriber = async () => {
        const getAllSubscriber = await axios.get(`${process.env.REACT_APP_URL}GetAllSubscriberByAdmin`, config);
        setSubscriberList(getAllSubscriber?.data.data);
    }

    const fetchAllTrainer = async () => {
        const allTrainers = await axios.get(`${process.env.REACT_APP_URL}getAllTrainerByAdmin`, config);
        setTrainerList(allTrainers?.data.data);
    }

    const fetchLockers = async () => {
        const allLockers = await axios.get(`${process.env.REACT_APP_URL}getAllLocker`, config);
        setLockerList(allLockers?.data.data);
    }

    // const fetchAllAddons = async () =>{
    //     const allAddon = await axios.get(`${process.env.REACT_APP_URL}getAllAddonsNameByGym`)
    //     setGetAllAddonName(allAddon?.data.data);
    // }
    
    // const handleAddonId = async() =>{
    //     const id = memberModel?.addonsName
    //     const allPrice = await axios.get(`${process.env.REACT_APP_URL}getAllAddonsPriceByPGym/${id}`)
    //     setFetchAllPrice(allPrice?.data.data);
    // }
    // console.log(fetchAllPrice)

    // useEffect(() => {
    //     // console.log('State Changed: addonsName:', memberModel.addonsName, 'addonsDuration:', memberModel.addonsDuration);
    //     if(memberModel.addonsName && memberModel.addonsDuration) {
    //         getAddonFees();
    //     }
    // }, [memberModel.addonsName, memberModel.addonsDuration]);
    // useEffect(() => {
    //     // console.log('State Changed: addonsName:', memberModel.addonsName, 'addonsDuration:', memberModel.addonsDuration);
    //     if(memberModel.addonsName && memberModel.addonsDuration) {
    //         getAddonFees();
    //     }
    // }, [memberModel.addonsName, memberModel.addonsDuration]);

    const handleInput = (e) => {

        // setMemberModel((prev) => {
        //     const updatedState = { ...prev, [e.target.name]: e.target.value };
        //     // console.log('Updated State:', updatedState);
        //     return updatedState
        // });
        
        setMemberModel((pre) => ({ ...pre, [e.target.name]: e.target.value }))
        const { name, value } = e.target;

        if (name === 'trainer') {
            // Make sure 'value' here contains the selected trainer's ID
            console.log('Selected Trainer ID:', value);

            // Fetch pricing data based on the selected trainer's ID
            fetchAllPricing(value);
        }

        if (name === 'locker') {
            // Make sure 'value' here contains the selected locker's ID
            const lockerId = e.target.options[e.target.selectedIndex].getAttribute('data-id'); // Extract locker ID from selected option
            console.log('Selected Locker ID:', lockerId);
    
            // Fetch locker addon data based on the selected locker's ID
            fetchLockerAddonData(lockerId).then((data) => {
                setLockerAddonData(data);
            });
        }

        if (name === 'addonBoxingType') {
            // Call the function to fetch the addon data with the selected addon's ID
            fetchBoxingAddonData(value); // Assuming 'value' is the addon ID
          }

    }

    // const handleInput = (e) => {
    //     setMemberModel((prev) => {
    //         const updatedState = { ...prev, [e.target.name]: e.target.value };
    //         // console.log('Updated State:', updatedState);
    //         return updatedState
    //     });
        
    //     // setMemberModel((pre) => ({ ...pre, [e.target.name]: e.target.value }));
    //     // getAddonFees();
    // }
    
    // const getAddonFees = async (name, duration) => {
    //     if(memberModel.addonsName && memberModel.addonsDuration) {
    //         const formData = new FormData();
    //         formData.append('addon_id', memberModel?.addonsName);
    //         formData.append('duration_id', memberModel?.addonsDuration);
    //         const addonPriceUlr = `${process.env.REACT_APP_URL}getAllAddonsPriceGym`;
    //         const getaddonPrice = await axios.post(addonPriceUlr, formData,config);
    //         if(getaddonPrice?.status === 200) {
    //             const pr = getaddonPrice?.data.Data[0].addon_price;
    //             setAddonFee(pr);
    //         }
    //     }
    // }
    // const getAddonFees = async (name, duration) => {
    //     if(memberModel.addonsName && memberModel.addonsDuration) {
    //         const formData = new FormData();
    //         formData.append('addon_id', memberModel?.addonsName);
    //         formData.append('duration_id', memberModel?.addonsDuration);
    //         const addonPriceUlr = `${process.env.REACT_APP_URL}getAllAddonsPriceGym`;
    //         const getaddonPrice = await axios.post(addonPriceUlr, formData,config);
    //         if(getaddonPrice?.status === 200) {
    //             const pr = getaddonPrice?.data.Data[0].addon_price;
    //             setAddonFee(pr);
    //         }
    //     }
    // }

    // useEffect(() => {
    //     console.log("price", addonFee);
    // }, [addonFee]);
    
    let planPrice = 0;
    let subPlan = 0;
    subscriberList?.filter((curVal) => {
        // eslint-disable-next-line
        if(curVal.id == memberModel?.membershipplan) {
            planPrice = curVal?.subsriberfees;
            subPlan = curVal?.duration;
        }
        return { planPrice, subPlan };
    });

    let addonId = 0;
    let addonname = 0;
    // addonName?.filter((curVal)=>{
    //     if(curVal.id == getAllAddonName?.addonsName ){
    //         addonname = curVal?.addons_name;
    //         addonId = curVal?.id;
    //     }
    //     return{ addonname, addonId};
    // });

    let addonpriceId = 0;
    // let addonname = 0;
    let duration30 = 0;
    let duration60 = 0;
    let duration90 = 0;
    let duration180 = 0;
    let duration365 = 0;
    addonName?.filter((curVal)=>{
        if(curVal.id == fetchAllPrice?.addonsPrice ){
            // addonname = curVal?.addons_name;
            addonpriceId = curVal?.id;
            duration30 = curVal?.days_30;
            duration60 = curVal?.days_60;
            duration90 = curVal?.days_90;
            duration180 = curVal?.days_180;
            duration365 = curVal?.days_365;
        }
        return{addonpriceId, duration30, duration60, duration90, duration180, duration365};
    });

    // let trainerFee = 0;
    // trainerList?.filter((curVal) => {
    //     // eslint-disable-next-line
    //     if(curVal.id == memberModel?.trainer) {
    //         trainerFee = curVal?.trainerfees
    //     }
    //     return trainerFee;
    // })

    let aPTPrice = 0;
    // eslint-disable-next-line
    if(memberModel?.trainerPT == 'Alternate') {
        priceList?.Alternate_PT?.filter((curVal) => {
            // eslint-disable-next-line
            if(memberModel?.trainerduration == 30) {
                aPTPrice = curVal?.monthly?.price;
            }
            // eslint-disable-next-line
            if(memberModel?.trainerduration == 60) {
                aPTPrice = curVal?.secondly?.price;
            }
            // eslint-disable-next-line
            if(memberModel?.trainerduration == 90) {
                aPTPrice = curVal?.quarterly?.price
            }
            // eslint-disable-next-line
            if(memberModel?.trainerduration == 180) {
                aPTPrice = curVal?.half_yearly?.price
            }
            // eslint-disable-next-line
            if(memberModel?.trainerduration == 365) {
                aPTPrice = curVal?.yearly?.price
            }
            return aPTPrice;
        })
    }

    // eslint-disable-next-line
    if(memberModel?.trainerPT == 'Regular') {
        priceList?.Regular_PT?.filter((curVal) => {
            // eslint-disable-next-line
            if(memberModel?.trainerduration == 30) {
                aPTPrice = curVal?.monthly?.price
                // console.log("price ofr 30",aPTPrice);
            }
            // eslint-disable-next-line
            if(memberModel?.trainerduration == 60) {
                aPTPrice = curVal?.secondly?.price
            }
            // eslint-disable-next-line
            if(memberModel?.trainerduration == 90) {
                aPTPrice = curVal?.quarterly?.price
            }
            // eslint-disable-next-line
            if(memberModel?.trainerduration == 180) {
                aPTPrice = curVal?.half_yearly?.price
            }
            // eslint-disable-next-line
            if(memberModel?.trainerduration == 365) {
                aPTPrice = curVal?.yearly?.price
            }
            return aPTPrice;
        })
    }

    let lockerPrice = 0;
    lockerPriceList?.data?.filter((curVal) => {
        // eslint-disable-next-line
        // if(memberModel?.lockerduration == 15) {
        //     lockerPrice = memberModel?.lockerduration * curVal?.monthly?.daily            
        // }
        // eslint-disable-next-line
        if(memberModel?.lockerduration == 30) {
            
            lockerPrice = curVal?.monthly?.price  
            // console.log("dkjbdakc", lockerPrice);          
        }
        if(memberModel?.lockerduration == 60) {
            
            lockerPrice = curVal?.secondly?.price  
            // console.log("dkjbdakc", lockerPrice);          
        }
        if(memberModel?.lockerduration == 90) {
            lockerPrice = curVal?.quarterly?.price            
        }
        if(memberModel?.lockerduration == 180) {
            lockerPrice = curVal?.half_yearly?.price            
        }
        if(memberModel?.lockerduration == 365) {
            lockerPrice = curVal?.yearly?.price            
        }
        return lockerPrice;
    })

    // let upgradePrice = 0;
    // priceList?.data?.filter((curVal) => {
    //     // eslint-disable-next-line
    //     // if(memberModel?.upgradeduration == 15) {
    //     //     upgradePrice = memberModel?.upgradeduration * curVal?.monthly?.daily            
    //     // }
    //     // eslint-disable-next-line
    //     if(memberModel?.upgradeduration == 30) {
    //         upgradePrice = curVal?.monthly?.price   
    //         // console.log("hello world");         
    //     }
    //     if(memberModel?.upgradeduration == 90) {
    //         upgradePrice = curVal?.quarterly?.price            
    //     }
    //     if(memberModel?.upgradeduration == 180) {
    //         upgradePrice = curVal?.half_yearly?.price            
    //     }
    //     if(memberModel?.upgradeduration == 365) {
    //         upgradePrice = curVal?.yearly?.price            
    //     }
    //     return upgradePrice;
    // })

    // let boxingPrice = 0;
    // let selectedBoxingId = null;
    // addonPrice?.Boxing?.filter((curVal) => {
    //     // eslint-disable-next-line
    //     // if(memberModel?.upgradeduration == 15) {
    //     //     upgradePrice = memberModel?.upgradeduration * curVal?.monthly?.daily            
    //     // }
    //     // eslint-disable-next-line
    //     if(memberModel?.addonDuration == 30) {
    //         boxingPrice = curVal?.monthly?.price;   
    //         // console.log("hello world");       
    //         selectedBoxingId = curVal.id;  
    //         console.log("id is : ", selectedBoxingId);
    //     }
    //     if(memberModel?.addonDuration == 60) {
    //         boxingPrice = curVal?.secondly?.price   
    //         // console.log("hello world");     
    //         selectedBoxingId = curVal.id;     
    //     }
    //     if(memberModel?.addonDuration == 90) {
    //         boxingPrice = curVal?.quarterly?.price  
    //         selectedBoxingId = curVal.id;           
    //     }
    //     if(memberModel?.addonDuration == 180) {
    //         boxingPrice = curVal?.half_yearly?.price
    //         selectedBoxingId = curVal.id;             
    //     }
    //     if(memberModel?.addonDuration == 365) {
    //         boxingPrice = curVal?.yearly?.price  
    //         selectedBoxingId = curVal.id;           
    //     }
    //     return boxingPrice;
    // })

    let boxingPrice = 0;
    let selectedBoxingId = null;

    addonPrice?.Boxing?.filter((curVal) => {
        // eslint-disable-next-line
        // if(memberModel?.upgradeduration == 15) {
        //     upgradePrice = memberModel?.upgradeduration * curVal?.monthly?.daily            
        // }
        // eslint-disable-next-line
        if(memberModel?.addonDuration == 30) {
            boxingPrice = curVal?.monthly?.price;
        }
        if(memberModel?.addonDuration == 60) {
            boxingPrice = curVal?.secondly?.price;
        }
        if(memberModel?.addonDuration == 90) {
            boxingPrice = curVal?.quarterly?.price;
        }
        if(memberModel?.addonDuration == 180) {
            boxingPrice = curVal?.half_yearly?.price;
        }
        if(memberModel?.addonDuration == 365) {
            boxingPrice = curVal?.yearly?.price;
        }
        
        // Assign selectedBoxingId outside of if conditions
        selectedBoxingId = curVal.id;
        // console.log("selected id is:", selectedBoxingId);

        return boxingPrice;
    });

    let DancePrice = 0;
    let selectedDanceId = null;

    addonPrice?.Dance?.filter((curVal) => {
        // eslint-disable-next-line
        // if(memberModel?.upgradeduration == 15) {
        //     upgradePrice = memberModel?.upgradeduration * curVal?.monthly?.daily            
        // }
        // eslint-disable-next-line
        if(memberModel?.addonDanceDuration == 30) {
            DancePrice = curVal?.monthly?.price   
            // console.log("hello world");         
        }
        if(memberModel?.addonDanceDuration == 60) {
            DancePrice = curVal?.secondly?.price   
            // console.log("hello world");         
        }
        if(memberModel?.addonDanceDuration == 90) {
            DancePrice = curVal?.quarterly?.price            
        }
        if(memberModel?.addonDanceDuration == 180) {
            DancePrice = curVal?.half_yearly?.price            
        }
        if(memberModel?.addonDanceDuration == 365) {
            DancePrice = curVal?.yearly?.price            
        }
        selectedDanceId = curVal.id;
        // console.log(selectedDanceId);
        return DancePrice;
    })

    let yogaPrice = 0;
    let selectedYogaId = null;
    addonPrice?.Yoga?.filter((curVal) => {
        // eslint-disable-next-line
        // if(memberModel?.upgradeduration == 15) {
        //     upgradePrice = memberModel?.upgradeduration * curVal?.monthly?.daily            
        // }
        // eslint-disable-next-line
        if(memberModel?.addonYogaDuration == 30) {
            yogaPrice = curVal?.monthly?.price   
            // console.log("hello world");         
        }
        if(memberModel?.addonYogaDuration == 60) {
            yogaPrice = curVal?.secondly?.price   
            // console.log("hello world");         
        }
        if(memberModel?.addonYogaDuration == 90) {
            yogaPrice = curVal?.quarterly?.price            
        }
        if(memberModel?.addonYogaDuration == 180) {
            yogaPrice = curVal?.half_yearly?.price            
        }
        if(memberModel?.addonYogaDuration == 365) {
            yogaPrice = curVal?.yearly?.price            
        }
        selectedYogaId = curVal.id;
        // console.log(selectedYogaId);

        return yogaPrice;
    })

    let steamPrice = 0;
    let selectedSteamId = null;
    addonPrice?.Steam?.filter((curVal) => {
        // eslint-disable-next-line
        // if(memberModel?.upgradeduration == 15) {
        //     upgradePrice = memberModel?.upgradeduration * curVal?.monthly?.daily            
        // }
        // eslint-disable-next-line
        if(memberModel?.addonSteamDuration == 30) {
            steamPrice = curVal?.monthly?.price   
            // console.log("hello world");         
        }
        if(memberModel?.addonSteamDuration == 60) {
            steamPrice = curVal?.secondly?.price   
            // console.log("hello world");         
        }
        if(memberModel?.addonSteamDuration == 90) {
            steamPrice = curVal?.quarterly?.price            
        }
        if(memberModel?.addonSteamDuration == 180) {
            steamPrice = curVal?.half_yearly?.price            
        }
        if(memberModel?.addonSteamDuration == 365) {
            steamPrice = curVal?.yearly?.price            
        }
        selectedSteamId = curVal.id;
        return steamPrice;
    })

    
    let cyclingPrice = 0;
    let selectedCyclingId = null;

    addonPrice?.Cycling?.filter((curVal) => {
        // eslint-disable-next-line
        // if(memberModel?.upgradeduration == 15) {
        //     upgradePrice = memberModel?.upgradeduration * curVal?.monthly?.daily            
        // }
        // eslint-disable-next-line
        if(memberModel?.addonCyclingDuration == 30) {
            cyclingPrice = curVal?.monthly?.price   
            // console.log("hello world");         
        }
        if(memberModel?.addonCyclingDuration == 60) {
            cyclingPrice = curVal?.secondly?.price   
            // console.log("hello world");         
        }
        if(memberModel?.addonCyclingDuration == 90) {
            cyclingPrice = curVal?.quarterly?.price            
        }
        if(memberModel?.addonCyclingDuration == 180) {
            cyclingPrice = curVal?.half_yearly?.price            
        }
        if(memberModel?.addonCyclingDuration == 365) {
            cyclingPrice = curVal?.yearly?.price            
        }
        selectedCyclingId = curVal.id;
        // console.log(selectedCyclingId);

        return cyclingPrice;
    })

    let functional_Training = 0;
    let selectedFunctional_trainingId = null;
    addonPrice?.Functional_Training?.filter((curVal) => {
        // eslint-disable-next-line
        // if(memberModel?.upgradeduration == 15) {
        //     upgradePrice = memberModel?.upgradeduration * curVal?.monthly?.daily            
        // }
        // eslint-disable-next-line
        if(memberModel?.addonFunctional_TrainingDuration == 30) {
            functional_Training = curVal?.monthly?.price   
            // console.log("hello world");         
        }
        if(memberModel?.addonFunctional_TrainingDuration == 60) {
            functional_Training = curVal?.secondly?.price   
            // console.log("hello world");         
        }
        if(memberModel?.addonFunctional_TrainingDuration == 90) {
            functional_Training = curVal?.quarterly?.price            
        }
        if(memberModel?.addonFunctional_TrainingDuration == 180) {
            functional_Training = curVal?.half_yearly?.price            
        }
        if(memberModel?.addonFunctional_TrainingDuration == 365) {
            functional_Training = curVal?.yearly?.price            
        }
        selectedFunctional_trainingId = curVal.id;
        // console.log(selectedFunctional_trainingId);
        return functional_Training;
    })

    let zumbaPrice = 0;
    let selectedZumbaId = null;

    addonPrice?.Zumba?.filter((curVal) => {
        // eslint-disable-next-line
        // if(memberModel?.upgradeduration == 15) {
        //     upgradePrice = memberModel?.upgradeduration * curVal?.monthly?.daily            
        // }
        // eslint-disable-next-line
        if(memberModel?.addonZumbaDuration == 30) {
            zumbaPrice = curVal?.monthly?.price   
            // console.log("hello world");         
        }
        if(memberModel?.addonZumbaDuration == 60) {
            zumbaPrice = curVal?.secondly?.price   
            // console.log("hello world");         
        }
        if(memberModel?.addonZumbaDuration == 90) {
            zumbaPrice = curVal?.quarterly?.price            
        }
        if(memberModel?.addonZumbaDuration == 180) {
            zumbaPrice = curVal?.half_yearly?.price            
        }
        if(memberModel?.addonZumbaDuration == 365) {
            zumbaPrice = curVal?.yearly?.price            
        }
        selectedZumbaId = curVal.id;
        // console.log(selectedZumbaId);

        return zumbaPrice;
    })

    let crossfitPrice = 0;
    let selectedCrossfitId = null;

    addonPrice?.Crossfit?.filter((curVal) => {
        if(memberModel?.addonCrossfitDuration == 30) {
            crossfitPrice = curVal?.monthly?.price   
            // console.log("hello world");         
        }
        if(memberModel?.addonCrossfitDuration == 60) {
            crossfitPrice = curVal?.secondly?.price   
            // console.log("hello world");         
        }
        if(memberModel?.addonCrossfitDuration == 90) {
            crossfitPrice = curVal?.quarterly?.price            
        }
        if(memberModel?.addonCrossfitDuration == 180) {
            crossfitPrice = curVal?.half_yearly?.price            
        }
        if(memberModel?.addonCrossfitDuration == 365) {
            crossfitPrice = curVal?.yearly?.price            
        }
        selectedCrossfitId = curVal.id;
        // console.log(selectedCrossfitId);

        return crossfitPrice;
    })

    let massagePrice = 0;
    let selectedMassageId = null;

    addonPrice?.Massage?.filter((curVal) => {
        if(memberModel?.addonMassageDuration == 30) {
            massagePrice = curVal?.monthly?.price   
            // console.log("hello world");         
        }
        if(memberModel?.addonMassageDuration == 60) {
            massagePrice = curVal?.secondly?.price   
            // console.log("hello world");         
        }
        if(memberModel?.addonMassageDuration == 90) {
            massagePrice = curVal?.quarterly?.price            
        }
        if(memberModel?.addonMassageDuration == 180) {
            massagePrice = curVal?.half_yearly?.price            
        }
        if(memberModel?.addonMassageDuration == 365) {
            massagePrice = curVal?.yearly?.price            
        }
        selectedMassageId = curVal.id;
        // console.log(selectedMassageId);

        return massagePrice;
    })

    let ncPrice = 0;
    let selectedNutrition_ConsultingId = null;

    addonPrice?.Nutrition_Consulting?.filter((curVal) => {
        if(memberModel?.addonNutrition_ConsultingDuration == 30) {
            ncPrice = curVal?.monthly?.price   
            // console.log("hello world");         
        }
        if(memberModel?.addonNutrition_ConsultingDuration == 60) {
            ncPrice = curVal?.secondly?.price   
            // console.log("hello world");         
        }
        if(memberModel?.addonNutrition_ConsultingDuration == 90) {
            ncPrice = curVal?.quarterly?.price            
        }
        if(memberModel?.addonNutrition_ConsultingDuration == 180) {
            ncPrice = curVal?.half_yearly?.price            
        }
        if(memberModel?.addonNutrition_ConsultingDuration == 365) {
            ncPrice = curVal?.yearly?.price            
        }
        selectedNutrition_ConsultingId = curVal.id;
        // console.log(selectedNutrition_ConsultingId);

        return ncPrice;
    })

    let wtPrice = 0;
    let selectWeight_TrainingId = null;

    addonPrice?.Weight_Training?.filter((curVal) => {
        if(memberModel?.addonWeight_TrainingDuration == 30) {
            wtPrice = curVal?.monthly?.price   
            // console.log("hello world");         
        }
        if(memberModel?.addonWeight_TrainingDuration == 60) {
            wtPrice = curVal?.secondly?.price   
            // console.log("hello world");         
        }
        if(memberModel?.addonWeight_TrainingDuration == 90) {
            wtPrice = curVal?.quarterly?.price            
        }
        if(memberModel?.addonWeight_TrainingDuration == 180) {
            wtPrice = curVal?.half_yearly?.price            
        }
        if(memberModel?.addonWeight_TrainingDuration == 365) {
            wtPrice = curVal?.yearly?.price            
        }
        selectWeight_TrainingId = curVal.id;
        // console.log(selectWeight_TrainingId);

        return wtPrice;
    })

    let hecPrice = 0;
    let selectHiit_Excercise_ClassesId = null;

    addonPrice?.Hiit_Excercise_Classes?.filter((curVal) => {
        if(memberModel?.addonHiit_Excercise_ClassesDuration == 30) {
            hecPrice = curVal?.monthly?.price   
            // console.log("hello world");         
        }
        if(memberModel?.addonHiit_Excercise_ClassesDuration == 60) {
            hecPrice = curVal?.secondly?.price   
            // console.log("hello world");         
        }
        if(memberModel?.addonHiit_Excercise_ClassesDuration == 90) {
            hecPrice = curVal?.quarterly?.price            
        }
        if(memberModel?.addonHiit_Excercise_ClassesDuration == 180) {
            hecPrice = curVal?.half_yearly?.price            
        }
        if(memberModel?.addonHiit_Excercise_ClassesDuration == 365) {
            hecPrice = curVal?.yearly?.price            
        }
        selectHiit_Excercise_ClassesId = curVal.id;
        // console.log(selectHiit_Excercise_ClassesId);

        return hecPrice;
    })

    let fsPrice = 0;
    let selectedFitness_StudioID = null;

    addonPrice?.Hiit_Excercise_Classes?.filter((curVal) => {
        if(memberModel?.addonFitness_StudioDuration == 30) {
            fsPrice = curVal?.monthly?.price   
            // console.log("hello world");         
        }
        if(memberModel?.addonFitness_StudioDuration == 60) {
            fsPrice = curVal?.secondly?.price   
            // console.log("hello world");         
        }
        if(memberModel?.addonFitness_StudioDuration == 90) {
            fsPrice = curVal?.quarterly?.price            
        }
        if(memberModel?.addonFitness_StudioDuration == 180) {
            fsPrice = curVal?.half_yearly?.price            
        }
        if(memberModel?.addonFitness_StudioDuration == 365) {
            fsPrice = curVal?.yearly?.price            
        }
        selectedFitness_StudioID = curVal.id;
        // console.log(selectedFitness_StudioID);

        return fsPrice;
    })

    let totalPrice = 0;
    totalPrice = (planPrice + aPTPrice + lockerPrice + boxingPrice + DancePrice + yogaPrice + steamPrice + cyclingPrice + functional_Training + zumbaPrice + crossfitPrice + massagePrice + ncPrice + wtPrice + hecPrice + fsPrice);

    const disablePastDate = () => {
        const today = new Date();
        const dd = String(today.getDate()).padStart(2, "0");
        const mm = String(today.getMonth() + 1).padStart(2, "0");
        const yyyy = today.getFullYear();
        return yyyy + "-" + mm + "-" + dd;
    }

    const disableNewPastDate = () => {
        const today = new Date(getMember?.subscription_addon_details?.enddate);
        const dd = String(today.getDate()).padStart(2, "0");
        const mm = String(today.getMonth() + 1).padStart(2, "0");
        const yyyy = today.getFullYear();
        return yyyy + "-" + mm + "-" + dd;
    }

    let prevBtn = document.getElementById('prev');
    let nextBtn = document.getElementById('next');
    let tab1 = document.getElementById('tab1');




    // const showTab = () => {
    //     if(step === 1) {
    //         prevBtn?.classList?.remove('hidden');
    //         tab1?.classList?.add('hidden');
    //         tab2?.classList?.remove('hidden');
    //         tab3?.classList?.add('hidden');
    //         tab4?.classList?.add('hidden');
    //         tab8?.classList?.add('hidden');
    //         tab9?.classList?.add('hidden');
    //         nextBtn?.classList?.remove('hidden');
    //     }
    //     else if(step === 0) {
    //         tab1?.classList?.remove('hidden');
    //         tab2?.classList?.add('hidden');
    //         tab3?.classList?.add('hidden');
    //         tab4?.classList?.add('hidden');
    //         tab8?.classList?.add('hidden');
    //         prevBtn?.classList?.add('hidden');
    //     }
    //     else if(step === 2) {
    //         nextBtn?.classList?.remove('hidden');
    //         tab3?.classList?.remove('hidden');
    //         tab1?.classList?.add('hidden');
    //         tab2?.classList?.add('hidden');
    //         tab4?.classList?.add('hidden');
    //         tab5?.classList?.add('hidden');
    //         tab8?.classList?.add('hidden');
    //         tab9?.classList?.add('hidden');
    //         tab10?.classList?.add('hidden');
    //         tab11?.classList?.add('hidden');
    //         tab12?.classList?.add('hidden');
    //         tab13?.classList?.add('hidden');
    //         tab14?.classList?.add('hidden');
    //         tab15?.classList?.add('hidden');
    //         tab16?.classList?.add('hidden');
    //         prevBtn?.classList?.remove('hidden');
    //     }
    //     else if(step === 3) {
    //         nextBtn?.classList?.remove('hidden');
    //         tab4?.classList.remove('hidden');
    //         tab3?.classList?.add('hidden');
    //         tab1?.classList?.add('hidden');
    //         tab2?.classList?.add('hidden');
    //         tab5?.classList?.add('hidden');
    //         tab9?.classList?.add('hidden');
    //         tab8?.classList?.add('hidden');
    //         tab10?.classList?.add('hidden');
    //         tab11?.classList?.add('hidden');
    //         tab12?.classList?.add('hidden');
    //         tab13?.classList?.add('hidden');
    //         tab14?.classList?.add('hidden');
    //         tab15?.classList?.add('hidden');
    //         tab16?.classList?.add('hidden');
    //     }
    //     else if(step === 4) {
    //         nextBtn?.classList?.remove('hidden');
    //         tab5?.classList.remove('hidden');
    //         tab3?.classList?.add('hidden');
    //         tab1?.classList?.add('hidden');
    //         tab2?.classList?.add('hidden');
    //         tab4?.classList?.add('hidden');
    //         tab6?.classList?.add('hidden');
    //         tab7?.classList?.add('hidden');
    //         tab8?.classList?.add('hidden');
    //         tab9?.classList?.add('hidden');
    //         tab10?.classList?.add('hidden');
    //         tab11?.classList?.add('hidden');
    //         tab12?.classList?.add('hidden');
    //         tab13?.classList?.add('hidden');
    //         tab14?.classList?.add('hidden');
    //         tab15?.classList?.add('hidden');
    //         tab16?.classList?.add('hidden');
    //         prevBtn?.classList?.remove('hidden');

    //     }
    //     else if(step === 5) {
    //         nextBtn?.classList?.remove('hidden');
    //         tab5?.classList.add('hidden');
    //         tab3?.classList?.add('hidden');
    //         tab1?.classList?.add('hidden');
    //         tab2?.classList?.add('hidden');
    //         tab4?.classList?.add('hidden');
    //         tab6?.classList?.remove('hidden');
    //         tab7?.classList?.add('hidden');
    //         tab8?.classList?.add('hidden');
    //         tab9?.classList?.add('hidden');
    //         tab10?.classList?.add('hidden');
    //         tab11?.classList?.add('hidden');
    //         tab12?.classList?.add('hidden');
    //         tab13?.classList?.add('hidden');
    //         tab14?.classList?.add('hidden');
    //         tab15?.classList?.add('hidden');
    //         tab16?.classList?.add('hidden');
    //         prevBtn?.classList?.remove('hidden');

    //     }
    //     else if(step === 6) {
    //         nextBtn?.classList?.remove('hidden');
    //         tab5?.classList.add('hidden');
    //         tab3?.classList?.add('hidden');
    //         tab1?.classList?.add('hidden');
    //         tab2?.classList?.add('hidden');
    //         tab4?.classList?.add('hidden');
    //         tab6?.classList?.add('hidden');
    //         tab7?.classList?.remove('hidden');
    //         tab8?.classList?.add('hidden');
    //         tab9?.classList?.add('hidden');
    //         tab10?.classList?.add('hidden');
    //         tab11?.classList?.add('hidden');
    //         tab12?.classList?.add('hidden');
    //         tab13?.classList?.add('hidden');
    //         tab14?.classList?.add('hidden');
    //         tab15?.classList?.add('hidden');
    //         tab16?.classList?.add('hidden');
    //         prevBtn?.classList?.remove('hidden');

    //     }
    //     else if(step === 7) {
    //         nextBtn?.classList?.remove('hidden');
    //         tab5?.classList.add('hidden');
    //         tab3?.classList?.add('hidden');
    //         tab1?.classList?.add('hidden');
    //         tab2?.classList?.add('hidden');
    //         tab4?.classList?.add('hidden');
    //         tab6?.classList?.add('hidden');
    //         tab7?.classList?.add('hidden');
    //         tab8?.classList?.remove('hidden');
    //         tab9?.classList?.add('hidden');
    //         tab10?.classList?.add('hidden');
    //         tab11?.classList?.add('hidden');
    //         tab12?.classList?.add('hidden');
    //         tab13?.classList?.add('hidden');
    //         tab14?.classList?.add('hidden');
    //         tab15?.classList?.add('hidden');
    //         tab16?.classList?.add('hidden');
    //         prevBtn?.classList?.remove('hidden');

    //     }
    //     else if(step === 8) {
    //         nextBtn?.classList?.remove('hidden');
    //         tab5?.classList.add('hidden');
    //         tab3?.classList?.add('hidden');
    //         tab1?.classList?.add('hidden');
    //         tab2?.classList?.add('hidden');
    //         tab4?.classList?.add('hidden');
    //         tab6?.classList?.add('hidden');
    //         tab7?.classList?.add('hidden');
    //         tab8?.classList?.add('hidden');
    //         tab9?.classList?.remove('hidden');
    //         tab10?.classList?.add('hidden');
    //         tab11?.classList?.add('hidden');
    //         tab12?.classList?.add('hidden');
    //         tab13?.classList?.add('hidden');
    //         tab14?.classList?.add('hidden');
    //         tab15?.classList?.add('hidden');
    //         tab16?.classList?.add('hidden');
    //         prevBtn?.classList?.remove('hidden');

    //     }
    //     else if(step === 9) {
    //         nextBtn?.classList?.remove('hidden');
    //         tab5?.classList.add('hidden');
    //         tab3?.classList?.add('hidden');
    //         tab1?.classList?.add('hidden');
    //         tab2?.classList?.add('hidden');
    //         tab4?.classList?.add('hidden');
    //         tab6?.classList?.add('hidden');
    //         tab7?.classList?.add('hidden');
    //         tab8?.classList?.add('hidden');
    //         tab9?.classList?.add('hidden');
    //         if(addonPrice?.Zumba === null){
    //             tab10?.classList?.add('hidden');
    //             // nextBtn?.classList?.add('hidden');
    //             // prevBtn?.classList?.add('hidden');
    //         }
    //         else {
    //             tab10?.classList?.remove('hidden');
    //             nextBtn?.classList?.remove('hidden');
    //             prevBtn?.classList?.remove('hidden');

    //         }
    //         tab11?.classList?.add('hidden');
    //         tab12?.classList?.add('hidden');
    //         tab13?.classList?.add('hidden');
    //         tab14?.classList?.add('hidden');
    //         tab15?.classList?.add('hidden');
    //         tab16?.classList?.add('hidden');
    //         prevBtn?.classList?.remove('hidden');

    //     }
    //     else if(step === 10) {
    //         nextBtn?.classList?.remove('hidden');
    //         tab5?.classList.add('hidden');
    //         tab3?.classList?.add('hidden');
    //         tab1?.classList?.add('hidden');
    //         tab2?.classList?.add('hidden');
    //         tab4?.classList?.add('hidden');
    //         tab6?.classList?.add('hidden');
    //         tab7?.classList?.add('hidden');
    //         tab8?.classList?.add('hidden');
    //         tab9?.classList?.add('hidden');
    //         tab10?.classList?.add('hidden');
    //         tab11?.classList?.remove('hidden');
    //         tab12?.classList?.add('hidden');
    //         tab13?.classList?.add('hidden');
    //         tab14?.classList?.add('hidden');
    //         tab15?.classList?.add('hidden');
    //         tab16?.classList?.add('hidden');
    //         prevBtn?.classList?.remove('hidden');

    //     }
    //     else if(step === 11) {
    //         nextBtn?.classList?.remove('hidden');
    //         tab5?.classList.add('hidden');
    //         tab3?.classList?.add('hidden');
    //         tab1?.classList?.add('hidden');
    //         tab2?.classList?.add('hidden');
    //         tab4?.classList?.add('hidden');
    //         tab6?.classList?.add('hidden');
    //         tab7?.classList?.add('hidden');
    //         tab8?.classList?.add('hidden');
    //         tab9?.classList?.add('hidden');
    //         tab10?.classList?.add('hidden');
    //         tab11?.classList?.add('hidden');
    //         tab12?.classList?.remove('hidden');
    //         tab13?.classList?.add('hidden');
    //         tab14?.classList?.add('hidden');
    //         tab15?.classList?.add('hidden');
    //         tab16?.classList?.add('hidden');
    //         prevBtn?.classList?.remove('hidden');

    //     }
    //     else if(step === 12) {
    //         nextBtn?.classList?.remove('hidden');
    //         tab5?.classList.add('hidden');
    //         tab3?.classList?.add('hidden');
    //         tab1?.classList?.add('hidden');
    //         tab2?.classList?.add('hidden');
    //         tab4?.classList?.add('hidden');
    //         tab6?.classList?.add('hidden');
    //         tab7?.classList?.add('hidden');
    //         tab8?.classList?.add('hidden');
    //         tab9?.classList?.add('hidden');
    //         tab10?.classList?.add('hidden');
    //         tab11?.classList?.add('hidden');
    //         tab12?.classList?.add('hidden');
    //         tab13?.classList?.remove('hidden');
    //         tab14?.classList?.add('hidden');
    //         tab15?.classList?.add('hidden');
    //         tab16?.classList?.add('hidden');
    //         prevBtn?.classList?.remove('hidden');

    //     }
    //     else if(step === 13) {
    //         nextBtn?.classList?.remove('hidden');
    //         tab5?.classList.add('hidden');
    //         tab3?.classList?.add('hidden');
    //         tab1?.classList?.add('hidden');
    //         tab2?.classList?.add('hidden');
    //         tab4?.classList?.add('hidden');
    //         tab6?.classList?.add('hidden');
    //         tab7?.classList?.add('hidden');
    //         tab8?.classList?.add('hidden');
    //         tab9?.classList?.add('hidden');
    //         tab10?.classList?.add('hidden');
    //         tab11?.classList?.add('hidden');
    //         tab12?.classList?.add('hidden');
    //         tab13?.classList?.add('hidden');
    //         tab14?.classList?.remove('hidden');
    //         tab15?.classList?.add('hidden');
    //         tab16?.classList?.add('hidden');
    //         prevBtn?.classList?.remove('hidden');

    //     }
    //     else if(step === 14) {
    //         nextBtn?.classList?.remove('hidden');
    //         tab5?.classList.add('hidden');
    //         tab3?.classList?.add('hidden');
    //         tab1?.classList?.add('hidden');
    //         tab2?.classList?.add('hidden');
    //         tab4?.classList?.add('hidden');
    //         tab6?.classList?.add('hidden');
    //         tab7?.classList?.add('hidden');
    //         tab8?.classList?.add('hidden');
    //         tab9?.classList?.add('hidden');
    //         tab10?.classList?.add('hidden');
    //         tab11?.classList?.add('hidden');
    //         tab12?.classList?.add('hidden');
    //         tab13?.classList?.add('hidden');
    //         tab14?.classList?.add('hidden');
    //         tab15?.classList?.remove('hidden');
    //         tab16?.classList?.add('hidden');
    //         prevBtn?.classList?.remove('hidden');

    //     }
    //     else if(step === 15) {
    //         nextBtn?.classList?.add('hidden');
    //         tab5?.classList.add('hidden');
    //         tab3?.classList?.add('hidden');
    //         tab1?.classList?.add('hidden');
    //         tab2?.classList?.add('hidden');
    //         tab4?.classList?.add('hidden');
    //         tab6?.classList?.add('hidden');
    //         tab7?.classList?.add('hidden');
    //         tab8?.classList?.add('hidden');
    //         tab9?.classList?.add('hidden');
    //         tab10?.classList?.add('hidden');
    //         tab11?.classList?.add('hidden');
    //         tab12?.classList?.add('hidden');
    //         tab13?.classList?.add('hidden');
    //         tab14?.classList?.add('hidden');
    //         tab15?.classList?.add('hidden');
    //         tab16?.classList?.remove('hidden');
    //         prevBtn?.classList?.remove('hidden');

    //     }
    // }

    const showTab = () => {
        
        if(step === 0) {
            tab1?.classList?.remove('hidden');
            nextBtn?.classList?.add('hidden');
        }
        
    }

    // const showTab = () => {
    //     prevBtn?.classList?.remove('hidden');
    //     nextBtn?.classList?.remove('hidden');
      
    //     // Hide all tabs
    //     for (let i = 1; i <= 16; i++) {
    //       const tab = document.getElementById(`tab${i}`);
    //       tab?.classList?.add('hidden');
    //     }
      
    //     if (step === 1) {
    //       tab2?.classList?.remove('hidden');
    //     } else if (step === 2) {
    //       tab3?.classList?.remove('hidden');
    //     } else if (step === 3) {
    //       tab4?.classList?.remove('hidden');
    //     } else if (step === 4) {
    //       tab5?.classList?.remove('hidden');
    //     } else if (step === 5) {
    //       tab6?.classList?.remove('hidden');
    //     } else if (step === 6) {
    //       tab7?.classList?.remove('hidden');
    //     } else if (step === 7) {
    //       tab8?.classList?.remove('hidden');
    //     } else if (step === 8) {
    //       tab9?.classList?.remove('hidden');
    //     } else if (step === 9) {
    //       if (addonPrice?.Zumba === null) {
    //         // Skip step 9 and go to step 10
    //         setStep(10);
    //         tab10?.classList?.remove('hidden');
    //       } else {
    //         tab10?.classList?.remove('hidden');
    //       }
    //     } else if (step === 10) {
    //       tab11?.classList?.remove('hidden');
    //     } else if (step === 11) {
    //       tab12?.classList?.remove('hidden');
    //     } else if (step === 12) {
    //       tab13?.classList?.remove('hidden');
    //     } else if (step === 13) {
    //       tab14?.classList?.remove('hidden');
    //     } else if (step === 14) {
    //       tab15?.classList?.remove('hidden');
    //     } else if (step === 15) {
    //       tab16?.classList?.remove('hidden');
    //       nextBtn?.classList?.add('hidden');
    //     }
    //   };
      

    useEffect(() => {
        showTab();   
    });

    const subscription = {
        "addon_type": 'Subscription',
        "addon_id": memberModel?.membershipplan,
        "startdate": memberModel?.planstart
    }
    const trainer = {
        "addon_type": memberModel?.addonTrainerType ? memberModel?.addonTrainerType : null,
        "addon_id": memberModel?.trainer,
        "duration": memberModel?.trainerduration ? memberModel?.trainerduration : null,
        "startdate": memberModel?.planstartTrainer ? memberModel?.planstartTrainer : null,
        "type": memberModel?.trainerPT ? memberModel?.trainerPT : null,
        "fees": aPTPrice ? aPTPrice : null
    }
    const locker = {
        "addon_type": memberModel?.addonLockerType ? memberModel?.addonLockerType : null,
        "addon_id": memberModel?.locker ? memberModel?.locker : null,
        "duration": memberModel?.lockerduration ? memberModel?.lockerduration : null,
        "startdate": memberModel?.planstartLocker ? memberModel?.planstartLocker : null,
        "fees": lockerPrice ? lockerPrice : null
    }
    // const upgrade = {
    //     "addon_type": memberModel?.addonUpgradeType ? memberModel?.addonUpgradeType : null,
    //     "addon_id": memberModel?.upgrade ? memberModel?.upgrade : null,
    //     "duration": memberModel?.upgradeduration ? memberModel?.upgradeduration : null,
    //     "startdate": memberModel?.planstartUpgrade ? memberModel?.planstartUpgrade : null,
    //     "fees": upgradePrice ? upgradePrice : null
    // }
    // const boxing = {
    //     "addon_type": memberModel?.addonBoxingType ? memberModel?.addonBoxingType : null,
    //     "addon_id": memberModel?.boxing ? memberModel?.boxing : null,
    //     "duration": memberModel?.addonDuration ? memberModel?.addonDuration : null,
    //     "fees": boxingPrice ? boxingPrice : null,
    //     "startdate": memberModel?.boxingPlanstart,
    // };
    const boxing = {
        "addon_type": memberModel?.addonBoxingType ? memberModel?.addonBoxingType : null,
        "addon_id": selectedBoxingId, // Use the selected ID here
        "duration": memberModel?.addonDuration ? memberModel?.addonDuration : null,
        "fees": boxingPrice ? boxingPrice.toString() : null, // Convert boxingPrice to a string
        "startdate": memberModel?.boxingPlanstart,
    };
    const dance = {
        "addon_type": memberModel?.addonDanceType ? memberModel?.addonDanceType : null,
        "addon_id": selectedDanceId, // use the selected ID here
        "duration": memberModel?.addonDanceDuration ? memberModel?.addonDanceDuration : null,
        "fees": DancePrice ? DancePrice : null,
        "startdate": memberModel?.dancePlanstart,
    };
    const yoga = {
        "addon_type": memberModel?.addonYogaType ? memberModel?.addonYogaType : null,
        "addon_id": selectedYogaId,
        "duration": memberModel?.addonYogaDuration ? memberModel?.addonYogaDuration : null,
        "fees": yogaPrice ? yogaPrice : null,
        "startdate": memberModel?.yogaPlanstart,
    };
    const steam = {
        "addon_type": memberModel?.addonSteamType ? memberModel?.addonSteamType : null,
        "addon_id": selectedSteamId,
        "duration": memberModel?.addonSteamDuration ? memberModel?.addonSteamDuration : null,
        "fees": steamPrice ? steamPrice : null,
        "startdate": memberModel?.steamPlanstart,
    };
    const cycling = {
        "addon_type": memberModel?.addonCyclingType ? memberModel?.addonCyclingType : null,
        "addon_id": selectedCyclingId,
        "duration": memberModel?.addonCyclingDuration ? memberModel?.addonCyclingDuration : null,
        "fees": cyclingPrice ? cyclingPrice : null,
        "startdate": memberModel?.cyclingPlanstart,
    };
    const Functional_Training = {
        "addon_type": memberModel?.addonFunctional_TrainingType ? memberModel?.addonFunctional_TrainingType : null,
        "addon_id": selectedFunctional_trainingId,
        "duration": memberModel?.addonFunctional_TrainingDuration ? memberModel?.addonFunctional_TrainingDuration : null,
        "fees": functional_Training ? functional_Training : null,
        "startdate": memberModel?.functional_TrainingPlanstart,
    };
    const zumba = {
        "addon_type": memberModel?.addonZumbaType ? memberModel?.addonZumbaType : null,
        "addon_id": selectedZumbaId,
        "duration": memberModel?.addonZumbaDuration ? memberModel?.addonZumbaDuration : null,
        "fees": zumbaPrice ? zumbaPrice : null,
        "startdate": memberModel?.zumbaPlanstart,
    };
    const crossfit = {
        "addon_type": memberModel?.addonCrossfitType ? memberModel?.addonCrossfitType : null,
        "addon_id": selectedCrossfitId,
        "duration": memberModel?.addonCrossfitDuration ? memberModel?.addonCrossfitDuration : null,
        "fees": crossfitPrice ? crossfitPrice : null,
        "startdate": memberModel?.crossfitPlanstart,
    };
    const massage = {
        "addon_type": memberModel?.addonMassageType ? memberModel?.addonMassageType : null,
        "addon_id": selectedMassageId,
        "duration": memberModel?.addonMassageDuration ? memberModel?.addonMassageDuration : null,
        "fees": massagePrice ? massagePrice : null,
        "startdate": memberModel?.massagePlanstart,
    };
    const nutrition_consulting = {
        "addon_type": memberModel?.addonNutrition_ConsultingType ? memberModel?.addonNutrition_ConsultingType : null,
        "addon_id": selectedNutrition_ConsultingId,
        "duration": memberModel?.addonNutrition_ConsultingDuration ? memberModel?.addonNutrition_ConsultingDuration : null,
        "fees": ncPrice ? ncPrice : null,
        "startdate": memberModel?.nutrition_ConsultingPlanstart,
    };
    const weight_training = {
        "addon_type": memberModel?.addonWeight_TrainingType ? memberModel?.addonWeight_TrainingType : null,
        "addon_id": selectWeight_TrainingId,
        "duration": memberModel?.addonWeight_TrainingDuration ? memberModel?.addonWeight_TrainingDuration : null,
        "fees": wtPrice ? wtPrice : null,
        "startdate": memberModel?.weight_trainingPlanstart,
    };
    const hiit_excercise_classes = {
        "addon_type": memberModel?.addonHiit_Excercise_ClassesType ? memberModel?.addonHiit_Excercise_ClassesType : null,
        "addon_id": selectHiit_Excercise_ClassesId,
        "duration": memberModel?.addonHiit_Excercise_ClassesDuration ? memberModel?.addonHiit_Excercise_ClassesDuration : null,
        "fees": hecPrice ? hecPrice : null,
        "startdate": memberModel?.hiit_excercise_classesPlanstart,
    };
    const fitness_studio = {
        "addon_type": memberModel?.addonFitness_StudioType ? memberModel?.addonFitness_StudioType : null,
        "addon_id": selectedFitness_StudioID,
        "duration": memberModel?.addonFitness_StudioDuration ? memberModel?.addonFitness_StudioDuration : null,
        "fees": fsPrice ? fsPrice : null,
        "startdate": memberModel?.Fitness_StudioPlanstart,
    };

    const state = {
        "member_id": getMember?.member_id,
        "staff_id": getMember?.ref_id,
        "pay_description": 'test payment',
        "Subscription": subscription ? subscription : null,
        // "Trainer": memberModel?.addonTrainerType === 'Trainer' ? trainer : null,
        "Trainer" : memberModel?.addonTrainerType === 'Trainer' ? trainer : null,
        "Locker": memberModel?.addonLockerType === 'Locker' ? locker : null,
        "addons": rows[0].addonsName ? rows : null,
        // "Boxing": memberModel?.addonBoxingType === 'Boxing' ? boxing : null,
        // "Dance" : memberModel?.addonDanceType === 'Dance' ? dance : null,
        // "Yoga" : memberModel?.addonYogaType === 'Yoga' ? yoga : null,
        // "Steam" : memberModel?.addonSteamType === 'Steam' ? steam : null,
        // "Cycling" : memberModel?.addonCyclingType === 'Cycling' ? cycling : null,
        // "Functional_Training": memberModel?.addonFunctional_TrainingType === 'Functional_Training'? Functional_Training: null,
        // "Zumba" : memberModel?.addonZumbaType === 'Zumba' ? zumba : null,
        // "Crossfit": memberModel?.addonCrossfitType === "Crossfit" ? crossfit : null,
        // "Massage": memberModel?.addonMassageType === "Massage" ? massage : null,
        // "Nutrition_Consulting" : memberModel?.addonNutrition_ConsultingType === "Nutrition_Consulting" ? nutrition_consulting : null,
        // "Weight_Training" : memberModel?.addonWeight_TrainingType === "Weight_Training" ? weight_training : null,
        // "Hiit_Excercise_Classes" : memberModel?.addonHiit_Excercise_ClassesType === "Hiit_Excercise_Classes" ? hiit_excercise_classes : null,
        // "Fitness_Studio" : memberModel?.addonFitness_StudioType === "Fitness_Studio" ? fitness_studio : null,
    }

    const success_state = {
        "order_id": '',
        "payment_status": 1,
        "payment_id": '',
        "signature": '',
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        const data = {...state}
        delete data.errors;
        console.log("data",data);
        
        const res = await axios.post(`${process.env.REACT_APP_URL}add_addons_new`, data);
        console.log("res",res);
        if(res?.status === 200){
            setOrderId(res.data?.paydata?.order_id)
            // const Res = await loadScript('https://checkout.razorpay.com/v1/checkout.js');
            // if(!Res) {
            //     alert('Razorpay SDK failed to load. Are you online?');
            //     return;
            // }
            // // const amount = res?.data.paydata.amount;
            // const options = {
            //     key: res?.data.razorpay_key,
            //     name: 'GYMApplication',
            //     description: 'Test Transaction',
            //     order_id: res?.data.paydata.order_id,
            //     // order_id: 'order_Lepy9UPxcYNYXD',
            //     // callback_url: 'https://eneqd3r9zrjok.x.pipedream.net/',
            //     handler: function(response) {
            //         success_state.order_id = response.razorpay_order_id;
            //         success_state.payment_id = response.razorpay_payment_id;
            //         success_state.signature = response.razorpay_signature;
            //         // alert(response.razorpay_payment_id);
            //         // alert(response.razorpay_order_id);
            //         // alert(response.razorpay_signature);
            //         // console.log(response);
            //         if(response) {
            //             const suc_data = {...success_state}
            //             axios.post(`${process.env.REACT_APP_URL}update_payment`, suc_data).then((resp) => {
            //                 if(resp?.status === 200) {
            //                     toast.success(resp?.data.message, {
            //                         position: 'top-right',
            //                         autoClose: 3000,
            //                         hideProgressBar: false,
            //                         closeOnClick: true,
            //                         pauseOnHover: true,
            //                         draggable: true,
            //                         progress: undefined,
            //                         theme: 'light',
            //                     });
            //                     setShowPlanModel(false);
            //                 }
            //             }).catch((err) => {
            //                 console.log(err);
            //             })
            //         }
            //     },
            //     prefill: {
            //         name: getMember?.firstname,
            //         contact: getMember?.mobile
            //     },
            //     theme: {
            //         color: '#3399cc'
            //     },
            // };

            // const paymentObject = new window.Razorpay(options);
            // paymentObject.open();
            // paymentObject.on('payment.failed', function(response) {
            //     alert(response.error.code);
            //     alert(response.error.description);
            //     alert(response.error.source);
            //     alert(response.error.step);
            //     alert(response.error.reason);
            //     alert(response.error.metadata.order_id);
            //     alert(response.error.metadata.payment_id);
            // });
            // setShowPlanModel(false);
            fetchQrCode();
            setQrModel(true);
        }
        // if(success_state.order_id) {
        //     const suc_data = {...success_state}
        //     const suc =  await axios.post(`${process.env.REACT_APP_URL}update_payment`, suc_data);
        //     console.log(suc);
        //     console.log(suc_data);
        // }
        
    }
    console.log("dcd",handleSubmit)


    
    const handleChange = (event, rowIndex) => {
        const { name, value } = event.target;
        const updatedRows = [...rows];
      
        // Check if the new value already exists in previous rows
        const newValueExists = updatedRows.slice(0, rowIndex).some((row) => row.addonsName === value);
      
        if (!newValueExists) {
          updatedRows[rowIndex] = { ...updatedRows[rowIndex], [name]: value };
      
          if (updatedRows[rowIndex].addonsName && updatedRows[rowIndex].addonsDuration) {
            const formData = new FormData();
            formData.append('addon_id', updatedRows[rowIndex].addonsName);
            formData.append('duration_id', updatedRows[rowIndex].addonsDuration);
            const addonPriceUlr = `${process.env.REACT_APP_URL}getAllAddonsPriceGym`;
      
            axios.post(addonPriceUlr, formData, config)
              .then((res) => {
                console.log(res);
                if (res?.status === 200) {
                  updatedRows[rowIndex] = { ...updatedRows[rowIndex], ["addonFees"]: res?.data.Data[0].addon_price };
                }
              })
              .catch((error) => {
                console.log(error);
              });
          }
      
          return setTimeout(() => {
            setRows(updatedRows);
          }, 1000);
        }
      };
      
    const addNewRow = () => {
      setRows([...rows, { addonsName: '', addonsDuration: '', addonFees: '', startdate:'' }]);
    };
  
    const removeRow = (rowIndex) => {
      const updatedRows = rows.filter((_, index) => index !== rowIndex);
      setRows(updatedRows);
    };

// console.log(rows)

const handleTransaction = async(e) => {
    e.preventDefault();
    let trans = {
        order_id : orderId,
    transaction_id : transactionId,
    amount_paid: selectedOption ===  "partialpay" ? (totalPrice + totalAdonFees)/2 : (totalPrice + totalAdonFees),
    }
        try {
            const transaction = `${process.env.REACT_APP_URL}addTransactionId `;
            const res = await axios.post(transaction, trans, config,);
           if(res?.status === 200){
            toast.success(res?.data.message);
            fetchMemberDetails();
            setQrModel(false);
            setShowPlanModel(false);
           }
          } catch (error) {
            console.error("Error fetching addons data: ", error);
          }
    
}


const totalAdonFees = rows.reduce((total, item) => {
    const fee = parseFloat(item.addonFees);
    if (!isNaN(fee)) {
      return total + fee;
    }
    return total;
  }, 0);
  
console.log(totalAdonFees)
  return (
    <>
    <Modal show={showPlanModel} size='lg'>
        <Modal.Header>
            <Modal.Title>{!qrModel ? "Plan Details" : "Scan Qr Code"}</Modal.Title>
            <button type="button" data-bs-dismiss="modal" className="btn-close" onClick={() => setShowPlanModel(false)}></button>
        </Modal.Header>
        <Modal.Body>
           {!qrModel ? <form onSubmit={handleSubmit}>
                <div id='tab1' className='tab'>
                    <div className='row'>
                        <div className='col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12'>
                            <div className='mb-3'>
                                <label htmlFor="membershipPlan" className='form-label'>Membership Plan:</label>
                                <select className='form-select' name='membershipplan' value={memberModel?.membershipplan} onChange={handleInput} required>
                                    <option value="">Select Plan</option>
                                    {
                                        subscriberList?.map((curVal, i) => {
                                            return (
                                                <option key={i} value={curVal.id}>{curVal.duration} {curVal.type}</option>
                                            )
                                        })
                                    }
                                </select>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                            <div className="mb-3">
                                <label htmlFor="membershipFees" className='form-label'>Membership Fees:</label>
                                <input type="text" className='form-control' name='membershipfees' value={planPrice} onChange={handleInput} required readOnly />
                                {/* <select name="membershipfees" className='form-select' value={memberModel?.membershipfees} onChange={handleInput} required>
                                    <option defaultValue={''}>Select</option>
                                    <option value={planPrice}>{planPrice}</option>
                                </select> */}
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                            <div className="mb-3">
                                <label htmlFor="planStart" className='form-label'>Plan Start: </label>
                                {
                                    memberDetails !== null ?
                                    <>
                                        <input type="date" className='form-control' name='planstart' value={memberModel?.planstart} min={disableNewPastDate()} onChange={handleInput} required />
                                    </>
                                    :
                                    <>
                                        <input type="date" className='form-control' name='planstart' value={memberModel?.planstart} min={disablePastDate()} onChange={handleInput} required />
                                    </>
                                }
                            </div>
                        </div>

                        <hr/>

                        <div className='row'>
                        <div className="col-sm-12 col-md-12 col-lg-4 col-xl-4 col-xxl-4">
                            <div className='mb-3'>
                                <label htmlFor="addonType" className='form-label'>Addon Type</label>
                                <select name="addonTrainerType" className='form-select' value={memberModel?.addonTrainerType} onChange={handleInput}>
                                    <option value="">Select</option>
                                    <option value="Trainer">Trainer</option>
                                </select>
                            </div>
                        </div>
                    <div className="col-sm-12 col-md-12 col-lg-4 col-xl-4 col-xxl-4">
                        <div className="mb-3">
                            <label htmlFor="experienceTrainer" className='form-label'>Trainer: </label>
                            <select className='form-select' name='trainer' value={memberModel?.trainer} onChange={handleInput}>
                                <option defaultValue={0}>Select Trainer</option>
                                {
                                    trainerList?.map((curVal, i) => {
                                        return (
                                            <option key={i} value={curVal.id}>{curVal.firstname} {curVal.lastname}</option>
                                        )
                                    })
                                }
                            </select>
                        </div>
                    </div>
                    <div className="col-sm-12 col-md-12 col-lg-4 col-xl-4 col-xxl-4">
                        <div className="mb-3">
                            <label htmlFor="ptTrainer" className='form-label'>Trainer PT: </label>
                            <select name='trainerPT' className='form-select' value={memberModel?.trainerPT} onChange={handleInput}>
                                <option defaultValue={''}>Select</option>
                                <option value="Alternate">Alternate PT</option>
                                <option value="Regular">Daily PT</option>
                            </select>
                        </div>
                    </div>
                    <div className="col-sm-12 col-md-12 col-lg-4 col-xl-4 col-xxl-4">
                        <div className="mb-3">
                            <label htmlFor="durationTrainer" className='form-label'>Trainer Duration: </label>
                            <select name='trainerduration' className='form-select' value={memberModel?.trainerduration} onChange={handleInput}>
                                <option defaultValue={''}>Select</option>
                                <option value={30}>30 Days</option>
                                <option value={60}>60 Days</option>
                                <option value={90}>90 Days</option>
                                <option value={180}>180 Days</option>
                                <option value={365}>365 Days</option>
                            </select>
                        </div>
                    </div>

                    <div className="col-sm-12 col-md-12 col-lg-4 col-xl-4 col-xxl-4">
                        <div className='mb-3'>
                            <label htmlFor="trainerFees" className='form-label'>Trainer Fees:</label>
                            <input
                                type="text"
                                name="trainerfees"
                                className='form-control'
                                value={aPTPrice}
                                onChange={handleInput}
                                readOnly
                            />
                        </div>
                    </div>
                    <div className="col-sm-12 col-md-12 col-lg-4 col-xl-4 col-xxl-4">
                        <div className="mb-3">
                            <label htmlFor="planStartTrainer" className='form-label'>Trainer Plan Start: </label>
                            <input type="date" className='form-control' name='planstartTrainer' value={memberModel?.planstartTrainer} min={disablePastDate()} onChange={handleInput} />
                        </div>
                    </div>
                </div>

                <hr/>

                    <div className='row'>
                        <div className="col-sm-12 col-md-12 col-lg-4 col-xl-4 col-xxl-4">
                            <div className='mb-3'>
                                <label htmlFor="addonType" className='form-label'>Addon Type</label>
                                <select name="addonLockerType" className='form-select' value={memberModel?.addonLockerType} onChange={handleInput}>
                                    <option value="">Select</option>
                                    <option value="Locker">Locker</option>
                                </select>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-12 col-lg-4 col-xl-4 col-xxl-4">
                            <div className="mb-3">
                                <label htmlFor="lockerNo" className='form-label'>Locker: </label>
                                <select className='form-select' name='locker' value={memberModel?.locker} onChange={handleInput}>
                                <option defaultValue={''}>Select</option>
                                {lockerList?.map((curVal, i) => {
                                    return (
                                        curVal?.assign === 0 ? (
                                            <option
                                                key={i}
                                                value={curVal?.id}
                                                data-id={curVal?.id} 
                                            >
                                                {curVal?.locker_no}
                                            </option>
                                        ) : (
                                            null
                                        )
                                    );
                                })}
                            </select>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-12 col-lg-4 col-xl-4 col-xxl-4">
                            <div className="mb-3">
                                <label htmlFor="lockerDuration" className='form-label'>Locker Duration:</label>
                                <select name='lockerduration' className='form-select' value={memberModel?.lockerduration} onChange={handleInput}>
                                    <option defaultValue={''}>Select</option>
                                    <option value={30}>30 Days</option>
                                    <option value={60}>60 Days</option>
                                    <option value={90}>90 Days</option>
                                    <option value={180}>180 Days</option>
                                    <option value={365}>365 Days</option>
                                </select>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-12 col-lg-4 col-xl-4 col-xxl-4">
                            <div className='mb-3'>
                                <label htmlFor="lockerPrice" className='form-label'>Locker Price:</label>
                                <input type="text" name="lockerPrice" className='form-control' value={Math.floor(lockerPrice)} onChange={handleInput} readOnly />
                                {/* <select name="lockerPrice" className='form-select' value={memberModel?.lockerPrice} onChange={handleInput}>
                                    <option defaultValue={''}>Select</option>
                                    <option value={Math.floor(lockerPrice)}>{Math.floor(lockerPrice)}</option>
                                </select> */}
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-12 col-lg-4 col-xl-4 col-xxl-4">
                            <div className="mb-3">
                                <label htmlFor="planStartLocker" className='form-label'>Locker Plan Start: </label>
                                <input type="date" className='form-control' name='planstartLocker' value={memberModel?.planstartLocker} min={disablePastDate()} onChange={handleInput} />
                            </div>
                        </div>
                    </div>
                
                <hr/>
                   
<div>
      {rows.map((row, rowIndex) => (
        <div className='row' key={rowIndex}>
          <div className='col-sm-12 col-md-3 col-lg-3 col-xl-3 col-xxl-3'>
            <div className='mb-3'>
              <label htmlFor={`addonsName`} className='form-label'>
                Addons Name:
              </label>
              <select
                className='form-select'
                name={`addonsName`}
                value={row.addonsName}
                onChange={(e) => handleChange(e, rowIndex)}
                
              >
                <option value=''>Addons</option>
                {
                                        addonName?.map((curVal, i) => {
                                            return (
                                                <option key={i} value={curVal.addon_id}>{curVal.addons_name}</option>
                                            )
                                        })
                                    }
              </select>
            </div>
          </div>

          <div className='col-sm-12 col-md-3 col-lg-3 col-xl-3 col-xxl-3'>
            <div className='mb-3'>
              <label htmlFor={`addonsDuration`} className='form-label'>
                Duration:
              </label>
              <select
                className='form-select'
                name={`addonsDuration`}
                value={row.addonsDuration}
                onChange={(e) => handleChange(e, rowIndex)}
                required={row.addonsName}
              >
                <option value=''>Select Plan</option>
                {
                                        addonDuration?.map((curVal, i) => {
                                            return (
                                                <option key={i} value={curVal.id}>{curVal.duration} Days</option>
                                            )
                                        })
                                    }
              </select>
            </div>
          </div>

          <div className='col-sm-12 col-md-3 col-lg-3 col-xl-3 col-xxl-3'>
            <div className='mb-2'>
              <label htmlFor={`addonFees`} className='form-label'>
                Addon Date:
              </label>
              <input
                type="date"
                className='form-control'
                name={`startdate`}
                value={row.startdate}
                onChange={(e) => handleChange(e, rowIndex)}
                required={row.addonsName}
              />
            </div>
            </div>

          <div className='col-sm-12 col-md-3 col-lg-3 col-xl-3 col-xxl-3 d-flex'>
            <div className='mb-2'>
              <label htmlFor={`addonFees`} className='form-label'>
                Addon Fees:
              </label>
              <input
                type='text'
                className='form-control'
                name={`addonFees`}
                value={row.addonFees}
                readOnly
              />
            </div>
            <div className='pt-4 d-flex align-items-center pointer'>
              {rows.length > 1 && (
                <i
                style={{cursor:"pointer"}}
                  className='fa-solid fa-minus ms-2 pointer'
                  onClick={() => removeRow(rowIndex)}
                ></i>
              )}
                 <i  style={{cursor:"pointer"}} className='fa-solid fa-plus  ms-2 ' onClick={addNewRow} />
          
            </div>
          </div>
        </div>
      ))}

      {/* <button onClick={addNewRow}>Add Row</button> */}
    </div>
                    </div>
                </div>
      {/* <i className='fa-solid fa-plus p-3' onClick={addRemoveRow}></i> */}

    
                {/* <div className='mb-3 d-flex gap-2'>
                    <button type='button' className='btn btn-outline-danger float-start hidden' id='prev' onClick={() => {setStep(step-1)}}>Previous</button>
                    <button type='button' className='btn btn-outline-warning float-start' id='next' onClick={() => {setStep(step+1)}}>Next</button>
                </div>    */}
                <div className="modal-footer">
                    <button type="submit" className="btn btn-outline-primary">Submit</button>
                </div>
            </form>
            :
            <div className='text-center'>
                    <h1>₹ {selectedOption ===  "partialpay" ? (totalPrice + totalAdonFees)/2 : (totalPrice + totalAdonFees)}</h1>
           <div>
      <label>
        <input
          type="radio"
          value="fullpayemnt"
          checked={selectedOption === 'fullpayemnt'}
          onChange={(e) =>  setSelectedOption(e.target.value)}
        />
        Full Payment
      </label>

      <label className='mx-4'>
        <input
          type="radio"
          value="partialpay"
          checked={selectedOption === 'partialpay'}
          onChange={(e) =>  setSelectedOption(e.target.value)}
        />
        Partial Payment
      </label>
      </div>
            {/* <h1>₹ {totalPrice + totalAdonFees}</h1> */}
            <div>
            <img src={`${process.env.REACT_APP_URL}/${qrData}`} height={"350px"}/>
            </div>
            <div className='mt-3 py-3'>
                <form onSubmit={handleTransaction}>
            <input placeholder='Enter Transaction Id' required value={transactionId} onChange={(e) => setTransactionId(e.target.value)} className='w-50'/>
            <button type='submit'  className='ms-3 btn btn-success'>Submit</button>
                </form>
            </div>
            </div>
            }
        </Modal.Body>
        <Modal.Footer>
            <div className='d-flex flex-column' style={{lineHeight: '0.5'}}>
                
                <p className='text-danger font_18'>Your Order is</p>
                <p className='text-success fw-bold font_22'>Total Amount: &#8377;{totalPrice + totalAdonFees}</p>
                {/* <p className='text-success fw-bold font_22'>Total Amount: &#8377;{totalPrice + totalAdonFees}</p> */}
                <div className='d-flex flex-row'>
                    <p className='text-success'>Subscription Amount: &#8377;{planPrice}</p>
                    <p className='text-success px-2'>Trainer Amount: &#8377;{aPTPrice}</p>
                    <p className='text-success'>Locker Amount: &#8377;{Math.floor(lockerPrice)}</p>
                </div>
                <div className='d-flex flex-row mb-4'>
               {rows.map((item, i) => (
                <p className='text-success px-2'> {item.addonsName === '1' ? 'Boxing' : item.addonsName === '2' ? 'Yoga' : item.addonsName === '3' ? 'Steam'  : item.addonsName === '4' ? 'Zumba' : item.addonsName === '5' ? 'Crossfit' : item.addonsName === '6' ? 'Dance' : item.addonsName === '7' ? 'Massage' : item.addonsName === '8' ? 'Functional training' : item.addonsName === '9' ? 'Nutrition Consulting' : item.addonsName === '10' ? 'Weight Training' : item.addonsName === '11' ? 'Hiit Excercise Classes' : item.addonsName === '12' ? 'Cycling' : item.addonsName === '13' ? "Fitness Studio" : item.addonsName }: &#8377;{Math.floor(item.addonFees)}</p>
               )) }
   
                </div>
                
            </div>
        </Modal.Footer>
    </Modal>
    <ToastContainer />
    {/* <Modal show={qrModel} size='lg'>
        <Modal.Header className='bg-dark text-white'>
            <Modal.Title className='fw-bold'>Scan Qr Code</Modal.Title>
            <button type="button" data-bs-dismiss="modal" className="btn-close bg-white" onClick={() => setQrModel(false)}></button>
        </Modal.Header>
        <Modal.Body>
            <div className='text-center'>
           <h1>₹ {totalPrice + totalAdonFees}</h1>
           <div>
           <img src={`${process.env.REACT_APP_URL}/${qrData}`} height={"350px"}/>
           </div>
           <div className='mt-3 py-3'>
           <input placeholder='Enter Transaction Id' className='w-50'/>
           <button onClick={handleTransaction} className='ms-3 btn btn-success'>Submit</button>
           </div>
           </div>
        </Modal.Body>
    </Modal> */}
    </>
  )
}

export default ActivatePlanModel;











// ------------------------------------------------------------------------------------------------------
// ------------------------------------------------------------------------------------------------------
// import React, { useEffect, useState } from 'react';
// import Modal from 'react-bootstrap/Modal';
// import axios from 'axios';
// import { useSelector } from 'react-redux';
// import { ToastContainer, toast } from 'react-toastify';

// function ActivatePlanModel({ showPlanModel, setShowPlanModel, memberDetails}) {
//     const getMember = useSelector(state => state.counterSlice.userDetails);
//     const [subscriberList, setSubscriberList] = useState([]);
//     // const [memberModel, setMemberModel] = useState();
//     // const [trainerList, setTrainerList] = useState([]);
//     const [lockerList, setLockerList] = useState([]);
//     const [priceList, setPriceList] = useState([]);
//     const [lockerPriceList, setLockerPriceList] = useState([]);
//     const [step, setStep] = useState(0);
//     const [memberModel, setMemberModel] = useState();

//     const [trainerList, setTrainerList] = useState([]);
//     const [data, setData] = useState([]); // Define the 'data' state variable
//     const [addonDuration, setAddonDuration] = useState(''); // State for selected duration
//     const [addonPrice, setAddonPrice] = useState([]); // State for addon price
//     const [danceAddonPrice, setDanceAddonPrice] = useState([]);
//     const [lockerAddonData, setLockerAddonData] = useState(null);
//     const [addonName, setAddonName] = useState([]);

//     // useEffect(() => {
//     //     // Fetch data from your API and set it to the 'data' state variable
//     //     async function fetchData() {
//     //         try {
//     //             const response = await axios.get(`${process.env.REACT_APP_URL}getAllTrainerByAdmin`, );
//     //             setData(response.data.data);
//     //         } catch (error) {
//     //             // Handle errors here
//     //             console.error('Error fetching data:', error);
//     //         }
//     //     }

//     //     fetchData(); // Call the fetchData function when the component mounts
//     // }, []);
//     // const [subscription, setSubscription] = useState([]);
//     const getGymId = useSelector(state=>state.counterSlice.gymId);
//     // console.log("the token is ", getGymId)
//     const deviceToken = getGymId.device_token;
//     // console.log(deviceToken);

//     const  = {
//         headers:{
//           "Authorization" : deviceToken,
//         }
//       };
    

//     // console.log(memberDetails);

//     const loadScript = (src) => {
//         return new Promise((resolve) => {
//             const script = document.createElement('script');
//             script.src = src;
//             script.onload = () => {
//                 resolve(true);
//             };
//             script.onerror = () => {
//                 resolve(false);
//             };
//             document.body.appendChild(script);
//         });
//     }

//     useEffect(() => {
//         fetchAllSubscriber();
//         fetchAllTrainer();
//         fetchLockers();
//         fetchAllPricing();
//         fetchBoxingAddonData();
//         fetchAddonName();
//     }, []);

//     // const fetchAllPricing = async () => {
//     //     const getAllPricing = await axios.get(`${process.env.REACT_APP_URL}pricing`, );
//     //     setPriceList(getAllPricing?.data);
//     // }
//     const fetchAllPricing = async (selectedTrainerId) => {
//         try {
//             // Construct the API URL with the selected trainer's ID
//             const pricingAPIUrl = `${process.env.REACT_APP_URL}pricing/${selectedTrainerId}`;
//             const getAllPricing = await axios.get(pricingAPIUrl, );
//             setPriceList(getAllPricing?.data);
//         } catch (error) {
//             // Handle errors if necessary
//             console.error("Error fetching pricing data:", error);
//         }
//     }

//     const fetchBoxingAddonData = async () => {
//         try {
//             const boxingPricingUrl = `${process.env.REACT_APP_URL}pricing_addons`;
//             const getBoxingAddonData = await axios.get(boxingPricingUrl, );
//             setAddonPrice(getBoxingAddonData?.data) 
//             // console.log("get bocxing k data", getBoxingAddonData.data.Zumba);
//         } catch (error) {
//             console.error("Error fetching Boxing Addon data: ", error);
//             return null;
//         }
//     };

//     const fetchAddonName = async () => {
//         try {
//             const addonNameUrl = `${process.env.REACT_APP_URL}getAllAddonsNameByGym`;
//             const getAddonName = await axios.get(addonNameUrl, );
//             setAddonName(getAddonName?.data) 
//             // console.log("names and id",setAddonName);
//         } catch (error) {
//             console.error("Error fetching Boxing Addon data: ", error);
//             return null;
//         }
//     };
      

//       const fetchLockerAddonData = async (lockerId) => {
//         try {
//             // Construct the API URL with the locker ID
//             const lockerAddonAPIUrl = `${process.env.REACT_APP_URL}pricing_lockers/${lockerId}`;
    
//             const response = await axios.get(lockerAddonAPIUrl, );
//             setLockerPriceList(response?.data) // Assuming the API response contains the locker addon pricing data
//         } catch (error) {
//             console.error("Error fetching Locker Addon data: ", error);
//             return null;
//         }
//     };
 
//     const fetchAllSubscriber = async () => {
//         const getAllSubscriber = await axios.get(`${process.env.REACT_APP_URL}GetAllSubscriberByAdmin`, );
//         setSubscriberList(getAllSubscriber?.data.data);
//     }

//     const fetchAllTrainer = async () => {
//         const allTrainers = await axios.get(`${process.env.REACT_APP_URL}getAllTrainerByAdmin`, );
//         setTrainerList(allTrainers?.data.data);
//     }

//     const fetchLockers = async () => {
//         const allLockers = await axios.get(`${process.env.REACT_APP_URL}getAllLocker`, );
//         setLockerList(allLockers?.data.data);
//     }

    // const handleInput = (e) => {
    //     setMemberModel((pre) => ({ ...pre, [e.target.name]: e.target.value }))
    //     const { name, value } = e.target;

    //     if (name === 'trainer') {
    //         // Make sure 'value' here contains the selected trainer's ID
    //         console.log('Selected Trainer ID:', value);

    //         // Fetch pricing data based on the selected trainer's ID
    //         fetchAllPricing(value);
    //     }

    //     if (name === 'locker') {
    //         // Make sure 'value' here contains the selected locker's ID
    //         const lockerId = e.target.options[e.target.selectedIndex].getAttribute('data-id'); // Extract locker ID from selected option
    //         console.log('Selected Locker ID:', lockerId);
    
    //         // Fetch locker addon data based on the selected locker's ID
    //         fetchLockerAddonData(lockerId).then((data) => {
    //             setLockerAddonData(data);
    //         });
    //     }

    //     if (name === 'addonBoxingType') {
    //         // Call the function to fetch the addon data with the selected addon's ID
    //         fetchBoxingAddonData(value); // Assuming 'value' is the addon ID
    //       }

    // }
    
//     let planPrice = 0;
//     let subPlan = 0;
//     subscriberList?.filter((curVal) => {
//         // eslint-disable-next-line
//         if(curVal.id == memberModel?.membershipplan) {
//             planPrice = curVal?.subsriberfees;
//             subPlan = curVal?.duration;
//         }
//         return { planPrice, subPlan };
//     });

//     // let trainerFee = 0;
//     // trainerList?.filter((curVal) => {
//     //     // eslint-disable-next-line
//     //     if(curVal.id == memberModel?.trainer) {
//     //         trainerFee = curVal?.trainerfees
//     //     }
//     //     return trainerFee;
//     // })

//     let aPTPrice = 0;
//     // eslint-disable-next-line
//     if(memberModel?.trainerPT == 'Alternate') {
//         priceList?.Alternate_PT?.filter((curVal) => {
//             // eslint-disable-next-line
//             if(memberModel?.trainerduration == 30) {
//                 aPTPrice = curVal?.monthly?.price;
//             }
//             // eslint-disable-next-line
//             if(memberModel?.trainerduration == 60) {
//                 aPTPrice = curVal?.secondly?.price;
//             }
//             // eslint-disable-next-line
//             if(memberModel?.trainerduration == 90) {
//                 aPTPrice = curVal?.quarterly?.price
//             }
//             // eslint-disable-next-line
//             if(memberModel?.trainerduration == 180) {
//                 aPTPrice = curVal?.half_yearly?.price
//             }
//             // eslint-disable-next-line
//             if(memberModel?.trainerduration == 365) {
//                 aPTPrice = curVal?.yearly?.price
//             }
//             return aPTPrice;
//         })
//     }

//     // eslint-disable-next-line
//     if(memberModel?.trainerPT == 'Regular') {
//         priceList?.Regular_PT?.filter((curVal) => {
//             // eslint-disable-next-line
//             if(memberModel?.trainerduration == 30) {
//                 aPTPrice = curVal?.monthly?.price
//                 // console.log("price ofr 30",aPTPrice);
//             }
//             // eslint-disable-next-line
//             if(memberModel?.trainerduration == 60) {
//                 aPTPrice = curVal?.secondly?.price
//             }
//             // eslint-disable-next-line
//             if(memberModel?.trainerduration == 90) {
//                 aPTPrice = curVal?.quarterly?.price
//             }
//             // eslint-disable-next-line
//             if(memberModel?.trainerduration == 180) {
//                 aPTPrice = curVal?.half_yearly?.price
//             }
//             // eslint-disable-next-line
//             if(memberModel?.trainerduration == 365) {
//                 aPTPrice = curVal?.yearly?.price
//             }
//             return aPTPrice;
//         })
//     }

//     let lockerPrice = 0;
//     lockerPriceList?.data?.filter((curVal) => {
//         // eslint-disable-next-line
//         // if(memberModel?.lockerduration == 15) {
//         //     lockerPrice = memberModel?.lockerduration * curVal?.monthly?.daily            
//         // }
//         // eslint-disable-next-line
//         if(memberModel?.lockerduration == 30) {
            
//             lockerPrice = curVal?.monthly?.price  
//             // console.log("dkjbdakc", lockerPrice);          
//         }
//         if(memberModel?.lockerduration == 60) {
            
//             lockerPrice = curVal?.secondly?.price  
//             console.log("dkjbdakc", lockerPrice);          
//         }
//         if(memberModel?.lockerduration == 90) {
//             lockerPrice = curVal?.quarterly?.price            
//         }
//         if(memberModel?.lockerduration == 180) {
//             lockerPrice = curVal?.half_yearly?.price            
//         }
//         if(memberModel?.lockerduration == 365) {
//             lockerPrice = curVal?.yearly?.price            
//         }
//         return lockerPrice;
//     })

//     // let upgradePrice = 0;
//     // priceList?.data?.filter((curVal) => {
//     //     // eslint-disable-next-line
//     //     // if(memberModel?.upgradeduration == 15) {
//     //     //     upgradePrice = memberModel?.upgradeduration * curVal?.monthly?.daily            
//     //     // }
//     //     // eslint-disable-next-line
//     //     if(memberModel?.upgradeduration == 30) {
//     //         upgradePrice = curVal?.monthly?.price   
//     //         // console.log("hello world");         
//     //     }
//     //     if(memberModel?.upgradeduration == 90) {
//     //         upgradePrice = curVal?.quarterly?.price            
//     //     }
//     //     if(memberModel?.upgradeduration == 180) {
//     //         upgradePrice = curVal?.half_yearly?.price            
//     //     }
//     //     if(memberModel?.upgradeduration == 365) {
//     //         upgradePrice = curVal?.yearly?.price            
//     //     }
//     //     return upgradePrice;
//     // })

//     // let boxingPrice = 0;
//     // let selectedBoxingId = null;
//     // addonPrice?.Boxing?.filter((curVal) => {
//     //     // eslint-disable-next-line
//     //     // if(memberModel?.upgradeduration == 15) {
//     //     //     upgradePrice = memberModel?.upgradeduration * curVal?.monthly?.daily            
//     //     // }
//     //     // eslint-disable-next-line
//     //     if(memberModel?.addonDuration == 30) {
//     //         boxingPrice = curVal?.monthly?.price;   
//     //         // console.log("hello world");       
//     //         selectedBoxingId = curVal.id;  
//     //         console.log("id is : ", selectedBoxingId);
//     //     }
//     //     if(memberModel?.addonDuration == 60) {
//     //         boxingPrice = curVal?.secondly?.price   
//     //         // console.log("hello world");     
//     //         selectedBoxingId = curVal.id;     
//     //     }
//     //     if(memberModel?.addonDuration == 90) {
//     //         boxingPrice = curVal?.quarterly?.price  
//     //         selectedBoxingId = curVal.id;           
//     //     }
//     //     if(memberModel?.addonDuration == 180) {
//     //         boxingPrice = curVal?.half_yearly?.price
//     //         selectedBoxingId = curVal.id;             
//     //     }
//     //     if(memberModel?.addonDuration == 365) {
//     //         boxingPrice = curVal?.yearly?.price  
//     //         selectedBoxingId = curVal.id;           
//     //     }
//     //     return boxingPrice;
//     // })

//     let boxingPrice = 0;
//     let selectedBoxingId = null;

//     addonPrice?.Boxing?.filter((curVal) => {
//         // eslint-disable-next-line
//         // if(memberModel?.upgradeduration == 15) {
//         //     upgradePrice = memberModel?.upgradeduration * curVal?.monthly?.daily            
//         // }
//         // eslint-disable-next-line
//         if(memberModel?.addonDuration == 30) {
//             boxingPrice = curVal?.monthly?.price;
//         }
//         if(memberModel?.addonDuration == 60) {
//             boxingPrice = curVal?.secondly?.price;
//         }
//         if(memberModel?.addonDuration == 90) {
//             boxingPrice = curVal?.quarterly?.price;
//         }
//         if(memberModel?.addonDuration == 180) {
//             boxingPrice = curVal?.half_yearly?.price;
//         }
//         if(memberModel?.addonDuration == 365) {
//             boxingPrice = curVal?.yearly?.price;
//         }
        
//         // Assign selectedBoxingId outside of if conditions
//         selectedBoxingId = curVal.id;
//         // console.log("selected id is:", selectedBoxingId);

//         return boxingPrice;
//     });

//     let DancePrice = 0;
//     let selectedDanceId = null;

//     addonPrice?.Dance?.filter((curVal) => {
//         // eslint-disable-next-line
//         // if(memberModel?.upgradeduration == 15) {
//         //     upgradePrice = memberModel?.upgradeduration * curVal?.monthly?.daily            
//         // }
//         // eslint-disable-next-line
//         if(memberModel?.addonDanceDuration == 30) {
//             DancePrice = curVal?.monthly?.price   
//             // console.log("hello world");         
//         }
//         if(memberModel?.addonDanceDuration == 60) {
//             DancePrice = curVal?.secondly?.price   
//             // console.log("hello world");         
//         }
//         if(memberModel?.addonDanceDuration == 90) {
//             DancePrice = curVal?.quarterly?.price            
//         }
//         if(memberModel?.addonDanceDuration == 180) {
//             DancePrice = curVal?.half_yearly?.price            
//         }
//         if(memberModel?.addonDanceDuration == 365) {
//             DancePrice = curVal?.yearly?.price            
//         }
//         selectedDanceId = curVal.id;
//         // console.log(selectedDanceId);
//         return DancePrice;
//     })

//     let yogaPrice = 0;
//     let selectedYogaId = null;
//     addonPrice?.Yoga?.filter((curVal) => {
//         // eslint-disable-next-line
//         // if(memberModel?.upgradeduration == 15) {
//         //     upgradePrice = memberModel?.upgradeduration * curVal?.monthly?.daily            
//         // }
//         // eslint-disable-next-line
//         if(memberModel?.addonYogaDuration == 30) {
//             yogaPrice = curVal?.monthly?.price   
//             // console.log("hello world");         
//         }
//         if(memberModel?.addonYogaDuration == 60) {
//             yogaPrice = curVal?.secondly?.price   
//             // console.log("hello world");         
//         }
//         if(memberModel?.addonYogaDuration == 90) {
//             yogaPrice = curVal?.quarterly?.price            
//         }
//         if(memberModel?.addonYogaDuration == 180) {
//             yogaPrice = curVal?.half_yearly?.price            
//         }
//         if(memberModel?.addonYogaDuration == 365) {
//             yogaPrice = curVal?.yearly?.price            
//         }
//         selectedYogaId = curVal.id;
//         // console.log(selectedYogaId);

//         return yogaPrice;
//     })

//     let steamPrice = 0;
//     let selectedSteamId = null;
//     addonPrice?.Steam?.filter((curVal) => {
//         // eslint-disable-next-line
//         // if(memberModel?.upgradeduration == 15) {
//         //     upgradePrice = memberModel?.upgradeduration * curVal?.monthly?.daily            
//         // }
//         // eslint-disable-next-line
//         if(memberModel?.addonSteamDuration == 30) {
//             steamPrice = curVal?.monthly?.price   
//             // console.log("hello world");         
//         }
//         if(memberModel?.addonSteamDuration == 60) {
//             steamPrice = curVal?.secondly?.price   
//             // console.log("hello world");         
//         }
//         if(memberModel?.addonSteamDuration == 90) {
//             steamPrice = curVal?.quarterly?.price            
//         }
//         if(memberModel?.addonSteamDuration == 180) {
//             steamPrice = curVal?.half_yearly?.price            
//         }
//         if(memberModel?.addonSteamDuration == 365) {
//             steamPrice = curVal?.yearly?.price            
//         }
//         selectedSteamId = curVal.id;
//         return steamPrice;
//     })

    
//     let cyclingPrice = 0;
//     let selectedCyclingId = null;

//     addonPrice?.Cycling?.filter((curVal) => {
//         // eslint-disable-next-line
//         // if(memberModel?.upgradeduration == 15) {
//         //     upgradePrice = memberModel?.upgradeduration * curVal?.monthly?.daily            
//         // }
//         // eslint-disable-next-line
//         if(memberModel?.addonCyclingDuration == 30) {
//             cyclingPrice = curVal?.monthly?.price   
//             // console.log("hello world");         
//         }
//         if(memberModel?.addonCyclingDuration == 60) {
//             cyclingPrice = curVal?.secondly?.price   
//             // console.log("hello world");         
//         }
//         if(memberModel?.addonCyclingDuration == 90) {
//             cyclingPrice = curVal?.quarterly?.price            
//         }
//         if(memberModel?.addonCyclingDuration == 180) {
//             cyclingPrice = curVal?.half_yearly?.price            
//         }
//         if(memberModel?.addonCyclingDuration == 365) {
//             cyclingPrice = curVal?.yearly?.price            
//         }
//         selectedCyclingId = curVal.id;
//         // console.log(selectedCyclingId);

//         return cyclingPrice;
//     })

//     let functional_Training = 0;
//     let selectedFunctional_trainingId = null;
//     addonPrice?.Functional_Training?.filter((curVal) => {
//         // eslint-disable-next-line
//         // if(memberModel?.upgradeduration == 15) {
//         //     upgradePrice = memberModel?.upgradeduration * curVal?.monthly?.daily            
//         // }
//         // eslint-disable-next-line
//         if(memberModel?.addonFunctional_TrainingDuration == 30) {
//             functional_Training = curVal?.monthly?.price   
//             // console.log("hello world");         
//         }
//         if(memberModel?.addonFunctional_TrainingDuration == 60) {
//             functional_Training = curVal?.secondly?.price   
//             // console.log("hello world");         
//         }
//         if(memberModel?.addonFunctional_TrainingDuration == 90) {
//             functional_Training = curVal?.quarterly?.price            
//         }
//         if(memberModel?.addonFunctional_TrainingDuration == 180) {
//             functional_Training = curVal?.half_yearly?.price            
//         }
//         if(memberModel?.addonFunctional_TrainingDuration == 365) {
//             functional_Training = curVal?.yearly?.price            
//         }
//         selectedFunctional_trainingId = curVal.id;
//         // console.log(selectedFunctional_trainingId);
//         return functional_Training;
//     })

//     let zumbaPrice = 0;
//     let selectedZumbaId = null;

//     addonPrice?.Zumba?.filter((curVal) => {
//         // eslint-disable-next-line
//         // if(memberModel?.upgradeduration == 15) {
//         //     upgradePrice = memberModel?.upgradeduration * curVal?.monthly?.daily            
//         // }
//         // eslint-disable-next-line
//         if(memberModel?.addonZumbaDuration == 30) {
//             zumbaPrice = curVal?.monthly?.price   
//             // console.log("hello world");         
//         }
//         if(memberModel?.addonZumbaDuration == 60) {
//             zumbaPrice = curVal?.secondly?.price   
//             // console.log("hello world");         
//         }
//         if(memberModel?.addonZumbaDuration == 90) {
//             zumbaPrice = curVal?.quarterly?.price            
//         }
//         if(memberModel?.addonZumbaDuration == 180) {
//             zumbaPrice = curVal?.half_yearly?.price            
//         }
//         if(memberModel?.addonZumbaDuration == 365) {
//             zumbaPrice = curVal?.yearly?.price            
//         }
//         selectedZumbaId = curVal.id;
//         // console.log(selectedZumbaId);

//         return zumbaPrice;
//     })

//     let crossfitPrice = 0;
//     let selectedCrossfitId = null;

//     addonPrice?.Crossfit?.filter((curVal) => {
//         if(memberModel?.addonCrossfitDuration == 30) {
//             crossfitPrice = curVal?.monthly?.price   
//             // console.log("hello world");         
//         }
//         if(memberModel?.addonCrossfitDuration == 60) {
//             crossfitPrice = curVal?.secondly?.price   
//             // console.log("hello world");         
//         }
//         if(memberModel?.addonCrossfitDuration == 90) {
//             crossfitPrice = curVal?.quarterly?.price            
//         }
//         if(memberModel?.addonCrossfitDuration == 180) {
//             crossfitPrice = curVal?.half_yearly?.price            
//         }
//         if(memberModel?.addonCrossfitDuration == 365) {
//             crossfitPrice = curVal?.yearly?.price            
//         }
//         selectedCrossfitId = curVal.id;
//         // console.log(selectedCrossfitId);

//         return crossfitPrice;
//     })

//     let massagePrice = 0;
//     let selectedMassageId = null;

//     addonPrice?.Massage?.filter((curVal) => {
//         if(memberModel?.addonMassageDuration == 30) {
//             massagePrice = curVal?.monthly?.price   
//             // console.log("hello world");         
//         }
//         if(memberModel?.addonMassageDuration == 60) {
//             massagePrice = curVal?.secondly?.price   
//             // console.log("hello world");         
//         }
//         if(memberModel?.addonMassageDuration == 90) {
//             massagePrice = curVal?.quarterly?.price            
//         }
//         if(memberModel?.addonMassageDuration == 180) {
//             massagePrice = curVal?.half_yearly?.price            
//         }
//         if(memberModel?.addonMassageDuration == 365) {
//             massagePrice = curVal?.yearly?.price            
//         }
//         selectedMassageId = curVal.id;
//         // console.log(selectedMassageId);

//         return massagePrice;
//     })

//     let ncPrice = 0;
//     let selectedNutrition_ConsultingId = null;

//     addonPrice?.Nutrition_Consulting?.filter((curVal) => {
//         if(memberModel?.addonNutrition_ConsultingDuration == 30) {
//             ncPrice = curVal?.monthly?.price   
//             // console.log("hello world");         
//         }
//         if(memberModel?.addonNutrition_ConsultingDuration == 60) {
//             ncPrice = curVal?.secondly?.price   
//             // console.log("hello world");         
//         }
//         if(memberModel?.addonNutrition_ConsultingDuration == 90) {
//             ncPrice = curVal?.quarterly?.price            
//         }
//         if(memberModel?.addonNutrition_ConsultingDuration == 180) {
//             ncPrice = curVal?.half_yearly?.price            
//         }
//         if(memberModel?.addonNutrition_ConsultingDuration == 365) {
//             ncPrice = curVal?.yearly?.price            
//         }
//         selectedNutrition_ConsultingId = curVal.id;
//         // console.log(selectedNutrition_ConsultingId);

//         return ncPrice;
//     })

//     let wtPrice = 0;
//     let selectWeight_TrainingId = null;

//     addonPrice?.Weight_Training?.filter((curVal) => {
//         if(memberModel?.addonWeight_TrainingDuration == 30) {
//             wtPrice = curVal?.monthly?.price   
//             // console.log("hello world");         
//         }
//         if(memberModel?.addonWeight_TrainingDuration == 60) {
//             wtPrice = curVal?.secondly?.price   
//             // console.log("hello world");         
//         }
//         if(memberModel?.addonWeight_TrainingDuration == 90) {
//             wtPrice = curVal?.quarterly?.price            
//         }
//         if(memberModel?.addonWeight_TrainingDuration == 180) {
//             wtPrice = curVal?.half_yearly?.price            
//         }
//         if(memberModel?.addonWeight_TrainingDuration == 365) {
//             wtPrice = curVal?.yearly?.price            
//         }
//         selectWeight_TrainingId = curVal.id;
//         // console.log(selectWeight_TrainingId);

//         return wtPrice;
//     })

//     let hecPrice = 0;
//     let selectHiit_Excercise_ClassesId = null;

//     addonPrice?.Hiit_Excercise_Classes?.filter((curVal) => {
//         if(memberModel?.addonHiit_Excercise_ClassesDuration == 30) {
//             hecPrice = curVal?.monthly?.price   
//             // console.log("hello world");         
//         }
//         if(memberModel?.addonHiit_Excercise_ClassesDuration == 60) {
//             hecPrice = curVal?.secondly?.price   
//             // console.log("hello world");         
//         }
//         if(memberModel?.addonHiit_Excercise_ClassesDuration == 90) {
//             hecPrice = curVal?.quarterly?.price            
//         }
//         if(memberModel?.addonHiit_Excercise_ClassesDuration == 180) {
//             hecPrice = curVal?.half_yearly?.price            
//         }
//         if(memberModel?.addonHiit_Excercise_ClassesDuration == 365) {
//             hecPrice = curVal?.yearly?.price            
//         }
//         selectHiit_Excercise_ClassesId = curVal.id;
//         // console.log(selectHiit_Excercise_ClassesId);

//         return hecPrice;
//     })

//     let fsPrice = 0;
//     let selectedFitness_StudioID = null;

//     addonPrice?.Hiit_Excercise_Classes?.filter((curVal) => {
//         if(memberModel?.addonFitness_StudioDuration == 30) {
//             fsPrice = curVal?.monthly?.price   
//             // console.log("hello world");         
//         }
//         if(memberModel?.addonFitness_StudioDuration == 60) {
//             fsPrice = curVal?.secondly?.price   
//             // console.log("hello world");         
//         }
//         if(memberModel?.addonFitness_StudioDuration == 90) {
//             fsPrice = curVal?.quarterly?.price            
//         }
//         if(memberModel?.addonFitness_StudioDuration == 180) {
//             fsPrice = curVal?.half_yearly?.price            
//         }
//         if(memberModel?.addonFitness_StudioDuration == 365) {
//             fsPrice = curVal?.yearly?.price            
//         }
//         selectedFitness_StudioID = curVal.id;
//         // console.log(selectedFitness_StudioID);

//         return fsPrice;
//     })

//     let totalPrice = 0;
//     totalPrice = (planPrice + aPTPrice + lockerPrice + boxingPrice + DancePrice + yogaPrice + steamPrice + cyclingPrice + functional_Training + zumbaPrice + crossfitPrice + massagePrice + ncPrice + wtPrice + hecPrice + fsPrice);

//     const disablePastDate = () => {
//         const today = new Date();
//         const dd = String(today.getDate()).padStart(2, "0");
//         const mm = String(today.getMonth() + 1).padStart(2, "0");
//         const yyyy = today.getFullYear();
//         return yyyy + "-" + mm + "-" + dd;
//     }

//     const disableNewPastDate = () => {
//         const today = new Date(getMember?.subscription_addon_details?.enddate);
//         const dd = String(today.getDate()).padStart(2, "0");
//         const mm = String(today.getMonth() + 1).padStart(2, "0");
//         const yyyy = today.getFullYear();
//         return yyyy + "-" + mm + "-" + dd;
//     }

//     let prevBtn = document.getElementById('prev');
//     let nextBtn = document.getElementById('next');
//     let tab1 = document.getElementById('tab1');
//     let tab2 = document.getElementById('tab2');
//     let tab3 = document.getElementById('tab3');
//     let tab4 = document.getElementById('tab4');
//     let tab5 = document.getElementById('tab5');
//     let tab6 = document.getElementById('tab6');
//     let tab7 = document.getElementById('tab7');
//     let tab8 = document.getElementById('tab8');
//     let tab9 = document.getElementById('tab9');
//     let tab10 = document.getElementById('tab10');
//     let tab11 = document.getElementById('tab11');
//     let tab12 = document.getElementById('tab12');
//     let tab13 = document.getElementById('tab13');
//     let tab14 = document.getElementById('tab14');
//     let tab15 = document.getElementById('tab15');
//     let tab16 = document.getElementById('tab16');
//     // const showTab = () => {
//     //     if(step === 1) {
//     //         prevBtn?.classList?.remove('hidden');
//     //         tab1?.classList?.add('hidden');
//     //         tab2?.classList?.remove('hidden');
//     //         tab3?.classList?.add('hidden');
//     //         tab4?.classList?.add('hidden');
//     //         tab8?.classList?.add('hidden');
//     //         tab9?.classList?.add('hidden');
//     //         nextBtn?.classList?.remove('hidden');
//     //     }
//     //     else if(step === 0) {
//     //         tab1?.classList?.remove('hidden');
//     //         tab2?.classList?.add('hidden');
//     //         tab3?.classList?.add('hidden');
//     //         tab4?.classList?.add('hidden');
//     //         tab8?.classList?.add('hidden');
//     //         prevBtn?.classList?.add('hidden');
//     //     }
//     //     else if(step === 2) {
//     //         nextBtn?.classList?.remove('hidden');
//     //         tab3?.classList?.remove('hidden');
//     //         tab1?.classList?.add('hidden');
//     //         tab2?.classList?.add('hidden');
//     //         tab4?.classList?.add('hidden');
//     //         tab5?.classList?.add('hidden');
//     //         tab8?.classList?.add('hidden');
//     //         tab9?.classList?.add('hidden');
//     //         tab10?.classList?.add('hidden');
//     //         tab11?.classList?.add('hidden');
//     //         tab12?.classList?.add('hidden');
//     //         tab13?.classList?.add('hidden');
//     //         tab14?.classList?.add('hidden');
//     //         tab15?.classList?.add('hidden');
//     //         tab16?.classList?.add('hidden');
//     //         prevBtn?.classList?.remove('hidden');
//     //     }
//     //     else if(step === 3) {
//     //         nextBtn?.classList?.remove('hidden');
//     //         tab4?.classList.remove('hidden');
//     //         tab3?.classList?.add('hidden');
//     //         tab1?.classList?.add('hidden');
//     //         tab2?.classList?.add('hidden');
//     //         tab5?.classList?.add('hidden');
//     //         tab9?.classList?.add('hidden');
//     //         tab8?.classList?.add('hidden');
//     //         tab10?.classList?.add('hidden');
//     //         tab11?.classList?.add('hidden');
//     //         tab12?.classList?.add('hidden');
//     //         tab13?.classList?.add('hidden');
//     //         tab14?.classList?.add('hidden');
//     //         tab15?.classList?.add('hidden');
//     //         tab16?.classList?.add('hidden');
//     //     }
//     //     else if(step === 4) {
//     //         nextBtn?.classList?.remove('hidden');
//     //         tab5?.classList.remove('hidden');
//     //         tab3?.classList?.add('hidden');
//     //         tab1?.classList?.add('hidden');
//     //         tab2?.classList?.add('hidden');
//     //         tab4?.classList?.add('hidden');
//     //         tab6?.classList?.add('hidden');
//     //         tab7?.classList?.add('hidden');
//     //         tab8?.classList?.add('hidden');
//     //         tab9?.classList?.add('hidden');
//     //         tab10?.classList?.add('hidden');
//     //         tab11?.classList?.add('hidden');
//     //         tab12?.classList?.add('hidden');
//     //         tab13?.classList?.add('hidden');
//     //         tab14?.classList?.add('hidden');
//     //         tab15?.classList?.add('hidden');
//     //         tab16?.classList?.add('hidden');
//     //         prevBtn?.classList?.remove('hidden');

//     //     }
//     //     else if(step === 5) {
//     //         nextBtn?.classList?.remove('hidden');
//     //         tab5?.classList.add('hidden');
//     //         tab3?.classList?.add('hidden');
//     //         tab1?.classList?.add('hidden');
//     //         tab2?.classList?.add('hidden');
//     //         tab4?.classList?.add('hidden');
//     //         tab6?.classList?.remove('hidden');
//     //         tab7?.classList?.add('hidden');
//     //         tab8?.classList?.add('hidden');
//     //         tab9?.classList?.add('hidden');
//     //         tab10?.classList?.add('hidden');
//     //         tab11?.classList?.add('hidden');
//     //         tab12?.classList?.add('hidden');
//     //         tab13?.classList?.add('hidden');
//     //         tab14?.classList?.add('hidden');
//     //         tab15?.classList?.add('hidden');
//     //         tab16?.classList?.add('hidden');
//     //         prevBtn?.classList?.remove('hidden');

//     //     }
//     //     else if(step === 6) {
//     //         nextBtn?.classList?.remove('hidden');
//     //         tab5?.classList.add('hidden');
//     //         tab3?.classList?.add('hidden');
//     //         tab1?.classList?.add('hidden');
//     //         tab2?.classList?.add('hidden');
//     //         tab4?.classList?.add('hidden');
//     //         tab6?.classList?.add('hidden');
//     //         tab7?.classList?.remove('hidden');
//     //         tab8?.classList?.add('hidden');
//     //         tab9?.classList?.add('hidden');
//     //         tab10?.classList?.add('hidden');
//     //         tab11?.classList?.add('hidden');
//     //         tab12?.classList?.add('hidden');
//     //         tab13?.classList?.add('hidden');
//     //         tab14?.classList?.add('hidden');
//     //         tab15?.classList?.add('hidden');
//     //         tab16?.classList?.add('hidden');
//     //         prevBtn?.classList?.remove('hidden');

//     //     }
//     //     else if(step === 7) {
//     //         nextBtn?.classList?.remove('hidden');
//     //         tab5?.classList.add('hidden');
//     //         tab3?.classList?.add('hidden');
//     //         tab1?.classList?.add('hidden');
//     //         tab2?.classList?.add('hidden');
//     //         tab4?.classList?.add('hidden');
//     //         tab6?.classList?.add('hidden');
//     //         tab7?.classList?.add('hidden');
//     //         tab8?.classList?.remove('hidden');
//     //         tab9?.classList?.add('hidden');
//     //         tab10?.classList?.add('hidden');
//     //         tab11?.classList?.add('hidden');
//     //         tab12?.classList?.add('hidden');
//     //         tab13?.classList?.add('hidden');
//     //         tab14?.classList?.add('hidden');
//     //         tab15?.classList?.add('hidden');
//     //         tab16?.classList?.add('hidden');
//     //         prevBtn?.classList?.remove('hidden');

//     //     }
//     //     else if(step === 8) {
//     //         nextBtn?.classList?.remove('hidden');
//     //         tab5?.classList.add('hidden');
//     //         tab3?.classList?.add('hidden');
//     //         tab1?.classList?.add('hidden');
//     //         tab2?.classList?.add('hidden');
//     //         tab4?.classList?.add('hidden');
//     //         tab6?.classList?.add('hidden');
//     //         tab7?.classList?.add('hidden');
//     //         tab8?.classList?.add('hidden');
//     //         tab9?.classList?.remove('hidden');
//     //         tab10?.classList?.add('hidden');
//     //         tab11?.classList?.add('hidden');
//     //         tab12?.classList?.add('hidden');
//     //         tab13?.classList?.add('hidden');
//     //         tab14?.classList?.add('hidden');
//     //         tab15?.classList?.add('hidden');
//     //         tab16?.classList?.add('hidden');
//     //         prevBtn?.classList?.remove('hidden');

//     //     }
//     //     else if(step === 9) {
//     //         nextBtn?.classList?.remove('hidden');
//     //         tab5?.classList.add('hidden');
//     //         tab3?.classList?.add('hidden');
//     //         tab1?.classList?.add('hidden');
//     //         tab2?.classList?.add('hidden');
//     //         tab4?.classList?.add('hidden');
//     //         tab6?.classList?.add('hidden');
//     //         tab7?.classList?.add('hidden');
//     //         tab8?.classList?.add('hidden');
//     //         tab9?.classList?.add('hidden');
//     //         if(addonPrice?.Zumba === null){
//     //             tab10?.classList?.add('hidden');
//     //             // nextBtn?.classList?.add('hidden');
//     //             // prevBtn?.classList?.add('hidden');
//     //         }
//     //         else {
//     //             tab10?.classList?.remove('hidden');
//     //             nextBtn?.classList?.remove('hidden');
//     //             prevBtn?.classList?.remove('hidden');

//     //         }
//     //         tab11?.classList?.add('hidden');
//     //         tab12?.classList?.add('hidden');
//     //         tab13?.classList?.add('hidden');
//     //         tab14?.classList?.add('hidden');
//     //         tab15?.classList?.add('hidden');
//     //         tab16?.classList?.add('hidden');
//     //         prevBtn?.classList?.remove('hidden');

//     //     }
//     //     else if(step === 10) {
//     //         nextBtn?.classList?.remove('hidden');
//     //         tab5?.classList.add('hidden');
//     //         tab3?.classList?.add('hidden');
//     //         tab1?.classList?.add('hidden');
//     //         tab2?.classList?.add('hidden');
//     //         tab4?.classList?.add('hidden');
//     //         tab6?.classList?.add('hidden');
//     //         tab7?.classList?.add('hidden');
//     //         tab8?.classList?.add('hidden');
//     //         tab9?.classList?.add('hidden');
//     //         tab10?.classList?.add('hidden');
//     //         tab11?.classList?.remove('hidden');
//     //         tab12?.classList?.add('hidden');
//     //         tab13?.classList?.add('hidden');
//     //         tab14?.classList?.add('hidden');
//     //         tab15?.classList?.add('hidden');
//     //         tab16?.classList?.add('hidden');
//     //         prevBtn?.classList?.remove('hidden');

//     //     }
//     //     else if(step === 11) {
//     //         nextBtn?.classList?.remove('hidden');
//     //         tab5?.classList.add('hidden');
//     //         tab3?.classList?.add('hidden');
//     //         tab1?.classList?.add('hidden');
//     //         tab2?.classList?.add('hidden');
//     //         tab4?.classList?.add('hidden');
//     //         tab6?.classList?.add('hidden');
//     //         tab7?.classList?.add('hidden');
//     //         tab8?.classList?.add('hidden');
//     //         tab9?.classList?.add('hidden');
//     //         tab10?.classList?.add('hidden');
//     //         tab11?.classList?.add('hidden');
//     //         tab12?.classList?.remove('hidden');
//     //         tab13?.classList?.add('hidden');
//     //         tab14?.classList?.add('hidden');
//     //         tab15?.classList?.add('hidden');
//     //         tab16?.classList?.add('hidden');
//     //         prevBtn?.classList?.remove('hidden');

//     //     }
//     //     else if(step === 12) {
//     //         nextBtn?.classList?.remove('hidden');
//     //         tab5?.classList.add('hidden');
//     //         tab3?.classList?.add('hidden');
//     //         tab1?.classList?.add('hidden');
//     //         tab2?.classList?.add('hidden');
//     //         tab4?.classList?.add('hidden');
//     //         tab6?.classList?.add('hidden');
//     //         tab7?.classList?.add('hidden');
//     //         tab8?.classList?.add('hidden');
//     //         tab9?.classList?.add('hidden');
//     //         tab10?.classList?.add('hidden');
//     //         tab11?.classList?.add('hidden');
//     //         tab12?.classList?.add('hidden');
//     //         tab13?.classList?.remove('hidden');
//     //         tab14?.classList?.add('hidden');
//     //         tab15?.classList?.add('hidden');
//     //         tab16?.classList?.add('hidden');
//     //         prevBtn?.classList?.remove('hidden');

//     //     }
//     //     else if(step === 13) {
//     //         nextBtn?.classList?.remove('hidden');
//     //         tab5?.classList.add('hidden');
//     //         tab3?.classList?.add('hidden');
//     //         tab1?.classList?.add('hidden');
//     //         tab2?.classList?.add('hidden');
//     //         tab4?.classList?.add('hidden');
//     //         tab6?.classList?.add('hidden');
//     //         tab7?.classList?.add('hidden');
//     //         tab8?.classList?.add('hidden');
//     //         tab9?.classList?.add('hidden');
//     //         tab10?.classList?.add('hidden');
//     //         tab11?.classList?.add('hidden');
//     //         tab12?.classList?.add('hidden');
//     //         tab13?.classList?.add('hidden');
//     //         tab14?.classList?.remove('hidden');
//     //         tab15?.classList?.add('hidden');
//     //         tab16?.classList?.add('hidden');
//     //         prevBtn?.classList?.remove('hidden');

//     //     }
//     //     else if(step === 14) {
//     //         nextBtn?.classList?.remove('hidden');
//     //         tab5?.classList.add('hidden');
//     //         tab3?.classList?.add('hidden');
//     //         tab1?.classList?.add('hidden');
//     //         tab2?.classList?.add('hidden');
//     //         tab4?.classList?.add('hidden');
//     //         tab6?.classList?.add('hidden');
//     //         tab7?.classList?.add('hidden');
//     //         tab8?.classList?.add('hidden');
//     //         tab9?.classList?.add('hidden');
//     //         tab10?.classList?.add('hidden');
//     //         tab11?.classList?.add('hidden');
//     //         tab12?.classList?.add('hidden');
//     //         tab13?.classList?.add('hidden');
//     //         tab14?.classList?.add('hidden');
//     //         tab15?.classList?.remove('hidden');
//     //         tab16?.classList?.add('hidden');
//     //         prevBtn?.classList?.remove('hidden');

//     //     }
//     //     else if(step === 15) {
//     //         nextBtn?.classList?.add('hidden');
//     //         tab5?.classList.add('hidden');
//     //         tab3?.classList?.add('hidden');
//     //         tab1?.classList?.add('hidden');
//     //         tab2?.classList?.add('hidden');
//     //         tab4?.classList?.add('hidden');
//     //         tab6?.classList?.add('hidden');
//     //         tab7?.classList?.add('hidden');
//     //         tab8?.classList?.add('hidden');
//     //         tab9?.classList?.add('hidden');
//     //         tab10?.classList?.add('hidden');
//     //         tab11?.classList?.add('hidden');
//     //         tab12?.classList?.add('hidden');
//     //         tab13?.classList?.add('hidden');
//     //         tab14?.classList?.add('hidden');
//     //         tab15?.classList?.add('hidden');
//     //         tab16?.classList?.remove('hidden');
//     //         prevBtn?.classList?.remove('hidden');

//     //     }
//     // }

//     const showTab = () => {
//         if(step === 1) {
//             prevBtn?.classList?.remove('hidden');
//             tab1?.classList?.add('hidden');
//             tab2?.classList?.remove('hidden');
//             tab3?.classList?.add('hidden');
//             tab4?.classList?.add('hidden');
//             tab8?.classList?.add('hidden');
//             tab9?.classList?.add('hidden');
//             nextBtn?.classList?.remove('hidden');
//         }
//         else if(step === 0) {
//             tab1?.classList?.remove('hidden');
//             tab2?.classList?.add('hidden');
//             tab3?.classList?.add('hidden');
//             tab4?.classList?.add('hidden');
//             tab8?.classList?.add('hidden');
//             prevBtn?.classList?.add('hidden');
//         }
//         else if(step === 2) {
//             nextBtn?.classList?.remove('hidden');
//             tab3?.classList?.remove('hidden');
//             tab1?.classList?.add('hidden');
//             tab2?.classList?.add('hidden');
//             tab4?.classList?.add('hidden');
//             tab5?.classList?.add('hidden');
//             tab8?.classList?.add('hidden');
//             tab9?.classList?.add('hidden');
//             tab10?.classList?.add('hidden');
//             tab11?.classList?.add('hidden');
//             tab12?.classList?.add('hidden');
//             tab13?.classList?.add('hidden');
//             tab14?.classList?.add('hidden');
//             tab15?.classList?.add('hidden');
//             tab16?.classList?.add('hidden');
//             prevBtn?.classList?.remove('hidden');
//         }
//         else if(step === 3) {
//             nextBtn?.classList?.remove('hidden');
//             tab4?.classList.remove('hidden');
//             tab3?.classList?.add('hidden');
//             tab1?.classList?.add('hidden');
//             tab2?.classList?.add('hidden');
//             tab5?.classList?.add('hidden');
//             tab9?.classList?.add('hidden');
//             tab8?.classList?.add('hidden');
//             tab10?.classList?.add('hidden');
//             tab11?.classList?.add('hidden');
//             tab12?.classList?.add('hidden');
//             tab13?.classList?.add('hidden');
//             tab14?.classList?.add('hidden');
//             tab15?.classList?.add('hidden');
//             tab16?.classList?.add('hidden');
//         }
//         else if(step === 4) {
//             nextBtn?.classList?.remove('hidden');
//             tab5?.classList.remove('hidden');
//             tab3?.classList?.add('hidden');
//             tab1?.classList?.add('hidden');
//             tab2?.classList?.add('hidden');
//             tab4?.classList?.add('hidden');
//             tab6?.classList?.add('hidden');
//             tab7?.classList?.add('hidden');
//             tab8?.classList?.add('hidden');
//             tab9?.classList?.add('hidden');
//             tab10?.classList?.add('hidden');
//             tab11?.classList?.add('hidden');
//             tab12?.classList?.add('hidden');
//             tab13?.classList?.add('hidden');
//             tab14?.classList?.add('hidden');
//             tab15?.classList?.add('hidden');
//             tab16?.classList?.add('hidden');
//             prevBtn?.classList?.remove('hidden');

//         }
//         else if(step === 5) {
//             nextBtn?.classList?.remove('hidden');
//             tab5?.classList.add('hidden');
//             tab3?.classList?.add('hidden');
//             tab1?.classList?.add('hidden');
//             tab2?.classList?.add('hidden');
//             tab4?.classList?.add('hidden');
//             tab6?.classList?.remove('hidden');
//             tab7?.classList?.add('hidden');
//             tab8?.classList?.add('hidden');
//             tab9?.classList?.add('hidden');
//             tab10?.classList?.add('hidden');
//             tab11?.classList?.add('hidden');
//             tab12?.classList?.add('hidden');
//             tab13?.classList?.add('hidden');
//             tab14?.classList?.add('hidden');
//             tab15?.classList?.add('hidden');
//             tab16?.classList?.add('hidden');
//             prevBtn?.classList?.remove('hidden');

//         }
//         else if(step === 6) {
//             nextBtn?.classList?.remove('hidden');
//             tab5?.classList.add('hidden');
//             tab3?.classList?.add('hidden');
//             tab1?.classList?.add('hidden');
//             tab2?.classList?.add('hidden');
//             tab4?.classList?.add('hidden');
//             tab6?.classList?.add('hidden');
//             tab7?.classList?.remove('hidden');
//             tab8?.classList?.add('hidden');
//             tab9?.classList?.add('hidden');
//             tab10?.classList?.add('hidden');
//             tab11?.classList?.add('hidden');
//             tab12?.classList?.add('hidden');
//             tab13?.classList?.add('hidden');
//             tab14?.classList?.add('hidden');
//             tab15?.classList?.add('hidden');
//             tab16?.classList?.add('hidden');
//             prevBtn?.classList?.remove('hidden');

//         }
//         else if(step === 7) {
//             nextBtn?.classList?.remove('hidden');
//             tab5?.classList.add('hidden');
//             tab3?.classList?.add('hidden');
//             tab1?.classList?.add('hidden');
//             tab2?.classList?.add('hidden');
//             tab4?.classList?.add('hidden');
//             tab6?.classList?.add('hidden');
//             tab7?.classList?.add('hidden');
//             tab8?.classList?.remove('hidden');
//             tab9?.classList?.add('hidden');
//             tab10?.classList?.add('hidden');
//             tab11?.classList?.add('hidden');
//             tab12?.classList?.add('hidden');
//             tab13?.classList?.add('hidden');
//             tab14?.classList?.add('hidden');
//             tab15?.classList?.add('hidden');
//             tab16?.classList?.add('hidden');
//             prevBtn?.classList?.remove('hidden');

//         }
//         else if(step === 8) {
//             nextBtn?.classList?.remove('hidden');
//             tab5?.classList.add('hidden');
//             tab3?.classList?.add('hidden');
//             tab1?.classList?.add('hidden');
//             tab2?.classList?.add('hidden');
//             tab4?.classList?.add('hidden');
//             tab6?.classList?.add('hidden');
//             tab7?.classList?.add('hidden');
//             tab8?.classList?.add('hidden');
//             tab9?.classList?.remove('hidden');
//             tab10?.classList?.add('hidden');
//             tab11?.classList?.add('hidden');
//             tab12?.classList?.add('hidden');
//             tab13?.classList?.add('hidden');
//             tab14?.classList?.add('hidden');
//             tab15?.classList?.add('hidden');
//             tab16?.classList?.add('hidden');
//             prevBtn?.classList?.remove('hidden');

//         }
//         else if(step === 9) {
//             nextBtn?.classList?.remove('hidden');
//             tab5?.classList.add('hidden');
//             tab3?.classList?.add('hidden');
//             tab1?.classList?.add('hidden');
//             tab2?.classList?.add('hidden');
//             tab4?.classList?.add('hidden');
//             tab6?.classList?.add('hidden');
//             tab7?.classList?.add('hidden');
//             tab8?.classList?.add('hidden');
//             tab9?.classList?.add('hidden');
//             tab10?.classList?.remove('hidden');
//             tab11?.classList?.add('hidden');
//             tab12?.classList?.add('hidden');
//             tab13?.classList?.add('hidden');
//             tab14?.classList?.add('hidden');
//             tab15?.classList?.add('hidden');
//             tab16?.classList?.add('hidden');
//             prevBtn?.classList?.remove('hidden');

//         }
//         else if(step === 10) {
//             nextBtn?.classList?.remove('hidden');
//             tab5?.classList.add('hidden');
//             tab3?.classList?.add('hidden');
//             tab1?.classList?.add('hidden');
//             tab2?.classList?.add('hidden');
//             tab4?.classList?.add('hidden');
//             tab6?.classList?.add('hidden');
//             tab7?.classList?.add('hidden');
//             tab8?.classList?.add('hidden');
//             tab9?.classList?.add('hidden');
//             tab10?.classList?.add('hidden');
//             tab11?.classList?.remove('hidden');
//             tab12?.classList?.add('hidden');
//             tab13?.classList?.add('hidden');
//             tab14?.classList?.add('hidden');
//             tab15?.classList?.add('hidden');
//             tab16?.classList?.add('hidden');
//             prevBtn?.classList?.remove('hidden');

//         }
//         else if(step === 11) {
//             nextBtn?.classList?.remove('hidden');
//             tab5?.classList.add('hidden');
//             tab3?.classList?.add('hidden');
//             tab1?.classList?.add('hidden');
//             tab2?.classList?.add('hidden');
//             tab4?.classList?.add('hidden');
//             tab6?.classList?.add('hidden');
//             tab7?.classList?.add('hidden');
//             tab8?.classList?.add('hidden');
//             tab9?.classList?.add('hidden');
//             tab10?.classList?.add('hidden');
//             tab11?.classList?.add('hidden');
//             tab12?.classList?.remove('hidden');
//             tab13?.classList?.add('hidden');
//             tab14?.classList?.add('hidden');
//             tab15?.classList?.add('hidden');
//             tab16?.classList?.add('hidden');
//             prevBtn?.classList?.remove('hidden');

//         }
//         else if(step === 12) {
//             nextBtn?.classList?.remove('hidden');
//             tab5?.classList.add('hidden');
//             tab3?.classList?.add('hidden');
//             tab1?.classList?.add('hidden');
//             tab2?.classList?.add('hidden');
//             tab4?.classList?.add('hidden');
//             tab6?.classList?.add('hidden');
//             tab7?.classList?.add('hidden');
//             tab8?.classList?.add('hidden');
//             tab9?.classList?.add('hidden');
//             tab10?.classList?.add('hidden');
//             tab11?.classList?.add('hidden');
//             tab12?.classList?.add('hidden');
//             tab13?.classList?.remove('hidden');
//             tab14?.classList?.add('hidden');
//             tab15?.classList?.add('hidden');
//             tab16?.classList?.add('hidden');
//             prevBtn?.classList?.remove('hidden');

//         }
//         else if(step === 13) {
//             nextBtn?.classList?.remove('hidden');
//             tab5?.classList.add('hidden');
//             tab3?.classList?.add('hidden');
//             tab1?.classList?.add('hidden');
//             tab2?.classList?.add('hidden');
//             tab4?.classList?.add('hidden');
//             tab6?.classList?.add('hidden');
//             tab7?.classList?.add('hidden');
//             tab8?.classList?.add('hidden');
//             tab9?.classList?.add('hidden');
//             tab10?.classList?.add('hidden');
//             tab11?.classList?.add('hidden');
//             tab12?.classList?.add('hidden');
//             tab13?.classList?.add('hidden');
//             tab14?.classList?.remove('hidden');
//             tab15?.classList?.add('hidden');
//             tab16?.classList?.add('hidden');
//             prevBtn?.classList?.remove('hidden');

//         }
//         else if(step === 14) {
//             nextBtn?.classList?.remove('hidden');
//             tab5?.classList.add('hidden');
//             tab3?.classList?.add('hidden');
//             tab1?.classList?.add('hidden');
//             tab2?.classList?.add('hidden');
//             tab4?.classList?.add('hidden');
//             tab6?.classList?.add('hidden');
//             tab7?.classList?.add('hidden');
//             tab8?.classList?.add('hidden');
//             tab9?.classList?.add('hidden');
//             tab10?.classList?.add('hidden');
//             tab11?.classList?.add('hidden');
//             tab12?.classList?.add('hidden');
//             tab13?.classList?.add('hidden');
//             tab14?.classList?.add('hidden');
//             tab15?.classList?.remove('hidden');
//             tab16?.classList?.add('hidden');
//             prevBtn?.classList?.remove('hidden');

//         }
//         else if(step === 15) {
//             nextBtn?.classList?.add('hidden');
//             tab5?.classList.add('hidden');
//             tab3?.classList?.add('hidden');
//             tab1?.classList?.add('hidden');
//             tab2?.classList?.add('hidden');
//             tab4?.classList?.add('hidden');
//             tab6?.classList?.add('hidden');
//             tab7?.classList?.add('hidden');
//             tab8?.classList?.add('hidden');
//             tab9?.classList?.add('hidden');
//             tab10?.classList?.add('hidden');
//             tab11?.classList?.add('hidden');
//             tab12?.classList?.add('hidden');
//             tab13?.classList?.add('hidden');
//             tab14?.classList?.add('hidden');
//             tab15?.classList?.add('hidden');
//             tab16?.classList?.remove('hidden');
//             prevBtn?.classList?.remove('hidden');

//         }
//     }

//     // const showTab = () => {
//     //     prevBtn?.classList?.remove('hidden');
//     //     nextBtn?.classList?.remove('hidden');
      
//     //     // Hide all tabs
//     //     for (let i = 1; i <= 16; i++) {
//     //       const tab = document.getElementById(`tab${i}`);
//     //       tab?.classList?.add('hidden');
//     //     }
      
//     //     if (step === 1) {
//     //       tab2?.classList?.remove('hidden');
//     //     } else if (step === 2) {
//     //       tab3?.classList?.remove('hidden');
//     //     } else if (step === 3) {
//     //       tab4?.classList?.remove('hidden');
//     //     } else if (step === 4) {
//     //       tab5?.classList?.remove('hidden');
//     //     } else if (step === 5) {
//     //       tab6?.classList?.remove('hidden');
//     //     } else if (step === 6) {
//     //       tab7?.classList?.remove('hidden');
//     //     } else if (step === 7) {
//     //       tab8?.classList?.remove('hidden');
//     //     } else if (step === 8) {
//     //       tab9?.classList?.remove('hidden');
//     //     } else if (step === 9) {
//     //       if (addonPrice?.Zumba === null) {
//     //         // Skip step 9 and go to step 10
//     //         setStep(10);
//     //         tab10?.classList?.remove('hidden');
//     //       } else {
//     //         tab10?.classList?.remove('hidden');
//     //       }
//     //     } else if (step === 10) {
//     //       tab11?.classList?.remove('hidden');
//     //     } else if (step === 11) {
//     //       tab12?.classList?.remove('hidden');
//     //     } else if (step === 12) {
//     //       tab13?.classList?.remove('hidden');
//     //     } else if (step === 13) {
//     //       tab14?.classList?.remove('hidden');
//     //     } else if (step === 14) {
//     //       tab15?.classList?.remove('hidden');
//     //     } else if (step === 15) {
//     //       tab16?.classList?.remove('hidden');
//     //       nextBtn?.classList?.add('hidden');
//     //     }
//     //   };
      

//     useEffect(() => {
//         showTab();   
//     });

//     const subscription = {
//         "addon_type": 'Subscription',
//         "addon_id": memberModel?.membershipplan,
//         "startdate": memberModel?.planstart
//     }
//     const trainer = {
//         "addon_type": memberModel?.addonTrainerType ? memberModel?.addonTrainerType : null,
//         "addon_id": memberModel?.trainer,
//         "duration": memberModel?.trainerduration ? memberModel?.trainerduration : null,
//         "startdate": memberModel?.planstartTrainer ? memberModel?.planstartTrainer : null,
//         "type": memberModel?.trainerPT ? memberModel?.trainerPT : null,
//         "fees": aPTPrice ? aPTPrice : null
//     }
//     const locker = {
//         "addon_type": memberModel?.addonLockerType ? memberModel?.addonLockerType : null,
//         "addon_id": memberModel?.locker ? memberModel?.locker : null,
//         "duration": memberModel?.lockerduration ? memberModel?.lockerduration : null,
//         "startdate": memberModel?.planstartLocker ? memberModel?.planstartLocker : null,
//         "fees": lockerPrice ? lockerPrice : null
//     }
//     // const upgrade = {
//     //     "addon_type": memberModel?.addonUpgradeType ? memberModel?.addonUpgradeType : null,
//     //     "addon_id": memberModel?.upgrade ? memberModel?.upgrade : null,
//     //     "duration": memberModel?.upgradeduration ? memberModel?.upgradeduration : null,
//     //     "startdate": memberModel?.planstartUpgrade ? memberModel?.planstartUpgrade : null,
//     //     "fees": upgradePrice ? upgradePrice : null
//     // }
//     // const boxing = {
//     //     "addon_type": memberModel?.addonBoxingType ? memberModel?.addonBoxingType : null,
//     //     "addon_id": memberModel?.boxing ? memberModel?.boxing : null,
//     //     "duration": memberModel?.addonDuration ? memberModel?.addonDuration : null,
//     //     "fees": boxingPrice ? boxingPrice : null,
//     //     "startdate": memberModel?.boxingPlanstart,
//     // };
//     const boxing = {
//         "addon_type": memberModel?.addonBoxingType ? memberModel?.addonBoxingType : null,
//         "addon_id": selectedBoxingId, // Use the selected ID here
//         "duration": memberModel?.addonDuration ? memberModel?.addonDuration : null,
//         "fees": boxingPrice ? boxingPrice.toString() : null, // Convert boxingPrice to a string
//         "startdate": memberModel?.boxingPlanstart,
//     };
//     const dance = {
//         "addon_type": memberModel?.addonDanceType ? memberModel?.addonDanceType : null,
//         "addon_id": selectedDanceId, // use the selected ID here
//         "duration": memberModel?.addonDanceDuration ? memberModel?.addonDanceDuration : null,
//         "fees": DancePrice ? DancePrice : null,
//         "startdate": memberModel?.dancePlanstart,
//     };
//     const yoga = {
//         "addon_type": memberModel?.addonYogaType ? memberModel?.addonYogaType : null,
//         "addon_id": selectedYogaId,
//         "duration": memberModel?.addonYogaDuration ? memberModel?.addonYogaDuration : null,
//         "fees": yogaPrice ? yogaPrice : null,
//         "startdate": memberModel?.yogaPlanstart,
//     };
//     const steam = {
//         "addon_type": memberModel?.addonSteamType ? memberModel?.addonSteamType : null,
//         "addon_id": selectedSteamId,
//         "duration": memberModel?.addonSteamDuration ? memberModel?.addonSteamDuration : null,
//         "fees": steamPrice ? steamPrice : null,
//         "startdate": memberModel?.steamPlanstart,
//     };
//     const cycling = {
//         "addon_type": memberModel?.addonCyclingType ? memberModel?.addonCyclingType : null,
//         "addon_id": selectedCyclingId,
//         "duration": memberModel?.addonCyclingDuration ? memberModel?.addonCyclingDuration : null,
//         "fees": cyclingPrice ? cyclingPrice : null,
//         "startdate": memberModel?.cyclingPlanstart,
//     };
//     const Functional_Training = {
//         "addon_type": memberModel?.addonFunctional_TrainingType ? memberModel?.addonFunctional_TrainingType : null,
//         "addon_id": selectedFunctional_trainingId,
//         "duration": memberModel?.addonFunctional_TrainingDuration ? memberModel?.addonFunctional_TrainingDuration : null,
//         "fees": functional_Training ? functional_Training : null,
//         "startdate": memberModel?.functional_TrainingPlanstart,
//     };
//     const zumba = {
//         "addon_type": memberModel?.addonZumbaType ? memberModel?.addonZumbaType : null,
//         "addon_id": selectedZumbaId,
//         "duration": memberModel?.addonZumbaDuration ? memberModel?.addonZumbaDuration : null,
//         "fees": zumbaPrice ? zumbaPrice : null,
//         "startdate": memberModel?.zumbaPlanstart,
//     };
//     const crossfit = {
//         "addon_type": memberModel?.addonCrossfitType ? memberModel?.addonCrossfitType : null,
//         "addon_id": selectedCrossfitId,
//         "duration": memberModel?.addonCrossfitDuration ? memberModel?.addonCrossfitDuration : null,
//         "fees": crossfitPrice ? crossfitPrice : null,
//         "startdate": memberModel?.crossfitPlanstart,
//     };
//     const massage = {
//         "addon_type": memberModel?.addonMassageType ? memberModel?.addonMassageType : null,
//         "addon_id": selectedMassageId,
//         "duration": memberModel?.addonMassageDuration ? memberModel?.addonMassageDuration : null,
//         "fees": massagePrice ? massagePrice : null,
//         "startdate": memberModel?.massagePlanstart,
//     };
//     const nutrition_consulting = {
//         "addon_type": memberModel?.addonNutrition_ConsultingType ? memberModel?.addonNutrition_ConsultingType : null,
//         "addon_id": selectedNutrition_ConsultingId,
//         "duration": memberModel?.addonNutrition_ConsultingDuration ? memberModel?.addonNutrition_ConsultingDuration : null,
//         "fees": ncPrice ? ncPrice : null,
//         "startdate": memberModel?.nutrition_ConsultingPlanstart,
//     };
//     const weight_training = {
//         "addon_type": memberModel?.addonWeight_TrainingType ? memberModel?.addonWeight_TrainingType : null,
//         "addon_id": selectWeight_TrainingId,
//         "duration": memberModel?.addonWeight_TrainingDuration ? memberModel?.addonWeight_TrainingDuration : null,
//         "fees": wtPrice ? wtPrice : null,
//         "startdate": memberModel?.weight_trainingPlanstart,
//     };
//     const hiit_excercise_classes = {
//         "addon_type": memberModel?.addonHiit_Excercise_ClassesType ? memberModel?.addonHiit_Excercise_ClassesType : null,
//         "addon_id": selectHiit_Excercise_ClassesId,
//         "duration": memberModel?.addonHiit_Excercise_ClassesDuration ? memberModel?.addonHiit_Excercise_ClassesDuration : null,
//         "fees": hecPrice ? hecPrice : null,
//         "startdate": memberModel?.hiit_excercise_classesPlanstart,
//     };
//     const fitness_studio = {
//         "addon_type": memberModel?.addonFitness_StudioType ? memberModel?.addonFitness_StudioType : null,
//         "addon_id": selectedFitness_StudioID,
//         "duration": memberModel?.addonFitness_StudioDuration ? memberModel?.addonFitness_StudioDuration : null,
//         "fees": fsPrice ? fsPrice : null,
//         "startdate": memberModel?.Fitness_StudioPlanstart,
//     };

//     const state = {
//         "member_id": getMember?.member_id,
//         "staff_id": getMember?.ref_id,
//         "pay_description": 'test payment',
//         "Subscription": subscription ? subscription : null,
//         // "Trainer": memberModel?.addonTrainerType === 'Trainer' ? trainer : null,
//         "Trainer" : memberModel?.addonTrainerType === 'Trainer' ? trainer : null,
//         "Locker": memberModel?.addonLockerType === 'Locker' ? locker : null,
//         "Boxing": memberModel?.addonBoxingType === 'Boxing' ? boxing : null,
//         "Dance" : memberModel?.addonDanceType === 'Dance' ? dance : null,
//         "Yoga" : memberModel?.addonYogaType === 'Yoga' ? yoga : null,
//         "Steam" : memberModel?.addonSteamType === 'Steam' ? steam : null,
//         "Cycling" : memberModel?.addonCyclingType === 'Cycling' ? cycling : null,
//         "Functional_Training": memberModel?.addonFunctional_TrainingType === 'Functional_Training'? Functional_Training: null,
//         "Zumba" : memberModel?.addonZumbaType === 'Zumba' ? zumba : null,
//         "Crossfit": memberModel?.addonCrossfitType === "Crossfit" ? crossfit : null,
//         "Massage": memberModel?.addonMassageType === "Massage" ? massage : null,
//         "Nutrition_Consulting" : memberModel?.addonNutrition_ConsultingType === "Nutrition_Consulting" ? nutrition_consulting : null,
//         "Weight_Training" : memberModel?.addonWeight_TrainingType === "Weight_Training" ? weight_training : null,
//         "Hiit_Excercise_Classes" : memberModel?.addonHiit_Excercise_ClassesType === "Hiit_Excercise_Classes" ? hiit_excercise_classes : null,
//         "Fitness_Studio" : memberModel?.addonFitness_StudioType === "Fitness_Studio" ? fitness_studio : null,
//     }

//     const success_state = {
//         "order_id": '',
//         "payment_status": 1,
//         "payment_id": '',
//         "signature": '',
//     }

//     const handleSubmit = async (e) => {
//         e.preventDefault();
//         const data = {...state}
//         delete data.errors;
//         console.log(data);
        
//         const res = await axios.post(`${process.env.REACT_APP_URL}add_addons_new`, data);
//         // console.log(res);
//         if(res?.status === 200){
//             const Res = await loadScript('https://checkout.razorpay.com/v1/checkout.js');
//             if(!Res) {
//                 alert('Razorpay SDK failed to load. Are you online?');
//                 return;
//             }
//             // const amount = res?.data.paydata.amount;
//             const options = {
//                 key: res?.data.razorpay_key,
//                 name: 'GYMApplication',
//                 description: 'Test Transaction',
//                 order_id: res?.data.paydata.order_id,
//                 // order_id: 'order_Lepy9UPxcYNYXD',
//                 // callback_url: 'https://eneqd3r9zrjok.x.pipedream.net/',
//                 handler: function(response) {
//                     success_state.order_id = response.razorpay_order_id;
//                     success_state.payment_id = response.razorpay_payment_id;
//                     success_state.signature = response.razorpay_signature;
//                     // alert(response.razorpay_payment_id);
//                     // alert(response.razorpay_order_id);
//                     // alert(response.razorpay_signature);
//                     // console.log(response);
//                     if(response) {
//                         const suc_data = {...success_state}
//                         axios.post(`${process.env.REACT_APP_URL}update_payment`, suc_data).then((resp) => {
//                             if(resp?.status === 200) {
//                                 toast.success(resp?.data.message, {
//                                     position: 'top-right',
//                                     autoClose: 3000,
//                                     hideProgressBar: false,
//                                     closeOnClick: true,
//                                     pauseOnHover: true,
//                                     draggable: true,
//                                     progress: undefined,
//                                     theme: 'light',
//                                 });
//                                 setShowPlanModel(false);
//                             }
//                         }).catch((err) => {
//                             console.log(err);
//                         })
//                     }
//                 },
//                 prefill: {
//                     name: getMember?.firstname,
//                     contact: getMember?.mobile
//                 },
//                 theme: {
//                     color: '#3399cc'
//                 },
//             };

//             const paymentObject = new window.Razorpay(options);
//             paymentObject.open();
//             paymentObject.on('payment.failed', function(response) {
//                 alert(response.error.code);
//                 alert(response.error.description);
//                 alert(response.error.source);
//                 alert(response.error.step);
//                 alert(response.error.reason);
//                 alert(response.error.metadata.order_id);
//                 alert(response.error.metadata.payment_id);
//             });
//         }
//         // if(success_state.order_id) {
//         //     const suc_data = {...success_state}
//         //     const suc =  await axios.post(`${process.env.REACT_APP_URL}update_payment`, suc_data);
//         //     console.log(suc);
//         //     console.log(suc_data);
//         // }
        
//     }

//   return (
//     <>
//     <Modal show={showPlanModel} size='lg'>
//         <Modal.Header>
//             <Modal.Title>Plan Details</Modal.Title>
//             <button type="button" data-bs-dismiss="modal" className="btn-close" onClick={() => setShowPlanModel(false)}></button>
//         </Modal.Header>
//         <Modal.Body>
//             <form onSubmit={handleSubmit}>
//                 <div id='tab1' className='tab'>
//                     <div className='row'>
//                         <div className='col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12'>
//                             <div className='mb-3'>
//                                 <label htmlFor="membershipPlan" className='form-label'>Membership Plan:</label>
//                                 <select className='form-select' name='membershipplan' value={memberModel?.membershipplan} onChange={handleInput} required>
//                                     <option value="">Select Plan</option>
//                                     {
//                                         subscriberList?.map((curVal, i) => {
//                                             return (
//                                                 <option key={i} value={curVal.id}>{curVal.duration} {curVal.type}</option>
//                                             )
//                                         })
//                                     }
//                                 </select>
//                             </div>
//                         </div>
//                         <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
//                             <div className="mb-3">
//                                 <label htmlFor="membershipFees" className='form-label'>Membership Fees:</label>
//                                 <input type="text" className='form-control' name='membershipfees' value={planPrice} onChange={handleInput} required readOnly />
//                                 {/* <select name="membershipfees" className='form-select' value={memberModel?.membershipfees} onChange={handleInput} required>
//                                     <option defaultValue={''}>Select</option>
//                                     <option value={planPrice}>{planPrice}</option>
//                                 </select> */}
//                             </div>
//                         </div>
//                         <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
//                             <div className="mb-3">
//                                 <label htmlFor="planStart" className='form-label'>Plan Start: </label>
//                                 {
//                                     memberDetails !== null ?
//                                     <>
//                                         <input type="date" className='form-control' name='planstart' value={memberModel?.planstart} min={disableNewPastDate()} onChange={handleInput} required />
//                                     </>
//                                     :
//                                     <>
//                                         <input type="date" className='form-control' name='planstart' value={memberModel?.planstart} min={disablePastDate()} onChange={handleInput} required />
//                                     </>
//                                 }
//                             </div>
//                         </div>
//                     </div>
//                 </div>

//                 <div id='tab2' className='tab hidden'>
//                     <div className='row'>
//                         <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
//                             <div className='mb-3'>
//                                 <label htmlFor="addonType" className='form-label'>Addon Type</label>
//                                 <select name="addonTrainerType" className='form-select' value={memberModel?.addonTrainerType} onChange={handleInput}>
//                                     <option value="">Select</option>
//                                     <option value="Trainer">Trainer</option>
//                                 </select>
//                             </div>
//                         </div>
//                     <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
//                         <div className="mb-3">
//                             <label htmlFor="experienceTrainer" className='form-label'>Trainer: </label>
//                             <select className='form-select' name='trainer' value={memberModel?.trainer} onChange={handleInput}>
//                                 <option defaultValue={0}>Select Trainer</option>
//                                 {
//                                     trainerList?.map((curVal, i) => {
//                                         return (
//                                             <option key={i} value={curVal.id}>{curVal.firstname} {curVal.lastname}</option>
//                                         )
//                                     })
//                                 }
//                             </select>
//                         </div>
//                     </div>
//                     <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
//                         <div className="mb-3">
//                             <label htmlFor="ptTrainer" className='form-label'>Trainer PT: </label>
//                             <select name='trainerPT' className='form-select' value={memberModel?.trainerPT} onChange={handleInput}>
//                                 <option defaultValue={''}>Select</option>
//                                 <option value="Alternate">Alternate PT</option>
//                                 <option value="Regular">Daily PT</option>
//                             </select>
//                         </div>
//                     </div>
//                     <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
//                         <div className="mb-3">
//                             <label htmlFor="durationTrainer" className='form-label'>Trainer Duration: </label>
//                             <select name='trainerduration' className='form-select' value={memberModel?.trainerduration} onChange={handleInput}>
//                                 <option defaultValue={''}>Select</option>
//                                 <option value={30}>30 Days</option>
//                                 <option value={60}>60 Days</option>
//                                 <option value={90}>90 Days</option>
//                                 <option value={180}>180 Days</option>
//                                 <option value={365}>365 Days</option>
//                             </select>
//                         </div>
//                     </div>

//                     <div className='col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12'>
//                         <div className='mb-3'>
//                             <label htmlFor="trainerFees" className='form-label'>Trainer Fees:</label>
//                             <input
//                                 type="text"
//                                 name="trainerfees"
//                                 className='form-control'
//                                 value={aPTPrice}
//                                 onChange={handleInput}
//                                 readOnly
//                             />
//                         </div>
//                     </div>
//                     <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
//                         <div className="mb-3">
//                             <label htmlFor="planStartTrainer" className='form-label'>Trainer Plan Start: </label>
//                             <input type="date" className='form-control' name='planstartTrainer' value={memberModel?.planstartTrainer} min={disablePastDate()} onChange={handleInput} />
//                         </div>
//                     </div>
//                 </div>
//             </div>



                // <div id='tab3' className='tab hidden'>
                //     <div className='row'>
                //         <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                //             <div className='mb-3'>
                //                 <label htmlFor="addonType" className='form-label'>Addon Type</label>
                //                 <select name="addonLockerType" className='form-select' value={memberModel?.addonLockerType} onChange={handleInput}>
                //                     <option value="">Select</option>
                //                     <option value="Locker">Locker</option>
                //                 </select>
                //             </div>
                //         </div>
                //         <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                //             <div className="mb-3">
                //                 <label htmlFor="lockerNo" className='form-label'>Locker: </label>
                //                 <select className='form-select' name='locker' value={memberModel?.locker} onChange={handleInput}>
                //                 <option defaultValue={''}>Select</option>
                //                 {lockerList?.map((curVal, i) => {
                //                     return (
                //                         curVal?.assign === 0 ? (
                //                             <option
                //                                 key={i}
                //                                 value={curVal?.id}
                //                                 data-id={curVal?.id} 
                //                             >
                //                                 {curVal?.locker_no}
                //                             </option>
                //                         ) : (
                //                             null
                //                         )
                //                     );
                //                 })}
                //             </select>
                //             </div>
                //         </div>
                //         <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                //             <div className="mb-3">
                //                 <label htmlFor="lockerDuration" className='form-label'>Locker Duration:</label>
                //                 <select name='lockerduration' className='form-select' value={memberModel?.lockerduration} onChange={handleInput}>
                //                     <option defaultValue={''}>Select</option>
                //                     <option value={30}>30 Days</option>
                //                     <option value={60}>60 Days</option>
                //                     <option value={90}>90 Days</option>
                //                     <option value={180}>180 Days</option>
                //                     <option value={365}>365 Days</option>
                //                 </select>
                //             </div>
                //         </div>
                //         <div className='col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12'>
                //             <div className='mb-3'>
                //                 <label htmlFor="lockerPrice" className='form-label'>Locker Price:</label>
                //                 <input type="text" name="lockerPrice" className='form-control' value={Math.floor(lockerPrice)} onChange={handleInput} readOnly />
                //                 {/* <select name="lockerPrice" className='form-select' value={memberModel?.lockerPrice} onChange={handleInput}>
                //                     <option defaultValue={''}>Select</option>
                //                     <option value={Math.floor(lockerPrice)}>{Math.floor(lockerPrice)}</option>
                //                 </select> */}
                //             </div>
                //         </div>
                //         <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                //             <div className="mb-3">
                //                 <label htmlFor="planStartLocker" className='form-label'>Locker Plan Start: </label>
                //                 <input type="date" className='form-control' name='planstartLocker' value={memberModel?.planstartLocker} min={disablePastDate()} onChange={handleInput} />
                //             </div>
                //         </div>
                //     </div>
                // </div>

//                 <>
//                 {
//                     console.log(addonName)
//                 }
//                 </>
//                 <div id='tab4' className={`tab ${step === 'tab4' ? '' : 'hidden'}`}>  
//                     <div className='row'>
//                     <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
//                         <div className='mb-3'>
//                             <label htmlFor="addonType" className='form-label'>Addon Type</label>: Boxing
//                             <select name="addonBoxingType" className='form-select' value={memberModel?.addonBoxingType} onChange={handleInput}>
//                                 <option value="">Select</option>
//                                 <option value="Boxing">Boxing</option> {/* Manually add "Boxing" as an option */}
//                                 {
//                                     addonPrice?.Boxing?.map((curVal, i) => (
//                                         <option key={i} value={curVal.id}>{curVal.name}</option>
//                                     ))
//                                 }
//                             </select>
//                         </div>
//                     </div>
//                         <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
//                             <div className="mb-3">
//                             <label htmlFor="addonDuration" className='form-label'>Addon Duration:</label>
//                             <select
//                             name='addonDuration'
//                             className='form-select'
//                             value={memberModel?.addonDuration} // Ensure this value is set correctly
//                             onChange={handleInput} // Create a new event handler for this dropdown
//                             >
//                                 <option value="">Select</option>
//                                 <option value="30">30 Days</option>
//                                 <option value="60">60 Days</option>
//                                 <option value="90">90 Days</option>
//                                 <option value="180">180 Days</option>
//                                 <option value="365">365 Days</option>
//                             </select>
//                             </div>
//                         </div>
//                         <div className='col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12'>
//                             <div className='mb-3'>
//                                 <label htmlFor="addonPrice" className='form-label'>Addon Price:</label>
//                                 <input
//                                     type="text"
//                                     name="addonPrice"
//                                     className='form-control'
//                                     value={boxingPrice}
//                                     readOnly
//                                 />
//                             </div>
//                         </div>
//                         <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
//                             <div className="mb-3">
//                                 <label htmlFor="planStart" className='form-label'>Plan Start: </label>
//                                 {
//                                     memberDetails !== null ?
//                                     <>
//                                         <input type="date" className='form-control' name='boxingPlanstart' value={memberModel?.boxingPlanstart} min={disableNewPastDate()} onChange={handleInput} />
//                                     </>
//                                     :
//                                     <>
//                                         <input type="date" className='form-control' name='boxingPlanstart' value={memberModel?.boxingPlanstart} min={disablePastDate()} onChange={handleInput} />
//                                     </>
//                                 }
//                             </div>
//                         </div>  
//                         {/* Add other fields related to the Boxing addon here */}
//                     </div>
//                 </div>

//                 <div id='tab5' className={`tab ${step === 'tab5' ? '' : 'hidden'}`}>
//                     <div className='row'>
//                     <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
//                         <div className='mb-3'>
//                             <label htmlFor="addonType" className='form-label'>Addon Type</label>: Dance
//                             <select name="addonDanceType" className='form-select' value={memberModel?.addonDanceType} onChange={handleInput}>
//                                 <option value="">Select</option>
//                                 <option value="Dance">Dance</option> {/* Manually add "Boxing" as an option */}
//                                 {
//                                     addonPrice?.Dance?.map((curVal, i) => (
//                                         <option key={i} value={curVal.id}>{curVal.name}</option>
//                                     ))
//                                 }
//                             </select>
//                         </div>
//                     </div>
//                         <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
//                             <div className="mb-3">
//                             <label htmlFor="addonDanceDuration" className='form-label'>Addon Duration:</label>
//                             <select
//                             name='addonDanceDuration'
//                             className='form-select'
//                             value={memberModel?.addonDanceDuration} // Ensure this value is set correctly
//                             onChange={handleInput} // Create a new event handler for this dropdown
//                             >
//                                 <option value="">Select</option>
//                                 <option value="30">30 Days</option>
//                                 <option value="60">60 Days</option>
//                                 <option value="90">90 Days</option>
//                                 <option value="180">180 Days</option>
//                                 <option value="365">365 Days</option>
//                             </select>
//                             </div>
//                         </div>
//                         <div className='col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12'>
//                             <div className='mb-3'>
//                                 <label htmlFor="addonPrice" className='form-label'>Addon Price:</label>
//                                 <input
//                                 type="text"
//                                 name="addonPrice"
//                                 className='form-control'
//                                 value={DancePrice}
//                                 readOnly
//                                 />
//                             </div>
//                         </div> 
//                         <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
//                             <div className="mb-3">
//                                 <label htmlFor="planStart" className='form-label'>Plan Start: </label>
//                                 {
//                                     memberDetails !== null ?
//                                     <>
//                                         <input type="date" className='form-control' name='dancePlanstart' value={memberModel?.dancePlanstart} min={disableNewPastDate()} onChange={handleInput}  />
//                                     </>
//                                     :
//                                     <>
//                                         <input type="date" className='form-control' name='dancePlanstart' value={memberModel?.dancePlanstart} min={disablePastDate()} onChange={handleInput}  />
//                                     </>
//                                 }
//                             </div>
//                         </div> 
//                         {/* Add other fields related to the Boxing addon here */}
//                     </div>
//                 </div>
                
//                 <div id='tab6' className={`tab ${step === 'tab6' ? '' : 'hidden'}`}>
//                     <div className='row'>
//                     <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
//                         <div className='mb-3'>
//                             <label htmlFor="addonType" className='form-label'>Addon Type</label>: yoga
//                             <select name="addonYogaType" className='form-select' value={memberModel?.addonYogaType} onChange={handleInput}>
//                                 <option value="">Select</option>
//                                 <option value="Yoga">Yoga</option> {/* Manually add "Boxing" as an option */}
//                                 {
//                                     addonPrice?.Yoga?.map((curVal, i) => (
//                                         <option key={i} value={curVal.id}>{curVal.name}</option>
//                                     ))
//                                 }
//                             </select>
//                         </div>
//                     </div>
//                         <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
//                             <div className="mb-3">
//                             <label htmlFor="addonYogaDuration" className='form-label'>Addon Duration:</label>
//                             <select
//                             name='addonYogaDuration'
//                             className='form-select'
//                             value={memberModel?.addonYogaDuration} // Ensure this value is set correctly
//                             onChange={handleInput} // Create a new event handler for this dropdown
//                             >
//                                 <option value="">Select</option>
//                                 <option value="30">30 Days</option>
//                                 <option value="60">60 Days</option>
//                                 <option value="90">90 Days</option>
//                                 <option value="180">180 Days</option>
//                                 <option value="365">365 Days</option>
//                             </select>
//                             </div>
//                         </div>
//                         <div className='col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12'>
//                             <div className='mb-3'>
//                                 <label htmlFor="addonPrice" className='form-label'>Addon Price:</label>
//                                 <input
//                                 type="text"
//                                 name="addonPrice"
//                                 className='form-control'
//                                 value={yogaPrice}
//                                 readOnly
//                                 />
//                             </div>
//                         </div>
//                         <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
//                             <div className="mb-3">
//                                 <label htmlFor="planStart" className='form-label'>Plan Start: </label>
//                                 {
//                                     memberDetails !== null ?
//                                     <>
//                                         <input type="date" className='form-control' name='yogaPlanstart' value={memberModel?.yogaPlanstart} min={disableNewPastDate()} onChange={handleInput}  />
//                                     </>
//                                     :
//                                     <>
//                                         <input type="date" className='form-control' name='yogaPlanstart' value={memberModel?.yogaPlanstart} min={disablePastDate()} onChange={handleInput}  />
//                                     </>
//                                 }
//                             </div>
//                         </div>  
//                         {/* Add other fields related to the Boxing addon here */}
//                     </div>
//                 </div>

//                 <div id='tab7' className={`tab ${step === 'tab7' ? '' : 'hidden'}`}>
//                     <div className='row'>
//                     <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
//                         <div className='mb-3'>
//                             <label htmlFor="addonType" className='form-label'>Addon Type</label>: Steam
//                             <select name="addonSteamType" className='form-select' value={memberModel?.addonSteamType} onChange={handleInput}>
//                                 <option value="">Select</option>
//                                 <option value="Steam">Steam</option> {/* Manually add "Boxing" as an option */}
//                                 {
//                                     addonPrice?.Steam?.map((curVal, i) => (
//                                         <option key={i} value={curVal.id}>{curVal.name}</option>
//                                     ))
//                                 }
//                             </select>
//                         </div>
//                     </div>
//                         <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
//                             <div className="mb-3">
//                             <label htmlFor="addonSteamDuration" className='form-label'>Addon Duration:</label>
//                             <select
//                             name='addonSteamDuration'
//                             className='form-select'
//                             value={memberModel?.addonSteamDuration} // Ensure this value is set correctly
//                             onChange={handleInput} // Create a new event handler for this dropdown
//                             >
//                                 <option value="">Select</option>
//                                 <option value="30">30 Days</option>
//                                 <option value="60">60 Days</option>
//                                 <option value="90">90 Days</option>
//                                 <option value="180">180 Days</option>
//                                 <option value="365">365 Days</option>
//                             </select>
//                             </div>
//                         </div>
//                         <div className='col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12'>
//                             <div className='mb-3'>
//                                 <label htmlFor="addonPrice" className='form-label'>Addon Price:</label>
//                                 <input
//                                 type="text"
//                                 name="addonPrice"
//                                 className='form-control'
//                                 value={steamPrice}
//                                 readOnly
//                                 />
//                             </div>
//                         </div>
//                         <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
//                             <div className="mb-3">
//                                 <label htmlFor="planStart" className='form-label'>Plan Start: </label>
//                                 {
//                                     memberDetails !== null ?
//                                     <>
//                                         <input type="date" className='form-control' name='steamPlanstart' value={memberModel?.steamPlanstart} min={disableNewPastDate()} onChange={handleInput}  />
//                                     </>
//                                     :
//                                     <>
//                                         <input type="date" className='form-control' name='steamPlanstart' value={memberModel?.steamPlanstart} min={disablePastDate()} onChange={handleInput}  />
//                                     </>
//                                 }
//                             </div>
//                         </div>  
//                         {/* Add other fields related to the Boxing addon here */}
//                     </div>
//                 </div>

//                 <div id='tab8' className={`tab ${step === 'tab8' ? '' : 'hidden'}`}>
//                     <div className='row'>
//                     <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
//                         <div className='mb-3'>
//                             <label htmlFor="addonType" className='form-label'>Addon Type</label>: Cycling
//                             <select name="addonCyclingType" className='form-select' value={memberModel?.addonCyclingType} onChange={handleInput}>
//                                 <option value="">Select</option>
//                                 <option value="Cycling">Cycling</option> {/* Manually add "Boxing" as an option */}
//                                 {
//                                     addonPrice?.Cycling?.map((curVal, i) => (
//                                         <option key={i} value={curVal.id}>{curVal.name}</option>
//                                     ))
//                                 }
//                             </select>
//                         </div>
//                     </div>
//                         <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
//                             <div className="mb-3">
//                             <label htmlFor="addonCyclingDuration" className='form-label'>Addon Duration:</label>
//                             <select
//                             name='addonCyclingDuration'
//                             className='form-select'
//                             value={memberModel?.addonCyclingDuration} // Ensure this value is set correctly
//                             onChange={handleInput} // Create a new event handler for this dropdown
//                             >
//                                 <option value="">Select</option>
//                                 <option value="30">30 Days</option>
//                                 <option value="60">60 Days</option>
//                                 <option value="90">90 Days</option>
//                                 <option value="180">180 Days</option>
//                                 <option value="365">365 Days</option>
//                             </select>
//                             </div>
//                         </div>
//                         <div className='col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12'>
//                             <div className='mb-3'>
//                                 <label htmlFor="addonPrice" className='form-label'>Addon Price:</label>
//                                 <input
//                                     type="text"
//                                     name="addonPrice"
//                                     className='form-control'
//                                     value={cyclingPrice}
//                                     readOnly
//                                 />
//                             </div>
//                         </div>
//                         <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
//                             <div className="mb-3">
//                                 <label htmlFor="planStart" className='form-label'>Plan Start: </label>
//                                 {
//                                     memberDetails !== null ?
//                                     <>
//                                         <input type="date" className='form-control' name='cyclingPlanstart' value={memberModel?.cyclingPlanstart} min={disableNewPastDate()} onChange={handleInput}  />
//                                     </>
//                                     :
//                                     <>
//                                         <input type="date" className='form-control' name='cyclingPlanstart' value={memberModel?.cyclingPlanstart} min={disablePastDate()} onChange={handleInput} />
//                                     </>
//                                 }
//                             </div>
//                         </div>  
//                     </div>
//                 </div>

//                 <div id='tab9' className={`tab ${step === 'tab9' ? '' : 'hidden'}`}>
//                     <div className='row'>
//                     <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
//                         <div className='mb-3'>
//                             <label htmlFor="addonType" className='form-label'>Addon Type</label>: Functional Training
//                             <select name="addonFunctional_TrainingType" className='form-select' value={memberModel?.addonFunctional_TrainingType} onChange={handleInput}>
//                                 <option value="">Select</option>
//                                 <option value="Functional_Training">Functional_Training</option> {/* Manually add "Boxing" as an option */}
//                                 {
//                                     addonPrice?.Functional_Training?.map((curVal, i) => (
//                                         <option key={i} value={curVal.id}>{curVal.name}</option>
//                                     ))
//                                 }
//                             </select>
//                         </div>
//                     </div>
//                         <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
//                             <div className="mb-3">
//                             <label htmlFor="addonFunctional_TrainingDuration" className='form-label'>Addon Duration:</label>
//                             <select
//                             name='addonFunctional_TrainingDuration'
//                             className='form-select'
//                             value={memberModel?.addonFunctional_TrainingDuration} // Ensure this value is set correctly
//                             onChange={handleInput} // Create a new event handler for this dropdown
//                             >
//                                 <option value="">Select</option>
//                                 <option value="30">30 Days</option>
//                                 <option value="60">60 Days</option>
//                                 <option value="90">90 Days</option>
//                                 <option value="180">180 Days</option>
//                                 <option value="365">365 Days</option>
//                             </select>
//                             </div>
//                         </div>
//                         <div className='col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12'>
//                             <div className='mb-3'>
//                                 <label htmlFor="addonPrice" className='form-label'>Addon Price:</label>
//                                 <input
//                                     type="text"
//                                     name="addonPrice"
//                                     className='form-control'
//                                     value={functional_Training}
//                                     readOnly
//                                 />
//                             </div>
//                         </div>
//                         <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
//                             <div className="mb-3">
//                                 <label htmlFor="planStart" className='form-label'>Plan Start: </label>
//                                 {
//                                     memberDetails !== null ?
//                                     <>
//                                         <input type="date" className='form-control' name='functional_TrainingPlanstart' value={memberModel?.functional_TrainingPlanstart} min={disableNewPastDate()} onChange={handleInput} />
//                                     </>
//                                     :
//                                     <>
//                                         <input type="date" className='form-control' name='functional_TrainingPlanstart' value={memberModel?.functional_TrainingPlanstart} min={disablePastDate()} onChange={handleInput} />
//                                     </>
//                                 }
//                             </div>
//                         </div>  
//                     </div>
//                 </div>

//                 <div id='tab10' className={`tab ${step === 'tab10' ? '' : 'hidden'}`}>
//                     <div className='row'>
//                     <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
//                         <div className='mb-3'>
//                             <label htmlFor="addonType" className='form-label'>Addon Type</label>: Zumba
//                             <select name="addonZumbaType" className='form-select' value={memberModel?.addonZumbaType} onChange={handleInput}>
//                                 <option value="">Select</option>
//                                 <option value="Zumba">Zumba</option>
//                                 {
//                                     addonPrice?.Zumba?.map((curVal, i) => (
//                                         <option key={i} value={curVal.id}>{curVal.name}</option>
//                                     ))
//                                 }
//                             </select>
//                         </div>
//                     </div>
//                         <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
//                             <div className="mb-3">
//                             <label htmlFor="addonZumbaDuration" className='form-label'>Addon Duration:</label>
//                             <select
//                             name='addonZumbaDuration'
//                             className='form-select'
//                             value={memberModel?.addonZumbaDuration}
//                             onChange={handleInput} 
//                             >
//                                 <option value="">Select</option>
//                                 <option value="30">30 Days</option>
//                                 <option value="60">60 Days</option>
//                                 <option value="90">90 Days</option>
//                                 <option value="180">180 Days</option>
//                                 <option value="365">365 Days</option>
//                             </select>
//                             </div>
//                         </div>
//                         <div className='col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12'>
//                             <div className='mb-3'>
//                                 <label htmlFor="addonPrice" className='form-label'>Addon Price:</label>
//                                 <input
//                                     type="text"
//                                     name="addonPrice"
//                                     className='form-control'
//                                     value={zumbaPrice}
//                                     readOnly
//                                 />
//                             </div>
//                         </div>
//                         <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
//                             <div className="mb-3">
//                                 <label htmlFor="planStart" className='form-label'>Plan Start: </label>
//                                 {
//                                     memberDetails !== null ?
//                                     <>
//                                         <input type="date" className='form-control' name='zumbaPlanstart' value={memberModel?.zumbaPlanstart} min={disableNewPastDate()} onChange={handleInput} />
//                                     </>
//                                     :
//                                     <>
//                                         <input type="date" className='form-control' name='zumbaPlanstart' value={memberModel?.zumbaPlanstart} min={disablePastDate()} onChange={handleInput}  />
//                                     </>
//                                 }
//                             </div>
//                         </div>  
//                     </div>
//                 </div>

//                 <div id='tab11' className={`tab ${step === 'tab11' ? '' : 'hidden'}`}>
//                     <div className='row'>
//                     <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
//                         <div className='mb-3'>
//                             <label htmlFor="addonType" className='form-label'>Addon Type</label>: Crossfit
//                             <select name="addonCrossfitType" className='form-select' value={memberModel?.addonCrossfitType} onChange={handleInput}>
//                                 <option value="">Select</option>
//                                 <option value="Crossfit">Crossfit</option> {/* Manually add "Zumba" as an option */}
//                                 {
//                                     addonPrice?.Crossfit?.map((curVal, i) => (
//                                         <option key={i} value={curVal.id}>{curVal.name}</option>
//                                     ))
//                                 }
//                             </select>
//                         </div>
//                     </div>
//                         <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
//                             <div className="mb-3">
//                             <label htmlFor="addonCrossfitDuration" className='form-label'>Addon Duration:</label>
//                             <select
//                             name='addonCrossfitDuration'
//                             className='form-select'
//                             value={memberModel?.addonCrossfitDuration} // Ensure this value is set correctly
//                             onChange={handleInput} // Create a new event handler for this dropdown
//                             >
//                                 <option value="">Select</option>
//                                 <option value="30">30 Days</option>
//                                 <option value="60">60 Days</option>
//                                 <option value="90">90 Days</option>
//                                 <option value="180">180 Days</option>
//                                 <option value="365">365 Days</option>
//                             </select>
//                             </div>
//                         </div>
//                         <div className='col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12'>
//                             <div className='mb-3'>
//                                 <label htmlFor="addonPrice" className='form-label'>Addon Price:</label>
//                                 <input
//                                     type="text"
//                                     name="addonPrice"
//                                     className='form-control'
//                                     value={crossfitPrice}
//                                     readOnly
//                                 />
//                             </div>
//                         </div>
//                         <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
//                             <div className="mb-3">
//                                 <label htmlFor="planStart" className='form-label'>Plan Start: </label>
//                                 {
//                                     memberDetails !== null ?
//                                     <>
//                                         <input type="date" className='form-control' name='crossfitPlanstart' value={memberModel?.crossfitPlanstart} min={disableNewPastDate()} onChange={handleInput}  />
//                                     </>
//                                     :
//                                     <>
//                                         <input type="date" className='form-control' name='crossfitPlanstart' value={memberModel?.crossfitPlanstart} min={disablePastDate()} onChange={handleInput}  />
//                                     </>
//                                 }
//                             </div>
//                         </div>  
//                     </div>
//                 </div>

//                 <div id='tab12' className={`tab ${step === 'tab12' ? '' : 'hidden'}`}>
//                     <div className='row'>
//                     <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
//                         <div className='mb-3'>
//                             <label htmlFor="addonType" className='form-label'>Addon Type</label>: Massage
//                             <select name="addonMassageType" className='form-select' value={memberModel?.addonMassageType} onChange={handleInput}>
//                                 <option value="">Select</option>
//                                 <option value="Massage">Massage</option> {/* Manually add "Zumba" as an option */}
//                                 {
//                                     addonPrice?.Massage?.map((curVal, i) => (
//                                         <option key={i} value={curVal.id}>{curVal.name}</option>
//                                     ))
//                                 }
//                             </select>
//                         </div>
//                     </div>
//                         <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
//                             <div className="mb-3">
//                             <label htmlFor="addonMassageDuration" className='form-label'>Addon Duration:</label>
//                             <select
//                             name='addonMassageDuration'
//                             className='form-select'
//                             value={memberModel?.addonMassageDuration} // Ensure this value is set correctly
//                             onChange={handleInput} // Create a new event handler for this dropdown
//                             >
//                                 <option value="">Select</option>
//                                 <option value="30">30 Days</option>
//                                 <option value="60">60 Days</option>
//                                 <option value="90">90 Days</option>
//                                 <option value="180">180 Days</option>
//                                 <option value="365">365 Days</option>
//                             </select>
//                             </div>
//                         </div>
//                         <div className='col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12'>
//                             <div className='mb-3'>
//                                 <label htmlFor="addonPrice" className='form-label'>Addon Price:</label>
//                                 <input
//                                     type="text"
//                                     name="addonPrice"
//                                     className='form-control'
//                                     value={massagePrice}
//                                     readOnly
//                                 />
//                             </div>
//                         </div>
//                         <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
//                             <div className="mb-3">
//                                 <label htmlFor="planStart" className='form-label'>Plan Start: </label>
//                                 {
//                                     memberDetails !== null ?
//                                     <>
//                                         <input type="date" className='form-control' name='massagePlanstart' value={memberModel?.massagePlanstart} min={disableNewPastDate()} onChange={handleInput}  />
//                                     </>
//                                     :
//                                     <>
//                                         <input type="date" className='form-control' name='massagePlanstart' value={memberModel?.massagePlanstart} min={disablePastDate()} onChange={handleInput} />
//                                     </>
//                                 }
//                             </div>
//                         </div>  
//                     </div>
//                 </div>

//                 <div id='tab13' className={`tab ${step === 'tab13' ? '' : 'hidden'}`}>
//                     <div className='row'>
//                     <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
//                         <div className='mb-3'>
//                             <label htmlFor="addonType" className='form-label'>Addon Type</label>: Nutrition_Consulting
//                             <select name="addonNutrition_ConsultingType" className='form-select' value={memberModel?.addonNutrition_ConsultingType} onChange={handleInput}>
//                                 <option value="">Select</option>
//                                 <option value="Nutrition_Consulting">Nutrition_Consulting</option> {/* Manually add "Zumba" as an option */}
//                                 {
//                                     addonPrice?.Nutrition_Consulting?.map((curVal, i) => (
//                                         <option key={i} value={curVal.id}>{curVal.name}</option>
//                                     ))
//                                 }
//                             </select>
//                         </div>
//                     </div>
//                         <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
//                             <div className="mb-3">
//                             <label htmlFor="addonNutrition_ConsultingDuration" className='form-label'>Addon Duration:</label>
//                             <select
//                             name='addonNutrition_ConsultingDuration'
//                             className='form-select'
//                             value={memberModel?.addonNutrition_ConsultingDuration} // Ensure this value is set correctly
//                             onChange={handleInput} // Create a new event handler for this dropdown
//                             >
//                                 <option value="">Select</option>
//                                 <option value="30">30 Days</option>
//                                 <option value="60">60 Days</option>
//                                 <option value="90">90 Days</option>
//                                 <option value="180">180 Days</option>
//                                 <option value="365">365 Days</option>
//                             </select>
//                             </div>
//                         </div>
//                         <div className='col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12'>
//                             <div className='mb-3'>
//                                 <label htmlFor="addonPrice" className='form-label'>Addon Price:</label>
//                                 <input
//                                     type="text"
//                                     name="addonPrice"
//                                     className='form-control'
//                                     value={ncPrice}
//                                     readOnly
//                                 />
//                             </div>
//                         </div>
//                         <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
//                             <div className="mb-3">
//                                 <label htmlFor="planStart" className='form-label'>Plan Start: </label>
//                                 {
//                                     memberDetails !== null ?
//                                     <>
//                                         <input type="date" className='form-control' name='nutrition_ConsultingPlanstart' value={memberModel?.nutrition_ConsultingPlanstart} min={disableNewPastDate()} onChange={handleInput}  />
//                                     </>
//                                     :
//                                     <>
//                                         <input type="date" className='form-control' name='nutrition_ConsultingPlanstart' value={memberModel?.nutrition_ConsultingPlanstart} min={disablePastDate()} onChange={handleInput}  />
//                                     </>
//                                 }
//                             </div>
//                         </div>  
//                     </div>
//                 </div>

//                 <div id='tab14' className={`tab ${step === 'tab14' ? '' : 'hidden'}`}>
//                     <div className='row'>
//                     <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
//                         <div className='mb-3'>
//                             <label htmlFor="addonType" className='form-label'>Addon Type</label>: Weight Training
//                             <select name="addonWeight_TrainingType" className='form-select' value={memberModel?.addonWeight_TrainingType} onChange={handleInput}>
//                                 <option value="">Select</option>
//                                 <option value="Weight_Training">Weight Training</option> {/* Manually add "Zumba" as an option */}
//                                 {
//                                     addonPrice?.Weight_Training?.map((curVal, i) => (
//                                         <option key={i} value={curVal.id}>{curVal.name}</option>
//                                     ))
//                                 }
//                             </select>
//                         </div>
//                     </div>
//                         <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
//                             <div className="mb-3">
//                             <label htmlFor="addonWeight_TrainingDuration" className='form-label'>Addon Duration:</label>
//                             <select
//                             name='addonWeight_TrainingDuration'
//                             className='form-select'
//                             value={memberModel?.addonWeight_TrainingDuration} // Ensure this value is set correctly
//                             onChange={handleInput} // Create a new event handler for this dropdown
//                             >
//                                 <option value="">Select</option>
//                                 <option value="30">30 Days</option>
//                                 <option value="60">60 Days</option>
//                                 <option value="90">90 Days</option>
//                                 <option value="180">180 Days</option>
//                                 <option value="365">365 Days</option>
//                             </select>
//                             </div>
//                         </div>
//                         <div className='col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12'>
//                             <div className='mb-3'>
//                                 <label htmlFor="addonPrice" className='form-label'>Addon Price:</label>
//                                 <input
//                                     type="text"
//                                     name="addonPrice"
//                                     className='form-control'
//                                     value={wtPrice}
//                                     readOnly
//                                 />
//                             </div>
//                         </div>
//                         <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
//                             <div className="mb-3">
//                                 <label htmlFor="planStart" className='form-label'>Plan Start: </label>
//                                 {
//                                     memberDetails !== null ?
//                                     <>
//                                         <input type="date" className='form-control' name='weight_TrainingPlanstart' value={memberModel?.weight_TrainingPlanstart} min={disableNewPastDate()} onChange={handleInput}  />
//                                     </>
//                                     :
//                                     <>
//                                         <input type="date" className='form-control' name='weight_TrainingPlanstart' value={memberModel?.weight_TrainingPlanstart} min={disablePastDate()} onChange={handleInput}  />
//                                     </>
//                                 }
//                             </div>
//                         </div>  
//                     </div>
//                 </div>

//                 <div id='tab15' className={`tab ${step === 'tab15' ? '' : 'hidden'}`}>
//                     <div className='row'>
//                     <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
//                         <div className='mb-3'>
//                             <label htmlFor="addonType" className='form-label'>Addon Type</label>: Hiit_Excercise_Classes
//                             <select name="addonHiit_Excercise_ClassesType" className='form-select' value={memberModel?.addonHiit_Excercise_ClassesType} onChange={handleInput}>
//                                 <option value="">Select</option>
//                                 <option value="Hiit_Excercise_Classes">Hiit Excercise Classes</option> {/* Manually add "Zumba" as an option */}
//                                 {
//                                     addonPrice?.Hiit_Excercise_Classes?.map((curVal, i) => (
//                                         <option key={i} value={curVal.id}>{curVal.name}</option>
//                                     ))
//                                 }
//                             </select>
//                         </div>
//                     </div>
//                         <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
//                             <div className="mb-3">
//                             <label htmlFor="addonHiit_Excercise_ClassesDuration" className='form-label'>Addon Duration:</label>
//                             <select
//                             name='addonHiit_Excercise_ClassesDuration'
//                             className='form-select'
//                             value={memberModel?.addonHiit_Excercise_ClassesDuration} // Ensure this value is set correctly
//                             onChange={handleInput} // Create a new event handler for this dropdown
//                             >
//                                 <option value="">Select</option>
//                                 <option value="30">30 Days</option>
//                                 <option value="60">60 Days</option>
//                                 <option value="90">90 Days</option>
//                                 <option value="180">180 Days</option>
//                                 <option value="365">365 Days</option>
//                             </select>
//                             </div>
//                         </div>
//                         <div className='col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12'>
//                             <div className='mb-3'>
//                                 <label htmlFor="addonPrice" className='form-label'>Addon Price:</label>
//                                 <input
//                                     type="text"
//                                     name="addonPrice"
//                                     className='form-control'
//                                     value={hecPrice}
//                                     readOnly
//                                 />
//                             </div>
//                         </div>
//                         <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
//                             <div className="mb-3">
//                                 <label htmlFor="planStart" className='form-label'>Plan Start: </label>
//                                 {
//                                     memberDetails !== null ?
//                                     <>
//                                         <input type="date" className='form-control' name='hiit_excercise_classesPlanstart' value={memberModel?.hiit_excercise_classesPlanstart} min={disableNewPastDate()} onChange={handleInput}  />
//                                     </>
//                                     :
//                                     <>
//                                         <input type="date" className='form-control' name='hiit_excercise_classesPlanstart' value={memberModel?.hiit_excercise_classesPlanstart} min={disablePastDate()} onChange={handleInput}  />
//                                     </>
//                                 }
//                             </div>
//                         </div>  
//                     </div>
//                 </div>

//                 <div id='tab16' className={`tab ${step === 'tab16' ? '' : 'hidden'}`}>
//                     <div className='row'>
//                     <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
//                         <div className='mb-3'>
//                             <label htmlFor="addonType" className='form-label'>Addon Type</label>: Fitness_Studio
//                             <select name="addonFitness_StudioType" className='form-select' value={memberModel?.addonFitness_StudioType} onChange={handleInput}>
//                                 <option value="">Select</option>
//                                 <option value="Fitness_Studio">Fitness_Studio</option> {/* Manually add "Zumba" as an option */}
//                                 {
//                                     addonPrice?.Fitness_Studio?.map((curVal, i) => (
//                                         <option key={i} value={curVal.id}>{curVal.name}</option>
//                                     ))
//                                 }
//                             </select>
//                         </div>
//                     </div>
//                         <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
//                             <div className="mb-3">
//                             <label htmlFor="addonFitness_StudioDuration" className='form-label'>Addon Duration:</label>
//                             <select
//                             name='addonFitness_StudioDuration'
//                             className='form-select'
//                             value={memberModel?.addonFitness_StudioDuration} // Ensure this value is set correctly
//                             onChange={handleInput} // Create a new event handler for this dropdown
//                             >
//                                 <option value="">Select</option>
//                                 <option value="30">30 Days</option>
//                                 <option value="60">60 Days</option>
//                                 <option value="90">90 Days</option>
//                                 <option value="180">180 Days</option>
//                                 <option value="365">365 Days</option>
//                             </select>
//                             </div>
//                         </div>
//                         <div className='col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12'>
//                             <div className='mb-3'>
//                                 <label htmlFor="addonPrice" className='form-label'>Addon Price:</label>
//                                 <input
//                                     type="text"
//                                     name="addonPrice"
//                                     className='form-control'
//                                     value={fsPrice}
//                                     readOnly
//                                 />
//                             </div>
//                         </div>
//                         <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
//                             <div className="mb-3">
//                                 <label htmlFor="planStart" className='form-label'>Plan Start: </label>
//                                 {
//                                     memberDetails !== null ?
//                                     <>
//                                         <input type="date" className='form-control' name='Fitness_StudioPlanstart' value={memberModel?.Fitness_StudioPlanstart} min={disableNewPastDate()} onChange={handleInput}  />
//                                     </>
//                                     :
//                                     <>
//                                         <input type="date" className='form-control' name='Fitness_StudioPlanstart' value={memberModel?.Fitness_StudioPlanstart} min={disablePastDate()} onChange={handleInput} />
//                                     </>
//                                 }
//                             </div>
//                         </div>  
//                     </div>
//                 </div>

//                 <div className='mb-3 d-flex gap-2'>
//                     <button type='button' className='btn btn-outline-danger float-start hidden' id='prev' onClick={() => {setStep(step-1)}}>Previous</button>
//                     <button type='button' className='btn btn-outline-warning float-start' id='next' onClick={() => {setStep(step+1)}}>Next</button>
//                 </div>   
//                 <div className="modal-footer">
//                     <button type="submit" className="btn btn-outline-primary">Submit</button>
//                 </div>
//             </form>
//         </Modal.Body>
//         <Modal.Footer>
//             <div className='d-flex flex-column' style={{lineHeight: '0.5'}}>
//                 <p className='text-danger font_18'>Your Order is</p>
//                 <p className='text-success fw-bold font_22'>Total Amount: &#8377;{totalPrice}</p>
//                 <div className='d-flex flex-row'>
//                     <p className='text-success'>Subscription Amount: &#8377;{planPrice}</p>
//                     <p className='text-success px-2'>Trainer Amount: &#8377;{aPTPrice}</p>
//                 </div>
//                 <div className='d-flex flex-row'>
//                     <p className='text-success'>Locker Amount: &#8377;{Math.floor(lockerPrice)}</p>
//                     {/* <p className='text-success px-2'>Upgrade Amount: &#8377;{Math.floor(upgradePrice)}</p> */}
//                 </div>
//                 <div className='d-flex flex-row mb-4'>
//                     <p className='text-success px-2'>Boxing: &#8377;{Math.floor(boxingPrice)}</p>
//                     <p className='text-success px-2'>Dance: &#8377;{Math.floor(DancePrice)}</p>
//                     <p className='text-success px-2'>Yoga: &#8377;{Math.floor(yogaPrice)}</p>
//                     <p className='text-success px-2'>Steam: &#8377;{Math.floor(steamPrice)}</p>
//                     <p className='text-success px-2'>Cycling: &#8377;{Math.floor(cyclingPrice)}</p>
//                     <p className='text-success px-2'>FT: &#8377;{Math.floor(functional_Training)}</p>
                    
//                 </div>
//             </div>
//         </Modal.Footer>
//     </Modal>
//     <ToastContainer />
//     </>
//   )
// }

// export default ActivatePlanModel;











// ------------------------------------------------------------------------------------------------------
// ------------------------------------------------------------------------------------------------------
// import React, { useEffect, useState } from 'react';
// import Modal from 'react-bootstrap/Modal';
// import axios from 'axios';
// import { useSelector } from 'react-redux';
// import { ToastContainer, toast } from 'react-toastify';

// function ActivatePlanModel({ showPlanModel, setShowPlanModel, memberDetails}) {
//     const getMember = useSelector(state => state.counterSlice.userDetails);
//     const [subscriberList, setSubscriberList] = useState([]);
//     // const [memberModel, setMemberModel] = useState();
//     // const [trainerList, setTrainerList] = useState([]);
//     const [lockerList, setLockerList] = useState([]);
//     const [priceList, setPriceList] = useState([]);
//     const [lockerPriceList, setLockerPriceList] = useState([]);
//     const [step, setStep] = useState(0);
//     const [memberModel, setMemberModel] = useState();

//     const [trainerList, setTrainerList] = useState([]);
//     const [data, setData] = useState([]); // Define the 'data' state variable
//     const [addonDuration, setAddonDuration] = useState(''); // State for selected duration
//     const [addonPrice, setAddonPrice] = useState([]); // State for addon price
//     const [danceAddonPrice, setDanceAddonPrice] = useState([]);
//     const [lockerAddonData, setLockerAddonData] = useState(null);
//     const [addonName, setAddonName] = useState([]);

//     // useEffect(() => {
//     //     // Fetch data from your API and set it to the 'data' state variable
//     //     async function fetchData() {
//     //         try {
//     //             const response = await axios.get(`${process.env.REACT_APP_URL}getAllTrainerByAdmin`, );
//     //             setData(response.data.data);
//     //         } catch (error) {
//     //             // Handle errors here
//     //             console.error('Error fetching data:', error);
//     //         }
//     //     }

//     //     fetchData(); // Call the fetchData function when the component mounts
//     // }, []);
//     // const [subscription, setSubscription] = useState([]);
//     const getGymId = useSelector(state=>state.counterSlice.gymId);
//     // console.log("the token is ", getGymId)
//     const deviceToken = getGymId.device_token;
//     // console.log(deviceToken);

//     const  = {
//         headers:{
//           "Authorization" : deviceToken,
//         }
//       };
    

//     // console.log(memberDetails);

//     const loadScript = (src) => {
//         return new Promise((resolve) => {
//             const script = document.createElement('script');
//             script.src = src;
//             script.onload = () => {
//                 resolve(true);
//             };
//             script.onerror = () => {
//                 resolve(false);
//             };
//             document.body.appendChild(script);
//         });
//     }

//     useEffect(() => {
//         fetchAllSubscriber();
//         fetchAllTrainer();
//         fetchLockers();
//         fetchAllPricing();
//         fetchBoxingAddonData();
//         fetchAddonName();
//     }, []);

//     // const fetchAllPricing = async () => {
//     //     const getAllPricing = await axios.get(`${process.env.REACT_APP_URL}pricing`, );
//     //     setPriceList(getAllPricing?.data);
//     // }
//     const fetchAllPricing = async (selectedTrainerId) => {
//         try {
//             // Construct the API URL with the selected trainer's ID
//             const pricingAPIUrl = `${process.env.REACT_APP_URL}pricing/${selectedTrainerId}`;
//             const getAllPricing = await axios.get(pricingAPIUrl, );
//             setPriceList(getAllPricing?.data);
//         } catch (error) {
//             // Handle errors if necessary
//             console.error("Error fetching pricing data:", error);
//         }
//     }

//     const fetchBoxingAddonData = async () => {
//         try {
//             const boxingPricingUrl = `${process.env.REACT_APP_URL}pricing_addons`;
//             const getBoxingAddonData = await axios.get(boxingPricingUrl, );
//             setAddonPrice(getBoxingAddonData?.data) 
//             // console.log("get bocxing k data", getBoxingAddonData.data.Zumba);
//         } catch (error) {
//             console.error("Error fetching Boxing Addon data: ", error);
//             return null;
//         }
//     };

//     const fetchAddonName = async () => {
//         try {
//             const addonNameUrl = `${process.env.REACT_APP_URL}getAllAddonsNameByGym`;
//             const getAddonName = await axios.get(addonNameUrl, );
//             setAddonName(getAddonName?.data) 
//             // console.log("names and id",setAddonName);
//         } catch (error) {
//             console.error("Error fetching Boxing Addon data: ", error);
//             return null;
//         }
//     };
      

//       const fetchLockerAddonData = async (lockerId) => {
//         try {
//             // Construct the API URL with the locker ID
//             const lockerAddonAPIUrl = `${process.env.REACT_APP_URL}pricing_lockers/${lockerId}`;
    
//             const response = await axios.get(lockerAddonAPIUrl, );
//             setLockerPriceList(response?.data) // Assuming the API response contains the locker addon pricing data
//         } catch (error) {
//             console.error("Error fetching Locker Addon data: ", error);
//             return null;
//         }
//     };
 
//     const fetchAllSubscriber = async () => {
//         const getAllSubscriber = await axios.get(`${process.env.REACT_APP_URL}GetAllSubscriberByAdmin`, );
//         setSubscriberList(getAllSubscriber?.data.data);
//     }

//     const fetchAllTrainer = async () => {
//         const allTrainers = await axios.get(`${process.env.REACT_APP_URL}getAllTrainerByAdmin`, );
//         setTrainerList(allTrainers?.data.data);
//     }

//     const fetchLockers = async () => {
//         const allLockers = await axios.get(`${process.env.REACT_APP_URL}getAllLocker`, );
//         setLockerList(allLockers?.data.data);
//     }

//     const handleInput = (e) => {
//         setMemberModel((pre) => ({ ...pre, [e.target.name]: e.target.value }))
//         const { name, value } = e.target;

//         if (name === 'trainer') {
//             // Make sure 'value' here contains the selected trainer's ID
//             console.log('Selected Trainer ID:', value);

//             // Fetch pricing data based on the selected trainer's ID
//             fetchAllPricing(value);
//         }

//         if (name === 'locker') {
//             // Make sure 'value' here contains the selected locker's ID
//             const lockerId = e.target.options[e.target.selectedIndex].getAttribute('data-id'); // Extract locker ID from selected option
//             console.log('Selected Locker ID:', lockerId);
    
//             // Fetch locker addon data based on the selected locker's ID
//             fetchLockerAddonData(lockerId).then((data) => {
//                 setLockerAddonData(data);
//             });
//         }

//         if (name === 'addonBoxingType') {
//             // Call the function to fetch the addon data with the selected addon's ID
//             fetchBoxingAddonData(value); // Assuming 'value' is the addon ID
//           }

//     }
    
//     let planPrice = 0;
//     let subPlan = 0;
//     subscriberList?.filter((curVal) => {
//         // eslint-disable-next-line
//         if(curVal.id == memberModel?.membershipplan) {
//             planPrice = curVal?.subsriberfees;
//             subPlan = curVal?.duration;
//         }
//         return { planPrice, subPlan };
//     });

//     // let trainerFee = 0;
//     // trainerList?.filter((curVal) => {
//     //     // eslint-disable-next-line
//     //     if(curVal.id == memberModel?.trainer) {
//     //         trainerFee = curVal?.trainerfees
//     //     }
//     //     return trainerFee;
//     // })

//     let aPTPrice = 0;
//     // eslint-disable-next-line
//     if(memberModel?.trainerPT == 'Alternate') {
//         priceList?.Alternate_PT?.filter((curVal) => {
//             // eslint-disable-next-line
//             if(memberModel?.trainerduration == 30) {
//                 aPTPrice = curVal?.monthly?.price;
//             }
//             // eslint-disable-next-line
//             if(memberModel?.trainerduration == 60) {
//                 aPTPrice = curVal?.secondly?.price;
//             }
//             // eslint-disable-next-line
//             if(memberModel?.trainerduration == 90) {
//                 aPTPrice = curVal?.quarterly?.price
//             }
//             // eslint-disable-next-line
//             if(memberModel?.trainerduration == 180) {
//                 aPTPrice = curVal?.half_yearly?.price
//             }
//             // eslint-disable-next-line
//             if(memberModel?.trainerduration == 365) {
//                 aPTPrice = curVal?.yearly?.price
//             }
//             return aPTPrice;
//         })
//     }

//     // eslint-disable-next-line
//     if(memberModel?.trainerPT == 'Regular') {
//         priceList?.Regular_PT?.filter((curVal) => {
//             // eslint-disable-next-line
//             if(memberModel?.trainerduration == 30) {
//                 aPTPrice = curVal?.monthly?.price
//                 // console.log("price ofr 30",aPTPrice);
//             }
//             // eslint-disable-next-line
//             if(memberModel?.trainerduration == 60) {
//                 aPTPrice = curVal?.secondly?.price
//             }
//             // eslint-disable-next-line
//             if(memberModel?.trainerduration == 90) {
//                 aPTPrice = curVal?.quarterly?.price
//             }
//             // eslint-disable-next-line
//             if(memberModel?.trainerduration == 180) {
//                 aPTPrice = curVal?.half_yearly?.price
//             }
//             // eslint-disable-next-line
//             if(memberModel?.trainerduration == 365) {
//                 aPTPrice = curVal?.yearly?.price
//             }
//             return aPTPrice;
//         })
//     }

//     let lockerPrice = 0;
//     lockerPriceList?.data?.filter((curVal) => {
//         // eslint-disable-next-line
//         // if(memberModel?.lockerduration == 15) {
//         //     lockerPrice = memberModel?.lockerduration * curVal?.monthly?.daily            
//         // }
//         // eslint-disable-next-line
//         if(memberModel?.lockerduration == 30) {
            
//             lockerPrice = curVal?.monthly?.price  
//             // console.log("dkjbdakc", lockerPrice);          
//         }
//         if(memberModel?.lockerduration == 60) {
            
//             lockerPrice = curVal?.secondly?.price  
//             console.log("dkjbdakc", lockerPrice);          
//         }
//         if(memberModel?.lockerduration == 90) {
//             lockerPrice = curVal?.quarterly?.price            
//         }
//         if(memberModel?.lockerduration == 180) {
//             lockerPrice = curVal?.half_yearly?.price            
//         }
//         if(memberModel?.lockerduration == 365) {
//             lockerPrice = curVal?.yearly?.price            
//         }
//         return lockerPrice;
//     })

//     // let upgradePrice = 0;
//     // priceList?.data?.filter((curVal) => {
//     //     // eslint-disable-next-line
//     //     // if(memberModel?.upgradeduration == 15) {
//     //     //     upgradePrice = memberModel?.upgradeduration * curVal?.monthly?.daily            
//     //     // }
//     //     // eslint-disable-next-line
//     //     if(memberModel?.upgradeduration == 30) {
//     //         upgradePrice = curVal?.monthly?.price   
//     //         // console.log("hello world");         
//     //     }
//     //     if(memberModel?.upgradeduration == 90) {
//     //         upgradePrice = curVal?.quarterly?.price            
//     //     }
//     //     if(memberModel?.upgradeduration == 180) {
//     //         upgradePrice = curVal?.half_yearly?.price            
//     //     }
//     //     if(memberModel?.upgradeduration == 365) {
//     //         upgradePrice = curVal?.yearly?.price            
//     //     }
//     //     return upgradePrice;
//     // })

//     // let boxingPrice = 0;
//     // let selectedBoxingId = null;
//     // addonPrice?.Boxing?.filter((curVal) => {
//     //     // eslint-disable-next-line
//     //     // if(memberModel?.upgradeduration == 15) {
//     //     //     upgradePrice = memberModel?.upgradeduration * curVal?.monthly?.daily            
//     //     // }
//     //     // eslint-disable-next-line
//     //     if(memberModel?.addonDuration == 30) {
//     //         boxingPrice = curVal?.monthly?.price;   
//     //         // console.log("hello world");       
//     //         selectedBoxingId = curVal.id;  
//     //         console.log("id is : ", selectedBoxingId);
//     //     }
//     //     if(memberModel?.addonDuration == 60) {
//     //         boxingPrice = curVal?.secondly?.price   
//     //         // console.log("hello world");     
//     //         selectedBoxingId = curVal.id;     
//     //     }
//     //     if(memberModel?.addonDuration == 90) {
//     //         boxingPrice = curVal?.quarterly?.price  
//     //         selectedBoxingId = curVal.id;           
//     //     }
//     //     if(memberModel?.addonDuration == 180) {
//     //         boxingPrice = curVal?.half_yearly?.price
//     //         selectedBoxingId = curVal.id;             
//     //     }
//     //     if(memberModel?.addonDuration == 365) {
//     //         boxingPrice = curVal?.yearly?.price  
//     //         selectedBoxingId = curVal.id;           
//     //     }
//     //     return boxingPrice;
//     // })

//     let boxingPrice = 0;
//     let selectedBoxingId = null;

//     addonPrice?.Boxing?.filter((curVal) => {
//         // eslint-disable-next-line
//         // if(memberModel?.upgradeduration == 15) {
//         //     upgradePrice = memberModel?.upgradeduration * curVal?.monthly?.daily            
//         // }
//         // eslint-disable-next-line
//         if(memberModel?.addonDuration == 30) {
//             boxingPrice = curVal?.monthly?.price;
//         }
//         if(memberModel?.addonDuration == 60) {
//             boxingPrice = curVal?.secondly?.price;
//         }
//         if(memberModel?.addonDuration == 90) {
//             boxingPrice = curVal?.quarterly?.price;
//         }
//         if(memberModel?.addonDuration == 180) {
//             boxingPrice = curVal?.half_yearly?.price;
//         }
//         if(memberModel?.addonDuration == 365) {
//             boxingPrice = curVal?.yearly?.price;
//         }
        
//         // Assign selectedBoxingId outside of if conditions
//         selectedBoxingId = curVal.id;
//         // console.log("selected id is:", selectedBoxingId);

//         return boxingPrice;
//     });

//     let DancePrice = 0;
//     let selectedDanceId = null;

//     addonPrice?.Dance?.filter((curVal) => {
//         // eslint-disable-next-line
//         // if(memberModel?.upgradeduration == 15) {
//         //     upgradePrice = memberModel?.upgradeduration * curVal?.monthly?.daily            
//         // }
//         // eslint-disable-next-line
//         if(memberModel?.addonDanceDuration == 30) {
//             DancePrice = curVal?.monthly?.price   
//             // console.log("hello world");         
//         }
//         if(memberModel?.addonDanceDuration == 60) {
//             DancePrice = curVal?.secondly?.price   
//             // console.log("hello world");         
//         }
//         if(memberModel?.addonDanceDuration == 90) {
//             DancePrice = curVal?.quarterly?.price            
//         }
//         if(memberModel?.addonDanceDuration == 180) {
//             DancePrice = curVal?.half_yearly?.price            
//         }
//         if(memberModel?.addonDanceDuration == 365) {
//             DancePrice = curVal?.yearly?.price            
//         }
//         selectedDanceId = curVal.id;
//         // console.log(selectedDanceId);
//         return DancePrice;
//     })

//     let yogaPrice = 0;
//     let selectedYogaId = null;
//     addonPrice?.Yoga?.filter((curVal) => {
//         // eslint-disable-next-line
//         // if(memberModel?.upgradeduration == 15) {
//         //     upgradePrice = memberModel?.upgradeduration * curVal?.monthly?.daily            
//         // }
//         // eslint-disable-next-line
//         if(memberModel?.addonYogaDuration == 30) {
//             yogaPrice = curVal?.monthly?.price   
//             // console.log("hello world");         
//         }
//         if(memberModel?.addonYogaDuration == 60) {
//             yogaPrice = curVal?.secondly?.price   
//             // console.log("hello world");         
//         }
//         if(memberModel?.addonYogaDuration == 90) {
//             yogaPrice = curVal?.quarterly?.price            
//         }
//         if(memberModel?.addonYogaDuration == 180) {
//             yogaPrice = curVal?.half_yearly?.price            
//         }
//         if(memberModel?.addonYogaDuration == 365) {
//             yogaPrice = curVal?.yearly?.price            
//         }
//         selectedYogaId = curVal.id;
//         // console.log(selectedYogaId);

//         return yogaPrice;
//     })

//     let steamPrice = 0;
//     let selectedSteamId = null;
//     addonPrice?.Steam?.filter((curVal) => {
//         // eslint-disable-next-line
//         // if(memberModel?.upgradeduration == 15) {
//         //     upgradePrice = memberModel?.upgradeduration * curVal?.monthly?.daily            
//         // }
//         // eslint-disable-next-line
//         if(memberModel?.addonSteamDuration == 30) {
//             steamPrice = curVal?.monthly?.price   
//             // console.log("hello world");         
//         }
//         if(memberModel?.addonSteamDuration == 60) {
//             steamPrice = curVal?.secondly?.price   
//             // console.log("hello world");         
//         }
//         if(memberModel?.addonSteamDuration == 90) {
//             steamPrice = curVal?.quarterly?.price            
//         }
//         if(memberModel?.addonSteamDuration == 180) {
//             steamPrice = curVal?.half_yearly?.price            
//         }
//         if(memberModel?.addonSteamDuration == 365) {
//             steamPrice = curVal?.yearly?.price            
//         }
//         selectedSteamId = curVal.id;
//         return steamPrice;
//     })

    
//     let cyclingPrice = 0;
//     let selectedCyclingId = null;

//     addonPrice?.Cycling?.filter((curVal) => {
//         // eslint-disable-next-line
//         // if(memberModel?.upgradeduration == 15) {
//         //     upgradePrice = memberModel?.upgradeduration * curVal?.monthly?.daily            
//         // }
//         // eslint-disable-next-line
//         if(memberModel?.addonCyclingDuration == 30) {
//             cyclingPrice = curVal?.monthly?.price   
//             // console.log("hello world");         
//         }
//         if(memberModel?.addonCyclingDuration == 60) {
//             cyclingPrice = curVal?.secondly?.price   
//             // console.log("hello world");         
//         }
//         if(memberModel?.addonCyclingDuration == 90) {
//             cyclingPrice = curVal?.quarterly?.price            
//         }
//         if(memberModel?.addonCyclingDuration == 180) {
//             cyclingPrice = curVal?.half_yearly?.price            
//         }
//         if(memberModel?.addonCyclingDuration == 365) {
//             cyclingPrice = curVal?.yearly?.price            
//         }
//         selectedCyclingId = curVal.id;
//         // console.log(selectedCyclingId);

//         return cyclingPrice;
//     })

//     let functional_Training = 0;
//     let selectedFunctional_trainingId = null;
//     addonPrice?.Functional_Training?.filter((curVal) => {
//         // eslint-disable-next-line
//         // if(memberModel?.upgradeduration == 15) {
//         //     upgradePrice = memberModel?.upgradeduration * curVal?.monthly?.daily            
//         // }
//         // eslint-disable-next-line
//         if(memberModel?.addonFunctional_TrainingDuration == 30) {
//             functional_Training = curVal?.monthly?.price   
//             // console.log("hello world");         
//         }
//         if(memberModel?.addonFunctional_TrainingDuration == 60) {
//             functional_Training = curVal?.secondly?.price   
//             // console.log("hello world");         
//         }
//         if(memberModel?.addonFunctional_TrainingDuration == 90) {
//             functional_Training = curVal?.quarterly?.price            
//         }
//         if(memberModel?.addonFunctional_TrainingDuration == 180) {
//             functional_Training = curVal?.half_yearly?.price            
//         }
//         if(memberModel?.addonFunctional_TrainingDuration == 365) {
//             functional_Training = curVal?.yearly?.price            
//         }
//         selectedFunctional_trainingId = curVal.id;
//         // console.log(selectedFunctional_trainingId);
//         return functional_Training;
//     })

//     let zumbaPrice = 0;
//     let selectedZumbaId = null;

//     addonPrice?.Zumba?.filter((curVal) => {
//         // eslint-disable-next-line
//         // if(memberModel?.upgradeduration == 15) {
//         //     upgradePrice = memberModel?.upgradeduration * curVal?.monthly?.daily            
//         // }
//         // eslint-disable-next-line
//         if(memberModel?.addonZumbaDuration == 30) {
//             zumbaPrice = curVal?.monthly?.price   
//             // console.log("hello world");         
//         }
//         if(memberModel?.addonZumbaDuration == 60) {
//             zumbaPrice = curVal?.secondly?.price   
//             // console.log("hello world");         
//         }
//         if(memberModel?.addonZumbaDuration == 90) {
//             zumbaPrice = curVal?.quarterly?.price            
//         }
//         if(memberModel?.addonZumbaDuration == 180) {
//             zumbaPrice = curVal?.half_yearly?.price            
//         }
//         if(memberModel?.addonZumbaDuration == 365) {
//             zumbaPrice = curVal?.yearly?.price            
//         }
//         selectedZumbaId = curVal.id;
//         // console.log(selectedZumbaId);

//         return zumbaPrice;
//     })

//     let crossfitPrice = 0;
//     let selectedCrossfitId = null;

//     addonPrice?.Crossfit?.filter((curVal) => {
//         if(memberModel?.addonCrossfitDuration == 30) {
//             crossfitPrice = curVal?.monthly?.price   
//             // console.log("hello world");         
//         }
//         if(memberModel?.addonCrossfitDuration == 60) {
//             crossfitPrice = curVal?.secondly?.price   
//             // console.log("hello world");         
//         }
//         if(memberModel?.addonCrossfitDuration == 90) {
//             crossfitPrice = curVal?.quarterly?.price            
//         }
//         if(memberModel?.addonCrossfitDuration == 180) {
//             crossfitPrice = curVal?.half_yearly?.price            
//         }
//         if(memberModel?.addonCrossfitDuration == 365) {
//             crossfitPrice = curVal?.yearly?.price            
//         }
//         selectedCrossfitId = curVal.id;
//         // console.log(selectedCrossfitId);

//         return crossfitPrice;
//     })

//     let massagePrice = 0;
//     let selectedMassageId = null;

//     addonPrice?.Massage?.filter((curVal) => {
//         if(memberModel?.addonMassageDuration == 30) {
//             massagePrice = curVal?.monthly?.price   
//             // console.log("hello world");         
//         }
//         if(memberModel?.addonMassageDuration == 60) {
//             massagePrice = curVal?.secondly?.price   
//             // console.log("hello world");         
//         }
//         if(memberModel?.addonMassageDuration == 90) {
//             massagePrice = curVal?.quarterly?.price            
//         }
//         if(memberModel?.addonMassageDuration == 180) {
//             massagePrice = curVal?.half_yearly?.price            
//         }
//         if(memberModel?.addonMassageDuration == 365) {
//             massagePrice = curVal?.yearly?.price            
//         }
//         selectedMassageId = curVal.id;
//         // console.log(selectedMassageId);

//         return massagePrice;
//     })

//     let ncPrice = 0;
//     let selectedNutrition_ConsultingId = null;

//     addonPrice?.Nutrition_Consulting?.filter((curVal) => {
//         if(memberModel?.addonNutrition_ConsultingDuration == 30) {
//             ncPrice = curVal?.monthly?.price   
//             // console.log("hello world");         
//         }
//         if(memberModel?.addonNutrition_ConsultingDuration == 60) {
//             ncPrice = curVal?.secondly?.price   
//             // console.log("hello world");         
//         }
//         if(memberModel?.addonNutrition_ConsultingDuration == 90) {
//             ncPrice = curVal?.quarterly?.price            
//         }
//         if(memberModel?.addonNutrition_ConsultingDuration == 180) {
//             ncPrice = curVal?.half_yearly?.price            
//         }
//         if(memberModel?.addonNutrition_ConsultingDuration == 365) {
//             ncPrice = curVal?.yearly?.price            
//         }
//         selectedNutrition_ConsultingId = curVal.id;
//         // console.log(selectedNutrition_ConsultingId);

//         return ncPrice;
//     })

//     let wtPrice = 0;
//     let selectWeight_TrainingId = null;

//     addonPrice?.Weight_Training?.filter((curVal) => {
//         if(memberModel?.addonWeight_TrainingDuration == 30) {
//             wtPrice = curVal?.monthly?.price   
//             // console.log("hello world");         
//         }
//         if(memberModel?.addonWeight_TrainingDuration == 60) {
//             wtPrice = curVal?.secondly?.price   
//             // console.log("hello world");         
//         }
//         if(memberModel?.addonWeight_TrainingDuration == 90) {
//             wtPrice = curVal?.quarterly?.price            
//         }
//         if(memberModel?.addonWeight_TrainingDuration == 180) {
//             wtPrice = curVal?.half_yearly?.price            
//         }
//         if(memberModel?.addonWeight_TrainingDuration == 365) {
//             wtPrice = curVal?.yearly?.price            
//         }
//         selectWeight_TrainingId = curVal.id;
//         // console.log(selectWeight_TrainingId);

//         return wtPrice;
//     })

//     let hecPrice = 0;
//     let selectHiit_Excercise_ClassesId = null;

//     addonPrice?.Hiit_Excercise_Classes?.filter((curVal) => {
//         if(memberModel?.addonHiit_Excercise_ClassesDuration == 30) {
//             hecPrice = curVal?.monthly?.price   
//             // console.log("hello world");         
//         }
//         if(memberModel?.addonHiit_Excercise_ClassesDuration == 60) {
//             hecPrice = curVal?.secondly?.price   
//             // console.log("hello world");         
//         }
//         if(memberModel?.addonHiit_Excercise_ClassesDuration == 90) {
//             hecPrice = curVal?.quarterly?.price            
//         }
//         if(memberModel?.addonHiit_Excercise_ClassesDuration == 180) {
//             hecPrice = curVal?.half_yearly?.price            
//         }
//         if(memberModel?.addonHiit_Excercise_ClassesDuration == 365) {
//             hecPrice = curVal?.yearly?.price            
//         }
//         selectHiit_Excercise_ClassesId = curVal.id;
//         // console.log(selectHiit_Excercise_ClassesId);

//         return hecPrice;
//     })

//     let fsPrice = 0;
//     let selectedFitness_StudioID = null;

//     addonPrice?.Hiit_Excercise_Classes?.filter((curVal) => {
//         if(memberModel?.addonFitness_StudioDuration == 30) {
//             fsPrice = curVal?.monthly?.price   
//             // console.log("hello world");         
//         }
//         if(memberModel?.addonFitness_StudioDuration == 60) {
//             fsPrice = curVal?.secondly?.price   
//             // console.log("hello world");         
//         }
//         if(memberModel?.addonFitness_StudioDuration == 90) {
//             fsPrice = curVal?.quarterly?.price            
//         }
//         if(memberModel?.addonFitness_StudioDuration == 180) {
//             fsPrice = curVal?.half_yearly?.price            
//         }
//         if(memberModel?.addonFitness_StudioDuration == 365) {
//             fsPrice = curVal?.yearly?.price            
//         }
//         selectedFitness_StudioID = curVal.id;
//         // console.log(selectedFitness_StudioID);

//         return fsPrice;
//     })

//     let totalPrice = 0;
//     totalPrice = (planPrice + aPTPrice + lockerPrice + boxingPrice + DancePrice + yogaPrice + steamPrice + cyclingPrice + functional_Training + zumbaPrice + crossfitPrice + massagePrice + ncPrice + wtPrice + hecPrice + fsPrice);

//     const disablePastDate = () => {
//         const today = new Date();
//         const dd = String(today.getDate()).padStart(2, "0");
//         const mm = String(today.getMonth() + 1).padStart(2, "0");
//         const yyyy = today.getFullYear();
//         return yyyy + "-" + mm + "-" + dd;
//     }

//     const disableNewPastDate = () => {
//         const today = new Date(getMember?.subscription_addon_details?.enddate);
//         const dd = String(today.getDate()).padStart(2, "0");
//         const mm = String(today.getMonth() + 1).padStart(2, "0");
//         const yyyy = today.getFullYear();
//         return yyyy + "-" + mm + "-" + dd;
//     }

//     let prevBtn = document.getElementById('prev');
//     let nextBtn = document.getElementById('next');
//     let tab1 = document.getElementById('tab1');
//     let tab2 = document.getElementById('tab2');
//     let tab3 = document.getElementById('tab3');
//     let tab4 = document.getElementById('tab4');
//     let tab5 = document.getElementById('tab5');
//     let tab6 = document.getElementById('tab6');
//     let tab7 = document.getElementById('tab7');
//     let tab8 = document.getElementById('tab8');
//     let tab9 = document.getElementById('tab9');
//     let tab10 = document.getElementById('tab10');
//     let tab11 = document.getElementById('tab11');
//     let tab12 = document.getElementById('tab12');
//     let tab13 = document.getElementById('tab13');
//     let tab14 = document.getElementById('tab14');
//     let tab15 = document.getElementById('tab15');
//     let tab16 = document.getElementById('tab16');
//     // const showTab = () => {
//     //     if(step === 1) {
//     //         prevBtn?.classList?.remove('hidden');
//     //         tab1?.classList?.add('hidden');
//     //         tab2?.classList?.remove('hidden');
//     //         tab3?.classList?.add('hidden');
//     //         tab4?.classList?.add('hidden');
//     //         tab8?.classList?.add('hidden');
//     //         tab9?.classList?.add('hidden');
//     //         nextBtn?.classList?.remove('hidden');
//     //     }
//     //     else if(step === 0) {
//     //         tab1?.classList?.remove('hidden');
//     //         tab2?.classList?.add('hidden');
//     //         tab3?.classList?.add('hidden');
//     //         tab4?.classList?.add('hidden');
//     //         tab8?.classList?.add('hidden');
//     //         prevBtn?.classList?.add('hidden');
//     //     }
//     //     else if(step === 2) {
//     //         nextBtn?.classList?.remove('hidden');
//     //         tab3?.classList?.remove('hidden');
//     //         tab1?.classList?.add('hidden');
//     //         tab2?.classList?.add('hidden');
//     //         tab4?.classList?.add('hidden');
//     //         tab5?.classList?.add('hidden');
//     //         tab8?.classList?.add('hidden');
//     //         tab9?.classList?.add('hidden');
//     //         tab10?.classList?.add('hidden');
//     //         tab11?.classList?.add('hidden');
//     //         tab12?.classList?.add('hidden');
//     //         tab13?.classList?.add('hidden');
//     //         tab14?.classList?.add('hidden');
//     //         tab15?.classList?.add('hidden');
//     //         tab16?.classList?.add('hidden');
//     //         prevBtn?.classList?.remove('hidden');
//     //     }
//     //     else if(step === 3) {
//     //         nextBtn?.classList?.remove('hidden');
//     //         tab4?.classList.remove('hidden');
//     //         tab3?.classList?.add('hidden');
//     //         tab1?.classList?.add('hidden');
//     //         tab2?.classList?.add('hidden');
//     //         tab5?.classList?.add('hidden');
//     //         tab9?.classList?.add('hidden');
//     //         tab8?.classList?.add('hidden');
//     //         tab10?.classList?.add('hidden');
//     //         tab11?.classList?.add('hidden');
//     //         tab12?.classList?.add('hidden');
//     //         tab13?.classList?.add('hidden');
//     //         tab14?.classList?.add('hidden');
//     //         tab15?.classList?.add('hidden');
//     //         tab16?.classList?.add('hidden');
//     //     }
//     //     else if(step === 4) {
//     //         nextBtn?.classList?.remove('hidden');
//     //         tab5?.classList.remove('hidden');
//     //         tab3?.classList?.add('hidden');
//     //         tab1?.classList?.add('hidden');
//     //         tab2?.classList?.add('hidden');
//     //         tab4?.classList?.add('hidden');
//     //         tab6?.classList?.add('hidden');
//     //         tab7?.classList?.add('hidden');
//     //         tab8?.classList?.add('hidden');
//     //         tab9?.classList?.add('hidden');
//     //         tab10?.classList?.add('hidden');
//     //         tab11?.classList?.add('hidden');
//     //         tab12?.classList?.add('hidden');
//     //         tab13?.classList?.add('hidden');
//     //         tab14?.classList?.add('hidden');
//     //         tab15?.classList?.add('hidden');
//     //         tab16?.classList?.add('hidden');
//     //         prevBtn?.classList?.remove('hidden');

//     //     }
//     //     else if(step === 5) {
//     //         nextBtn?.classList?.remove('hidden');
//     //         tab5?.classList.add('hidden');
//     //         tab3?.classList?.add('hidden');
//     //         tab1?.classList?.add('hidden');
//     //         tab2?.classList?.add('hidden');
//     //         tab4?.classList?.add('hidden');
//     //         tab6?.classList?.remove('hidden');
//     //         tab7?.classList?.add('hidden');
//     //         tab8?.classList?.add('hidden');
//     //         tab9?.classList?.add('hidden');
//     //         tab10?.classList?.add('hidden');
//     //         tab11?.classList?.add('hidden');
//     //         tab12?.classList?.add('hidden');
//     //         tab13?.classList?.add('hidden');
//     //         tab14?.classList?.add('hidden');
//     //         tab15?.classList?.add('hidden');
//     //         tab16?.classList?.add('hidden');
//     //         prevBtn?.classList?.remove('hidden');

//     //     }
//     //     else if(step === 6) {
//     //         nextBtn?.classList?.remove('hidden');
//     //         tab5?.classList.add('hidden');
//     //         tab3?.classList?.add('hidden');
//     //         tab1?.classList?.add('hidden');
//     //         tab2?.classList?.add('hidden');
//     //         tab4?.classList?.add('hidden');
//     //         tab6?.classList?.add('hidden');
//     //         tab7?.classList?.remove('hidden');
//     //         tab8?.classList?.add('hidden');
//     //         tab9?.classList?.add('hidden');
//     //         tab10?.classList?.add('hidden');
//     //         tab11?.classList?.add('hidden');
//     //         tab12?.classList?.add('hidden');
//     //         tab13?.classList?.add('hidden');
//     //         tab14?.classList?.add('hidden');
//     //         tab15?.classList?.add('hidden');
//     //         tab16?.classList?.add('hidden');
//     //         prevBtn?.classList?.remove('hidden');

//     //     }
//     //     else if(step === 7) {
//     //         nextBtn?.classList?.remove('hidden');
//     //         tab5?.classList.add('hidden');
//     //         tab3?.classList?.add('hidden');
//     //         tab1?.classList?.add('hidden');
//     //         tab2?.classList?.add('hidden');
//     //         tab4?.classList?.add('hidden');
//     //         tab6?.classList?.add('hidden');
//     //         tab7?.classList?.add('hidden');
//     //         tab8?.classList?.remove('hidden');
//     //         tab9?.classList?.add('hidden');
//     //         tab10?.classList?.add('hidden');
//     //         tab11?.classList?.add('hidden');
//     //         tab12?.classList?.add('hidden');
//     //         tab13?.classList?.add('hidden');
//     //         tab14?.classList?.add('hidden');
//     //         tab15?.classList?.add('hidden');
//     //         tab16?.classList?.add('hidden');
//     //         prevBtn?.classList?.remove('hidden');

//     //     }
//     //     else if(step === 8) {
//     //         nextBtn?.classList?.remove('hidden');
//     //         tab5?.classList.add('hidden');
//     //         tab3?.classList?.add('hidden');
//     //         tab1?.classList?.add('hidden');
//     //         tab2?.classList?.add('hidden');
//     //         tab4?.classList?.add('hidden');
//     //         tab6?.classList?.add('hidden');
//     //         tab7?.classList?.add('hidden');
//     //         tab8?.classList?.add('hidden');
//     //         tab9?.classList?.remove('hidden');
//     //         tab10?.classList?.add('hidden');
//     //         tab11?.classList?.add('hidden');
//     //         tab12?.classList?.add('hidden');
//     //         tab13?.classList?.add('hidden');
//     //         tab14?.classList?.add('hidden');
//     //         tab15?.classList?.add('hidden');
//     //         tab16?.classList?.add('hidden');
//     //         prevBtn?.classList?.remove('hidden');

//     //     }
//     //     else if(step === 9) {
//     //         nextBtn?.classList?.remove('hidden');
//     //         tab5?.classList.add('hidden');
//     //         tab3?.classList?.add('hidden');
//     //         tab1?.classList?.add('hidden');
//     //         tab2?.classList?.add('hidden');
//     //         tab4?.classList?.add('hidden');
//     //         tab6?.classList?.add('hidden');
//     //         tab7?.classList?.add('hidden');
//     //         tab8?.classList?.add('hidden');
//     //         tab9?.classList?.add('hidden');
//     //         if(addonPrice?.Zumba === null){
//     //             tab10?.classList?.add('hidden');
//     //             // nextBtn?.classList?.add('hidden');
//     //             // prevBtn?.classList?.add('hidden');
//     //         }
//     //         else {
//     //             tab10?.classList?.remove('hidden');
//     //             nextBtn?.classList?.remove('hidden');
//     //             prevBtn?.classList?.remove('hidden');

//     //         }
//     //         tab11?.classList?.add('hidden');
//     //         tab12?.classList?.add('hidden');
//     //         tab13?.classList?.add('hidden');
//     //         tab14?.classList?.add('hidden');
//     //         tab15?.classList?.add('hidden');
//     //         tab16?.classList?.add('hidden');
//     //         prevBtn?.classList?.remove('hidden');

//     //     }
//     //     else if(step === 10) {
//     //         nextBtn?.classList?.remove('hidden');
//     //         tab5?.classList.add('hidden');
//     //         tab3?.classList?.add('hidden');
//     //         tab1?.classList?.add('hidden');
//     //         tab2?.classList?.add('hidden');
//     //         tab4?.classList?.add('hidden');
//     //         tab6?.classList?.add('hidden');
//     //         tab7?.classList?.add('hidden');
//     //         tab8?.classList?.add('hidden');
//     //         tab9?.classList?.add('hidden');
//     //         tab10?.classList?.add('hidden');
//     //         tab11?.classList?.remove('hidden');
//     //         tab12?.classList?.add('hidden');
//     //         tab13?.classList?.add('hidden');
//     //         tab14?.classList?.add('hidden');
//     //         tab15?.classList?.add('hidden');
//     //         tab16?.classList?.add('hidden');
//     //         prevBtn?.classList?.remove('hidden');

//     //     }
//     //     else if(step === 11) {
//     //         nextBtn?.classList?.remove('hidden');
//     //         tab5?.classList.add('hidden');
//     //         tab3?.classList?.add('hidden');
//     //         tab1?.classList?.add('hidden');
//     //         tab2?.classList?.add('hidden');
//     //         tab4?.classList?.add('hidden');
//     //         tab6?.classList?.add('hidden');
//     //         tab7?.classList?.add('hidden');
//     //         tab8?.classList?.add('hidden');
//     //         tab9?.classList?.add('hidden');
//     //         tab10?.classList?.add('hidden');
//     //         tab11?.classList?.add('hidden');
//     //         tab12?.classList?.remove('hidden');
//     //         tab13?.classList?.add('hidden');
//     //         tab14?.classList?.add('hidden');
//     //         tab15?.classList?.add('hidden');
//     //         tab16?.classList?.add('hidden');
//     //         prevBtn?.classList?.remove('hidden');

//     //     }
//     //     else if(step === 12) {
//     //         nextBtn?.classList?.remove('hidden');
//     //         tab5?.classList.add('hidden');
//     //         tab3?.classList?.add('hidden');
//     //         tab1?.classList?.add('hidden');
//     //         tab2?.classList?.add('hidden');
//     //         tab4?.classList?.add('hidden');
//     //         tab6?.classList?.add('hidden');
//     //         tab7?.classList?.add('hidden');
//     //         tab8?.classList?.add('hidden');
//     //         tab9?.classList?.add('hidden');
//     //         tab10?.classList?.add('hidden');
//     //         tab11?.classList?.add('hidden');
//     //         tab12?.classList?.add('hidden');
//     //         tab13?.classList?.remove('hidden');
//     //         tab14?.classList?.add('hidden');
//     //         tab15?.classList?.add('hidden');
//     //         tab16?.classList?.add('hidden');
//     //         prevBtn?.classList?.remove('hidden');

//     //     }
//     //     else if(step === 13) {
//     //         nextBtn?.classList?.remove('hidden');
//     //         tab5?.classList.add('hidden');
//     //         tab3?.classList?.add('hidden');
//     //         tab1?.classList?.add('hidden');
//     //         tab2?.classList?.add('hidden');
//     //         tab4?.classList?.add('hidden');
//     //         tab6?.classList?.add('hidden');
//     //         tab7?.classList?.add('hidden');
//     //         tab8?.classList?.add('hidden');
//     //         tab9?.classList?.add('hidden');
//     //         tab10?.classList?.add('hidden');
//     //         tab11?.classList?.add('hidden');
//     //         tab12?.classList?.add('hidden');
//     //         tab13?.classList?.add('hidden');
//     //         tab14?.classList?.remove('hidden');
//     //         tab15?.classList?.add('hidden');
//     //         tab16?.classList?.add('hidden');
//     //         prevBtn?.classList?.remove('hidden');

//     //     }
//     //     else if(step === 14) {
//     //         nextBtn?.classList?.remove('hidden');
//     //         tab5?.classList.add('hidden');
//     //         tab3?.classList?.add('hidden');
//     //         tab1?.classList?.add('hidden');
//     //         tab2?.classList?.add('hidden');
//     //         tab4?.classList?.add('hidden');
//     //         tab6?.classList?.add('hidden');
//     //         tab7?.classList?.add('hidden');
//     //         tab8?.classList?.add('hidden');
//     //         tab9?.classList?.add('hidden');
//     //         tab10?.classList?.add('hidden');
//     //         tab11?.classList?.add('hidden');
//     //         tab12?.classList?.add('hidden');
//     //         tab13?.classList?.add('hidden');
//     //         tab14?.classList?.add('hidden');
//     //         tab15?.classList?.remove('hidden');
//     //         tab16?.classList?.add('hidden');
//     //         prevBtn?.classList?.remove('hidden');

//     //     }
//     //     else if(step === 15) {
//     //         nextBtn?.classList?.add('hidden');
//     //         tab5?.classList.add('hidden');
//     //         tab3?.classList?.add('hidden');
//     //         tab1?.classList?.add('hidden');
//     //         tab2?.classList?.add('hidden');
//     //         tab4?.classList?.add('hidden');
//     //         tab6?.classList?.add('hidden');
//     //         tab7?.classList?.add('hidden');
//     //         tab8?.classList?.add('hidden');
//     //         tab9?.classList?.add('hidden');
//     //         tab10?.classList?.add('hidden');
//     //         tab11?.classList?.add('hidden');
//     //         tab12?.classList?.add('hidden');
//     //         tab13?.classList?.add('hidden');
//     //         tab14?.classList?.add('hidden');
//     //         tab15?.classList?.add('hidden');
//     //         tab16?.classList?.remove('hidden');
//     //         prevBtn?.classList?.remove('hidden');

//     //     }
//     // }

//     const showTab = () => {
//         if(step === 1) {
//             prevBtn?.classList?.remove('hidden');
//             tab1?.classList?.add('hidden');
//             tab2?.classList?.remove('hidden');
//             tab3?.classList?.add('hidden');
//             tab4?.classList?.add('hidden');
//             tab8?.classList?.add('hidden');
//             tab9?.classList?.add('hidden');
//             nextBtn?.classList?.remove('hidden');
//         }
//         else if(step === 0) {
//             tab1?.classList?.remove('hidden');
//             tab2?.classList?.add('hidden');
//             tab3?.classList?.add('hidden');
//             tab4?.classList?.add('hidden');
//             tab8?.classList?.add('hidden');
//             prevBtn?.classList?.add('hidden');
//         }
//         else if(step === 2) {
//             nextBtn?.classList?.remove('hidden');
//             tab3?.classList?.remove('hidden');
//             tab1?.classList?.add('hidden');
//             tab2?.classList?.add('hidden');
//             tab4?.classList?.add('hidden');
//             tab5?.classList?.add('hidden');
//             tab8?.classList?.add('hidden');
//             tab9?.classList?.add('hidden');
//             tab10?.classList?.add('hidden');
//             tab11?.classList?.add('hidden');
//             tab12?.classList?.add('hidden');
//             tab13?.classList?.add('hidden');
//             tab14?.classList?.add('hidden');
//             tab15?.classList?.add('hidden');
//             tab16?.classList?.add('hidden');
//             prevBtn?.classList?.remove('hidden');
//         }
//         else if(step === 3) {
//             nextBtn?.classList?.remove('hidden');
//             tab4?.classList.remove('hidden');
//             tab3?.classList?.add('hidden');
//             tab1?.classList?.add('hidden');
//             tab2?.classList?.add('hidden');
//             tab5?.classList?.add('hidden');
//             tab9?.classList?.add('hidden');
//             tab8?.classList?.add('hidden');
//             tab10?.classList?.add('hidden');
//             tab11?.classList?.add('hidden');
//             tab12?.classList?.add('hidden');
//             tab13?.classList?.add('hidden');
//             tab14?.classList?.add('hidden');
//             tab15?.classList?.add('hidden');
//             tab16?.classList?.add('hidden');
//         }
//         else if(step === 4) {
//             nextBtn?.classList?.remove('hidden');
//             tab5?.classList.remove('hidden');
//             tab3?.classList?.add('hidden');
//             tab1?.classList?.add('hidden');
//             tab2?.classList?.add('hidden');
//             tab4?.classList?.add('hidden');
//             tab6?.classList?.add('hidden');
//             tab7?.classList?.add('hidden');
//             tab8?.classList?.add('hidden');
//             tab9?.classList?.add('hidden');
//             tab10?.classList?.add('hidden');
//             tab11?.classList?.add('hidden');
//             tab12?.classList?.add('hidden');
//             tab13?.classList?.add('hidden');
//             tab14?.classList?.add('hidden');
//             tab15?.classList?.add('hidden');
//             tab16?.classList?.add('hidden');
//             prevBtn?.classList?.remove('hidden');

//         }
//         else if(step === 5) {
//             nextBtn?.classList?.remove('hidden');
//             tab5?.classList.add('hidden');
//             tab3?.classList?.add('hidden');
//             tab1?.classList?.add('hidden');
//             tab2?.classList?.add('hidden');
//             tab4?.classList?.add('hidden');
//             tab6?.classList?.remove('hidden');
//             tab7?.classList?.add('hidden');
//             tab8?.classList?.add('hidden');
//             tab9?.classList?.add('hidden');
//             tab10?.classList?.add('hidden');
//             tab11?.classList?.add('hidden');
//             tab12?.classList?.add('hidden');
//             tab13?.classList?.add('hidden');
//             tab14?.classList?.add('hidden');
//             tab15?.classList?.add('hidden');
//             tab16?.classList?.add('hidden');
//             prevBtn?.classList?.remove('hidden');

//         }
//         else if(step === 6) {
//             nextBtn?.classList?.remove('hidden');
//             tab5?.classList.add('hidden');
//             tab3?.classList?.add('hidden');
//             tab1?.classList?.add('hidden');
//             tab2?.classList?.add('hidden');
//             tab4?.classList?.add('hidden');
//             tab6?.classList?.add('hidden');
//             tab7?.classList?.remove('hidden');
//             tab8?.classList?.add('hidden');
//             tab9?.classList?.add('hidden');
//             tab10?.classList?.add('hidden');
//             tab11?.classList?.add('hidden');
//             tab12?.classList?.add('hidden');
//             tab13?.classList?.add('hidden');
//             tab14?.classList?.add('hidden');
//             tab15?.classList?.add('hidden');
//             tab16?.classList?.add('hidden');
//             prevBtn?.classList?.remove('hidden');

//         }
//         else if(step === 7) {
//             nextBtn?.classList?.remove('hidden');
//             tab5?.classList.add('hidden');
//             tab3?.classList?.add('hidden');
//             tab1?.classList?.add('hidden');
//             tab2?.classList?.add('hidden');
//             tab4?.classList?.add('hidden');
//             tab6?.classList?.add('hidden');
//             tab7?.classList?.add('hidden');
//             tab8?.classList?.remove('hidden');
//             tab9?.classList?.add('hidden');
//             tab10?.classList?.add('hidden');
//             tab11?.classList?.add('hidden');
//             tab12?.classList?.add('hidden');
//             tab13?.classList?.add('hidden');
//             tab14?.classList?.add('hidden');
//             tab15?.classList?.add('hidden');
//             tab16?.classList?.add('hidden');
//             prevBtn?.classList?.remove('hidden');

//         }
//         else if(step === 8) {
//             nextBtn?.classList?.remove('hidden');
//             tab5?.classList.add('hidden');
//             tab3?.classList?.add('hidden');
//             tab1?.classList?.add('hidden');
//             tab2?.classList?.add('hidden');
//             tab4?.classList?.add('hidden');
//             tab6?.classList?.add('hidden');
//             tab7?.classList?.add('hidden');
//             tab8?.classList?.add('hidden');
//             tab9?.classList?.remove('hidden');
//             tab10?.classList?.add('hidden');
//             tab11?.classList?.add('hidden');
//             tab12?.classList?.add('hidden');
//             tab13?.classList?.add('hidden');
//             tab14?.classList?.add('hidden');
//             tab15?.classList?.add('hidden');
//             tab16?.classList?.add('hidden');
//             prevBtn?.classList?.remove('hidden');

//         }
//         else if(step === 9) {
//             nextBtn?.classList?.remove('hidden');
//             tab5?.classList.add('hidden');
//             tab3?.classList?.add('hidden');
//             tab1?.classList?.add('hidden');
//             tab2?.classList?.add('hidden');
//             tab4?.classList?.add('hidden');
//             tab6?.classList?.add('hidden');
//             tab7?.classList?.add('hidden');
//             tab8?.classList?.add('hidden');
//             tab9?.classList?.add('hidden');
//             tab10?.classList?.remove('hidden');
//             tab11?.classList?.add('hidden');
//             tab12?.classList?.add('hidden');
//             tab13?.classList?.add('hidden');
//             tab14?.classList?.add('hidden');
//             tab15?.classList?.add('hidden');
//             tab16?.classList?.add('hidden');
//             prevBtn?.classList?.remove('hidden');

//         }
//         else if(step === 10) {
//             nextBtn?.classList?.remove('hidden');
//             tab5?.classList.add('hidden');
//             tab3?.classList?.add('hidden');
//             tab1?.classList?.add('hidden');
//             tab2?.classList?.add('hidden');
//             tab4?.classList?.add('hidden');
//             tab6?.classList?.add('hidden');
//             tab7?.classList?.add('hidden');
//             tab8?.classList?.add('hidden');
//             tab9?.classList?.add('hidden');
//             tab10?.classList?.add('hidden');
//             tab11?.classList?.remove('hidden');
//             tab12?.classList?.add('hidden');
//             tab13?.classList?.add('hidden');
//             tab14?.classList?.add('hidden');
//             tab15?.classList?.add('hidden');
//             tab16?.classList?.add('hidden');
//             prevBtn?.classList?.remove('hidden');

//         }
//         else if(step === 11) {
//             nextBtn?.classList?.remove('hidden');
//             tab5?.classList.add('hidden');
//             tab3?.classList?.add('hidden');
//             tab1?.classList?.add('hidden');
//             tab2?.classList?.add('hidden');
//             tab4?.classList?.add('hidden');
//             tab6?.classList?.add('hidden');
//             tab7?.classList?.add('hidden');
//             tab8?.classList?.add('hidden');
//             tab9?.classList?.add('hidden');
//             tab10?.classList?.add('hidden');
//             tab11?.classList?.add('hidden');
//             tab12?.classList?.remove('hidden');
//             tab13?.classList?.add('hidden');
//             tab14?.classList?.add('hidden');
//             tab15?.classList?.add('hidden');
//             tab16?.classList?.add('hidden');
//             prevBtn?.classList?.remove('hidden');

//         }
//         else if(step === 12) {
//             nextBtn?.classList?.remove('hidden');
//             tab5?.classList.add('hidden');
//             tab3?.classList?.add('hidden');
//             tab1?.classList?.add('hidden');
//             tab2?.classList?.add('hidden');
//             tab4?.classList?.add('hidden');
//             tab6?.classList?.add('hidden');
//             tab7?.classList?.add('hidden');
//             tab8?.classList?.add('hidden');
//             tab9?.classList?.add('hidden');
//             tab10?.classList?.add('hidden');
//             tab11?.classList?.add('hidden');
//             tab12?.classList?.add('hidden');
//             tab13?.classList?.remove('hidden');
//             tab14?.classList?.add('hidden');
//             tab15?.classList?.add('hidden');
//             tab16?.classList?.add('hidden');
//             prevBtn?.classList?.remove('hidden');

//         }
//         else if(step === 13) {
//             nextBtn?.classList?.remove('hidden');
//             tab5?.classList.add('hidden');
//             tab3?.classList?.add('hidden');
//             tab1?.classList?.add('hidden');
//             tab2?.classList?.add('hidden');
//             tab4?.classList?.add('hidden');
//             tab6?.classList?.add('hidden');
//             tab7?.classList?.add('hidden');
//             tab8?.classList?.add('hidden');
//             tab9?.classList?.add('hidden');
//             tab10?.classList?.add('hidden');
//             tab11?.classList?.add('hidden');
//             tab12?.classList?.add('hidden');
//             tab13?.classList?.add('hidden');
//             tab14?.classList?.remove('hidden');
//             tab15?.classList?.add('hidden');
//             tab16?.classList?.add('hidden');
//             prevBtn?.classList?.remove('hidden');

//         }
//         else if(step === 14) {
//             nextBtn?.classList?.remove('hidden');
//             tab5?.classList.add('hidden');
//             tab3?.classList?.add('hidden');
//             tab1?.classList?.add('hidden');
//             tab2?.classList?.add('hidden');
//             tab4?.classList?.add('hidden');
//             tab6?.classList?.add('hidden');
//             tab7?.classList?.add('hidden');
//             tab8?.classList?.add('hidden');
//             tab9?.classList?.add('hidden');
//             tab10?.classList?.add('hidden');
//             tab11?.classList?.add('hidden');
//             tab12?.classList?.add('hidden');
//             tab13?.classList?.add('hidden');
//             tab14?.classList?.add('hidden');
//             tab15?.classList?.remove('hidden');
//             tab16?.classList?.add('hidden');
//             prevBtn?.classList?.remove('hidden');

//         }
//         else if(step === 15) {
//             nextBtn?.classList?.add('hidden');
//             tab5?.classList.add('hidden');
//             tab3?.classList?.add('hidden');
//             tab1?.classList?.add('hidden');
//             tab2?.classList?.add('hidden');
//             tab4?.classList?.add('hidden');
//             tab6?.classList?.add('hidden');
//             tab7?.classList?.add('hidden');
//             tab8?.classList?.add('hidden');
//             tab9?.classList?.add('hidden');
//             tab10?.classList?.add('hidden');
//             tab11?.classList?.add('hidden');
//             tab12?.classList?.add('hidden');
//             tab13?.classList?.add('hidden');
//             tab14?.classList?.add('hidden');
//             tab15?.classList?.add('hidden');
//             tab16?.classList?.remove('hidden');
//             prevBtn?.classList?.remove('hidden');

//         }
//     }

//     // const showTab = () => {
//     //     prevBtn?.classList?.remove('hidden');
//     //     nextBtn?.classList?.remove('hidden');
      
//     //     // Hide all tabs
//     //     for (let i = 1; i <= 16; i++) {
//     //       const tab = document.getElementById(`tab${i}`);
//     //       tab?.classList?.add('hidden');
//     //     }
      
//     //     if (step === 1) {
//     //       tab2?.classList?.remove('hidden');
//     //     } else if (step === 2) {
//     //       tab3?.classList?.remove('hidden');
//     //     } else if (step === 3) {
//     //       tab4?.classList?.remove('hidden');
//     //     } else if (step === 4) {
//     //       tab5?.classList?.remove('hidden');
//     //     } else if (step === 5) {
//     //       tab6?.classList?.remove('hidden');
//     //     } else if (step === 6) {
//     //       tab7?.classList?.remove('hidden');
//     //     } else if (step === 7) {
//     //       tab8?.classList?.remove('hidden');
//     //     } else if (step === 8) {
//     //       tab9?.classList?.remove('hidden');
//     //     } else if (step === 9) {
//     //       if (addonPrice?.Zumba === null) {
//     //         // Skip step 9 and go to step 10
//     //         setStep(10);
//     //         tab10?.classList?.remove('hidden');
//     //       } else {
//     //         tab10?.classList?.remove('hidden');
//     //       }
//     //     } else if (step === 10) {
//     //       tab11?.classList?.remove('hidden');
//     //     } else if (step === 11) {
//     //       tab12?.classList?.remove('hidden');
//     //     } else if (step === 12) {
//     //       tab13?.classList?.remove('hidden');
//     //     } else if (step === 13) {
//     //       tab14?.classList?.remove('hidden');
//     //     } else if (step === 14) {
//     //       tab15?.classList?.remove('hidden');
//     //     } else if (step === 15) {
//     //       tab16?.classList?.remove('hidden');
//     //       nextBtn?.classList?.add('hidden');
//     //     }
//     //   };
      

//     useEffect(() => {
//         showTab();   
//     });

//     const subscription = {
//         "addon_type": 'Subscription',
//         "addon_id": memberModel?.membershipplan,
//         "startdate": memberModel?.planstart
//     }
//     const trainer = {
//         "addon_type": memberModel?.addonTrainerType ? memberModel?.addonTrainerType : null,
//         "addon_id": memberModel?.trainer,
//         "duration": memberModel?.trainerduration ? memberModel?.trainerduration : null,
//         "startdate": memberModel?.planstartTrainer ? memberModel?.planstartTrainer : null,
//         "type": memberModel?.trainerPT ? memberModel?.trainerPT : null,
//         "fees": aPTPrice ? aPTPrice : null
//     }
//     const locker = {
//         "addon_type": memberModel?.addonLockerType ? memberModel?.addonLockerType : null,
//         "addon_id": memberModel?.locker ? memberModel?.locker : null,
//         "duration": memberModel?.lockerduration ? memberModel?.lockerduration : null,
//         "startdate": memberModel?.planstartLocker ? memberModel?.planstartLocker : null,
//         "fees": lockerPrice ? lockerPrice : null
//     }
//     // const upgrade = {
//     //     "addon_type": memberModel?.addonUpgradeType ? memberModel?.addonUpgradeType : null,
//     //     "addon_id": memberModel?.upgrade ? memberModel?.upgrade : null,
//     //     "duration": memberModel?.upgradeduration ? memberModel?.upgradeduration : null,
//     //     "startdate": memberModel?.planstartUpgrade ? memberModel?.planstartUpgrade : null,
//     //     "fees": upgradePrice ? upgradePrice : null
//     // }
//     // const boxing = {
//     //     "addon_type": memberModel?.addonBoxingType ? memberModel?.addonBoxingType : null,
//     //     "addon_id": memberModel?.boxing ? memberModel?.boxing : null,
//     //     "duration": memberModel?.addonDuration ? memberModel?.addonDuration : null,
//     //     "fees": boxingPrice ? boxingPrice : null,
//     //     "startdate": memberModel?.boxingPlanstart,
//     // };
//     const boxing = {
//         "addon_type": memberModel?.addonBoxingType ? memberModel?.addonBoxingType : null,
//         "addon_id": selectedBoxingId, // Use the selected ID here
//         "duration": memberModel?.addonDuration ? memberModel?.addonDuration : null,
//         "fees": boxingPrice ? boxingPrice.toString() : null, // Convert boxingPrice to a string
//         "startdate": memberModel?.boxingPlanstart,
//     };
//     const dance = {
//         "addon_type": memberModel?.addonDanceType ? memberModel?.addonDanceType : null,
//         "addon_id": selectedDanceId, // use the selected ID here
//         "duration": memberModel?.addonDanceDuration ? memberModel?.addonDanceDuration : null,
//         "fees": DancePrice ? DancePrice : null,
//         "startdate": memberModel?.dancePlanstart,
//     };
//     const yoga = {
//         "addon_type": memberModel?.addonYogaType ? memberModel?.addonYogaType : null,
//         "addon_id": selectedYogaId,
//         "duration": memberModel?.addonYogaDuration ? memberModel?.addonYogaDuration : null,
//         "fees": yogaPrice ? yogaPrice : null,
//         "startdate": memberModel?.yogaPlanstart,
//     };
//     const steam = {
//         "addon_type": memberModel?.addonSteamType ? memberModel?.addonSteamType : null,
//         "addon_id": selectedSteamId,
//         "duration": memberModel?.addonSteamDuration ? memberModel?.addonSteamDuration : null,
//         "fees": steamPrice ? steamPrice : null,
//         "startdate": memberModel?.steamPlanstart,
//     };
//     const cycling = {
//         "addon_type": memberModel?.addonCyclingType ? memberModel?.addonCyclingType : null,
//         "addon_id": selectedCyclingId,
//         "duration": memberModel?.addonCyclingDuration ? memberModel?.addonCyclingDuration : null,
//         "fees": cyclingPrice ? cyclingPrice : null,
//         "startdate": memberModel?.cyclingPlanstart,
//     };
//     const Functional_Training = {
//         "addon_type": memberModel?.addonFunctional_TrainingType ? memberModel?.addonFunctional_TrainingType : null,
//         "addon_id": selectedFunctional_trainingId,
//         "duration": memberModel?.addonFunctional_TrainingDuration ? memberModel?.addonFunctional_TrainingDuration : null,
//         "fees": functional_Training ? functional_Training : null,
//         "startdate": memberModel?.functional_TrainingPlanstart,
//     };
//     const zumba = {
//         "addon_type": memberModel?.addonZumbaType ? memberModel?.addonZumbaType : null,
//         "addon_id": selectedZumbaId,
//         "duration": memberModel?.addonZumbaDuration ? memberModel?.addonZumbaDuration : null,
//         "fees": zumbaPrice ? zumbaPrice : null,
//         "startdate": memberModel?.zumbaPlanstart,
//     };
//     const crossfit = {
//         "addon_type": memberModel?.addonCrossfitType ? memberModel?.addonCrossfitType : null,
//         "addon_id": selectedCrossfitId,
//         "duration": memberModel?.addonCrossfitDuration ? memberModel?.addonCrossfitDuration : null,
//         "fees": crossfitPrice ? crossfitPrice : null,
//         "startdate": memberModel?.crossfitPlanstart,
//     };
//     const massage = {
//         "addon_type": memberModel?.addonMassageType ? memberModel?.addonMassageType : null,
//         "addon_id": selectedMassageId,
//         "duration": memberModel?.addonMassageDuration ? memberModel?.addonMassageDuration : null,
//         "fees": massagePrice ? massagePrice : null,
//         "startdate": memberModel?.massagePlanstart,
//     };
//     const nutrition_consulting = {
//         "addon_type": memberModel?.addonNutrition_ConsultingType ? memberModel?.addonNutrition_ConsultingType : null,
//         "addon_id": selectedNutrition_ConsultingId,
//         "duration": memberModel?.addonNutrition_ConsultingDuration ? memberModel?.addonNutrition_ConsultingDuration : null,
//         "fees": ncPrice ? ncPrice : null,
//         "startdate": memberModel?.nutrition_ConsultingPlanstart,
//     };
//     const weight_training = {
//         "addon_type": memberModel?.addonWeight_TrainingType ? memberModel?.addonWeight_TrainingType : null,
//         "addon_id": selectWeight_TrainingId,
//         "duration": memberModel?.addonWeight_TrainingDuration ? memberModel?.addonWeight_TrainingDuration : null,
//         "fees": wtPrice ? wtPrice : null,
//         "startdate": memberModel?.weight_trainingPlanstart,
//     };
//     const hiit_excercise_classes = {
//         "addon_type": memberModel?.addonHiit_Excercise_ClassesType ? memberModel?.addonHiit_Excercise_ClassesType : null,
//         "addon_id": selectHiit_Excercise_ClassesId,
//         "duration": memberModel?.addonHiit_Excercise_ClassesDuration ? memberModel?.addonHiit_Excercise_ClassesDuration : null,
//         "fees": hecPrice ? hecPrice : null,
//         "startdate": memberModel?.hiit_excercise_classesPlanstart,
//     };
//     const fitness_studio = {
//         "addon_type": memberModel?.addonFitness_StudioType ? memberModel?.addonFitness_StudioType : null,
//         "addon_id": selectedFitness_StudioID,
//         "duration": memberModel?.addonFitness_StudioDuration ? memberModel?.addonFitness_StudioDuration : null,
//         "fees": fsPrice ? fsPrice : null,
//         "startdate": memberModel?.Fitness_StudioPlanstart,
//     };

//     const state = {
//         "member_id": getMember?.member_id,
//         "staff_id": getMember?.ref_id,
//         "pay_description": 'test payment',
//         "Subscription": subscription ? subscription : null,
//         // "Trainer": memberModel?.addonTrainerType === 'Trainer' ? trainer : null,
//         "Trainer" : memberModel?.addonTrainerType === 'Trainer' ? trainer : null,
//         "Locker": memberModel?.addonLockerType === 'Locker' ? locker : null,
//         "Boxing": memberModel?.addonBoxingType === 'Boxing' ? boxing : null,
//         "Dance" : memberModel?.addonDanceType === 'Dance' ? dance : null,
//         "Yoga" : memberModel?.addonYogaType === 'Yoga' ? yoga : null,
//         "Steam" : memberModel?.addonSteamType === 'Steam' ? steam : null,
//         "Cycling" : memberModel?.addonCyclingType === 'Cycling' ? cycling : null,
//         "Functional_Training": memberModel?.addonFunctional_TrainingType === 'Functional_Training'? Functional_Training: null,
//         "Zumba" : memberModel?.addonZumbaType === 'Zumba' ? zumba : null,
//         "Crossfit": memberModel?.addonCrossfitType === "Crossfit" ? crossfit : null,
//         "Massage": memberModel?.addonMassageType === "Massage" ? massage : null,
//         "Nutrition_Consulting" : memberModel?.addonNutrition_ConsultingType === "Nutrition_Consulting" ? nutrition_consulting : null,
//         "Weight_Training" : memberModel?.addonWeight_TrainingType === "Weight_Training" ? weight_training : null,
//         "Hiit_Excercise_Classes" : memberModel?.addonHiit_Excercise_ClassesType === "Hiit_Excercise_Classes" ? hiit_excercise_classes : null,
//         "Fitness_Studio" : memberModel?.addonFitness_StudioType === "Fitness_Studio" ? fitness_studio : null,
//     }

//     const success_state = {
//         "order_id": '',
//         "payment_status": 1,
//         "payment_id": '',
//         "signature": '',
//     }

//     const handleSubmit = async (e) => {
//         e.preventDefault();
//         const data = {...state}
//         delete data.errors;
//         console.log(data);
        
//         const res = await axios.post(`${process.env.REACT_APP_URL}add_addons_new`, data);
//         // console.log(res);
//         if(res?.status === 200){
//             const Res = await loadScript('https://checkout.razorpay.com/v1/checkout.js');
//             if(!Res) {
//                 alert('Razorpay SDK failed to load. Are you online?');
//                 return;
//             }
//             // const amount = res?.data.paydata.amount;
//             const options = {
//                 key: res?.data.razorpay_key,
//                 name: 'GYMApplication',
//                 description: 'Test Transaction',
//                 order_id: res?.data.paydata.order_id,
//                 // order_id: 'order_Lepy9UPxcYNYXD',
//                 // callback_url: 'https://eneqd3r9zrjok.x.pipedream.net/',
//                 handler: function(response) {
//                     success_state.order_id = response.razorpay_order_id;
//                     success_state.payment_id = response.razorpay_payment_id;
//                     success_state.signature = response.razorpay_signature;
//                     // alert(response.razorpay_payment_id);
//                     // alert(response.razorpay_order_id);
//                     // alert(response.razorpay_signature);
//                     // console.log(response);
//                     if(response) {
//                         const suc_data = {...success_state}
//                         axios.post(`${process.env.REACT_APP_URL}update_payment`, suc_data).then((resp) => {
//                             if(resp?.status === 200) {
//                                 toast.success(resp?.data.message, {
//                                     position: 'top-right',
//                                     autoClose: 3000,
//                                     hideProgressBar: false,
//                                     closeOnClick: true,
//                                     pauseOnHover: true,
//                                     draggable: true,
//                                     progress: undefined,
//                                     theme: 'light',
//                                 });
//                                 setShowPlanModel(false);
//                             }
//                         }).catch((err) => {
//                             console.log(err);
//                         })
//                     }
//                 },
//                 prefill: {
//                     name: getMember?.firstname,
//                     contact: getMember?.mobile
//                 },
//                 theme: {
//                     color: '#3399cc'
//                 },
//             };

//             const paymentObject = new window.Razorpay(options);
//             paymentObject.open();
//             paymentObject.on('payment.failed', function(response) {
//                 alert(response.error.code);
//                 alert(response.error.description);
//                 alert(response.error.source);
//                 alert(response.error.step);
//                 alert(response.error.reason);
//                 alert(response.error.metadata.order_id);
//                 alert(response.error.metadata.payment_id);
//             });
//         }
//         // if(success_state.order_id) {
//         //     const suc_data = {...success_state}
//         //     const suc =  await axios.post(`${process.env.REACT_APP_URL}update_payment`, suc_data);
//         //     console.log(suc);
//         //     console.log(suc_data);
//         // }
        
//     }

//   return (
//     <>
//     <Modal show={showPlanModel} size='lg'>
//         <Modal.Header>
//             <Modal.Title>Plan Details</Modal.Title>
//             <button type="button" data-bs-dismiss="modal" className="btn-close" onClick={() => setShowPlanModel(false)}></button>
//         </Modal.Header>
//         <Modal.Body>
//             <form onSubmit={handleSubmit}>
//                 <div id='tab1' className='tab'>
//                     <div className='row'>
//                         <div className='col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12'>
//                             <div className='mb-3'>
//                                 <label htmlFor="membershipPlan" className='form-label'>Membership Plan:</label>
//                                 <select className='form-select' name='membershipplan' value={memberModel?.membershipplan} onChange={handleInput} required>
//                                     <option value="">Select Plan</option>
//                                     {
//                                         subscriberList?.map((curVal, i) => {
//                                             return (
//                                                 <option key={i} value={curVal.id}>{curVal.duration} {curVal.type}</option>
//                                             )
//                                         })
//                                     }
//                                 </select>
//                             </div>
//                         </div>
//                         <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
//                             <div className="mb-3">
//                                 <label htmlFor="membershipFees" className='form-label'>Membership Fees:</label>
//                                 <input type="text" className='form-control' name='membershipfees' value={planPrice} onChange={handleInput} required readOnly />
//                                 {/* <select name="membershipfees" className='form-select' value={memberModel?.membershipfees} onChange={handleInput} required>
//                                     <option defaultValue={''}>Select</option>
//                                     <option value={planPrice}>{planPrice}</option>
//                                 </select> */}
//                             </div>
//                         </div>
//                         <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
//                             <div className="mb-3">
//                                 <label htmlFor="planStart" className='form-label'>Plan Start: </label>
//                                 {
//                                     memberDetails !== null ?
//                                     <>
//                                         <input type="date" className='form-control' name='planstart' value={memberModel?.planstart} min={disableNewPastDate()} onChange={handleInput} required />
//                                     </>
//                                     :
//                                     <>
//                                         <input type="date" className='form-control' name='planstart' value={memberModel?.planstart} min={disablePastDate()} onChange={handleInput} required />
//                                     </>
//                                 }
//                             </div>
//                         </div>
//                     </div>
//                 </div>

//                 <div id='tab2' className='tab hidden'>
//                     <div className='row'>
//                         <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
//                             <div className='mb-3'>
//                                 <label htmlFor="addonType" className='form-label'>Addon Type</label>
//                                 <select name="addonTrainerType" className='form-select' value={memberModel?.addonTrainerType} onChange={handleInput}>
//                                     <option value="">Select</option>
//                                     <option value="Trainer">Trainer</option>
//                                 </select>
//                             </div>
//                         </div>
//                     <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
//                         <div className="mb-3">
//                             <label htmlFor="experienceTrainer" className='form-label'>Trainer: </label>
//                             <select className='form-select' name='trainer' value={memberModel?.trainer} onChange={handleInput}>
//                                 <option defaultValue={0}>Select Trainer</option>
//                                 {
//                                     trainerList?.map((curVal, i) => {
//                                         return (
//                                             <option key={i} value={curVal.id}>{curVal.firstname} {curVal.lastname}</option>
//                                         )
//                                     })
//                                 }
//                             </select>
//                         </div>
//                     </div>
//                     <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
//                         <div className="mb-3">
//                             <label htmlFor="ptTrainer" className='form-label'>Trainer PT: </label>
//                             <select name='trainerPT' className='form-select' value={memberModel?.trainerPT} onChange={handleInput}>
//                                 <option defaultValue={''}>Select</option>
//                                 <option value="Alternate">Alternate PT</option>
//                                 <option value="Regular">Daily PT</option>
//                             </select>
//                         </div>
//                     </div>
//                     <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
//                         <div className="mb-3">
//                             <label htmlFor="durationTrainer" className='form-label'>Trainer Duration: </label>
//                             <select name='trainerduration' className='form-select' value={memberModel?.trainerduration} onChange={handleInput}>
//                                 <option defaultValue={''}>Select</option>
//                                 <option value={30}>30 Days</option>
//                                 <option value={60}>60 Days</option>
//                                 <option value={90}>90 Days</option>
//                                 <option value={180}>180 Days</option>
//                                 <option value={365}>365 Days</option>
//                             </select>
//                         </div>
//                     </div>

//                     <div className='col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12'>
//                         <div className='mb-3'>
//                             <label htmlFor="trainerFees" className='form-label'>Trainer Fees:</label>
//                             <input
//                                 type="text"
//                                 name="trainerfees"
//                                 className='form-control'
//                                 value={aPTPrice}
//                                 onChange={handleInput}
//                                 readOnly
//                             />
//                         </div>
//                     </div>
//                     <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
//                         <div className="mb-3">
//                             <label htmlFor="planStartTrainer" className='form-label'>Trainer Plan Start: </label>
//                             <input type="date" className='form-control' name='planstartTrainer' value={memberModel?.planstartTrainer} min={disablePastDate()} onChange={handleInput} />
//                         </div>
//                     </div>
//                 </div>
//             </div>



//                 <div id='tab3' className='tab hidden'>
//                     <div className='row'>
//                         <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
//                             <div className='mb-3'>
//                                 <label htmlFor="addonType" className='form-label'>Addon Type</label>
//                                 <select name="addonLockerType" className='form-select' value={memberModel?.addonLockerType} onChange={handleInput}>
//                                     <option value="">Select</option>
//                                     <option value="Locker">Locker</option>
//                                 </select>
//                             </div>
//                         </div>
//                         <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
//                             <div className="mb-3">
//                                 <label htmlFor="lockerNo" className='form-label'>Locker: </label>
//                                 <select className='form-select' name='locker' value={memberModel?.locker} onChange={handleInput}>
//                                 <option defaultValue={''}>Select</option>
//                                 {lockerList?.map((curVal, i) => {
//                                     return (
//                                         curVal?.assign === 0 ? (
//                                             <option
//                                                 key={i}
//                                                 value={curVal?.id}
//                                                 data-id={curVal?.id} 
//                                             >
//                                                 {curVal?.locker_no}
//                                             </option>
//                                         ) : (
//                                             null
//                                         )
//                                     );
//                                 })}
//                             </select>
//                             </div>
//                         </div>
//                         <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
//                             <div className="mb-3">
//                                 <label htmlFor="lockerDuration" className='form-label'>Locker Duration:</label>
//                                 <select name='lockerduration' className='form-select' value={memberModel?.lockerduration} onChange={handleInput}>
//                                     <option defaultValue={''}>Select</option>
//                                     <option value={30}>30 Days</option>
//                                     <option value={60}>60 Days</option>
//                                     <option value={90}>90 Days</option>
//                                     <option value={180}>180 Days</option>
//                                     <option value={365}>365 Days</option>
//                                 </select>
//                             </div>
//                         </div>
//                         <div className='col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12'>
//                             <div className='mb-3'>
//                                 <label htmlFor="lockerPrice" className='form-label'>Locker Price:</label>
//                                 <input type="text" name="lockerPrice" className='form-control' value={Math.floor(lockerPrice)} onChange={handleInput} readOnly />
//                                 {/* <select name="lockerPrice" className='form-select' value={memberModel?.lockerPrice} onChange={handleInput}>
//                                     <option defaultValue={''}>Select</option>
//                                     <option value={Math.floor(lockerPrice)}>{Math.floor(lockerPrice)}</option>
//                                 </select> */}
//                             </div>
//                         </div>
//                         <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
//                             <div className="mb-3">
//                                 <label htmlFor="planStartLocker" className='form-label'>Locker Plan Start: </label>
//                                 <input type="date" className='form-control' name='planstartLocker' value={memberModel?.planstartLocker} min={disablePastDate()} onChange={handleInput} />
//                             </div>
//                         </div>
//                     </div>
//                 </div>

//                 <>
//                 {
//                     console.log(addonName)
//                 }
//                 </>
//                 <div id='tab4' className={`tab ${step === 'tab4' ? '' : 'hidden'}`}>  
//                     <div className='row'>
//                     <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
//                         <div className='mb-3'>
//                             <label htmlFor="addonType" className='form-label'>Addon Type</label>: Boxing
//                             <select name="addonBoxingType" className='form-select' value={memberModel?.addonBoxingType} onChange={handleInput}>
//                                 <option value="">Select</option>
//                                 <option value="Boxing">Boxing</option> {/* Manually add "Boxing" as an option */}
//                                 {
//                                     addonPrice?.Boxing?.map((curVal, i) => (
//                                         <option key={i} value={curVal.id}>{curVal.name}</option>
//                                     ))
//                                 }
//                             </select>
//                         </div>
//                     </div>
//                         <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
//                             <div className="mb-3">
//                             <label htmlFor="addonDuration" className='form-label'>Addon Duration:</label>
//                             <select
//                             name='addonDuration'
//                             className='form-select'
//                             value={memberModel?.addonDuration} // Ensure this value is set correctly
//                             onChange={handleInput} // Create a new event handler for this dropdown
//                             >
//                                 <option value="">Select</option>
//                                 <option value="30">30 Days</option>
//                                 <option value="60">60 Days</option>
//                                 <option value="90">90 Days</option>
//                                 <option value="180">180 Days</option>
//                                 <option value="365">365 Days</option>
//                             </select>
//                             </div>
//                         </div>
//                         <div className='col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12'>
//                             <div className='mb-3'>
//                                 <label htmlFor="addonPrice" className='form-label'>Addon Price:</label>
//                                 <input
//                                     type="text"
//                                     name="addonPrice"
//                                     className='form-control'
//                                     value={boxingPrice}
//                                     readOnly
//                                 />
//                             </div>
//                         </div>
//                         <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
//                             <div className="mb-3">
//                                 <label htmlFor="planStart" className='form-label'>Plan Start: </label>
//                                 {
//                                     memberDetails !== null ?
//                                     <>
//                                         <input type="date" className='form-control' name='boxingPlanstart' value={memberModel?.boxingPlanstart} min={disableNewPastDate()} onChange={handleInput} />
//                                     </>
//                                     :
//                                     <>
//                                         <input type="date" className='form-control' name='boxingPlanstart' value={memberModel?.boxingPlanstart} min={disablePastDate()} onChange={handleInput} />
//                                     </>
//                                 }
//                             </div>
//                         </div>  
//                         {/* Add other fields related to the Boxing addon here */}
//                     </div>
//                 </div>

//                 <div id='tab5' className={`tab ${step === 'tab5' ? '' : 'hidden'}`}>
//                     <div className='row'>
//                     <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
//                         <div className='mb-3'>
//                             <label htmlFor="addonType" className='form-label'>Addon Type</label>: Dance
//                             <select name="addonDanceType" className='form-select' value={memberModel?.addonDanceType} onChange={handleInput}>
//                                 <option value="">Select</option>
//                                 <option value="Dance">Dance</option> {/* Manually add "Boxing" as an option */}
//                                 {
//                                     addonPrice?.Dance?.map((curVal, i) => (
//                                         <option key={i} value={curVal.id}>{curVal.name}</option>
//                                     ))
//                                 }
//                             </select>
//                         </div>
//                     </div>
//                         <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
//                             <div className="mb-3">
//                             <label htmlFor="addonDanceDuration" className='form-label'>Addon Duration:</label>
//                             <select
//                             name='addonDanceDuration'
//                             className='form-select'
//                             value={memberModel?.addonDanceDuration} // Ensure this value is set correctly
//                             onChange={handleInput} // Create a new event handler for this dropdown
//                             >
//                                 <option value="">Select</option>
//                                 <option value="30">30 Days</option>
//                                 <option value="60">60 Days</option>
//                                 <option value="90">90 Days</option>
//                                 <option value="180">180 Days</option>
//                                 <option value="365">365 Days</option>
//                             </select>
//                             </div>
//                         </div>
//                         <div className='col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12'>
//                             <div className='mb-3'>
//                                 <label htmlFor="addonPrice" className='form-label'>Addon Price:</label>
//                                 <input
//                                 type="text"
//                                 name="addonPrice"
//                                 className='form-control'
//                                 value={DancePrice}
//                                 readOnly
//                                 />
//                             </div>
//                         </div> 
//                         <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
//                             <div className="mb-3">
//                                 <label htmlFor="planStart" className='form-label'>Plan Start: </label>
//                                 {
//                                     memberDetails !== null ?
//                                     <>
//                                         <input type="date" className='form-control' name='dancePlanstart' value={memberModel?.dancePlanstart} min={disableNewPastDate()} onChange={handleInput}  />
//                                     </>
//                                     :
//                                     <>
//                                         <input type="date" className='form-control' name='dancePlanstart' value={memberModel?.dancePlanstart} min={disablePastDate()} onChange={handleInput}  />
//                                     </>
//                                 }
//                             </div>
//                         </div> 
//                         {/* Add other fields related to the Boxing addon here */}
//                     </div>
//                 </div>
                
//                 <div id='tab6' className={`tab ${step === 'tab6' ? '' : 'hidden'}`}>
//                     <div className='row'>
//                     <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
//                         <div className='mb-3'>
//                             <label htmlFor="addonType" className='form-label'>Addon Type</label>: yoga
//                             <select name="addonYogaType" className='form-select' value={memberModel?.addonYogaType} onChange={handleInput}>
//                                 <option value="">Select</option>
//                                 <option value="Yoga">Yoga</option> {/* Manually add "Boxing" as an option */}
//                                 {
//                                     addonPrice?.Yoga?.map((curVal, i) => (
//                                         <option key={i} value={curVal.id}>{curVal.name}</option>
//                                     ))
//                                 }
//                             </select>
//                         </div>
//                     </div>
//                         <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
//                             <div className="mb-3">
//                             <label htmlFor="addonYogaDuration" className='form-label'>Addon Duration:</label>
//                             <select
//                             name='addonYogaDuration'
//                             className='form-select'
//                             value={memberModel?.addonYogaDuration} // Ensure this value is set correctly
//                             onChange={handleInput} // Create a new event handler for this dropdown
//                             >
//                                 <option value="">Select</option>
//                                 <option value="30">30 Days</option>
//                                 <option value="60">60 Days</option>
//                                 <option value="90">90 Days</option>
//                                 <option value="180">180 Days</option>
//                                 <option value="365">365 Days</option>
//                             </select>
//                             </div>
//                         </div>
//                         <div className='col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12'>
//                             <div className='mb-3'>
//                                 <label htmlFor="addonPrice" className='form-label'>Addon Price:</label>
//                                 <input
//                                 type="text"
//                                 name="addonPrice"
//                                 className='form-control'
//                                 value={yogaPrice}
//                                 readOnly
//                                 />
//                             </div>
//                         </div>
//                         <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
//                             <div className="mb-3">
//                                 <label htmlFor="planStart" className='form-label'>Plan Start: </label>
//                                 {
//                                     memberDetails !== null ?
//                                     <>
//                                         <input type="date" className='form-control' name='yogaPlanstart' value={memberModel?.yogaPlanstart} min={disableNewPastDate()} onChange={handleInput}  />
//                                     </>
//                                     :
//                                     <>
//                                         <input type="date" className='form-control' name='yogaPlanstart' value={memberModel?.yogaPlanstart} min={disablePastDate()} onChange={handleInput}  />
//                                     </>
//                                 }
//                             </div>
//                         </div>  
//                         {/* Add other fields related to the Boxing addon here */}
//                     </div>
//                 </div>

//                 <div id='tab7' className={`tab ${step === 'tab7' ? '' : 'hidden'}`}>
//                     <div className='row'>
//                     <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
//                         <div className='mb-3'>
//                             <label htmlFor="addonType" className='form-label'>Addon Type</label>: Steam
//                             <select name="addonSteamType" className='form-select' value={memberModel?.addonSteamType} onChange={handleInput}>
//                                 <option value="">Select</option>
//                                 <option value="Steam">Steam</option> {/* Manually add "Boxing" as an option */}
//                                 {
//                                     addonPrice?.Steam?.map((curVal, i) => (
//                                         <option key={i} value={curVal.id}>{curVal.name}</option>
//                                     ))
//                                 }
//                             </select>
//                         </div>
//                     </div>
//                         <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
//                             <div className="mb-3">
//                             <label htmlFor="addonSteamDuration" className='form-label'>Addon Duration:</label>
//                             <select
//                             name='addonSteamDuration'
//                             className='form-select'
//                             value={memberModel?.addonSteamDuration} // Ensure this value is set correctly
//                             onChange={handleInput} // Create a new event handler for this dropdown
//                             >
//                                 <option value="">Select</option>
//                                 <option value="30">30 Days</option>
//                                 <option value="60">60 Days</option>
//                                 <option value="90">90 Days</option>
//                                 <option value="180">180 Days</option>
//                                 <option value="365">365 Days</option>
//                             </select>
//                             </div>
//                         </div>
//                         <div className='col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12'>
//                             <div className='mb-3'>
//                                 <label htmlFor="addonPrice" className='form-label'>Addon Price:</label>
//                                 <input
//                                 type="text"
//                                 name="addonPrice"
//                                 className='form-control'
//                                 value={steamPrice}
//                                 readOnly
//                                 />
//                             </div>
//                         </div>
//                         <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
//                             <div className="mb-3">
//                                 <label htmlFor="planStart" className='form-label'>Plan Start: </label>
//                                 {
//                                     memberDetails !== null ?
//                                     <>
//                                         <input type="date" className='form-control' name='steamPlanstart' value={memberModel?.steamPlanstart} min={disableNewPastDate()} onChange={handleInput}  />
//                                     </>
//                                     :
//                                     <>
//                                         <input type="date" className='form-control' name='steamPlanstart' value={memberModel?.steamPlanstart} min={disablePastDate()} onChange={handleInput}  />
//                                     </>
//                                 }
//                             </div>
//                         </div>  
//                         {/* Add other fields related to the Boxing addon here */}
//                     </div>
//                 </div>

//                 <div id='tab8' className={`tab ${step === 'tab8' ? '' : 'hidden'}`}>
//                     <div className='row'>
//                     <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
//                         <div className='mb-3'>
//                             <label htmlFor="addonType" className='form-label'>Addon Type</label>: Cycling
//                             <select name="addonCyclingType" className='form-select' value={memberModel?.addonCyclingType} onChange={handleInput}>
//                                 <option value="">Select</option>
//                                 <option value="Cycling">Cycling</option> {/* Manually add "Boxing" as an option */}
//                                 {
//                                     addonPrice?.Cycling?.map((curVal, i) => (
//                                         <option key={i} value={curVal.id}>{curVal.name}</option>
//                                     ))
//                                 }
//                             </select>
//                         </div>
//                     </div>
//                         <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
//                             <div className="mb-3">
//                             <label htmlFor="addonCyclingDuration" className='form-label'>Addon Duration:</label>
//                             <select
//                             name='addonCyclingDuration'
//                             className='form-select'
//                             value={memberModel?.addonCyclingDuration} // Ensure this value is set correctly
//                             onChange={handleInput} // Create a new event handler for this dropdown
//                             >
//                                 <option value="">Select</option>
//                                 <option value="30">30 Days</option>
//                                 <option value="60">60 Days</option>
//                                 <option value="90">90 Days</option>
//                                 <option value="180">180 Days</option>
//                                 <option value="365">365 Days</option>
//                             </select>
//                             </div>
//                         </div>
//                         <div className='col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12'>
//                             <div className='mb-3'>
//                                 <label htmlFor="addonPrice" className='form-label'>Addon Price:</label>
//                                 <input
//                                     type="text"
//                                     name="addonPrice"
//                                     className='form-control'
//                                     value={cyclingPrice}
//                                     readOnly
//                                 />
//                             </div>
//                         </div>
//                         <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
//                             <div className="mb-3">
//                                 <label htmlFor="planStart" className='form-label'>Plan Start: </label>
//                                 {
//                                     memberDetails !== null ?
//                                     <>
//                                         <input type="date" className='form-control' name='cyclingPlanstart' value={memberModel?.cyclingPlanstart} min={disableNewPastDate()} onChange={handleInput}  />
//                                     </>
//                                     :
//                                     <>
//                                         <input type="date" className='form-control' name='cyclingPlanstart' value={memberModel?.cyclingPlanstart} min={disablePastDate()} onChange={handleInput} />
//                                     </>
//                                 }
//                             </div>
//                         </div>  
//                     </div>
//                 </div>

//                 <div id='tab9' className={`tab ${step === 'tab9' ? '' : 'hidden'}`}>
//                     <div className='row'>
//                     <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
//                         <div className='mb-3'>
//                             <label htmlFor="addonType" className='form-label'>Addon Type</label>: Functional Training
//                             <select name="addonFunctional_TrainingType" className='form-select' value={memberModel?.addonFunctional_TrainingType} onChange={handleInput}>
//                                 <option value="">Select</option>
//                                 <option value="Functional_Training">Functional_Training</option> {/* Manually add "Boxing" as an option */}
//                                 {
//                                     addonPrice?.Functional_Training?.map((curVal, i) => (
//                                         <option key={i} value={curVal.id}>{curVal.name}</option>
//                                     ))
//                                 }
//                             </select>
//                         </div>
//                     </div>
//                         <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
//                             <div className="mb-3">
//                             <label htmlFor="addonFunctional_TrainingDuration" className='form-label'>Addon Duration:</label>
//                             <select
//                             name='addonFunctional_TrainingDuration'
//                             className='form-select'
//                             value={memberModel?.addonFunctional_TrainingDuration} // Ensure this value is set correctly
//                             onChange={handleInput} // Create a new event handler for this dropdown
//                             >
//                                 <option value="">Select</option>
//                                 <option value="30">30 Days</option>
//                                 <option value="60">60 Days</option>
//                                 <option value="90">90 Days</option>
//                                 <option value="180">180 Days</option>
//                                 <option value="365">365 Days</option>
//                             </select>
//                             </div>
//                         </div>
//                         <div className='col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12'>
//                             <div className='mb-3'>
//                                 <label htmlFor="addonPrice" className='form-label'>Addon Price:</label>
//                                 <input
//                                     type="text"
//                                     name="addonPrice"
//                                     className='form-control'
//                                     value={functional_Training}
//                                     readOnly
//                                 />
//                             </div>
//                         </div>
//                         <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
//                             <div className="mb-3">
//                                 <label htmlFor="planStart" className='form-label'>Plan Start: </label>
//                                 {
//                                     memberDetails !== null ?
//                                     <>
//                                         <input type="date" className='form-control' name='functional_TrainingPlanstart' value={memberModel?.functional_TrainingPlanstart} min={disableNewPastDate()} onChange={handleInput} />
//                                     </>
//                                     :
//                                     <>
//                                         <input type="date" className='form-control' name='functional_TrainingPlanstart' value={memberModel?.functional_TrainingPlanstart} min={disablePastDate()} onChange={handleInput} />
//                                     </>
//                                 }
//                             </div>
//                         </div>  
//                     </div>
//                 </div>

//                 <div id='tab10' className={`tab ${step === 'tab10' ? '' : 'hidden'}`}>
//                     <div className='row'>
//                     <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
//                         <div className='mb-3'>
//                             <label htmlFor="addonType" className='form-label'>Addon Type</label>: Zumba
//                             <select name="addonZumbaType" className='form-select' value={memberModel?.addonZumbaType} onChange={handleInput}>
//                                 <option value="">Select</option>
//                                 <option value="Zumba">Zumba</option>
//                                 {
//                                     addonPrice?.Zumba?.map((curVal, i) => (
//                                         <option key={i} value={curVal.id}>{curVal.name}</option>
//                                     ))
//                                 }
//                             </select>
//                         </div>
//                     </div>
//                         <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
//                             <div className="mb-3">
//                             <label htmlFor="addonZumbaDuration" className='form-label'>Addon Duration:</label>
//                             <select
//                             name='addonZumbaDuration'
//                             className='form-select'
//                             value={memberModel?.addonZumbaDuration}
//                             onChange={handleInput} 
//                             >
//                                 <option value="">Select</option>
//                                 <option value="30">30 Days</option>
//                                 <option value="60">60 Days</option>
//                                 <option value="90">90 Days</option>
//                                 <option value="180">180 Days</option>
//                                 <option value="365">365 Days</option>
//                             </select>
//                             </div>
//                         </div>
//                         <div className='col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12'>
//                             <div className='mb-3'>
//                                 <label htmlFor="addonPrice" className='form-label'>Addon Price:</label>
//                                 <input
//                                     type="text"
//                                     name="addonPrice"
//                                     className='form-control'
//                                     value={zumbaPrice}
//                                     readOnly
//                                 />
//                             </div>
//                         </div>
//                         <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
//                             <div className="mb-3">
//                                 <label htmlFor="planStart" className='form-label'>Plan Start: </label>
//                                 {
//                                     memberDetails !== null ?
//                                     <>
//                                         <input type="date" className='form-control' name='zumbaPlanstart' value={memberModel?.zumbaPlanstart} min={disableNewPastDate()} onChange={handleInput} />
//                                     </>
//                                     :
//                                     <>
//                                         <input type="date" className='form-control' name='zumbaPlanstart' value={memberModel?.zumbaPlanstart} min={disablePastDate()} onChange={handleInput}  />
//                                     </>
//                                 }
//                             </div>
//                         </div>  
//                     </div>
//                 </div>

//                 <div id='tab11' className={`tab ${step === 'tab11' ? '' : 'hidden'}`}>
//                     <div className='row'>
//                     <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
//                         <div className='mb-3'>
//                             <label htmlFor="addonType" className='form-label'>Addon Type</label>: Crossfit
//                             <select name="addonCrossfitType" className='form-select' value={memberModel?.addonCrossfitType} onChange={handleInput}>
//                                 <option value="">Select</option>
//                                 <option value="Crossfit">Crossfit</option> {/* Manually add "Zumba" as an option */}
//                                 {
//                                     addonPrice?.Crossfit?.map((curVal, i) => (
//                                         <option key={i} value={curVal.id}>{curVal.name}</option>
//                                     ))
//                                 }
//                             </select>
//                         </div>
//                     </div>
//                         <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
//                             <div className="mb-3">
//                             <label htmlFor="addonCrossfitDuration" className='form-label'>Addon Duration:</label>
//                             <select
//                             name='addonCrossfitDuration'
//                             className='form-select'
//                             value={memberModel?.addonCrossfitDuration} // Ensure this value is set correctly
//                             onChange={handleInput} // Create a new event handler for this dropdown
//                             >
//                                 <option value="">Select</option>
//                                 <option value="30">30 Days</option>
//                                 <option value="60">60 Days</option>
//                                 <option value="90">90 Days</option>
//                                 <option value="180">180 Days</option>
//                                 <option value="365">365 Days</option>
//                             </select>
//                             </div>
//                         </div>
//                         <div className='col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12'>
//                             <div className='mb-3'>
//                                 <label htmlFor="addonPrice" className='form-label'>Addon Price:</label>
//                                 <input
//                                     type="text"
//                                     name="addonPrice"
//                                     className='form-control'
//                                     value={crossfitPrice}
//                                     readOnly
//                                 />
//                             </div>
//                         </div>
//                         <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
//                             <div className="mb-3">
//                                 <label htmlFor="planStart" className='form-label'>Plan Start: </label>
//                                 {
//                                     memberDetails !== null ?
//                                     <>
//                                         <input type="date" className='form-control' name='crossfitPlanstart' value={memberModel?.crossfitPlanstart} min={disableNewPastDate()} onChange={handleInput}  />
//                                     </>
//                                     :
//                                     <>
//                                         <input type="date" className='form-control' name='crossfitPlanstart' value={memberModel?.crossfitPlanstart} min={disablePastDate()} onChange={handleInput}  />
//                                     </>
//                                 }
//                             </div>
//                         </div>  
//                     </div>
//                 </div>

//                 <div id='tab12' className={`tab ${step === 'tab12' ? '' : 'hidden'}`}>
//                     <div className='row'>
//                     <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
//                         <div className='mb-3'>
//                             <label htmlFor="addonType" className='form-label'>Addon Type</label>: Massage
//                             <select name="addonMassageType" className='form-select' value={memberModel?.addonMassageType} onChange={handleInput}>
//                                 <option value="">Select</option>
//                                 <option value="Massage">Massage</option> {/* Manually add "Zumba" as an option */}
//                                 {
//                                     addonPrice?.Massage?.map((curVal, i) => (
//                                         <option key={i} value={curVal.id}>{curVal.name}</option>
//                                     ))
//                                 }
//                             </select>
//                         </div>
//                     </div>
//                         <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
//                             <div className="mb-3">
//                             <label htmlFor="addonMassageDuration" className='form-label'>Addon Duration:</label>
//                             <select
//                             name='addonMassageDuration'
//                             className='form-select'
//                             value={memberModel?.addonMassageDuration} // Ensure this value is set correctly
//                             onChange={handleInput} // Create a new event handler for this dropdown
//                             >
//                                 <option value="">Select</option>
//                                 <option value="30">30 Days</option>
//                                 <option value="60">60 Days</option>
//                                 <option value="90">90 Days</option>
//                                 <option value="180">180 Days</option>
//                                 <option value="365">365 Days</option>
//                             </select>
//                             </div>
//                         </div>
//                         <div className='col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12'>
//                             <div className='mb-3'>
//                                 <label htmlFor="addonPrice" className='form-label'>Addon Price:</label>
//                                 <input
//                                     type="text"
//                                     name="addonPrice"
//                                     className='form-control'
//                                     value={massagePrice}
//                                     readOnly
//                                 />
//                             </div>
//                         </div>
//                         <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
//                             <div className="mb-3">
//                                 <label htmlFor="planStart" className='form-label'>Plan Start: </label>
//                                 {
//                                     memberDetails !== null ?
//                                     <>
//                                         <input type="date" className='form-control' name='massagePlanstart' value={memberModel?.massagePlanstart} min={disableNewPastDate()} onChange={handleInput}  />
//                                     </>
//                                     :
//                                     <>
//                                         <input type="date" className='form-control' name='massagePlanstart' value={memberModel?.massagePlanstart} min={disablePastDate()} onChange={handleInput} />
//                                     </>
//                                 }
//                             </div>
//                         </div>  
//                     </div>
//                 </div>

//                 <div id='tab13' className={`tab ${step === 'tab13' ? '' : 'hidden'}`}>
//                     <div className='row'>
//                     <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
//                         <div className='mb-3'>
//                             <label htmlFor="addonType" className='form-label'>Addon Type</label>: Nutrition_Consulting
//                             <select name="addonNutrition_ConsultingType" className='form-select' value={memberModel?.addonNutrition_ConsultingType} onChange={handleInput}>
//                                 <option value="">Select</option>
//                                 <option value="Nutrition_Consulting">Nutrition_Consulting</option> {/* Manually add "Zumba" as an option */}
//                                 {
//                                     addonPrice?.Nutrition_Consulting?.map((curVal, i) => (
//                                         <option key={i} value={curVal.id}>{curVal.name}</option>
//                                     ))
//                                 }
//                             </select>
//                         </div>
//                     </div>
//                         <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
//                             <div className="mb-3">
//                             <label htmlFor="addonNutrition_ConsultingDuration" className='form-label'>Addon Duration:</label>
//                             <select
//                             name='addonNutrition_ConsultingDuration'
//                             className='form-select'
//                             value={memberModel?.addonNutrition_ConsultingDuration} // Ensure this value is set correctly
//                             onChange={handleInput} // Create a new event handler for this dropdown
//                             >
//                                 <option value="">Select</option>
//                                 <option value="30">30 Days</option>
//                                 <option value="60">60 Days</option>
//                                 <option value="90">90 Days</option>
//                                 <option value="180">180 Days</option>
//                                 <option value="365">365 Days</option>
//                             </select>
//                             </div>
//                         </div>
//                         <div className='col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12'>
//                             <div className='mb-3'>
//                                 <label htmlFor="addonPrice" className='form-label'>Addon Price:</label>
//                                 <input
//                                     type="text"
//                                     name="addonPrice"
//                                     className='form-control'
//                                     value={ncPrice}
//                                     readOnly
//                                 />
//                             </div>
//                         </div>
//                         <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
//                             <div className="mb-3">
//                                 <label htmlFor="planStart" className='form-label'>Plan Start: </label>
//                                 {
//                                     memberDetails !== null ?
//                                     <>
//                                         <input type="date" className='form-control' name='nutrition_ConsultingPlanstart' value={memberModel?.nutrition_ConsultingPlanstart} min={disableNewPastDate()} onChange={handleInput}  />
//                                     </>
//                                     :
//                                     <>
//                                         <input type="date" className='form-control' name='nutrition_ConsultingPlanstart' value={memberModel?.nutrition_ConsultingPlanstart} min={disablePastDate()} onChange={handleInput}  />
//                                     </>
//                                 }
//                             </div>
//                         </div>  
//                     </div>
//                 </div>

//                 <div id='tab14' className={`tab ${step === 'tab14' ? '' : 'hidden'}`}>
//                     <div className='row'>
//                     <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
//                         <div className='mb-3'>
//                             <label htmlFor="addonType" className='form-label'>Addon Type</label>: Weight Training
//                             <select name="addonWeight_TrainingType" className='form-select' value={memberModel?.addonWeight_TrainingType} onChange={handleInput}>
//                                 <option value="">Select</option>
//                                 <option value="Weight_Training">Weight Training</option> {/* Manually add "Zumba" as an option */}
//                                 {
//                                     addonPrice?.Weight_Training?.map((curVal, i) => (
//                                         <option key={i} value={curVal.id}>{curVal.name}</option>
//                                     ))
//                                 }
//                             </select>
//                         </div>
//                     </div>
//                         <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
//                             <div className="mb-3">
//                             <label htmlFor="addonWeight_TrainingDuration" className='form-label'>Addon Duration:</label>
//                             <select
//                             name='addonWeight_TrainingDuration'
//                             className='form-select'
//                             value={memberModel?.addonWeight_TrainingDuration} // Ensure this value is set correctly
//                             onChange={handleInput} // Create a new event handler for this dropdown
//                             >
//                                 <option value="">Select</option>
//                                 <option value="30">30 Days</option>
//                                 <option value="60">60 Days</option>
//                                 <option value="90">90 Days</option>
//                                 <option value="180">180 Days</option>
//                                 <option value="365">365 Days</option>
//                             </select>
//                             </div>
//                         </div>
//                         <div className='col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12'>
//                             <div className='mb-3'>
//                                 <label htmlFor="addonPrice" className='form-label'>Addon Price:</label>
//                                 <input
//                                     type="text"
//                                     name="addonPrice"
//                                     className='form-control'
//                                     value={wtPrice}
//                                     readOnly
//                                 />
//                             </div>
//                         </div>
//                         <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
//                             <div className="mb-3">
//                                 <label htmlFor="planStart" className='form-label'>Plan Start: </label>
//                                 {
//                                     memberDetails !== null ?
//                                     <>
//                                         <input type="date" className='form-control' name='weight_TrainingPlanstart' value={memberModel?.weight_TrainingPlanstart} min={disableNewPastDate()} onChange={handleInput}  />
//                                     </>
//                                     :
//                                     <>
//                                         <input type="date" className='form-control' name='weight_TrainingPlanstart' value={memberModel?.weight_TrainingPlanstart} min={disablePastDate()} onChange={handleInput}  />
//                                     </>
//                                 }
//                             </div>
//                         </div>  
//                     </div>
//                 </div>

//                 <div id='tab15' className={`tab ${step === 'tab15' ? '' : 'hidden'}`}>
//                     <div className='row'>
//                     <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
//                         <div className='mb-3'>
//                             <label htmlFor="addonType" className='form-label'>Addon Type</label>: Hiit_Excercise_Classes
//                             <select name="addonHiit_Excercise_ClassesType" className='form-select' value={memberModel?.addonHiit_Excercise_ClassesType} onChange={handleInput}>
//                                 <option value="">Select</option>
//                                 <option value="Hiit_Excercise_Classes">Hiit Excercise Classes</option> {/* Manually add "Zumba" as an option */}
//                                 {
//                                     addonPrice?.Hiit_Excercise_Classes?.map((curVal, i) => (
//                                         <option key={i} value={curVal.id}>{curVal.name}</option>
//                                     ))
//                                 }
//                             </select>
//                         </div>
//                     </div>
//                         <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
//                             <div className="mb-3">
//                             <label htmlFor="addonHiit_Excercise_ClassesDuration" className='form-label'>Addon Duration:</label>
//                             <select
//                             name='addonHiit_Excercise_ClassesDuration'
//                             className='form-select'
//                             value={memberModel?.addonHiit_Excercise_ClassesDuration} // Ensure this value is set correctly
//                             onChange={handleInput} // Create a new event handler for this dropdown
//                             >
//                                 <option value="">Select</option>
//                                 <option value="30">30 Days</option>
//                                 <option value="60">60 Days</option>
//                                 <option value="90">90 Days</option>
//                                 <option value="180">180 Days</option>
//                                 <option value="365">365 Days</option>
//                             </select>
//                             </div>
//                         </div>
//                         <div className='col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12'>
//                             <div className='mb-3'>
//                                 <label htmlFor="addonPrice" className='form-label'>Addon Price:</label>
//                                 <input
//                                     type="text"
//                                     name="addonPrice"
//                                     className='form-control'
//                                     value={hecPrice}
//                                     readOnly
//                                 />
//                             </div>
//                         </div>
//                         <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
//                             <div className="mb-3">
//                                 <label htmlFor="planStart" className='form-label'>Plan Start: </label>
//                                 {
//                                     memberDetails !== null ?
//                                     <>
//                                         <input type="date" className='form-control' name='hiit_excercise_classesPlanstart' value={memberModel?.hiit_excercise_classesPlanstart} min={disableNewPastDate()} onChange={handleInput}  />
//                                     </>
//                                     :
//                                     <>
//                                         <input type="date" className='form-control' name='hiit_excercise_classesPlanstart' value={memberModel?.hiit_excercise_classesPlanstart} min={disablePastDate()} onChange={handleInput}  />
//                                     </>
//                                 }
//                             </div>
//                         </div>  
//                     </div>
//                 </div>

//                 <div id='tab16' className={`tab ${step === 'tab16' ? '' : 'hidden'}`}>
//                     <div className='row'>
//                     <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
//                         <div className='mb-3'>
//                             <label htmlFor="addonType" className='form-label'>Addon Type</label>: Fitness_Studio
//                             <select name="addonFitness_StudioType" className='form-select' value={memberModel?.addonFitness_StudioType} onChange={handleInput}>
//                                 <option value="">Select</option>
//                                 <option value="Fitness_Studio">Fitness_Studio</option> {/* Manually add "Zumba" as an option */}
//                                 {
//                                     addonPrice?.Fitness_Studio?.map((curVal, i) => (
//                                         <option key={i} value={curVal.id}>{curVal.name}</option>
//                                     ))
//                                 }
//                             </select>
//                         </div>
//                     </div>
//                         <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
//                             <div className="mb-3">
//                             <label htmlFor="addonFitness_StudioDuration" className='form-label'>Addon Duration:</label>
//                             <select
//                             name='addonFitness_StudioDuration'
//                             className='form-select'
//                             value={memberModel?.addonFitness_StudioDuration} // Ensure this value is set correctly
//                             onChange={handleInput} // Create a new event handler for this dropdown
//                             >
//                                 <option value="">Select</option>
//                                 <option value="30">30 Days</option>
//                                 <option value="60">60 Days</option>
//                                 <option value="90">90 Days</option>
//                                 <option value="180">180 Days</option>
//                                 <option value="365">365 Days</option>
//                             </select>
//                             </div>
//                         </div>
//                         <div className='col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12'>
//                             <div className='mb-3'>
//                                 <label htmlFor="addonPrice" className='form-label'>Addon Price:</label>
//                                 <input
//                                     type="text"
//                                     name="addonPrice"
//                                     className='form-control'
//                                     value={fsPrice}
//                                     readOnly
//                                 />
//                             </div>
//                         </div>
//                         <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
//                             <div className="mb-3">
//                                 <label htmlFor="planStart" className='form-label'>Plan Start: </label>
//                                 {
//                                     memberDetails !== null ?
//                                     <>
//                                         <input type="date" className='form-control' name='Fitness_StudioPlanstart' value={memberModel?.Fitness_StudioPlanstart} min={disableNewPastDate()} onChange={handleInput}  />
//                                     </>
//                                     :
//                                     <>
//                                         <input type="date" className='form-control' name='Fitness_StudioPlanstart' value={memberModel?.Fitness_StudioPlanstart} min={disablePastDate()} onChange={handleInput} />
//                                     </>
//                                 }
//                             </div>
//                         </div>  
//                     </div>
//                 </div>

//                 <div className='mb-3 d-flex gap-2'>
//                     <button type='button' className='btn btn-outline-danger float-start hidden' id='prev' onClick={() => {setStep(step-1)}}>Previous</button>
//                     <button type='button' className='btn btn-outline-warning float-start' id='next' onClick={() => {setStep(step+1)}}>Next</button>
//                 </div>   
//                 <div className="modal-footer">
//                     <button type="submit" className="btn btn-outline-primary">Submit</button>
//                 </div>
//             </form>
//         </Modal.Body>
//         <Modal.Footer>
//             <div className='d-flex flex-column' style={{lineHeight: '0.5'}}>
//                 <p className='text-danger font_18'>Your Order is</p>
//                 <p className='text-success fw-bold font_22'>Total Amount: &#8377;{totalPrice}</p>
//                 <div className='d-flex flex-row'>
//                     <p className='text-success'>Subscription Amount: &#8377;{planPrice}</p>
//                     <p className='text-success px-2'>Trainer Amount: &#8377;{aPTPrice}</p>
//                 </div>
//                 <div className='d-flex flex-row'>
//                     <p className='text-success'>Locker Amount: &#8377;{Math.floor(lockerPrice)}</p>
//                     {/* <p className='text-success px-2'>Upgrade Amount: &#8377;{Math.floor(upgradePrice)}</p> */}
//                 </div>
//                 <div className='d-flex flex-row mb-4'>
//                     <p className='text-success px-2'>Boxing: &#8377;{Math.floor(boxingPrice)}</p>
//                     <p className='text-success px-2'>Dance: &#8377;{Math.floor(DancePrice)}</p>
//                     <p className='text-success px-2'>Yoga: &#8377;{Math.floor(yogaPrice)}</p>
//                     <p className='text-success px-2'>Steam: &#8377;{Math.floor(steamPrice)}</p>
//                     <p className='text-success px-2'>Cycling: &#8377;{Math.floor(cyclingPrice)}</p>
//                     <p className='text-success px-2'>FT: &#8377;{Math.floor(functional_Training)}</p>
                    
//                 </div>
//             </div>
//         </Modal.Footer>
//     </Modal>
//     <ToastContainer />
//     </>
//   )
// }

// export default ActivatePlanModel;


// const data =[
//     {id:212, name:"ramu"},
//     {id:3, name:"janumu"},
//     {id:5, name:"wda"},
// ]

// packgedd: data

// let selctedData= {
//     id:123,
//     packgedd: data
// }


// const dada = ["cbhjhd", "chdghs", "djkdfh", "dhjjdf"]