import React, { useEffect, useState } from "react";
import axios from "axios";
import Loading from "./Loading";
import { useSelector } from "react-redux";

function Notification() {
  const [noti, setNoti] = useState([]);
  const [missedData, setMissedData] = useState([]);
  const [planData, setPlanData] = useState([]);
  const [paymentData, setPaymentData] = useState([]);
  const [filterText, setFilterText] = useState("");
  const [tabState, setTabState] = useState("all");
  const getGymId = useSelector((state) => state.counterSlice.gymId);
  const deviceToken = getGymId.device_token;
  // const [count, setCount] = useState([]);
  // const refreshTime = 600000;
  console.log(tabState);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 5000);
  }, []);

  const handleNotification = async () => {
    const res = await axios.get(
      `${process.env.REACT_APP_URL}test_cron/admin_notifications`
    );
    // console.log(res);
    setNoti(res?.data.data);
    // setCount(res?.data.count);
  };
  useEffect(() => {
    handleNotification();
    // const notInterval = setInterval(handleNotification, refreshTime);
    // return () => clearInterval(notInterval);
  }, []);

  const initialReadStatus =
    JSON.parse(localStorage.getItem("readStatus")) || {};
  const initialClickedStatus = {};

  // const initialUnreadCount = noti?.reduce(
  //   (count, item) => {
  //     if(!initialReadStatus[item?.id] && !initialClickedStatus[item?.id]) {
  //       return count + 1;
  //     }
  //     else if(initialReadStatus[item?.id] && initialClickedStatus[item?.id]) {
  //       return count;
  //     }
  //     else {
  //       return count - 1;
  //     }
  //   },
  //   noti?.length
  // )

  const [readStatus, setReadStatus] = useState(initialReadStatus);
  const [clickedStatus, setClickedStatus] = useState(initialClickedStatus);
  // const [unreadCount, setUnreadCount] = useState(initialUnreadCount);

  const readNotification = (id) => {
    if (!clickedStatus[id]) {
      setClickedStatus((prevStatus) => ({
        ...prevStatus,
        [id]: true,
      }));
    }

    if (!readStatus[id]) {
      setReadStatus((prevStatus) => ({
        ...prevStatus,
        [id]: true,
      }));

      // setUnreadCount((prevCount) => prevCount - 1);
    }
  };

  useEffect(() => {
    localStorage.setItem("readStatus", JSON.stringify(readStatus));
  }, [readStatus]);

  const unreadCount = noti?.reduce(
    (count, item) => (!readStatus[item.id] ? 1 : count),
    0
  );

  // const handleFilterClick = (event) => {
  //   const buttonTitle = event.target.title;
  //   setFilterText(buttonTitle);
  //   // console.log(buttonTitle);
  // }

  useEffect(() => {
    handleGetMissed();
  }, []);

  const handleGetMissed = async () => {
    const config = {
      headers: {
        Authorization: deviceToken,
      },
    };
    const res = await axios.get(
      `${process.env.REACT_APP_URL}checkAttendanceYesterday`,
      config
    );
    // console.log(98989, data)
    if (res?.status === 200) {
      setMissedData(res?.data?.data || []);
    }
  };

  useEffect(() => {
    handleGetPlan();
  }, []);

  const handleGetPlan = async () => {
    const config = {
      headers: {
        Authorization: deviceToken,
      },
    };
    const res = await axios.get(
      `${process.env.REACT_APP_URL}getExpiringSubscriptions`,
      config
    );
    // console.log(98989, data)
    if (res?.status === 200) {
      setPlanData(res?.data?.data || []);
    }
  };

  useEffect(() => {
    handleNewPayment();
  }, []);

  const handleNewPayment = async () => {
    const config = {
      headers: {
        Authorization: deviceToken,
      },
    };
    const res = await axios.get(
      `${process.env.REACT_APP_URL}getNewPaymentToday`,
      config
    );
    // console.log(98989, data)
    if (res?.status === 200) {
      setPaymentData(res?.data?.data || []);
    }
  };

  return (
    <>
      {loading ? (
        <Loading loading={loading} />
      ) : (
        <>
          <div
            style={{ maxWidth: "1600px" }}
            className="mx-auto container px-5 py-sm-5 py-md-5 py-lg-5 py-xl-5 py-xxl-5 px-5"
          >
            <div className="row">
              <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                <h3 className="mt-5">Notification List ({unreadCount})</h3>
              </div>
            </div>
          </div>
          <section className="py-sm-2 py-md-2 py-lg-2 py-xl-2 py-xxl-2 min-vh-100">
            <div
              style={{ maxWidth: "1600px" }}
              className="mx-auto container-fluid px-5"
            >
              <div className="card">
                <div className="card-body">
                  <div className="d-flex gap-2">
                    <button
                      type="button"
                      className={
                        tabState === "all"
                          ? "btn btn-outline-primary active"
                          : "btn btn-outline-primary"
                      }
                      title=""
                      onClick={() => setTabState("all")}
                    >
                      All
                    </button>
                    <button
                      type="button"
                      className={
                        tabState === "relogin"
                          ? "btn btn-outline-primary active"
                          : "btn btn-outline-primary"
                      }
                      title="Re-Login"
                      onClick={() => setTabState("relogin")}
                    >
                      Re-Login
                    </button>
                    <button
                      type="button"
                      className={
                        tabState === "payment"
                          ? "btn btn-outline-primary active"
                          : "btn btn-outline-primary"
                      }
                      title="Re-Login"
                      onClick={() => setTabState("payment")}
                    >
                      New Payments
                    </button>
                    <button
                      type="button"
                      className={
                        tabState === "missed"
                          ? "btn btn-outline-primary active"
                          : "btn btn-outline-primary"
                      }
                      title="Missed Login"
                      onClick={() => setTabState("missed")}
                    >
                      Missed Login
                    </button>
                    <button
                      type="button"
                      className={
                        tabState === "expiry"
                          ? "btn btn-outline-primary active"
                          : "btn btn-outline-primary"
                      }
                      title="Plan Expiry"
                      onClick={() => setTabState("expiry")}
                    >
                      Plan Expiry
                    </button>
                  </div>

                  <div className="row pb-5">
                    <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                      <div className="table table-responsive-sm table-responsive-md table-responsive-lg table-responsive-xl table-responsive-xxl mt-3">
                        {/* {tabState === "all" ?
                            <>
                              <div>
                                <h4>New Payments</h4>
                                <table className="table table-bordered table-striped-rows table-hover">
                                  <thead className='table-dark'>
                                    <tr>
                                      <th>Sr no.</th>
                                      <th>Member ID</th>
                                      <th>Name</th>
                                      <th>Total Amount</th>
                                      <th>Amount Due</th>
                                      <th>Amount Paid</th>
                                      <th>Status</th>
                                      <th>Payment Date</th>
                                      <th>Payment Time</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {paymentData.length > 0 &&
                                      paymentData.map((item, i) => (
                                        <tr>
                                          <td>{i + 1}</td>
                                          <td>{item.member_id}</td>
                                          <td>{item.firstname} {item.lastname}</td>
                                          <td>{item.amount}</td>
                                          <td>{item.amount_due}</td>
                                          <td>{item.amount_paid}</td>
                                          <td>{item.status}</td>
                                          <td>{item.payment_date}</td>
                                          <td>{item.payment_time}</td>
                                        </tr>))}

                                  </tbody>
                                </table>
                              </div>
                              <div>
                                <h4>Missed Login</h4>
                                <table className="table table-bordered table-striped-rows table-hover">
                                  <thead className='table-dark'>
                                    <tr>
                                      <th>Sr no.</th>
                                      <th>Member ID</th>

                                      <th>Name</th>

                                    </tr>
                                  </thead>
                                  <tbody>
                                    {missedData.length > 0 &&
                                      missedData.map((item, i) => (
                                        <tr>
                                          <td>{i + 1}</td>
                                          <td>{item.member_id}</td>
                                          <td>{item.firstname} {item.lastname}</td>
                                        </tr>))}

                                  </tbody>
                                </table>
                              </div>
                              <div>
                                <h4>Plan Expiry</h4>
                                <table className="table table-bordered table-striped-rows table-hover">
                                  <thead className='table-dark'>
                                    <tr>
                                      <th>Sr no.</th>
                                      <th>Member ID</th>

                                      <th>Name</th>

                                      <th>Days Left</th>
                                      <th>Expiry Date</th>


                                    </tr>
                                  </thead>
                                  <tbody>
                                    {planData.length > 0 &&
                                      planData.map((item, i) => (
                                        <tr>
                                          <td>{i + 1}</td>
                                          <td>{item.member_id}</td>
                                          <td>{item.firstname} {item.lastname}</td>
                                          <td>{item.remaining_days}</td>
                                          <td>{item.enddate}</td>
                                        </tr>))}
                                  </tbody>
                                </table>
                              </div>
                            </>
                            :  */}

                        {(tabState === "relogin" || tabState === "all") && (
                          <div>
                            {tabState === "all" && (
                              <h2 className="text-center">
                                {noti?.length > 0 ? "Re-Login" : ""}
                              </h2>
                            )}
                            <div className="container pt-2">
                              <ul className="list-group noti-ul">
                                {noti?.length > 0 &&
                                  // eslint-disable-next-line
                                  noti
                                    ?.filter((curVal) => {
                                      if (filterText.toLowerCase() === "") {
                                        return curVal;
                                      } else if (curVal?.title === filterText) {
                                        return curVal;
                                      }
                                    })
                                    ?.map((curVal, i) => {
                                      return (
                                        <>
                                          <li
                                            className={`list-group-item ${
                                              readStatus[curVal?.id]
                                                ? "read"
                                                : "unread active"
                                            }`}
                                            key={i}
                                            onClick={() =>
                                              readNotification(curVal?.id)
                                            }
                                          >
                                            <div className="notifi-item">
                                              <div className="noti-content text text-wrap">
                                                <h4>{curVal?.title}</h4>
                                                <p>{curVal?.body}</p>
                                              </div>
                                            </div>
                                          </li>
                                        </>
                                      );
                                    })}
                              </ul>
                            </div>
                          </div>
                        )}

                        {(tabState === "payment" || tabState === "all") && (
                          <div>
                            <div className="text-center">
                              {tabState === "all" && (
                                <h2>
                                  {paymentData.length > 0 ? "New Payments" : ""}
                                </h2>
                              )}
                            </div>
                            <table className="table table-bordered table-striped-rows table-hover">
                              <thead className="table-dark">
                                <tr>
                                  <th>Sr no.</th>
                                  <th>Member ID</th>
                                  <th>Name</th>
                                  <th>Total Amount</th>
                                  <th>Amount Due</th>
                                  <th>Amount Paid</th>
                                  <th>Status</th>
                                  <th>Payment Date</th>
                                  <th>Payment Time</th>
                                </tr>
                              </thead>
                              <tbody>
                                {paymentData.length > 0 &&
                                  paymentData.map((item, i) => (
                                    <tr>
                                      <td>{i + 1}</td>
                                      <td>{item.member_id}</td>
                                      <td>
                                        {item.firstname} {item.lastname}
                                      </td>
                                      <td>{item.amount}</td>
                                      <td>{item.amount_due}</td>
                                      <td>{item.amount_paid}</td>
                                      <td>{item.status}</td>
                                      <td>{item.payment_date}</td>
                                      <td>{item.payment_time}</td>
                                    </tr>
                                  ))}
                              </tbody>
                            </table>
                          </div>
                        )}
                        {(tabState === "missed" || tabState === "all") && (
                          <div>
                            <div className="text-center">
                              {tabState === "all" && (
                                <h2>
                                  {missedData.length > 0 ? "Missed Login" : ""}
                                </h2>
                              )}
                            </div>

                            <table className="table table-bordered table-striped-rows table-hover">
                              <thead className="table-dark">
                                <tr>
                                  <th>Sr no.</th>
                                  <th>Member ID</th>

                                  <th>Name</th>
                                </tr>
                              </thead>
                              <tbody>
                                {missedData.length > 0 &&
                                  missedData.map((item, i) => (
                                    <tr>
                                      <td>{i + 1}</td>
                                      <td>{item.member_id}</td>
                                      <td>
                                        {item.firstname} {item.lastname}
                                      </td>
                                    </tr>
                                  ))}
                              </tbody>
                            </table>
                          </div>
                        )}
                        {(tabState === "expiry" || tabState === "all") && (
                          <div>
                            <div className="text-center">
                              {tabState === "all" && (
                                <h2>
                                  {planData?.length > 0 ? "Plan Expiry" : ""}
                                </h2>
                              )}
                            </div>

                            <table className="table table-bordered table-striped-rows table-hover">
                              <thead className="table-dark">
                                <tr>
                                  <th>Sr no.</th>
                                  <th>Member ID</th>

                                  <th>Name</th>

                                  <th>Days left</th>
                                  <th>Expiry Date</th>
                                </tr>
                              </thead>
                              <tbody>
                                {planData.length > 0 &&
                                  planData.map((item, i) => (
                                    <tr>
                                      <td>{i + 1}</td>
                                      <td>{item.member_id}</td>
                                      <td>
                                        {item.firstname} {item.lastname}
                                      </td>
                                      <td>{item.remaining_days}</td>
                                      <td>{item.enddate}</td>
                                    </tr>
                                  ))}
                              </tbody>
                            </table>
                          </div>
                        )}
                      </div>
                      {/* <Pagination
                                totalPosts={memberList1?.length}
                                postsPerPage={postsPerPage}
                                setCurrentPage={setCurrentPage}
                                currentPage={currentPage}
                            /> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="container pt-2">
                <ul className="list-group noti-ul">
                  {noti?.length > 0 ? (
                   
                    noti?.filter((curVal) => {
                      if (filterText.toLowerCase() === '') {
                        return curVal;
                      }
                      else if (curVal?.title === filterText) {
                        return curVal;
                      }
                    })?.map((curVal, i) => {
                      return (
                        <>
                          <li className={`list-group-item ${readStatus[curVal?.id] ? 'read' : 'unread active'}`} key={i} onClick={() => readNotification(curVal?.id)}>
                            <div className="notifi-item">
                              <div className="noti-content text text-wrap">
                                <h4>
                                  {curVal?.title}
                                </h4>
                                <p>{curVal?.body}</p>
                              </div>
                            </div>
                          </li>
                        </>
                      );
                    })
                  ) : (
                    <div className="notifi-item">
                      <div className="text">
                       
                      </div>
                    </div>
                  )}
                </ul>
              </div> */}
          </section>
        </>
      )}
    </>
  );
}

export default Notification;
